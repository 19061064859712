import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { MetaAddonsType } from '@/hooks/useAddonsMeta';
import { parseDate } from '@/lib/date';
import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { getWorkGroups } from '@/pages/workgroup/api';
import { Space } from '@/pages/workgroup/tab/General';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { DrawerProps } from 'baseui/drawer';
import { FC, useCallback, useState } from 'react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { LoadingIndicator } from '@/components/loading-indicator';
import { AddonsMetaID } from '@/components/addons/consts';
import clsx from 'clsx';
import { getToolsLink } from '@/hooks/useToolsLink';
import { useOrgContext } from '@/contexts/orgContext';
import { useConfig } from '@/lib/config';
import PluginIcon from '@/assets/plugin.svg?react';
import { Link } from 'react-router-dom';
import { Button } from '@tigergraph/app-ui-lib/button';
import LinkIcon from '@/assets/link.svg?react';
import { cn } from '@/lib/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ConfirmResumeWorkspace } from '@/pages/workgroup/tab/SettingMenu';

const EmptyMessage = () => {
  const [css, theme] = useStyletron();
  return (
    <div className="flex flex-col items-center justify-center h-[70%]">
      <PluginIcon className="h-[90px] w-[90px] mb-4" />
      <h4
        className={cn(
          'mb-1 leading-4 text-sm',
          css({
            color: theme.colors['text.secondary'],
          })
        )}
      >
        No available workspaces
      </h4>
      <ul
        className={cn(
          'flex flex-col items-center leading-4 text-xs',
          css({
            color: theme.colors['text.tertiary'],
          })
        )}
      >
        <li>{"You haven't added this Add-On to your workspace yet."}</li>
        <li className="m-auto">
          <Link to="/groups" className={css({ color: theme.colors['text.link'] })}>
            Go to Workspace
          </Link>{' '}
          to manage your add-ons.
        </li>
      </ul>
    </div>
  );
};

const useListAddonsRelatedWorkspaces = (addonsID?: string) => {
  return useQuery(
    ['list', 'workspaces', 'addonsID', addonsID],
    async () => {
      const res = await axiosController.get<Result<WorkspaceT[]>>(`/v2/addons/${addonsID}/workspaces`);
      return res.data.Result;
    },
    {
      enabled: Boolean(addonsID),
    }
  );
};

const useListWorkGroupsMap = () => {
  return useQuery(['groups'], getWorkGroups, {
    select: useCallback(
      (res?: Result<WorkGroupT[]>) => new Map<string, WorkGroupT>(res?.Result?.map((g) => [g.workgroup_id, g])),
      []
    ),
  });
};

const ViewAddonsWorkspaceDrawer: FC<
  {
    selectedAddonsMeta?: MetaAddonsType;
  } & DrawerProps
> = ({ selectedAddonsMeta, ...props }) => {
  const {
    data: workspaces,
    isLoading: isLoadingWorkspaces,
    refetch: refreshWorkspaces,
  } = useListAddonsRelatedWorkspaces(props.isOpen ? selectedAddonsMeta?.ID : undefined);
  const { data: workgroupMap, isLoading: isLoadingWorkGroups } = useListWorkGroupsMap();
  const isLoading = isLoadingWorkGroups || isLoadingWorkspaces;
  const { currentOrg } = useOrgContext();
  const [css, theme] = useStyletron();
  const { appConfig } = useConfig();
  const [isOpenResumeConfirm, setOpenResumeConfirm] = useState(false);
  const [resumeWorkspace, setResumeWorkspace] = useState<WorkspaceT>();

  const canJump = (workspace: WorkspaceT) => {
    if (workspace.status === 'Active' || workspace.status === 'Idle') {
      return true;
    }
    return false;
  };

  const canResume = (workspace: WorkspaceT) => {
    if (workspace.status === 'Stopped') {
      return true;
    }
    return false;
  };

  const getLink = (workspace: WorkspaceT) => {
    if (selectedAddonsMeta?.ID === AddonsMetaID.COPILOT) {
      return `/explore?tab=copilot&workspace_id=${workspace.workspace_id}`;
    }

    const presetToolsLinkPathMap = new Map<string, string>([
      [AddonsMetaID.ADMIN_PORTAL, '/admin'],
      [AddonsMetaID.GRAPH_STUDIO, '/studio'],
      [AddonsMetaID.INSIGHTS, '/insights'],
      [AddonsMetaID.GRAPHQL, '/graphql'],
    ]);
    return getToolsLink(
      appConfig,
      currentOrg,
      workspace,
      presetToolsLinkPathMap.get(selectedAddonsMeta?.ID as string) || ''
    );
  };

  return (
    <Drawer {...props}>
      <DrawerHeader>
        <LinkIcon />
        Connect to {selectedAddonsMeta?.Title || 'Add-ons'}
      </DrawerHeader>
      <DrawerBody>
        {isLoading ? (
          <LoadingIndicator />
        ) : workspaces?.length ? (
          <div className="space-y-2">
            {workspaces?.map((workspace) => {
              const workgroup = workgroupMap?.get(workspace.workgroup_id);
              return workspace ? (
                <div
                  className={clsx(
                    'block [&>div]:!w-full ',
                    canJump(workspace) || canResume(workspace) ? '[&>div]:cursor-pointer' : '[&>div]:cursor-not-allowed'
                  )}
                  key={workspace.workspace_id}
                  onClick={() => {
                    if (canJump(workspace)) {
                      window.open(getLink(workspace), '_blank');
                    } else if (canResume(workspace)) {
                      const wg = workgroupMap?.get(workspace.workgroup_id);
                      const database = wg?.tg_databases.find((i) => i.database_id === workspace.database_id);
                      setResumeWorkspace({
                        ...workspace,
                        workgroup_name: wg?.name as string,
                        database_name: database?.name as string,
                      });
                      setOpenResumeConfirm(true);
                    }
                  }}
                >
                  <Space
                    showRWTag
                    workspace={workspace}
                    workgroup={workgroup}
                    workspaceInfoPlacement="bottom"
                    editable={false}
                    renderConnectionMenu={
                      <span
                        className={cn(
                          'text-xs',
                          css({
                            color: theme.colors['text.secondary'],
                          })
                        )}
                      >
                        Created on {format(parseDate(workspace.created_at), 'yyyy-MM-dd HH:mm:ss')}
                      </span>
                    }
                  />
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <EmptyMessage />
        )}
        {resumeWorkspace && (
          <ConfirmResumeWorkspace
            isOpen={isOpenResumeConfirm}
            onClose={() => {
              setOpenResumeConfirm(false);
              refreshWorkspaces();
            }}
            workspace={resumeWorkspace}
          />
        )}
      </DrawerBody>
      <DrawerAction>
        <Button
          kind="secondary"
          onClick={() =>
            props.onClose?.({
              closeSource: 'closeButton',
            })
          }
        >
          Cancel
        </Button>
      </DrawerAction>
    </Drawer>
  );
};

export default ViewAddonsWorkspaceDrawer;
