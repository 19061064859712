import { Attribute } from '@tigergraph/tools-models';

export type Info = {
  name: string;
  attributes: Attribute[];
};

export const supportedValueTypes: string[] = ['LIST', 'SET'];

export const DefaultAttributeType = 'STRING';

export enum AttributeType {
  INT,
  UINT,
  BOOL,
  FLOAT,
  DOUBLE,
  STRING,
  DATETIME,
  LIST,
  SET,
  MAP,
  UDT,
}
