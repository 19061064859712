import { Parameter } from '@/pages/workgroup/tab/restPP/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { forwardRef } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';

const InfoWithRef = forwardRef((props: any, ref) => {
  const { children, ...restProps } = props;
  return (
    <div ref={ref} {...restProps}>
      {children}
    </div>
  );
});

export default function QueryParamPopover({ queryParams }: { queryParams: Parameter[] }) {
  const [css, theme] = useStyletron();

  return (
    <StatefulPopover
      content={() => (
        <div>
          <div>{'{'}</div>
          {queryParams &&
            queryParams.map((param) => (
              <div
                key={param.name}
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                })}
              >
                <div>
                  &nbsp;&nbsp; &quot;{param.name}&quot;: {param.type}
                </div>
              </div>
            ))}
          <div>{'}'}</div>
        </div>
      )}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.rightTop}
      ignoreBoundary
      showArrow={false}
      overrides={{
        Body: {
          style: {
            backgroundColor: theme.colors.white,
            width: '200px',
          },
        },
        Inner: {
          style: {
            backgroundColor: theme.colors.white,
            color: theme.colors.black,
          },
        },
      }}
    >
      <InfoWithRef>
        <FaRegQuestionCircle color={theme.colors.gray600} />
      </InfoWithRef>
    </StatefulPopover>
  );
}
