import EditIcon from './edit.svg?react';
import SettingIcon from './setting.svg?react';
import DeleteIcon from '@/assets/delete.svg?react';
import { useState } from 'react';
import {
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableHead,
  StyledTableHeadRow,
} from 'baseui/table-semantic';
import UserForm from './UserForm';
import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { useMutationDeleteDBUser, useQueryListDBUser } from '@/pages/admin/user/hook';
import { WorkspaceT, isStatusActive } from '@/pages/workgroup/type';
import { DBUser } from '@/pages/admin/user/type';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';
import { StyledTableBodyCell, StyledTableHeadCell } from '@/components/StyleTable';
import { Empty } from '@/pages/workgroup/tab/iam/db_user/components';
import AssignRoles from '@/pages/workgroup/tab/iam/db_user/AssignRoles';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';

function UserTableItem({ dbUser, workspace }: { dbUser: DBUser; workspace: WorkspaceT }) {
  const { username } = dbUser;
  const [editVisible, setEditVisible] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const deleteUser = useMutationDeleteDBUser();
  const [css, theme] = useStyletron();

  const handleEdit = () => {
    setEditVisible(true);
  };

  const handleSetting = () => {
    setShowSetting(true);
  };

  const onDelete = async () => {
    deleteUser.mutate(
      {
        groupID: workspace.workgroup_id,
        spaceID: workspace.workspace_id,
        username: dbUser.username,
      },
      {
        onSuccess: () => {
          showToast({
            kind: 'positive',
            message: 'The user has been successfully deleted.',
          });
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: getErrorMessage(error),
          });
        },
      }
    );
  };

  return (
    <>
      <StyledTableBodyRow className="group">
        <StyledTableBodyCell>{username}</StyledTableBodyCell>
        <StyledTableBodyCell>*******************</StyledTableBodyCell>
        <StyledTableBodyCell>
          <div className="opacity-0 group-hover:opacity-100">
            <div className="flex space-x-1">
              <Button size="compact" kind="text" shape="square" disabled={!isStatusActive(workspace.status)}>
                <SettingIcon onClick={handleSetting} />
              </Button>
              <Button size="compact" kind="text" shape="square">
                <EditIcon onClick={handleEdit} />
              </Button>
              <ConfirmStatefulPopover confirmLabel="Confirm to delete" onConfirm={onDelete}>
                <Button size="compact" kind="text" shape="square" onClick={onDelete}>
                  <DeleteIcon />
                </Button>
              </ConfirmStatefulPopover>
            </div>
          </div>
        </StyledTableBodyCell>
      </StyledTableBodyRow>
      {editVisible ? (
        <tr>
          <td
            colSpan={3}
            className={css({
              background: theme.colors['background.secondary'],
              ':hover': {
                background: theme.colors['background.secondary'],
              },
            })}
          >
            <UserForm dbUser={dbUser} workspace={workspace} onCancel={() => setEditVisible(false)} />
          </td>
        </tr>
      ) : null}
      {showSetting ? (
        <AssignRoles name={username} isOpen={showSetting} onClose={() => setShowSetting(false)} workspace={workspace} />
      ) : null}
    </>
  );
}

export default function UserTable({
  showInsertForm,
  onCancel,
  workspace,
}: {
  showInsertForm: boolean;
  onCancel: () => void;
  workspace: WorkspaceT;
}) {
  const [css, theme] = useStyletron();
  const { data, error, isFetching } = useQueryListDBUser(workspace.workgroup_id, workspace.workspace_id);
  const showEmpty = !data || (data.length <= 0 && !showInsertForm);

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {error ? <ErrorDisplay error={error} /> : null}
      <StyledRoot>
        <StyledTable>
          <StyledTableHead>
            <StyledTableHeadRow>
              <StyledTableHeadCell className="w-[200px]">Name</StyledTableHeadCell>
              <StyledTableHeadCell>Password</StyledTableHeadCell>
              <StyledTableHeadCell className="w-[50px]" />
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {showInsertForm && (
              <StyledTableBodyRow>
                <td
                  colSpan={3}
                  className={css({
                    background: theme.colors['background.secondary'],
                    ':hover': {
                      background: theme.colors['background.secondary'],
                    },
                  })}
                >
                  <UserForm onCancel={onCancel} workspace={workspace} />
                </td>
              </StyledTableBodyRow>
            )}
            {data?.map((i) => (
              <UserTableItem key={i.username} dbUser={i} workspace={workspace} />
            ))}
          </StyledTableBody>
        </StyledTable>
        {showEmpty && <Empty title="No database users" description="Click + button to create database users" />}
      </StyledRoot>
    </>
  );
}
