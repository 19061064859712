import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { SVGProps } from 'react';

export const HelpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667Zm0 15A6.676 6.676 0 0 1 3.333 10 6.676 6.676 0 0 1 10 3.333 6.676 6.676 0 0 1 16.666 10 6.676 6.676 0 0 1 10 16.666Zm-.834-3.334h1.667V15H9.166v-1.667Zm1.342-8.3a3.335 3.335 0 0 0-3.691 2.325.756.756 0 0 0 .724.975h.167c.342 0 .617-.242.733-.558a1.668 1.668 0 0 1 1.917-1.067c.792.167 1.375.942 1.308 1.75-.083 1.117-1.35 1.358-2.041 2.4 0 .008-.008.008-.008.017-.009.017-.017.025-.026.041-.074.125-.15.267-.208.417-.008.025-.025.042-.033.067-.008.016-.008.033-.017.058-.1.284-.167.625-.167 1.042h1.667c0-.35.092-.642.233-.892.017-.025.025-.05.042-.075.067-.117.15-.225.233-.325.009-.008.017-.025.026-.033.083-.1.174-.192.274-.284.8-.758 1.884-1.374 1.659-2.966-.2-1.45-1.342-2.675-2.792-2.892Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const AccessBlocked = (props: SVGProps<SVGSVGElement>) => {
  const [, theme] = useStyletron();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill={theme.colors['modal.icon']}
        fillRule="evenodd"
        d="M2.793 2.98 7.46.907c.34-.154.74-.154 1.08 0l4.667 2.073c.48.213.793.693.793 1.22v3.133c0 .438-.036.871-.104 1.298a4.651 4.651 0 0 0-3.23-1.298c-.874 0-1.693.241-2.393.66H8V2.127L3.333 4.2V8H8v.17A4.661 4.661 0 0 0 6 12c0 1.174.433 2.246 1.148 3.066C4.146 13.907 2 10.721 2 7.333V4.2c0-.527.313-1.007.793-1.22Z"
        clipRule="evenodd"
      />
      <g clipPath="url(#a)">
        <path
          fill={theme.colors['icon.danger']}
          stroke={theme.colors['icon.danger']}
          strokeWidth={0.5}
          d="M10.667 8.667a3.335 3.335 0 0 0 0 6.666A3.335 3.335 0 0 0 14 12a3.335 3.335 0 0 0-3.333-3.333Zm0 6A2.666 2.666 0 0 1 8 12c0-.617.21-1.183.563-1.633l3.737 3.736a2.634 2.634 0 0 1-1.633.564Zm2.103-1.034L9.034 9.897a2.634 2.634 0 0 1 1.633-.564A2.666 2.666 0 0 1 13.334 12c0 .617-.21 1.183-.564 1.633Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M6.667 8h8v8h-8z" />
        </clipPath>
      </defs>
    </svg>
  );
};
