import { styled } from '@tigergraph/app-ui-lib/Theme';

const LinkButton = styled('button', ({ $theme }) => {
  return {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: 0,
    color: $theme.colors.secondary700,
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: $theme.colors.gray50,
    },
    ':active': {
      backgroundColor: $theme.colors.gray200,
    },
    ':focus-visible': {
      outline: `2px solid ${$theme.colors.borderSelected}`,
    },
    ':disabled': {
      cursor: 'not-allowed',
      color: $theme.colors.secondary600,
    },
  };
});

export default LinkButton;
