import { Drawer } from '@/components/Drawer';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { CLOSE_SOURCE } from 'baseui/drawer';
import { ReactNode } from 'react';

export interface SidebarProps {
  isOpen: boolean;
  onClose?: (args: { closeSource?: (typeof CLOSE_SOURCE)[keyof typeof CLOSE_SOURCE] }) => void;
  isDrawer?: boolean;
  children?: ReactNode;
  width?: string;
  backgroundColor?: string;
}

export function Sidebar({ isDrawer = true, isOpen, onClose, children, width, backgroundColor }: SidebarProps) {
  const [css, theme] = useStyletron();

  return (
    <>
      {isDrawer ? (
        <Drawer isOpen={isOpen} onClose={onClose}>
          {children}
        </Drawer>
      ) : (
        <div
          className={css({
            width: isOpen ? width || '600px' : '0',
            height: '100%',
            position: 'absolute',
            top: '0',
            right: '0',
            transition: 'width 0.3s ease',
            overflowY: 'hidden',
            backgroundColor: backgroundColor ?? 'transparent',
          })}
        >
          {children}
        </div>
      )}
    </>
  );
}
