import { Attribute } from '@tigergraph/tools-models/topology';
import { EditableInput } from '@/components/editableInput';
import { AttributeTypeSelect } from '@/components/schemaDesigner/AttributeTypeSelect';
import VertexAttributeKey from '@/components/schemaDesigner/attributeEditor/VertexAttributeKey';
import { AttributeValueColumn } from '@/components/schemaDesigner/AttributeValueColumn';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { StyleObject } from 'styletron-standard';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import EdgeAttributeKey from '@/components/schemaDesigner/attributeEditor/EdgeAttributeKey';
import { MdVpnKey } from 'react-icons/md';
import { Tag } from '@tigergraph/app-ui-lib/tag';

export interface AttributeEditorRowProps {
  css: (arg: StyleObject) => string;
  theme: CustomTheme;
  attribute: Attribute;
  isPrimaryId: boolean;
  deleting: boolean;
  isVertex: boolean;
  readOnly?: boolean;
  deletable?: boolean;
  attributeNameLabel?: string;
  handleAttributeChange: (attribute: Attribute) => void;
  handleDeleteSelection: () => void;
}

export function buildAttributeEditorRow(props: AttributeEditorRowProps) {
  const {
    css,
    theme,
    attribute,
    isPrimaryId,
    deleting,
    isVertex,
    readOnly,
    deletable = true,
    attributeNameLabel,
    handleAttributeChange,
    handleDeleteSelection,
  } = props;

  const handleAttributeNameChange = (name: string) => {
    const newAttribute = attribute.clone();
    newAttribute.name = name;
    handleAttributeChange(newAttribute);
  };

  const row = [
    <div
      key={`delete_checkbox_${attribute.name}`}
      className={css({
        display: 'flex',
        alignItems: 'center',
        lineHeight: '35px',
        height: '35px',
      })}
    >
      <Checkbox checked={deleting} disabled={readOnly || !deletable} onChange={() => handleDeleteSelection()} />
    </div>,
    <div
      key={`Attribute_name_${attribute.name}`}
      className={css({
        minWidth: '100px',
        display: 'flex',
        height: '32px',
        lineHeight: '32px',
        alignItems: 'center',
        columnGap: '4px',
      })}
    >
      {attributeNameLabel && <Tag closeable={false}>{attributeNameLabel}</Tag>}
      <EditableInput
        value={attribute.name}
        placeholder="Attribute name"
        readOnly={readOnly}
        onChange={(name) => handleAttributeNameChange(name.trim())}
      />
    </div>,
    <div
      key={`Type_${attribute.name}`}
      className={css({
        minWidth: '100px',
      })}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <AttributeTypeSelect
        attribute={attribute}
        isPrimaryId={isPrimaryId}
        readOnly={readOnly}
        onChange={(attr) => handleAttributeChange(attr)}
      />
    </div>,
  ];
  if (isVertex) {
    row.push(
      <div
        key={`Key_${attribute.name}`}
        className={css({
          display: 'flex',
          justifyContent: 'center',
        })}
      >
        <VertexAttributeKey
          attribute={attribute}
          isPrimaryId={isPrimaryId}
          readOnly={readOnly}
          primaryKeyOnly
          onChangeKey={(attr) => handleAttributeChange(attr)}
        />
      </div>
    );
  }
  row.push(
    <div
      key={`Default_value_${attribute.name}`}
      className={css({
        minWidth: '100px',
      })}
    >
      <AttributeValueColumn
        attribute={attribute}
        isPrimaryId={isPrimaryId}
        readOnly={readOnly}
        onChange={(attr) => handleAttributeChange(attr)}
      />
    </div>
  );

  return row;
}

export interface AttributeKeyTableRowProps {
  css: (arg: StyleObject) => string;
  theme: CustomTheme;
  attribute: Attribute;
  isPrimaryId: boolean;
  readOnly?: boolean;
  isVertex?: boolean;
  handleAttributeChange: (attribute: Attribute) => void;
}

export function buildAttributeKeyTableRow(props: AttributeKeyTableRowProps) {
  const { css, theme, attribute, isPrimaryId, readOnly, isVertex = true, handleAttributeChange } = props;

  return [
    <div
      key={`Attribute_name_${attribute.name}`}
      className={css({
        minWidth: '100px',
        display: 'flex',
        height: '32px',
        lineHeight: '32px',
        alignItems: 'center',
        columnGap: '8px',
      })}
    >
      <span
        className={css({
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          fontFamily: 'Roboto',
        })}
      >
        {attribute.name}
      </span>
      {isPrimaryId && <MdVpnKey color={theme.colors['table.icon']} size={16} />}
    </div>,
    <div key={`Key_${attribute.name}`}>
      {isVertex ? (
        <VertexAttributeKey
          attribute={attribute}
          isPrimaryId={isPrimaryId}
          hidePrimaryKey
          readOnly={readOnly}
          onChangeKey={(attr) => handleAttributeChange(attr)}
        />
      ) : (
        <EdgeAttributeKey
          attribute={attribute}
          readOnly={readOnly}
          onChangeKey={(attr) => handleAttributeChange(attr)}
        />
      )}
    </div>,
  ];
}
