import { styled } from '@tigergraph/app-ui-lib/Theme';

export const Container = styled('div', ({ $theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: $theme.colors['background.primary'],
  };
});

export const ChildPage = styled('div', {
  flexBasis: 0,
  flexGrow: 1,
});

export const Form = styled('form', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const FormContentContainer = styled('div', {
  display: 'flex',
  flexBasis: 0,
  flexGrow: 1,
  overflowY: 'auto',
});

export const FormContent = styled('div', {
  margin: '0 auto',
  maxWidth: '1200px',
  padding: '16px',
});

export const FormControllerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingBottom: '24px',
});

export const Desc = styled('div', ({ $theme }) => ({
  ...$theme.typography.Label,
  color: $theme.colors['contentSecondary'],
  marginTop: '-8px',
}));
