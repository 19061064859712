import * as React from "react";
const SvgProvideDatabase = (props) => /* @__PURE__ */ React.createElement("svg", { width: 136, height: 139, viewBox: "0 0 136 139", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M48.8892 111.628C49.9252 110.592 51.0187 108.894 54.357 108.894H92.5453C95.567 108.894 112.229 92.1743 114.359 89.8146C116.402 87.5411 125.467 73.9004 128 68.0009C126.417 65.8713 122.129 64.0871 117.093 65.267C112.258 66.3893 109.438 69.8427 106.186 76.1738L93.6964 82.6776", stroke: "#F4B51A", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M65.2655 92.5216H87.0791C97.986 92.5216 97.986 78.8809 87.0791 78.8809H72.6614C70.3879 78.8809 66.1288 73.4131 62.5316 73.4131H48.8908C45.2072 73.4131 40.6316 76.406 37.984 78.8809C33.8975 82.9674 27.0771 89.7877 27.0771 89.7877", stroke: "#F4B51A", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M21.6339 84.3877L8 98.0216L40.7213 130.743L54.3552 117.109L21.6339 84.3877Z", stroke: "#EE9240", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M21.6372 97.9874L27.0762 103.455", stroke: "#EE9240", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M48.8892 73.441V8H108.891V70.7071", stroke: "#EE9240", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M48.8892 32.5459H108.891", stroke: "#EE9240", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M48.8892 57.0967H108.891", stroke: "#EE9240", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M57.0928 21.6445H65.2657", stroke: "#EE9240", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M57.0928 46.1904H65.2657", stroke: "#EE9240", strokeWidth: 8, strokeLinecap: "round", strokeLinejoin: "round" })));
export default SvgProvideDatabase;
