import GCPIcon from '@/assets/gcp.svg';
import AzureIcon from '@/assets/azure.svg';
import ByocIcon from '@/assets/byoc-label.svg';
import { AWS, ByocSelected } from '@/pages/home/icons';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { ReactNode } from 'react';

export enum CloudPlatform {
  GCP = 'GCP',
  AWS = 'AWS',
  Azure = 'Azure',
}

interface Props extends Partial<CloudProviderT> {
  selected: boolean;
  disabled: boolean;
  onSelect?: () => void;
}

export default function CloudProviderCategory({ selected, disabled, onSelect, ...cloudProvider }: Props) {
  const [css, theme] = useStyletron();
  const platform = cloudProvider.platform as CloudPlatform;
  const categoryMap: Record<CloudPlatform, { name: string; icon: ReactNode | string }> = {
    GCP: {
      name: 'GCP',
      icon: GCPIcon,
    },
    AWS: {
      name: 'AWS',
      icon: <AWS />,
    },
    Azure: {
      name: 'Azure',
      icon: AzureIcon,
    },
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
        width: '201px',
        height: '84px',
        boxSizing: 'border-box',
        borderWidth: '2px',
        borderColor: selected ? theme.colors['card.border.selected'] : theme.colors['card.border'],
        position: 'relative',
      })}
      onClick={() => {
        if (!disabled) {
          onSelect?.();
        }
      }}
    >
      {selected && (
        <div className={css({ position: 'absolute', top: '-1px', right: '-2px' })}>
          <ByocSelected />
        </div>
      )}
      {cloudProvider.type !== 'public' && (
        <img src={ByocIcon} alt="byoc" className={css({ position: 'absolute', top: '0', left: '0' })} />
      )}
      {typeof categoryMap[platform].icon !== 'string' ? (
        categoryMap[platform].icon
      ) : (
        <img src={categoryMap[platform].icon as string} alt={categoryMap[platform].name} />
      )}
      <div
        className={css({
          fontSize: '12px',
          fontWeight: 700,
          lineHeight: '16px',
          textAlign: 'center',
          marginTop: '8px',
          color: theme.colors['card.text.primary'],
        })}
      >
        {cloudProvider.name || categoryMap[platform].name}
      </div>
    </div>
  );
}
