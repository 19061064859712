export interface ServerSourcedEvent {
  event: 'message' | 'error';
  data: string;
}

// Payload split by `\n\n`
async function* streamToPayload(stream: ReadableStream<Uint8Array> | null): AsyncIterableIterator<string> {
  if (stream) {
    let currentPart = '';
    for await (const chunkBytes of toAsyncIterable(stream)) {
      const chunk = new TextDecoder().decode(chunkBytes);
      currentPart += chunk;
      while (true) {
        const index = currentPart.indexOf('\n\n');
        if (index !== -1) {
          const payload = currentPart.slice(0, index);
          if (payload !== '') {
            yield payload;
          }
          currentPart = currentPart.slice(index + 2);
        } else {
          break;
        }
      }
    }
  }
  return undefined;
}

// Event split by `\n`
async function* payloadToEvent(payloads: AsyncIterableIterator<string>): AsyncIterableIterator<ServerSourcedEvent> {
  for await (const payload of payloads) {
    const fields = payload.split('\n');
    const result: ServerSourcedEvent = {
      event: 'message',
      data: '',
    };
    for (const field of fields) {
      const keyValues = field.split(':');
      const k = keyValues[0];
      const value = keyValues.slice(1).join(':');
      if (k === 'data') {
        if (result[k] === undefined) {
          result[k] = value;
        } else {
          result[k] += value;
        }
      } else if (k === 'event') {
        if (value === 'message' || value === 'error') {
          result[k] = value;
        } else {
          console.log(result);
          throw 'unexpected event type';
        }
      } else {
        console.log(result);
        throw 'unreachable';
      }
    }
    yield result;
  }
}

export async function* eventStream(
  stream: ReadableStream<Uint8Array> | null
): AsyncIterableIterator<ServerSourcedEvent> {
  yield* payloadToEvent(streamToPayload(stream));
}

async function* toAsyncIterable<T>(stream: ReadableStream<T>): AsyncIterable<T> {
  const reader = stream.getReader();
  while (true) {
    const result = await reader.read();
    if (!result.done) {
      yield result.value;
    } else {
      return result.value;
    }
  }
}
