import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import {
  Bs1CircleFill,
  Bs2CircleFill,
  Bs3CircleFill,
  Bs4CircleFill,
  Bs5CircleFill,
  Bs6CircleFill,
  Bs7CircleFill,
  Bs8CircleFill,
  Bs9CircleFill,
  BsCheckCircleFill,
} from 'react-icons/bs';

export interface LoadDataProps {
  names: string[];
  index: number;
  compact?: boolean;
}

export default function Breadcrumbs(props: LoadDataProps) {
  const [css, theme] = useStyletron();
  let { names, index, compact } = props;

  return (
    <div
      className={css({
        display: 'flex',
        fontFamily: 'Urbanist',
        fontSize: compact ? '14px' : '16px',
        fontWeight: 600,
        overflow: 'hidden',
        borderTop: `1px solid ${theme.colors.divider}`,
        borderBottom: `1px solid ${theme.colors.divider}`,
        background:
          index === 0
            ? `linear-gradient(to right, ${theme.colors['step.background.active']} 50%, ${theme.colors['step.background.primary']} 50%)`
            : index === names.length - 1
            ? `linear-gradient(to left, ${theme.colors['step.background.active']} 50%, ${theme.colors['step.background.primary']} 50%)`
            : theme.colors['step.background.primary'],
      })}
    >
      {names.map((name, i) => (
        <div
          key={name + i}
          className={css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            flexGrow: 1,
            height: '40px',
          })}
        >
          <div
            className={css({
              width: '100%',
              height: '50%',
              transform: 'skew(45deg, 0deg)',
              borderRight: i === names.length - 1 ? 'none' : `1px solid ${theme.colors['step.border']}`,
              backgroundColor:
                i === index ? theme.colors['step.background.active'] : theme.colors['step.background.primary'],
              position: 'absolute',
              left: 0,
              top: 0,
            })}
          />
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              columnGap: '8px',
              color: i <= index ? theme.colors['step.text'] : theme.colors['step.text.disabled'],
              fontWeight: i < index ? 600 : i === index ? 700 : 400,
              position: 'relative',
              zIndex: 1,
            })}
          >
            {i < index && !compact && <BsCheckCircleFill />}
            {i >= index && !compact && i === 0 && <Bs1CircleFill />}
            {i >= index && !compact && i === 1 && <Bs2CircleFill />}
            {i >= index && !compact && i === 2 && <Bs3CircleFill />}
            {i >= index && !compact && i === 3 && <Bs4CircleFill />}
            {i >= index && !compact && i === 4 && <Bs5CircleFill />}
            {i >= index && !compact && i === 5 && <Bs6CircleFill />}
            {i >= index && !compact && i === 6 && <Bs7CircleFill />}
            {i >= index && !compact && i === 7 && <Bs8CircleFill />}
            {i >= index && !compact && i === 8 && <Bs9CircleFill />}
            {name}
          </div>
          <div
            className={css({
              width: '100%',
              height: '50%',
              transform: 'skew(-45deg, 0deg)',
              borderRight: i === names.length - 1 ? 'none' : `1px solid ${theme.colors['step.border']}`,
              backgroundColor:
                i === index ? theme.colors['step.background.active'] : theme.colors['step.background.primary'],
              position: 'absolute',
              bottom: 0,
              left: 0,
            })}
          />
        </div>
      ))}
    </div>
  );
}
