import { Option } from 'baseui/select';

type IFormField = {
  question: string;
  name: string;
  inputType: 'rating' | 'select' | 'input' | 'textarea';
  defaultValue?: string;
  required?: boolean;
  selectOptions?: Option[];
};

export type IFormInput = { [key: string]: string };

export const normalFormFields: IFormField[] = [
  {
    question: 'How satisfied are you with our product?',
    name: 'rating',
    inputType: 'rating',
    required: true,
  },
  {
    question: 'Suggestions/Comments:',
    name: 'message',
    inputType: 'textarea',
    required: true,
  },
];

export const copilotFormFields: IFormField[] = [
  {
    question: 'What is your job title?',
    name: 'jobTitle',
    inputType: 'input',
  },
  {
    question: 'How many people on your team tried the CoPilot Alpha Release?',
    name: 'teamSize',
    inputType: 'select',
    required: true,
    selectOptions: [
      { id: '1-5', label: '1 - 5' },
      { id: '5-10', label: '5 - 10' },
      { id: '10+', label: '10+' },
    ],
    defaultValue: '1-5',
  },
  {
    question: 'What is your overall user experience with CoPilot?',
    name: 'overallExperience',
    inputType: 'rating',
  },
  {
    question:
      'Among the current features of CoPilot, which ones have proven to be particularly valuable or effective in meeting your needs?',
    name: 'valuableFeatures',
    inputType: 'textarea',
  },
  {
    question:
      'If there were any challenges or issues, could you provide details on the issues and any suggestions for improvement?',
    name: 'issues',
    inputType: 'textarea',
  },
  {
    question:
      'Considering your experience with the alpha version, how likely are you going to continue using CoPilot in its future releases?',
    name: 'continueUsage',
    inputType: 'rating',
  },
  {
    question: 'If you found it not not worth continuing , what factors influenced your decision to discontinue usage?',
    name: 'discontinueFactors',
    inputType: 'textarea',
  },
  {
    question:
      'Looking ahead to the General Availability (GA) launch, what additional features or enhancements would you like to see incorporated into CoPilot to better address your requirements or improve your overall experience?',
    name: 'additionalFeatures',
    inputType: 'textarea',
  },
  {
    question: 'What specific tasks or activities are you using CoPilot for? Could you share your primary use cases?',
    name: 'primaryUseCases',
    inputType: 'textarea',
  },
  {
    question:
      'What other similar tools or solutions have you used in the past, and how does CoPilot compare to them in terms of usability, features, and overall satisfaction?',
    name: 'comparison',
    inputType: 'textarea',
  },
  {
    question: 'Do you have any other comments, questions, concerns?',
    name: 'comments',
    inputType: 'textarea',
  },
];
