import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { Return } from '@/lib/type';
import { getDescription } from '@tigergraph/tools-models';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

type ListQueriesResponse = Return<{
  queries: { queryName: string; description: string }[];
}>;

export const useListQueries = (nginxHost: string, tgVersion: string, graphName?: string) => {
  return useQuery({
    queryKey: ['list', 'queries', nginxHost, graphName],
    queryFn: async () => {
      const res = (await getDescription(
        { graph: graphName as string },
        {
          baseURL: `https://${nginxHost}`,
          version: tgVersion,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          },
        }
      )) as AxiosResponse<ListQueriesResponse>;

      const queries = res.data.results?.queries;

      return queries?.filter((q) => 'description' in q);
    },
    enabled: Boolean(nginxHost && graphName),
  });
};
