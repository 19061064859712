// 2024-04-10 11:19:19.65042 +0000 UTC
export function parseDate(str: string) {
  if (!str) {
    return new Date(0);
  }
  const date = new Date(str);
  if (isFinite(date.getTime())) {
    return date;
  }
  // to support safari
  str = str.slice(0, str.indexOf('.'));

  const [s1, s2] = str.split(' ');
  const [year, month, day] = s1.split('-').map(Number);
  const [hour, minute, second] = s2.split(':').map(Number);

  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
}

// refer: https://gist.github.com/mcraz/11349449
export function timeSince(date: Date) {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + ' years';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' months';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}
