import { Result } from '@/lib/type';
import EmptyState from '@/pages/workgroup/EmptyState';
import { getWorkGroups } from '@/pages/workgroup/api';
import { WorkGroupT } from '@/pages/workgroup/type';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

export default function Groups() {
  const navigate = useNavigate();

  const groupsQuery = useQuery<Result<WorkGroupT[]>>(['groups'], getWorkGroups);

  useEffect(() => {
    // redirect to first group on mount
    if (groupsQuery.data?.Result?.length) {
      navigate(`/groups/${groupsQuery.data?.Result?.[0].workgroup_id}`, { replace: true });
    }
  }, [navigate, groupsQuery.data]);

  if (groupsQuery.data?.Result?.length === 0) {
    return <EmptyState useGroupIcon={true} />;
  }

  return null;
}
