import { useTheme } from '@/contexts/themeContext';
// import { xcodeDarkInit, xcodeLightInit } from '@uiw/codemirror-theme-xcode';
import { vscodeDarkInit, vscodeLightInit } from '@uiw/codemirror-theme-vscode';
import { useMemo } from 'react';

export default function useEditorTheme(settings?: { background: string }) {
  const { themeType } = useTheme();
  const theme = useMemo(() => {
    if (themeType === 'dark') {
      return vscodeDarkInit({
        settings: {
          gutterBorder: 'transparent',
          gutterBackground: 'transparent',
          background: settings?.background || 'transparent',
        },
      });
    }

    return vscodeLightInit({
      settings: {
        gutterBorder: 'transparent',
        gutterBackground: 'transparent',
        background: settings?.background || 'transparent',
      },
    });
  }, [settings?.background, themeType]);

  return theme;
}
