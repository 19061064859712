import { PhotoLibrary } from '@/pages/home/icons';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { TRIGGER_TYPE, PLACEMENT } from 'baseui/popover';
import { expand } from 'inline-style-expand-shorthand';
import { SelectIconContainer } from '@tigergraph/tools-ui/insights/components/selectIcon';
import { useEffect, useState } from 'react';
import { getInstanceAxios } from '@tigergraph/tools-ui/insights/api/ajax';
import { getUserUploadedIconPathPrefix, uploadedIconPath } from '@tigergraph/tools-ui/insights/utils/path';
import { iconMap } from '@tigergraph/tools-ui/graph/icons/iconMap';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useTheme } from '@/contexts/themeContext';

export interface IconSelectorProps {
  onSelect: (iconName: string) => void;
  value?: string;
  readOnly?: boolean;
  mountNode?: HTMLElement;
}

export function IconSelector({ onSelect, value, mountNode, readOnly }: IconSelectorProps) {
  const [css, theme] = useStyletron();
  const [iconUrl, setIconUrl] = useState<string>('');
  const { themeType } = useTheme();

  useEffect(() => {
    const userUploadedIconPathPrefix = getUserUploadedIconPathPrefix(true);
    const imgUrl =
      value && iconMap[value]
        ? `${getInstanceAxios()?.defaults?.baseURL || ''}/studio/assets/gvis/icons/builtin/${iconMap[value]}`
        : userUploadedIconPathPrefix && value
        ? `${userUploadedIconPathPrefix}/${value}`
        : '';
    setIconUrl(imgUrl || '');
  }, [value]);

  return (
    <StatefulPopover
      showArrow={false}
      mountNode={mountNode}
      triggerType={TRIGGER_TYPE.click}
      placement={PLACEMENT.leftTop}
      overrides={{
        Body: {
          style: {
            padding: '8px',
            maxWidth: '370px',
            maxHeight: '250px',
            backgroundColor: theme.colors['background.primary'],
            ...expand({
              borderRadius: '2px',
              border: `1px solid ${theme.colors.divider}`,
            }),
          },
        },
        Inner: {
          style: {
            ...expand({
              padding: '8px',
            }),
            backgroundColor: theme.colors['background.primary'],
          },
        },
      }}
      content={({ close }) => {
        return (
          <SelectIconContainer
            iconURL={iconUrl}
            onIconSelected={(url, name) => {
              setIconUrl(url);
              onSelect(name!);
              close();
            }}
            themeType={themeType}
            isCloud={true}
          />
        );
      }}
    >
      <div>
        <StatefulPopover
          triggerType={TRIGGER_TYPE.hover}
          content={'Select icon'}
          overrides={{
            Body: {
              style: {
                ...expand({
                  margin: '10px',
                }),
              },
            },
          }}
        >
          <Button
            kind="secondary"
            disabled={readOnly}
            overrides={{
              BaseButton: {
                style: {
                  width: '32px',
                  height: '32px',
                  ...expand({
                    padding: '2px',
                  }),
                },
              },
            }}
          >
            {iconUrl ? (
              <img
                src={iconUrl.includes('https') ? iconUrl : uploadedIconPath(iconUrl, true)}
                style={{
                  filter: themeType === 'light' && value && !!iconMap[value] ? 'invert(1)' : 'none',
                }}
              />
            ) : (
              <PhotoLibrary />
            )}
          </Button>
        </StatefulPopover>
      </div>
    </StatefulPopover>
  );
}
