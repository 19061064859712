import { Select } from '@tigergraph/app-ui-lib/select';
import { OnChangeParams } from 'baseui/select';
import {
  Attribute,
  deprecatedPrimaryIdTypes,
  supportedAttributeTypes,
  supportedDiscriminatorAttributeTypes,
  supportedPrimaryIdTypes,
} from '@tigergraph/tools-models';
import { expand } from 'inline-style-expand-shorthand';

export interface AttributeTypeSelectProps {
  attribute: Attribute;
  isPrimaryId: boolean;
  readOnly?: boolean;
  onChange: (attr: Attribute) => void;
}

export function AttributeTypeSelect({ attribute, isPrimaryId, readOnly, onChange }: AttributeTypeSelectProps) {
  let options = [];

  const attrOptions = supportedAttributeTypes
    .filter((type) => type !== 'STRING COMPRESS')
    .map((type) => ({ id: type, label: type }));

  const primaryIdTypeOptions = supportedPrimaryIdTypes
    .filter((type) => !deprecatedPrimaryIdTypes.includes(type))
    .map((type) => ({
      id: type,
      label: type,
    }));

  const discriminatorTypeOptions = supportedDiscriminatorAttributeTypes.map((type) => ({
    id: type,
    label: type,
  }));

  if (isPrimaryId) {
    options = primaryIdTypeOptions;
  } else if (attribute.isDiscriminator) {
    options = discriminatorTypeOptions;
  } else {
    options = attrOptions;
  }

  const handleChange = ({ value }: OnChangeParams) => {
    if (value.length > 0) {
      const typeName = value[0].id as string;
      const newAttribute = attribute.clone();
      newAttribute.type.name = typeName;
      if (newAttribute.type.name === 'MAP') {
        newAttribute.type.hasKeyTypeName = newAttribute.type.keyTypeName !== '';
      } else {
        newAttribute.type.hasKeyTypeName = false;
        newAttribute.type.keyTypeName = '';
      }
      if (['MAP', 'SET', 'LIST'].includes(newAttribute.type.name)) {
        newAttribute.type.hasValueTypeName = newAttribute.type.valueTypeName !== '';
        newAttribute.type.hasValueTypeTupleName = newAttribute.type.valueTypeTupleName !== '';
      } else {
        newAttribute.type.hasValueTypeName = false;
        newAttribute.type.valueTypeName = '';
      }
      if (['LIST', 'SET', 'MAP', 'UDT'].includes(newAttribute.type.name)) {
        newAttribute.defaultValue = '';
        newAttribute.hasDefaultValue = false;
      }
      onChange(newAttribute);
    }
  };

  return (
    <Select
      disabled={readOnly}
      maxDropdownHeight={'230px'}
      size="compact"
      value={[{ id: attribute.type.name, label: attribute.type.name }]}
      overrides={{
        Root: {
          style: {
            height: '32px',
            backgroundColor: 'inherit',
            fontSize: '12px',
          },
        },
        ControlContainer: {
          style: ({ $isFocused }) => ({
            backgroundColor: 'inherit',
            height: '32px',
            ...expand({
              border: $isFocused ? '1px' : '0',
            }),
          }),
        },
        ValueContainer: {
          style: {
            paddingLeft: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
            fontSize: '12px',
          },
        },
        SingleValue: {
          style: {
            lineHeight: '30px',
          },
        },
        IconsContainer: {
          style: {
            paddingRight: '0px',
            paddingLeft: '0px',
          },
        },
        Popover: {
          props: {
            ignoreBoundary: true,
          },
        },
        DropdownOption: {
          props: {
            style: {
              fontSize: '12px',
            },
          },
        },
      }}
      onChange={handleChange}
      options={options}
      clearable={false}
    />
  );
}
