import { showToast } from '@/components/styledToasterContainer';
import { getApplicationDetail } from '@/insights/cloud/api';
import { ApplicationT } from '@/insights/cloud/type';
import { Result } from '@/lib/type';
import { quickInsightsAtom } from '@/pages/workgroup/atom';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const pollingInterval = 3 * 1000;

export function QuickInsightsPoll() {
  const [quickInsights] = useAtom(quickInsightsAtom);

  return (
    <div>
      <div>
        {quickInsights.map((application) => (
          <QuickInsightPoll application={application} key={application.id} />
        ))}
      </div>
    </div>
  );
}

function QuickInsightPoll({ application }: { application: ApplicationT }) {
  const [, setQuickInsights] = useAtom(quickInsightsAtom);
  const queryClient = useQueryClient();
  const navigator = useNavigate();
  const [css] = useStyletron();

  useQuery<Result<ApplicationT>, AxiosError>(
    ['application', application.id],
    async () => {
      return getApplicationDetail(application.workgroup_id, application.workspace_id, application.id);
    },
    {
      initialData: {
        Error: false,
        Result: application,
      },
      onError: () => {
        // when error, remove it from the polling list
        setQuickInsights((oldData) => {
          return oldData.filter((item) => item.id !== application.id);
        });
      },
      onSuccess: (data) => {
        if (data?.Result) {
          const quickInsight = data?.Result;
          let allDone = true;

          if (quickInsight.cache_status === 'pending') {
            allDone = false;
          }

          if (!allDone) {
            return;
          }

          // remove the workspace from the polling list
          setQuickInsights((oldData) => {
            return oldData.filter((item) => item.id !== application.id);
          });

          // update cache
          queryClient.setQueryData<Result<ApplicationT[]> | undefined>(
            ['quick_insights', application.workgroup_id],
            (oldData) => {
              if (!oldData?.Result) {
                return oldData;
              }
              let newData = {
                ...oldData,
                Result: oldData.Result.map((application) =>
                  application.id !== quickInsight.id ? application : quickInsight
                ),
              };
              return newData;
            }
          );

          const message =
            quickInsight.cache_status === 'success' ? (
              `Data profile on ${quickInsight.workspace_name} is ready.`
            ) : (
              <>
                <div>{`Data profile on ${quickInsight.workspace_name} is failed.`}</div>
                <div>{quickInsight.cache_error}</div>
              </>
            );

          showToast({
            kind: quickInsight.cache_status === 'success' ? 'positive' : 'negative',
            message: <div>{message}</div>,
            autoHideDuration: 0,
            title: 'Data profile ' + (quickInsight.cache_status === 'success' ? 'Ready' : 'Failed'),
            action: () => {
              navigator(`/groups/${application.workgroup_id}?tab=dataprofiles`);
            },
          });
        }
      },
      refetchInterval: (data) => {
        if (!data?.Result) {
          setQuickInsights((oldData) => {
            return oldData.filter((item) => item.id !== application.id);
          });
          return false;
        }

        const quickInsight = data?.Result;
        let shouldFetch = false;

        if (quickInsight.cache_status === 'pending') {
          shouldFetch = true;
        }

        if (!shouldFetch) {
          return false;
        }

        return pollingInterval;
      },
    }
  );

  return null;
}
