import { MyIP } from '@/pages/workgroup/tab/networkAccess/type';
import { WorkGroupT } from '@/pages/workgroup/type';
import { createContext, ReactNode, useContext } from 'react';

export const AccessContext = createContext<MyIP>({
  current_ip: '192.168.1.2',
  is_allowed: true,
});

export function AccessProvider({ children, myIP, group }: { children: ReactNode; myIP: MyIP; group: WorkGroupT }) {
  let is_allowed = true;

  // only after check enable_allow_list is true
  if (group.enable_allow_list) {
    is_allowed = myIP.is_allowed;
  }

  return (
    <AccessContext.Provider
      value={{
        ...myIP,
        is_allowed,
      }}
    >
      {children}
    </AccessContext.Provider>
  );
}

export function useAccess() {
  return useContext(AccessContext);
}
