import { RefObject, useEffect } from 'react';
import { GraphResultRef } from '@/pages/editor/result/GraphResult';
import ConfirmModal from '@/components/ConfirmModal';
import { useBlocker } from 'react-router-dom';

interface SchemaDesignerDeactiveDialogProps {
  graphResultRef: RefObject<GraphResultRef>;
  showSchemaChangeModal?: boolean;
  showPrompt?: boolean;
  onConfirmCloseSchemaDesigner?: () => void;
  onCancelCloseSchemaDesigner?: () => void;
}

export default function SchemaDesignerDeactiveDialog(props: SchemaDesignerDeactiveDialogProps) {
  const {
    graphResultRef,
    showSchemaChangeModal,
    showPrompt,
    onConfirmCloseSchemaDesigner,
    onCancelCloseSchemaDesigner,
  } = props;

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (showPrompt && graphResultRef.current?.isSchemaChanged()) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [graphResultRef, showPrompt]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !!graphResultRef.current?.isSchemaChanged() && currentLocation.pathname !== nextLocation.pathname
  );

  return (
    <ConfirmModal
      open={!!showSchemaChangeModal || blocker.state === 'blocked'}
      header="Warning"
      body={'You have unsaved changes that will be lost. Do you still want to exit schema designer?'}
      onConfirm={() => {
        if (blocker.state === 'blocked') {
          blocker.proceed();
        }
        onConfirmCloseSchemaDesigner?.();
      }}
      onCancel={() => {
        if (blocker.state === 'blocked') {
          blocker.reset();
        }
        onCancelCloseSchemaDesigner?.();
      }}
    />
  );
}
