import Provider from '@tigergraph/app-ui-lib/Provider';
import { createDarkTheme, createTheme, CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { createContext, useState, ReactNode, useContext, useCallback, useEffect, useMemo } from 'react';

export const ThemeContext = createContext({
  themeType: 'light' as 'light' | 'dark',
  theme: createTheme({}),
  toggleTheme: () => {},
});

const themeKey = 'theme';

export function ThemeProvider({ children }: { children: ReactNode }) {
  // 1. get the theme type from localStorage
  // 2. default to dark theme if no theme type is found
  // @ts-ignore
  const [themeType, setThemeType] = useState<'light' | 'dark'>(localStorage.getItem(themeKey) || 'dark');
  const [theme, setTheme] = useState<CustomTheme>(themeType === 'light' ? createTheme({}) : createDarkTheme({}));
  const toggleTheme = useCallback(() => {
    if (themeType === 'light') {
      setTheme(createDarkTheme({}));
      setThemeType('dark');
      document.documentElement.classList.add('dark');
    } else {
      setTheme(createTheme({}));
      setThemeType('light');
      document.documentElement.classList.remove('dark');
    }
  }, [themeType]);

  useEffect(() => {
    localStorage.setItem(themeKey, themeType);
  }, [themeType]);

  const value = useMemo(() => {
    return {
      theme,
      toggleTheme,
      themeType,
    };
  }, [theme, toggleTheme, themeType]);

  return (
    <ThemeContext.Provider value={value}>
      <Provider theme={theme}>{children}</Provider>
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}
