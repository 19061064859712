import { RadioOverrides } from 'baseui/radio';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';

export const radioOverrides: RadioOverrides = {
  Root: {
    style: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  Label: {
    style: ({ $checked, $theme }: { $checked: boolean; $theme: CustomTheme }) => {
      return {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '3px',
        paddingBottom: '3px',
        borderColor: $checked ? $theme.colors['tabs.border.selected'] : $theme.colors['tabs.border.default'],
        borderLeftWidth: $checked ? '1px' : '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderRightWidth: $checked ? '1px' : '0px',
        ...$theme.typography.Label,
        color: $theme.colors['tabs.text.default'],
        fontWeight: $checked ? '600' : '400',
        backgroundColor: $checked
          ? $theme.colors['tabs.background.selected']
          : $theme.colors['tabs.background.alternative'],
      };
    },
  },
  RadioMarkInner: {
    style: {
      display: 'none',
    },
  },
  RadioMarkOuter: {
    style: {
      display: 'none',
    },
  },
};

export const leftRadioOverrides = mergeOverrides(radioOverrides as Overrides<any>, {
  Label: {
    style: {
      borderRadius: '2px 0px 0px 2px',
      borderLeftWidth: '1px',
    },
  },
});

export const rightRadioOverrides = mergeOverrides(radioOverrides as Overrides<any>, {
  Label: {
    style: {
      borderRadius: '0px 2px 2px 0px',
      borderRightWidth: '1px',
    },
  },
});
