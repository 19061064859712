import { getUserFullname } from '@/utils/utils';

import DeleteIcon from '@/assets/delete.svg?react';
import { Avatar, formatName } from '@/components/Avatar';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { UserInfo } from '@/lib/models';
import { User, isOrgAdmin, isSpaceAdmin } from '@/pages/admin/user/type';
import { WorkspaceT } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body2, Label } from '@tigergraph/app-ui-lib/typography';
import { filterProps } from '@tigergraph/tools-ui/insights/utils';
import useHover from 'ahooks/lib/useHover';
import { Option, Value } from 'baseui/select';
import { forwardRef, useRef } from 'react';
import { Button } from '@tigergraph/app-ui-lib/button';

export function getDropdownUserList(allUser: User[], admins: UserInfo[]) {
  let ret: User[] = [];

  for (let u of allUser) {
    // 1. filter out org admin
    if (isOrgAdmin(u.roles)) {
      continue;
    }
    // 2. filter out inactive user
    if (!u.lastActive) {
      continue;
    }
    // 3. filter out group admin
    if (admins.findIndex((admin) => admin.id === u.userId) === -1) {
      ret.push(u);
    }
  }

  return ret;
}

export function diffGroupAdminsList(before: UserInfo[] | undefined, after: UserInfo[]) {
  if (before === undefined) {
    return {
      assignUserEmails: [],
      unAssignUserEmails: [],
    };
  }
  let added: UserInfo[] = [];
  let deleted: UserInfo[] = [];

  for (let u1 of before) {
    if (after.findIndex((u2) => u2.id === u1.id) === -1) {
      deleted.push(u1);
    }
  }

  for (let u1 of after) {
    if (before.findIndex((u2) => u2.id === u1.id) === -1) {
      added.push(u1);
    }
  }

  return {
    assignUserEmails: added.map((u) => u.email),
    unAssignUserEmails: deleted.map((u) => u.email),
  };
}

export function diffSpaceUserList(before: UserInfo[] | undefined, after: UserInfo[], space: WorkspaceT) {
  if (before === undefined) {
    return {
      assignAdminEmails: [],
      assignUserEmails: [],
      unAssignEmails: [],
    };
  }

  let assignAdmins: UserInfo[] = [];
  let assignUsers: UserInfo[] = [];
  let unAssigns: UserInfo[] = [];

  // 1. user removed
  for (let u1 of before) {
    if (after.findIndex((u2) => u2.id === u1.id) === -1) {
      unAssigns.push(u1);
    }
  }

  for (let u1 of after) {
    // 2. user added
    // new user's role is set by clientk on [0]
    if (before.findIndex((u2) => u2.id === u1.id) === -1) {
      if (u1.roles[0].name === 'workspace-admins') {
        assignAdmins.push(u1);
      } else {
        assignUsers.push(u1);
      }
    } else {
      const u2 = before.find((u2) => u2.id === u1.id);
      if (!u2) {
        continue;
      }

      // 3. user role changed
      const isAdminAfter = isSpaceAdmin(u1.roles, space.workgroup_id, space.workspace_id);
      const isAdminBefore = isSpaceAdmin(u2.roles, space.workgroup_id, space.workspace_id);

      if (isAdminAfter !== isAdminBefore) {
        if (isAdminAfter) {
          assignAdmins.push(u1);
        } else {
          assignUsers.push(u1);
        }
      }
    }
  }

  return {
    assignAdminEmails: assignAdmins.map((u) => u.email),
    assignUserEmails: assignUsers.map((u) => u.email),
    unAssignEmails: unAssigns.map((u) => u.email),
  };
}

export function filterOptions(options: Value, filterValue: string) {
  let ret: Option[] = [];
  for (let option of options) {
    const u = option as User;
    let displayName = getUserFullname(u.userInfo);
    if (
      u.email.toLowerCase().includes(filterValue.toLowerCase()) ||
      displayName.toLowerCase().includes(filterValue.toLowerCase())
    ) {
      ret.push(u);
    }
  }
  return ret;
}

type UserDropDownItemProps = {
  [x: string]: any;
  item: User;
};

export const UserDropDownItem = forwardRef<HTMLLIElement, UserDropDownItemProps>((props, ref) => {
  const [css] = useStyletron();
  const { item, ...rest } = props;
  return (
    <li
      ref={ref}
      {...filterProps(rest)}
      className={css({
        cursor: 'pointer',
        listStyle: 'none',
        padding: '8px',
      })}
    >
      <div
        className={css({
          display: 'flex',
          gap: '4px',
          alignItems: 'stretch',
        })}
      >
        <Avatar size="default">{formatName(item.userInfo)}</Avatar>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          })}
        >
          <Body2>{getUserFullname(item.userInfo)}</Body2>
          <Label>{item.email}</Label>
        </div>
      </div>
    </li>
  );
});

export function GroupAdminItem({ user, onDelete }: { user: UserInfo; onDelete?: (user: UserInfo) => void }) {
  const [css, theme] = useStyletron();
  const ref = useRef<HTMLDivElement>(null);
  const hover = useHover(ref);
  return (
    <div
      className={css({
        display: 'grid',
        columnGap: '4px',
        alignItems: 'center',
        gridTemplateColumns: `auto 1fr auto ${onDelete ? '20px' : ''}`,
        padding: '4px 8px',
        ':hover': {
          backgroundColor: theme.colors['background.primary.hover'],
        },
      })}
      ref={ref}
    >
      <Avatar size="compact">{formatName(user)}</Avatar>
      <span>{user.email}</span>
      <Tag kind="neutral" closeable={false}>
        {isOrgAdmin(user.roles) ? 'Org Admin' : 'Workgroup Admin'}
      </Tag>
      {hover && onDelete && !isOrgAdmin(user.roles) ? (
        <Button size="compact" kind="text" shape="square" onClick={() => onDelete(user)}>
          <DeleteIcon />
        </Button>
      ) : null}
    </div>
  );
}
