import { styled } from '@tigergraph/app-ui-lib/Theme';

export const Subtitle = styled('div', ({ $theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  marginBottom: '8px',
  color: $theme.colors['input.text'],
}));

export const Desc = styled('div', ({ $theme }) => ({
  color: $theme.colors['dropdown.text.secondary'],
  ...$theme.typography.Label,
}));

export const SubSection = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '8px',
  fontFamily: 'Urbanist',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  color: $theme.colors['input.text'],
}));
