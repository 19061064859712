import { Parameter } from '@/pages/workgroup/tab/restPP/type.ts';

const numericTypes = ['FLOAT', 'DOUBLE', 'UINT32', 'UINT64', 'INT32', 'INT64', 'UINT', 'INT'];

export function mapTypeToDefaultValue(type: string) {
  if (numericTypes.includes(type)) {
    return 0;
  }

  const defaultValueMap: { [key: string]: any } = {
    STRING: '',
    BOOL: false,
    TYPENAME: '',
    DATETIME: '0',
    EDGETYPENAME: '',
  };
  return defaultValueMap[type] ?? null;
}

export function generateJsonFromParams(params: Parameter[]) {
  const json: { [key: string]: any } = {};

  params.forEach((param) => {
    json[param.name] = param.default ?? mapTypeToDefaultValue(param.type);

    if (param?.is_id === 'true') {
      json[param.name] = {
        id: '',
        type: param.id_type || '',
      };
    }

    // TODO is this correct?
    if (param?.max_count) {
      json[param.name] = [];
    }
  });

  return JSON.stringify(json, null, 2);
}
