import TooltipLabel from '@/components/TooltipLabel';
import { FormTitleContainer } from '@/components/styled';
import { StyledToast, showToast } from '@/components/styledToasterContainer';
import { Result } from '@/lib/type';
import { ValidateCPRequest, validateCP } from '@/pages/admin/settings/cloud_provider/api';
import { IFormInput } from '@/pages/admin/settings/cloud_provider/types';
import { getErrorMessage } from '@/utils/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { Input } from '@tigergraph/app-ui-lib/input';
import { AxiosError } from 'axios';
import { StatefulPanel } from 'baseui/accordion';
import { KIND, SIZE } from 'baseui/button';
import { STYLE_TYPE } from 'baseui/checkbox';
import { ReactNode, useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useMutation } from 'react-query';
import { expand } from 'inline-style-expand-shorthand';
import { getSubnetId } from '@/pages/admin/settings/cloud_provider/util';
import { Desc, SubSection, Subtitle } from '@/pages/admin/settings/cloud_provider/StyledComponents';
import { Book, Plane } from '@/pages/home/icons';

interface CreateRoleProps {
  control: Control<IFormInput, any>;
  errors: FieldErrors<IFormInput>;
  watch: UseFormWatch<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
  validateForm: UseFormTrigger<IFormInput>;
  cloudPlatform: string;
}

export default function CreateRole({ control, errors, watch, setValue, validateForm, cloudPlatform }: CreateRoleProps) {
  const [css, theme] = useStyletron();

  const vpcOwnedByUser = watch('vpcOwnedByUser');
  const awsRoleArn = watch('awsRoleArn');
  const awsExternalId = watch('awsExternalId');
  const vpcId = watch('vpcId');
  const subnetId = watch('subnetId');
  const securityGroupId = watch('securityGroupId');
  const region = watch('region');
  const secureConnection = watch('secureConnection');
  const accountId = watch('trustedAccountId');

  const [editDisabled, setEditDisabled] = useState<Record<string, boolean>>({ secureConnection: false });

  useEffect(() => {
    if (vpcOwnedByUser) {
      setValue('secureConnection', true);
      setEditDisabled({ secureConnection: true });
    } else {
      setEditDisabled({ secureConnection: false });
    }
  }, [setValue, vpcOwnedByUser]);

  const [validateErr, setValidateErr] = useState('');
  const { mutate: validate, isLoading: isValidating } = useMutation<Result<void>, AxiosError, ValidateCPRequest>(
    async (data) => {
      const res = await validateCP(data);
      return res;
    },
    {
      onSuccess() {
        showToast({
          kind: 'positive',
          message: 'Cloud provider validated successfully',
        });
      },
      onError(error) {
        setValidateErr(getErrorMessage(error));
      },
    }
  );

  const onValidate = async () => {
    const formValidation = await validateForm();
    if (!formValidation) {
      return;
    }

    setValidateErr('');
    validate({
      region,
      role_arn: awsRoleArn,
      validate_vpc: vpcOwnedByUser,
      vpc_id: vpcId,
      subnet_id: getSubnetId(subnetId),
      security_group_id: securityGroupId,
      platform: cloudPlatform,
      secure_connection: secureConnection,
      role_external_id: awsExternalId,
      account_id: accountId,
    });
  };

  useEffect(() => {
    if (vpcOwnedByUser) {
      return;
    }

    setValue('vpcId', '');
    setValue('subnetId', '');
    setValue('securityGroupId', '');
  }, [vpcOwnedByUser, setValue]);

  // extract trusted account id from role arn
  // I.E, the ARN is arn:aws:iam::381491969203:role/duhao-cp-role-9203, then the prefilled “Trusted Account Id” is 381491969203
  useEffect(() => {
    const arn = awsRoleArn;
    if (!arn) {
      return;
    }

    const parts = arn.split(':');
    if (parts.length < 6) {
      return;
    }

    setValue('trustedAccountId', parts[4]);
  }, [awsRoleArn, setValue]);

  const randomId = Date.now().toString().slice(0, 10);
  const cloudFormationURL = `https://console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/create/review?stackName=MySampleStack-${randomId}&templateURL=https://tgcloud-cloudformation.s3.us-east-1.amazonaws.com/byoc_customer_role.yaml&param_ExternalId=${awsExternalId}`;

  return (
    <>
      <SubSection>Create Role for Cloud Privoder</SubSection>
      <Desc>
        Select a method to create a AWS role that we will assume to access resources on your behalf. You can either use
        the CloudFormation script we provide or manually create a role in the AWS console by following the instructions
        in our documentation.
      </Desc>
      <div
        className={css({
          display: 'flex',
          gap: '16px',
          padding: '8px 0 16px',
          borderBottom: `1px solid ${theme.colors['card.border']}`,
          marginBottom: '16px',
        })}
      >
        <RoleMethod
          name="Quick Start"
          desc="Automatically create the role using the CloudFormation script we provide"
          url={cloudFormationURL}
          icon={<Plane />}
        />
        <RoleMethod
          name="Manual"
          desc="Manually create the role in the AWS console by following the step-by-step instructions"
          url="https://docs.tigergraph.com/cloud/current/start/overview"
          icon={<Book />}
        />
      </div>

      <div
        className={css({
          borderBottom: `1px solid ${theme.colors['card.border']}`,
          marginBottom: '16px',
        })}
      >
        <SubSection>Credential Configuration</SubSection>
        <Subtitle>AWS External ID</Subtitle>
        <FormControl>
          <Controller control={control} name="awsExternalId" render={({ field }) => <Input {...field} />} />
        </FormControl>
        <Subtitle>AWS Role ARN</Subtitle>
        <FormControl error={errors?.awsRoleArn?.message}>
          <Controller
            control={control}
            name="awsRoleArn"
            rules={{ required: 'Required' }}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
      </div>

      <StatefulPanel
        title={<SubSection>Advanced Settings</SubSection>}
        overrides={{
          PanelContainer: {
            style: {
              ...expand({ borderBottom: 'none' }),
            },
          },
          Content: {
            style: {
              ...expand({
                padding: '0',
              }),
            },
          },
          Header: {
            style: {
              ...expand({ padding: '0 0 8px 0', backgroundColor: 'transparent' }),
            },
          },
          ToggleIcon: ({ $expanded }) =>
            $expanded ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />,
        }}
      >
        <FormTitleContainer>
          <TooltipLabel label="Secure Connection" tooltip="" />
          <Controller
            control={control}
            name="secureConnection"
            render={({ field: { value, onChange, ref: _, ...field } }) => (
              <Checkbox
                checked={value}
                labelPlacement="left"
                onChange={() => {
                  onChange(!value);
                }}
                {...field}
                disabled={editDisabled.secureConnection}
                checkmarkType={STYLE_TYPE.toggle_round}
              />
            )}
          />
        </FormTitleContainer>
        {secureConnection && (
          <FormControl label="Trusted Account ID" error={errors?.trustedAccountId?.message}>
            <Controller
              control={control}
              rules={{ required: 'Required' }}
              name="trustedAccountId"
              render={({ field }) => <Input id="trustedAccountId" {...field} />}
            />
          </FormControl>
        )}
        <FormTitleContainer>
          <TooltipLabel label="User Owned VPC" tooltip="" />
          <Controller
            control={control}
            name="vpcOwnedByUser"
            render={({ field: { value, onChange, ref: _, ...field } }) => (
              <Checkbox
                checked={value}
                labelPlacement="left"
                onChange={() => {
                  onChange(!value);
                }}
                {...field}
                checkmarkType={STYLE_TYPE.toggle_round}
              />
            )}
          />
        </FormTitleContainer>
        {vpcOwnedByUser && (
          <>
            <FormControl label="VPC ID" error={errors?.vpcId?.message}>
              <Controller
                rules={{ required: 'Required' }}
                control={control}
                name="vpcId"
                render={({ field }) => <Input id="vpcId" {...field} />}
              />
            </FormControl>
            <FormControl label="Subnet ID" error={errors?.subnetId?.message}>
              <Controller
                control={control}
                rules={{ required: 'Required' }}
                name="subnetId"
                render={({ field }) => <Input id="subnetId" {...field} />}
              />
            </FormControl>
            <div className={css({ color: '#656565', ...theme.typography.Label, marginTop: '-16px' })}>
              You can specify multiple subnet id by comma-separating them.
            </div>
            <FormControl label="Security Group ID" error={errors?.securityGroupId?.message}>
              <Controller
                control={control}
                rules={{ required: 'Required' }}
                name="securityGroupId"
                render={({ field }) => <Input id="securityGroupId" {...field} />}
              />
            </FormControl>
          </>
        )}
      </StatefulPanel>

      <div className={css({ margin: '8px 0 16px' })}>
        <Button
          kind={KIND.secondary}
          size={SIZE.compact}
          overrides={{ BaseButton: { style: { fontWeight: 500 } } }}
          onClick={(evt) => {
            evt.preventDefault();
            onValidate();
          }}
          isLoading={isValidating}
        >
          Test
        </Button>
      </div>
      {!!validateErr && <StyledToast kind="negative" title="Validation Failed" message={validateErr} />}
    </>
  );
}

interface RoleMethodProps {
  icon: ReactNode;
  name: string;
  desc: string;
  url: string;
}

function RoleMethod({ icon, name, desc, url }: RoleMethodProps) {
  const [css, theme] = useStyletron();

  return (
    <a
      href={url}
      target="_blank"
      className={css({
        flex: '1',
        display: 'flex',
        height: '108px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '2px',
        border: `1px solid ${theme.colors['card.border']}`,
        gap: '4px',
      })}
      rel="noreferrer"
    >
      <div className={css({ marginBottom: '8px' })}>{icon}</div>
      <div
        className={css({
          ...theme.typography.Label,
          color: theme.colors['card.text.primary'],
          fontWeight: 700,
          lineHeight: '16px',
        })}
      >
        {name}
      </div>
      <Desc $style={{ margin: '0 32px', textAlign: 'center' }}>{desc}</Desc>
    </a>
  );
}
