import { GLOBAL_VIEW, ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { axiosCluster } from '@/lib/network';
import { GSQL_COMMAND, ParsedResult, parseRes } from '@/utils/graphEditor';
import { eventStream } from '@/utils/graphEditor/sse-client';
import { getErrorMessage } from '@/utils/utils';
import { AxiosError } from 'axios';
import EventEmitter from 'eventemitter3';

export interface CommandResult {
  done?: boolean;
  error: boolean;
  result?: string;
  errorMsg?: string;
}

export interface Command {
  id: string;
  type: 'GSQL' | 'Query';
}
export interface GSQLCommand extends Command {
  type: 'GSQL';
  GSQLCode: string;
}
export interface QueryCommand extends Command {
  type: 'Query';
  queryName: string;
  URL: string; // endpoint to run the query
  payload: any; // payload to run the query
}

export type EventName = 'error' | 'progress' | 'finish';
export interface Event {
  target: string;
  payload?: string;
}

class CommandExecutor extends EventEmitter {
  private cmdResults: Map<string, CommandResult> = new Map();
  private cookie: object = {};

  constructor() {
    super();
    this.loadCookie();
  }

  private loadCookie() {
    const cookieStr = localStorage.getItem('gsql-cookie');
    try {
      this.cookie = cookieStr ? JSON.parse(cookieStr) : {};
    } catch (error) {
      //
    }
  }
  clearCookie() {
    this.setCookie({});
  }
  setCookie(cookie: object) {
    this.cookie = cookie;
    localStorage.setItem('gsql-cookie', JSON.stringify(cookie));
  }

  getCmdResult(id: string) {
    return this.cmdResults.get(id);
  }

  removeCmdResult(id: string) {
    this.cmdResults.delete(id);
  }

  async executeGSQL(cmd: GSQLCommand, host: string, setCurrentGraph: (graph: string) => void) {
    if (this.getCmdResult(cmd.id)) {
      return;
    }

    this.cmdResults.set(cmd.id, { error: false });

    const res = await fetch(`https://${host}/api/gsql-command`, {
      method: 'post',
      body: JSON.stringify({ command: cmd.GSQLCode, Cookie: { ...this.cookie, fromGsqlServer: false } }),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
      },
    });
    if (res.status === 401) {
      // reload the current page to refresh the token
      window.location.reload();
      return;
    }
    if (res.status >= 300) {
      const errorMsg = (await res.text()) || res.statusText;
      this.handleError(cmd.id, errorMsg);
      return;
    }

    const events = eventStream(res.body);
    let result = '';
    let cursorIdx = 0;
    for await (const event of events) {
      const json = JSON.parse(event.data);
      if (json.error) {
        this.handleError(cmd.id, json.message);
        return;
      }

      const data = json.results;
      if (data) {
        const parsedData = parseRes(data);
        const cookie = parsedData.cookie;
        if (cookie) {
          this.setCookie(cookie);
          if (cookie.graph) {
            setCurrentGraph(cookie.graph);
          } else {
            setCurrentGraph(GLOBAL_VIEW);
          }
        }
        ({ cursorIdx, result } = this.handleGSQLReslt(cmd.id, cursorIdx, result, parsedData));

        if (parsedData.retCode !== undefined && parsedData.retCode !== 0) {
          this.handleError(cmd.id, result);
          return;
        }
      }

      if (json.done) {
        this.handleFinish(cmd.id);
      }
    }

    this.handleFinish(cmd.id);
  }

  private emitEvent(event: EventName, eventObj?: Event) {
    super.emit(event, eventObj);
  }

  private handleFinish(cmdId: string, result?: string) {
    let cmdResult = this.cmdResults.get(cmdId);
    if (!cmdResult) {
      return;
    }

    cmdResult.done = true;
    if (result) {
      cmdResult.result = result;
    }
    this.cmdResults.set(cmdId, cmdResult);
    this.emitEvent('finish', { target: cmdId, payload: result });
  }

  private handleError(cmdId: string, errorMsg: string) {
    this.cmdResults.set(cmdId, { error: true, errorMsg });
    this.emitEvent('error', { payload: errorMsg, target: cmdId });
  }

  private handleGSQLReslt(cmdId: string, cursorIdx: number, data: string, parsedData: ParsedResult) {
    let result = data;
    const lines = parsedData.ret.split('\n');
    lines.forEach((line: string, lineIdx: number) => {
      if (lineIdx !== lines.length - 1) {
        line += '\n';
      }
      if (line.startsWith(GSQL_COMMAND.MOVE_CURSOR_UP)) {
        let num = parseInt(line.split(',')[1]);
        let idx = cursorIdx;
        while (num && (idx = result.lastIndexOf('\n', idx - 1)) > -1) {
          --num;
        }
        idx = result.lastIndexOf('\n', idx - 1);
        cursorIdx = idx === -1 ? 0 : idx + 1;
      } else if (line.startsWith(GSQL_COMMAND.CLEAN_LINE)) {
        const newLineIdx = result.indexOf('\n', cursorIdx);
        if (newLineIdx > -1) {
          result = result.slice(0, cursorIdx) + result.slice(newLineIdx + 1);
        }
      } else if (line.indexOf('\r') > -1) {
        const lastCarriageIdx = line.lastIndexOf('\r');
        const lastLine = result.lastIndexOf('\n');
        result = result.slice(0, lastLine + 1) + line.slice(lastCarriageIdx + 1);
        cursorIdx = result.length;
      } else {
        result = result.slice(0, cursorIdx) + line + result.slice(cursorIdx);
        cursorIdx += line.length;
      }
    });

    this.emitEvent('progress', { payload: result, target: cmdId });
    this.cmdResults.set(cmdId, { error: false, result });

    return { cursorIdx, result };
  }

  async executeQuery(cmd: QueryCommand) {
    if (this.getCmdResult(cmd.id)) {
      return;
    }

    this.cmdResults.set(cmd.id, { error: false });

    let data = null;
    try {
      const res = await axiosCluster.post(cmd.URL, cmd.payload, {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      data = res.data;
    } catch (error) {
      this.handleError(cmd.id, getErrorMessage(error as AxiosError));
      return;
    }

    if (data.results) {
      this.handleFinish(cmd.id, JSON.stringify(data.results));
      return;
    }

    this.handleError(cmd.id, 'No result');
  }
}

const commandExecutor = new CommandExecutor();
export default commandExecutor;
