import { BasicSetupOptions } from '@uiw/react-codemirror';

export enum ApiType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const cmOptions: BasicSetupOptions = {
  lineNumbers: false,
  foldGutter: false,
  highlightActiveLine: false,
};

export interface Parameter {
  name: string;
  type: string;
  default?: string;
  is_id?: 'true';
  id_type?: string;
  max_count?: number;
}
