import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { WorkspaceT } from '@/pages/workgroup/type';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useForm, Controller } from 'react-hook-form';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { FormControl } from '@/components/FormControl';
import { Input } from '@tigergraph/app-ui-lib/input';
import { FormControlOverrides } from 'baseui/form-control';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { ConfigType } from '@/pages/workgroup/form/setting/api';
import {
  useMutationRestartService,
  useMutationUpdateConfig,
  useQueryConfig,
} from '@/pages/workgroup/form/setting/hook';
import { LoadingIndicator } from '@/components/loading-indicator';
import { ErrorDisplay } from '@/components/error';
import { compare } from 'compare-versions';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';
import { useState } from 'react';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { isEqual } from 'lodash-es';

const githubAccessTokenKey = 'GSQL.GithubUserAcessToken';

const Configs = [
  {
    service: 'GSQL',
    servicesToRestart: ['gsql'],
    keys: [
      {
        key: 'GSQL.QueryResponseMaxSizeByte',
        label: 'Maximum response size (Bytes)',
      },
      {
        key: 'GSQL.GithubUrl',
        label: 'Github URL for UDF',
        placeHolder: 'https://api.github.com',
        type: 'string',
        optional: true,
      },
      {
        key: 'GSQL.GithubRepository',
        label: 'Github repository for UDF',
        placeHolder: 'tigergraph/ecosys',
        type: 'string',
        optional: true,
      },
      {
        key: 'GSQL.GithubBranch',
        label: 'Repository branch for UDF',
        placeHolder: 'main',
        type: 'string',
        optional: true,
      },
      {
        key: 'GSQL.GithubPath',
        label: 'Repository path for UDF',
        placeHolder: 'UDF',
        type: 'string',
        optional: true,
      },
      {
        key: 'GSQL.GithubUserAcessToken',
        label: 'Github user access token for UDF',
        skipFetch: true,
        type: 'password',
        optional: true,
      },
    ],
  },
  {
    service: 'GUI',
    servicesToRestart: ['gui'],
    keys: [
      {
        key: 'GUI.HTTPRequest.TimeoutSec',
        label: 'HTTP request timeout (seconds)',
      },
      {
        key: 'GUI.RESTPPResponseMaxSizeBytes',
        label: 'Query return size (bytes)',
      },
      {
        key: 'GUI.Cookie.DurationSec',
        label: 'Cookie duration (seconds)',
      },
    ],
  },
  {
    service: 'RESTPP',
    servicesToRestart: ['restpp', 'gui', 'nginx'],
    keys: [
      {
        key: 'RESTPP.Factory.DefaultQueryTimeoutSec',
        label: 'Default query timeout (seconds)',
      },
      {
        key: 'RESTPP.WorkLoadManager.MaxHeavyBuiltinQueries',
        label: 'Maximum concurrent running "heavy" built-in queries',
      },
      {
        key: 'RESTPP.WorkLoadManager.MaxConcurrentQueries',
        label: 'Maximum concurrent running queries',
      },
      {
        key: 'RESTPP.WorkLoadManager.MaxDelayQueueSize',
        label: 'Maximum delay queue size',
      },
    ],
  },
  {
    service: 'Kafka',
    servicesToRestart: ['restpp', 'gpe', 'gse', 'ctrl'],
    keys: [
      {
        key: 'KafkaLoader.ReplicaNumber',
        label: 'Maximum number of concurrent Kafka loading jobs',
      },
      {
        key: 'KafkaConnect.AllowedTaskPerCPU',
        label: 'Maximum number of Kafka tasks per CPU',
      },
    ],
  },
  {
    service: 'GPE',
    servicesToRestart: ['gpe'],
    keys: [
      {
        key: 'GPE.QueryLocalMemLimitMB',
        label: 'Maximum memory per instance (MB) ',
        caption: '0 means no limit is applied.',
      },
    ],
  },
];

export default function TigergraphForm({ workspace, onClose }: { workspace: WorkspaceT; onClose: () => void }) {
  let configs = Configs;
  // for >= 4.1, we support extra configuration
  if (compare(workspace.tg_version, '4.0.x', '>')) {
    // @ts-ignore
    configs = Configs.map((config) =>
      config.service !== 'GPE'
        ? config
        : {
            ...config,
            keys: [
              ...config.keys,
              {
                key: 'GPE.QueryOutputS3AWSAccessKeyID',
                label: 'S3 AWS access key ID for query output',
                type: 'password',
              },
              {
                key: 'GPE.QueryOutputS3AWSSecretAccessKey',
                label: 'S3 AWS access key for query output',
                type: 'password',
              },
            ],
          }
    );
  }

  let keys: string[] = [];
  for (let config of configs) {
    for (let key of config.keys) {
      if ('skipFetch' in key && key.skipFetch) {
        continue;
      }
      keys.push(key.key);
    }
  }

  const { isFetching, isError, error } = useQueryConfig(workspace.nginx_host, keys, {
    // set form default value
    onSuccess(data) {
      if (data.results) {
        const defaultValues: Record<string, string | number> = {};
        for (let key of Object.keys(data.results)) {
          defaultValues[escapeKey(key)] = `${data.results[key]}`;
        }

        if (data.results['GSQL.GithubRepository']) {
          defaultValues[escapeKey(githubAccessTokenKey)] = '<masked>';
        } else {
          defaultValues[escapeKey(githubAccessTokenKey)] = '';
        }
        reset(defaultValues);
      }
    },
  });

  const updateConfigMutation = useMutationUpdateConfig();

  const restartServiceMutation = useMutationRestartService();
  const [showRestartDialog, setShowRestartDialog] = useState(false);

  const form = useForm<ConfigType>({
    defaultValues: {},
  });

  const {
    handleSubmit,
    control,
    formState: { errors, defaultValues },
    reset,
    watch,
  } = form;

  if (isFetching) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return <ErrorDisplay error={error} label="Failed to fetch TigerGraph configuration." />;
  }

  const currentValues = watch();
  const isSame = isEqual(defaultValues, currentValues);

  console.log({
    isSame,
    defaultValues,
    currentValues,
  });

  const config: ConfigType = {};

  const serviceToRestart = new Set<string>();
  if (defaultValues) {
    for (let key of Object.keys(defaultValues)) {
      const originalValue = defaultValues[key];
      const currentValue = currentValues[key];
      if (`${currentValue}` !== `${originalValue}`) {
        config[unEscapeKey(key)] = currentValue;

        for (let config of configs) {
          for (let k of config.keys) {
            if (k.key === unEscapeKey(key)) {
              for (let service of config.servicesToRestart) {
                serviceToRestart.add(service);
              }
            }
          }
        }
      }
    }
  }

  const onUpdate = () => {
    updateConfigMutation.mutate(
      {
        nginx_host: workspace.nginx_host,
        config: config,
      },
      {
        onSuccess() {
          setShowRestartDialog(true);
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: `Failed to apply the new configurations: ${getErrorMessage(error)}.`,
          });
        },
      }
    );
  };

  return (
    <>
      <StyledForm>
        <StyledFormBody>
          <Accordion type="multiple" defaultValue={[]}>
            {configs.map((config) => (
              <AccordionItem value={config.service} key={config.service}>
                <AccordionTrigger>{config.service}</AccordionTrigger>
                <AccordionContent>
                  {config.keys.map((key) => (
                    <FormControl
                      label={key.label}
                      caption={'caption' in key ? key.caption : undefined}
                      error={errors?.[escapeKey(key.key)]?.message}
                      key={escapeKey(key.key)}
                      overrides={formControlOverrides}
                    >
                      <Controller
                        control={control}
                        name={escapeKey(key.key)}
                        rules={
                          'optional' in key && key.optional
                            ? {}
                            : {
                                required: 'required',
                              }
                        }
                        render={({ field }) => (
                          <Input
                            {...field}
                            error={!!errors?.[escapeKey(key.key)]}
                            type={'type' in key ? (key.type as string) : 'number'}
                            placeholder={'placeHolder' in key ? key.placeHolder : undefined}
                          />
                        )}
                      />
                    </FormControl>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </StyledFormBody>
        <StyledFormAction>
          <Button type="button" kind="secondary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="button"
            size="large"
            onClick={handleSubmit(async () => {
              onUpdate();
            })}
            isLoading={updateConfigMutation.isLoading}
            disabled={isSame || updateConfigMutation.isLoading}
          >
            Save
          </Button>
        </StyledFormAction>
      </StyledForm>
      <Modal onClose={() => setShowRestartDialog(false)} isOpen={showRestartDialog}>
        <ModalHeader>Restart the server</ModalHeader>
        <ModalBody>
          For new configurations to take effect,{' '}
          {Array.from(serviceToRestart)
            .map((s) => s.toUpperCase())
            .join(', ')}{' '}
          server needs to be restarted.
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="secondary" onClick={() => setShowRestartDialog(false)}>
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={restartServiceMutation.isLoading}
            disabled={restartServiceMutation.isLoading}
            onClick={() => {
              restartServiceMutation.mutate(
                {
                  nginx_host: workspace.nginx_host,
                  services: Array.from(serviceToRestart),
                },
                {
                  onSuccess() {
                    reset(currentValues);
                    setShowRestartDialog(false);
                    showToast({
                      kind: 'positive',
                      message:
                        'Please note that the service will undergo a complete restart to become online. The duration of this process depends on the data size.',
                      autoHideDuration: 10 * 1000,
                    });
                  },
                  onError(error) {
                    showToast({
                      kind: 'negative',
                      message: `Failed to restart the services: ${getErrorMessage(error)}.`,
                    });
                  },
                }
              );
            }}
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}

const formControlOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        ...theme.typography.Body2,
        marginTop: '-8px',
      };
    },
  },
};

// react final form use '.' for nested field
// we need to escape/unescape
function escapeKey(key: string) {
  return key.replaceAll('.', '_');
}

function unEscapeKey(key: string) {
  return key.replaceAll('_', '.');
}
