import { Drawer, DrawerHeader } from '@/components/Drawer';
import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import toast from 'react-hot-toast';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { isStatusActive, WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { MdOutlineAddToQueue } from 'react-icons/md';
import Empty from './icon/empty.svg?react';
import EmptyDark from './icon/empty-dark.svg?react';
import { useNavigate } from 'react-router-dom';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { Result } from '@/lib/type';
import { listSolution } from '@/pages/marketplace/solution/api';
import { useMutationDeleteSolution, useQueryGetSolutionCatalog } from '@/pages/marketplace/solution/hook';
import { SolutionInstance } from '@/pages/marketplace/solution/type';
import { solutionsAtom } from '@/pages/workgroup/atom';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { Trash2Icon } from 'lucide-react';
import { useCallback, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { GSQL_COMMAND } from '@/utils/graphEditor';
import { calculateRoleForSpace } from '@/pages/admin/user/type';
import { useOrgContext } from '@/contexts/orgContext';
import { getErrorMessage } from '@/utils/utils';
import { parseDate } from '@/lib/date';
import clsx from 'clsx';
import useMutationObserver from 'ahooks/lib/useMutationObserver';
import EmptyState from '@/pages/workgroup/EmptyState';
import { useConfig } from '@/lib/config';
import InsightsIcon from '@/pages/workgroup/tab/icons/insights.svg?react';
import { getToolsLink } from '@/hooks/useToolsLink';
import { EnabledAddonsType, useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { AddonsMetaID } from '@/components/addons/consts';
import { useTheme } from '@/contexts/themeContext';
import { useAccess } from '@/contexts/accessContext';

type Props = {
  group: WorkGroupT;
};

export default function SolutionList({ group }: Props) {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();

  const { userInfo, currentOrg } = useOrgContext();
  const { appConfig } = useConfig();
  const myIP = useAccess();

  const navigate = useNavigate();
  const [selectSolutionId, setSelectSolutionId] = useState<string | undefined>(undefined);
  const ref = useRef<HTMLPreElement>(null);
  useMutationObserver(
    () => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    },
    ref,
    { childList: true, characterData: true, attributes: true, subtree: true }
  );

  const { data: solutionCatalogs = [] } = useQueryGetSolutionCatalog();

  const [solutions, setSolutions] = useAtom(solutionsAtom);
  const { data, isLoading, isError, error } = useQuery<Result<SolutionInstance[]>, AxiosError>(
    ['solutions', group.workgroup_id],
    async () => {
      return listSolution(group.workgroup_id);
    },
    {
      onSuccess: (data) => {
        const results = data?.Result || [];
        for (let solution of results) {
          if (solution.solution_status === 'install_pending' || solution.solution_status === 'uninstall_pending') {
            if (!solutions.find((item) => item.id === solution.id)) {
              setSolutions((solutions) =>
                solutions.concat({
                  ...solution,
                  workspace_name: group.workspaces.find((ws) => ws.workspace_id === solution.workspace_id)?.name || '',
                })
              );
            }
          }
        }
      },
    }
  );

  const deleteSolutionMutation = useMutationDeleteSolution();

  const onDeleteSolution = (workgroup_id: string, workspace_id: string, solution_id: string) => {
    const promise = deleteSolutionMutation.mutateAsync({
      solution_id,
      workgroup_id,
      workspace_id,
    });
    toast.promise(
      promise,
      {
        loading: 'Deleting Solution',
        success: (data) => `${data.Message}`,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const { data: addonsEnabledMap } = useListEnabledAddons({
    select: useCallback((data?: EnabledAddonsType[]) => new Map(data?.map((item) => [item.AddonsID, item])), []),
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <ErrorDisplay label="Server Error:" error={error} />;
  }

  const { workspaces, tg_databases } = group;
  const sols = (data?.Result || []).map((solution) => {
    const {
      id,
      workgroup_id,
      workspace_id,
      solution_catalog_id,
      name,
      graph_name,
      owner_email,
      created_at,
      updated_at,
      solution_status,
      gsql_log,
    } = solution;
    let database = '';
    let workspace: WorkspaceT | undefined = undefined;
    for (let ws of workspaces) {
      if (ws.workspace_id === workspace_id) {
        workspace = ws;
        for (let db of tg_databases) {
          if (db.database_id === ws.database_id) {
            database = db.name;
            break;
          }
        }
        break;
      }
    }

    let hasInsights = false;
    for (let s of solutionCatalogs) {
      if (s.name === solution_catalog_id) {
        hasInsights = s.hasInsights;
        break;
      }
    }

    return {
      id,
      workgroup_id,
      workspace_id,
      solution_catalog_id,
      name,
      graph_name,
      owner_email,
      created_at,
      updated_at,
      database,
      workspace,
      solution_status,
      gsql_log,
      hasInsights,
    };
  });

  const selectSolution = sols.find((item) => item.id === selectSolutionId);

  return (
    <>
      {sols.length > 0 && (
        <>
          <TableBuilder data={sols}>
            <TableBuilderColumn header="Solution Name" id="name">
              {(row) => row.name}
            </TableBuilderColumn>
            <TableBuilderColumn
              header="Status"
              id="status"
              overrides={{
                TableBodyCell: {
                  style: {
                    paddingTop: '14px',
                    paddingBottom: '14px',
                  },
                },
              }}
            >
              {(row) => <Status solution={row} />}
            </TableBuilderColumn>
            <TableBuilderColumn header="Workspace" id="workspace">
              {(row) => row.workspace?.name}
            </TableBuilderColumn>
            <TableBuilderColumn header="Solution" id="solution">
              {(row) => row.solution_catalog_id}
            </TableBuilderColumn>
            {addonsEnabledMap?.get(AddonsMetaID.INSIGHTS)?.Enabled ? (
              <TableBuilderColumn header="Insights" id="insights">
                {(row) => {
                  if (!row.hasInsights || !row.workspace) {
                    return null;
                  }

                  // if the workspace is not active, we should disable the access to insights
                  return isStatusActive(row.workspace.status) ? (
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={getToolsLink(appConfig, currentOrg, row.workspace, '/insights/apps')}
                    >
                      <InsightsIcon />
                    </a>
                  ) : (
                    <Button kind="text" disabled={true}>
                      <InsightsIcon />
                    </Button>
                  );
                }}
              </TableBuilderColumn>
            ) : null}
            <TableBuilderColumn header="Provider" id="provider">
              {(row) => 'TigerGraph'}
            </TableBuilderColumn>
            <TableBuilderColumn header="Created on" id="createDate">
              {(row) => format(parseDate(row.created_at), 'yyyy-MM-dd HH:mm:ss')}
            </TableBuilderColumn>
            <TableBuilderColumn header="Owner" id="owner">
              {(row) => row.owner_email}
            </TableBuilderColumn>
            <TableBuilderColumn
              header="Log"
              id="log"
              overrides={{
                TableBodyCell: {
                  style: {
                    paddingTop: '12px',
                    paddingBottom: '12px',
                  },
                },
              }}
            >
              {(row) => (
                <Button kind="link" onClick={() => setSelectSolutionId(row.id)}>
                  view
                </Button>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn header="Actions">
              {(row) => {
                const effectRole = calculateRoleForSpace(userInfo.roles, row.workgroup_id, row.workspace_id);

                return (
                  <div
                    className={clsx(
                      css({
                        marginTop: '2px',
                        display: 'flex',
                        gap: '8px',
                      })
                      // 'hoverToShow'
                    )}
                  >
                    {effectRole === 'workspace-admins' && myIP.is_allowed ? (
                      <ConfirmStatefulPopover
                        confirmLabel={`Are you sure you want to delete the solution and all the data, queries on the graph ${row.graph_name}?`}
                        onConfirm={() => onDeleteSolution(row.workgroup_id, row.workspace_id, row.id)}
                      >
                        <Button kind="text" shape="square">
                          <Trash2Icon size={16} />
                        </Button>
                      </ConfirmStatefulPopover>
                    ) : null}
                  </div>
                );
              }}
            </TableBuilderColumn>
          </TableBuilder>
        </>
      )}
      {sols.length == 0 && (
        <>
          {workspaces.length == 0 && <EmptyState />}
          {workspaces.length > 0 && (
            <Container>
              {themeType === 'light' ? <Empty /> : <EmptyDark />}
              <Body2>Click “Add Solution” to install your first solution.</Body2>
              <Button
                size="large"
                onClick={() => navigate('/marketplace/solutions')}
                startEnhancer={<MdOutlineAddToQueue size={20} />}
              >
                <span>Add Solution</span>
              </Button>
            </Container>
          )}
        </>
      )}

      {selectSolution ? (
        <Drawer isOpen={!!selectSolution} onClose={() => setSelectSolutionId(undefined)}>
          <DrawerHeader>
            Solution Log
            {selectSolution.solution_status === 'install_pending' ||
            selectSolution.solution_status === 'uninstall_pending' ? (
              <Spinner $size="16px" $borderWidth="2px" />
            ) : null}
          </DrawerHeader>
          <pre
            ref={ref}
            className={css({
              width: '658px',
              height: '100%',
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              backgroundColor: 'black',
              color: theme.colors.gray200,
              fontSize: '12px',
              padding: '8px',
            })}
          >
            {stripLog(selectSolution.gsql_log)}
          </pre>
        </Drawer>
      ) : null}
    </>
  );
}

// todo(lin): handle different status
function Status({ solution }: { solution: SolutionInstance }) {
  const { solution_status } = solution;
  if (solution_status === 'installed') {
    return (
      <Tag closeable={false} kind="positive">
        Installed
      </Tag>
    );
  }

  if (solution_status === 'uninstalled') {
    return (
      <Tag closeable={false} kind="positive">
        Ready
      </Tag>
    );
  }

  if (solution_status === 'install_error' || solution_status === 'uninstall_error') {
    return (
      <Tag closeable={false} kind="negative">
        Failed
      </Tag>
    );
  }

  if (solution_status === 'install_pending') {
    return (
      <Tag
        closeable={false}
        kind="neutral"
        overrides={{
          Text: {
            style: {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            },
          },
        }}
      >
        Initializing
        <Spinner $size="12px" $borderWidth="2px" />
      </Tag>
    );
  }

  return null;
}

function stripLog(gsql_log: string) {
  // solution log have two type
  // 1. run gsql command log, it does not contain `\r\n`.
  // 2. error when call tigergraph api, it may contain `\r\n`, we need to replace this with `\n`
  gsql_log = gsql_log.replaceAll('\r\n', '\n');

  let lines = gsql_log.split('\n');
  lines = lines.filter((line) => !line.startsWith(GSQL_COMMAND.COOKIE) && !line.startsWith(GSQL_COMMAND.RETURN_CODE));

  let result = '';
  let cursorIdx = 0;
  lines.forEach((line, lineIdx) => {
    if (lineIdx !== lines.length - 1) {
      line += '\n';
    }
    if (line.startsWith(GSQL_COMMAND.MOVE_CURSOR_UP)) {
      let num = parseInt(line.split(',')[1]);
      let idx = cursorIdx;
      while (num && (idx = result.lastIndexOf('\n', idx - 1)) > -1) {
        --num;
      }
      idx = result.lastIndexOf('\n', idx - 1);
      cursorIdx = idx === -1 ? 0 : idx + 1;
    } else if (line.startsWith(GSQL_COMMAND.CLEAN_LINE)) {
      const newLineIdx = result.indexOf('\n', cursorIdx);
      if (newLineIdx > -1) {
        result = result.slice(0, cursorIdx) + result.slice(newLineIdx + 1);
      }
    } else if (line.indexOf('\r') > -1) {
      const lastCarriageIdx = line.lastIndexOf('\r');
      const lastLine = result.lastIndexOf('\n');
      result = result.slice(0, lastLine + 1) + line.slice(lastCarriageIdx + 1);
      cursorIdx = result.length;
    } else {
      result = result.slice(0, cursorIdx) + line + result.slice(cursorIdx);
      cursorIdx += line.length;
    }
  });

  return result;
}

const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: $theme.colors.gray1000,
  ...$theme.typography.Body1,
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '100%',
  textAlign: 'center',
  gap: '16px',
}));
