import { Color } from '@tigergraph/tools-models/gvis/color';

import { History } from '@tigergraph/tools-models/history';
import {
  DBGraphStyleJson,
  Edge,
  EdgeStyle,
  Graph,
  GSQLGraphJson,
  GraphStyle,
  GraphVis,
  Vertex,
  VertexStyle,
  DBVertexStyleJson,
} from '@tigergraph/tools-models/topology';
import { GLOBAL_GRAPH_NAME, ValidateResult } from '@tigergraph/tools-models/utils';

export class SchemaDesignerLogicService {
  // Graph schema
  // @ts-ignore
  public graph: Graph;
  // Records the graph schema history
  // @ts-ignore
  private history: History<Graph>;
  // @ts-ignore
  // GSQL type names
  private gsqlTypeNames: { [graphName: string]: string[] };
  // @ts-ignore
  // Original vertex and edge type names
  private originalSchemaVertexTypeTypeNames: string[];

  constructor() {
    this.initialize();
  }

  /**
   * Initialize the service.
   *
   * @memberof SchemaDesignerLogicService
   */
  initialize() {
    this.graph = new Graph();
    this.history = new History<Graph>();
    // At the beginning, always put an empty schema to avoid undefined redo / undo issue.
    this.history.update(this.graph);
    this.gsqlTypeNames = {};
    this.originalSchemaVertexTypeTypeNames = [];
  }

  /**
   * Update GSQL type names retrieved from server side.
   *
   * @param {{[graphName: string]: string[]}} gsqlTypeNames
   * @memberof SchemaDesignerLogicService
   */
  setGSQLTypeNames(gsqlTypeNames: { [graphName: string]: string[] }) {
    this.gsqlTypeNames = gsqlTypeNames;
  }

  /**
   * Get GSQL type names.
   * NOTE: Here global gsql type names only contains udt, global data source, etc.
   *
   * @readonly
   * @type {string[]}
   * @memberof SchemaDesignerLogicService
   */
  get gsqlTypeNameList(): string[] {
    if (this.graph.name === GLOBAL_GRAPH_NAME) {
      return this.gsqlTypeNames[this.graph.name];
    } else {
      return this.gsqlTypeNames[this.graph.name].concat(this.gsqlTypeNames[GLOBAL_GRAPH_NAME]);
    }
  }

  // ======================== Graph schema and style bi-directional conversion =====================

  /**
   * Update graph schema and style.
   *
   * @param {GSQLGraphJson} graphJson
   * @param {DBGraphStyleJson} styleJson
   * @returns {GraphVis}
   *
   * @memberof SchemaDesignerLogicService
   */
  setSchemaAndStyle(graphJson: GSQLGraphJson, styleJson: DBGraphStyleJson | undefined): GraphVis {
    this.graph = new Graph();
    this.originalSchemaVertexTypeTypeNames = [];

    // If schema is defined, apply it onto the graph.
    if (graphJson !== undefined) {
      this.graph.loadFromGSQLJson(graphJson);
      // fill in original schema vertex and edge type names in the schema
      this.graph.vertexTypes.forEach((vertexType) => {
        this.originalSchemaVertexTypeTypeNames.push(vertexType.name);
      });
      this.graph.edgeTypes.forEach((edgeType) => {
        this.originalSchemaVertexTypeTypeNames.push(edgeType.name);
        if (edgeType.hasReverseEdge) {
          this.originalSchemaVertexTypeTypeNames.push(edgeType.reverseEdge);
        }
      });
    }
    // If style is defined, apply it onto the graph.
    if (styleJson !== undefined) {
      const style = new GraphStyle().loadFromDBJson(styleJson);
      this.graph.applyGraphStyle(style);
    }

    // Clear history and set the first state.
    this.reset();

    return this.getGraphVis();
  }

  /**
   * Get a new edge style.
   *
   * @param {string} edgeType
   * @param {Color} color
   * @returns {EdgeStyle}
   * @memberof SchemaDesignerLogicService
   */
  getNewEdgeStyle(edgeType: string, color: Color): EdgeStyle {
    return this.graph.getNewEdgeStyle(edgeType, color);
  }

  /**
   * Get a new vertex style.
   *
   * @param {string} vertexType
   * @param {Color} color
   * @returns {VertexStyle}
   * @memberof SchemaDesignerLogicService
   */
  getNewVertexStyle(vertexType: string, color: Color): VertexStyle {
    return this.graph.getNewVertexStyle(vertexType, color);
  }

  /**
   * Get the schema dump GSQL json.
   *
   * @returns {GSQLGraphJson}
   *
   * @memberof SchemaDesignerLogicService
   */
  getGSQLGraphJson(): GSQLGraphJson {
    return this?.graph.dumpToGSQLJson();
  }

  /**
   * Get the graph visualization representation.
   *
   * @returns {GraphVis}
   *
   * @memberof SchemaDesignerLogicService
   */
  getGraphVis(): GraphVis {
    return this.graph.getVis();
  }

  /**
   * Get the graph style DB representation.
   *
   * @returns {DBGraphStyleJson}
   *
   * @memberof SchemaDesignerLogicService
   */
  getDBGraphStyleJson(): DBGraphStyleJson {
    return this.graph.getGraphStyle().dumpToDBJson();
  }

  // ================================== History control ============================================

  /**
   * Get the schema change history.
   *
   * @returns {History<Graph>}
   * @memberof SchemaDesignerLogicService
   */
  getHistory(): History<Graph> {
    return this.history;
  }

  get curHistoryPointer(): number {
    return this.history.currentPointer;
  }

  /**
   * Forward to next schema status.
   *
   * @returns {GraphVis}
   *
   * @memberof SchemaDesignerLogicService
   */
  redo(): GraphVis {
    this.graph = this.history.redo();
    return this.getGraphVis();
  }

  /**
   * Backward to previous schema status.
   *
   * @returns {GraphVis}
   *
   * @memberof SchemaDesignerLogicService
   */
  undo(): GraphVis {
    this.graph = this.history.undo();
    return this.getGraphVis();
  }

  /**
   * Backward to previous schema status without being able to forward back.
   *
   * @returns {GraphVis}
   * @memberof SchemaDesignerLogicService
   */
  unreversibleUndo(): GraphVis {
    this.graph = this.history.removeLast();
    return this.getGraphVis();
  }

  /**
   * Update a graph to history.
   *
   * @param {Graph} graph
   * @memberof SchemaDesignerLogicService
   */
  updateGraph(graph: Graph) {
    this.history.update(graph);
  }

  /**
   * Reset the history and set the first state.
   *
   * @memberof SchemaDesignerLogicService
   */
  reset() {
    this.history.clear();
    this.history.update(this.graph);
  }
  // ========================== Get vertex and edge by specifying type name ========================

  /**
   * Get the clone of one vertex in the schema.
   *
   * @param {string} name
   * @returns {Vertex}
   *
   * @memberof SchemaDesignerLogicService
   */
  getVertex(name: string): Vertex | undefined {
    const vertex = this.graph.getVertex(name);
    if (vertex !== undefined) {
      return vertex.clone();
    }
  }

  /**
   * Get the clone of one edge in the schema.
   *
   * @param {string} name
   * @returns {Edge}
   *
   * @memberof SchemaDesignerLogicService
   */
  getEdge(name: string): Edge | undefined {
    const edge = this.graph.getEdge(name);
    if (edge !== undefined) {
      return edge.clone();
    }
  }

  /**
   * Get the reverse edge type of an edge type.
   * Return undefined if the there is no reverse edge type for this edge type.
   *
   * @param {string} edgeType
   * @returns {string}
   * @memberof SchemaDesignerLogicService
   */
  getReverseEdgeType(edgeType: string): string | undefined {
    const edge = this.graph.getEdge(edgeType);
    if (edge !== undefined) {
      return edge.reverseEdge;
    } else {
      const forwardEdge = this.graph.edgeTypes.find((e) => e.reverseEdge === edgeType);
      return forwardEdge ? forwardEdge.name : undefined;
    }
  }

  // ============================== Vertex or edge style control ===================================

  /**
   * Update vertex style.
   * This is used for the 1st time assign a position and color for a vertex. After 1st time, should
   * call updateVertexFillColor and updateVertexPosition correspondingly.
   *
   * @param {string} vertexTypeName
   * @param {VertexStyle} style
   * @returns {GraphVis}
   * @memberof SchemaDesignerLogicService
   */
  updateVertexStyle(vertexTypeName: string, style: VertexStyle): GraphVis {
    this.graph.getVertex(vertexTypeName).style = style;
    return this.getGraphVis();
  }

  /**
   * Update vertex style based on info got from server.
   *
   * @param {string} vertexTypeName
   * @param {DBVertexStyleJson} styleJson
   * @returns {GraphVis}
   * @memberof SchemaDesignerLogicService
   */
  updateVertexStyleFromJson(vertexTypeName: string, styleJson: DBVertexStyleJson): GraphVis {
    const style = new VertexStyle().loadFromDBJson(styleJson);
    return this.updateVertexStyle(vertexTypeName, style);
  }

  /**
   * Update edge stroke color.
   * This will be called after choose new color from edge attribute edition panel and apply the
   * edge update, so won't be recorded in history.
   *
   * @param {string} edgeTypeName
   * @param {string} color
   * @returns {GraphVis}
   * @memberof SchemaDesignerLogicService
   */
  updateEdgeStrokeColor(edgeTypeName: string, color: string): GraphVis {
    const edge = this.graph.getEdge(edgeTypeName);
    // If no edge style inited yet, can directly create new one.
    if (edge.style === undefined) {
      edge.style = new EdgeStyle();
    }
    edge.style.fillColor = color;
    edge.style.other = {};
    return this.getGraphVis();
  }

  /**
   * Update vertices positions and record in history.
   *
   * @param {[string, number, number][]} verticesToUpdate
   * @returns {GraphVis}
   * @memberof SchemaDesignerLogicService
   */
  updateVerticesPositions(verticesToUpdate: [string, number, number][]): GraphVis {
    const graph = this.graph.clone();
    let updated = false;
    for (const [vertexTypeName, x, y] of verticesToUpdate) {
      const vertex = graph.getVertex(vertexTypeName);
      // If style not set yet, shouldn't use this method to update position.
      // Use updateVertexStyle instead.
      if (vertex.style !== undefined) {
        vertex.style.x = x;
        vertex.style.y = y;
        updated = true;
      }
    }
    if (updated) {
      this.graph = this.history.update(graph);
    }
    return this.getGraphVis();
  }

  // ================================ Topology update control ======================================

  /**
   * Try to add one vertex type, and do semantic check. If pass, update to new schema;
   * If not pass, return the error message.
   *
   * @param {Vertex} vertex
   * @returns {ValidateResult}
   *
   * @memberof SchemaDesignerLogicService
   */
  addVertex(vertex: Vertex): ValidateResult {
    const graph = this.graph.clone();
    graph.vertexTypes.push(vertex);
    return this.semanticCheckAndUpdateGraph(graph);
  }

  /**
   * Try to add one edge type, and do semantic check. If pass, update to new schema;
   * If not pass, return the error message.
   *
   * @param {Edge} edge
   * @returns {ValidateResult}
   *
   * @memberof SchemaDesignerLogicService
   */
  addEdge(edge: Edge): ValidateResult {
    const graph = this.graph.clone();
    graph.edgeTypes.push(edge);
    return this.semanticCheckAndUpdateGraph(graph);
  }

  /**
   * Update one vertex, update the vertex and edge from / to vertex. Then try to
   * update the schema after doing semantic check.
   *
   * @param {string} vertexTypeName
   * @param {Vertex} vertex
   * @returns {ValidateResult}
   *
   * @memberof SchemaDesignerLogicService
   */
  updateVertex(vertexTypeName: string, vertex: Vertex): ValidateResult {
    const graph = this.graph.clone();
    // Update the vertex
    for (let i = 0; i < graph.vertexTypes.length; i++) {
      if (graph.vertexTypes[i].name === vertexTypeName) {
        graph.vertexTypes[i] = vertex;
        break;
      }
    }
    // Update edge from / to vertex name
    graph.edgeTypes.forEach((edgeType) => {
      edgeType.fromToVertexTypePairs.forEach((pair) => {
        if (pair.from === vertexTypeName) {
          pair.from = vertex.name;
        }
        if (pair.to === vertexTypeName) {
          pair.to = vertex.name;
        }
      });
    });
    // Do semantic check
    return this.semanticCheckAndUpdateGraph(graph);
  }

  /**
   * Update one edge. Then try to update the schema after doing semantic check.
   *
   * @param {string} edgeTypeName
   * @param {Edge} edge
   * @returns {ValidateResult}
   *
   * @memberof SchemaDesignerLogicService
   */
  updateEdge(edgeTypeName: string, edge: Edge): ValidateResult {
    const graph = this.graph.clone();
    // Update the edge
    for (let i = 0; i < graph.edgeTypes.length; i++) {
      if (graph.edgeTypes[i].name === edgeTypeName) {
        graph.edgeTypes[i] = edge;
        break;
      }
    }
    // Do semantic check
    return this.semanticCheckAndUpdateGraph(graph);
  }

  /**
   * Update vertex/edge type usage through entire history.
   *
   * @param {GSQLGraphJson} graphJSON
   * @memberof SchemaDesignerLogicService
   */
  updateUsage(graphJSON: GSQLGraphJson) {
    if (graphJSON.GraphName !== GLOBAL_GRAPH_NAME) {
      return;
    }

    const vertexNames = graphJSON.VertexTypes.map((vertex) => vertex.Name);
    const edgeNames = graphJSON.EdgeTypes.map((edge) => edge.Name);
    for (let i = 0; i < this.history.getHistoryLength(); i++) {
      const graph = this.history.getRecord(i);
      graph.vertexTypes.forEach((vertex) => {
        if (vertexNames.includes(vertex.name)) {
          const index = vertexNames.indexOf(vertex.name);
          vertex.usage = graphJSON.VertexTypes[index].Usage ?? [];
        }
      });
      graph.edgeTypes.forEach((edge) => {
        if (edgeNames.includes(edge.name)) {
          const index = edgeNames.indexOf(edge.name);
          edge.usage = graphJSON.EdgeTypes[index].Usage ?? [];
        }
      });
    }
  }

  /**
   * Remove and add global vertex or edge types in a graph.
   *
   * @param {string[]} verticesToRemove
   * @param {string[]} edgesToRemove
   * @param {Vertex[]} verticesToAdd
   * @param {Edge[]} edgesToAdd
   * @returns {ValidateResult}
   * @memberof SchemaDesignerLogicService
   */
  updateGlobalTypes(
    verticesToRemove: string[],
    edgesToRemove: string[],
    verticesToAdd: Vertex[],
    edgesToAdd: Edge[]
  ): ValidateResult {
    const graph = this.graph.clone();

    // Remove edge types.
    edgesToRemove.forEach((edgeTypeName) => {
      for (let i = 0; i < graph.edgeTypes.length; i++) {
        if (graph.edgeTypes[i].name === edgeTypeName) {
          graph.edgeTypes.splice(i, 1);
          break;
        }
      }
    });

    // Remove vertex types.
    this.removeVertices(verticesToRemove, graph);

    verticesToAdd.forEach((vertexType) => graph.vertexTypes.push(vertexType));
    edgesToAdd.forEach((edgeType) => graph.edgeTypes.push(edgeType));
    return this.semanticCheckAndUpdateGraph(graph);
  }

  /**
   * Remove selected vertices and edges.
   *
   * @param {string[]} vertices
   * @param {{ from: string, to: string, type: string }[]} edges
   * @returns {ValidateResult}
   * @memberof SchemaDesignerLogicService
   */
  remove(vertices: string[], edges: { from: string; to: string; type: string }[]): ValidateResult {
    const graph = this.graph.clone();

    // Remove the edge pairs.
    edges.forEach((triple) => {
      for (let i = 0; i < graph.edgeTypes.length; i++) {
        if (graph.edgeTypes[i].name === triple.type) {
          const edgeType = graph.edgeTypes[i];
          edgeType.fromToVertexTypePairs = edgeType.fromToVertexTypePairs.filter(
            (pair) => !(pair.from === triple.from && pair.to === triple.to)
          );
          if (!edgeType.directed) {
            edgeType.fromToVertexTypePairs = edgeType.fromToVertexTypePairs.filter(
              (pair) => !(pair.from === triple.to && pair.to === triple.from)
            );
          }
          break;
        }
      }
    });

    // Remove the vertices
    this.removeVertices(vertices, graph);

    // Do semantic check
    return this.semanticCheckAndUpdateGraph(graph);
  }

  /**
   * Remove vertex types in a graph.
   *
   * @private
   * @param {string[]} vertices
   * @param {Graph} graph
   * @memberof SchemaDesignerLogicService
   */
  private removeVertices(vertices: string[], graph: Graph) {
    vertices.forEach((vertexTypeName) => {
      // Remove the vertex itself.
      for (let i = 0; i < graph.vertexTypes.length; i++) {
        if (graph.vertexTypes[i].name === vertexTypeName) {
          graph.vertexTypes.splice(i, 1);
          break;
        }
      }
      // Remove all edges starting from or pointing at this vertex type.
      graph.edgeTypes.forEach((edgeType) => {
        edgeType.fromToVertexTypePairs = edgeType.fromToVertexTypePairs.filter(
          (pair) => pair.from !== vertexTypeName && pair.to !== vertexTypeName
        );
      });
    });

    // Filter out all edge types not connecting to any vertex types.
    graph.edgeTypes = graph.edgeTypes.filter((edgeType) => edgeType.fromToVertexTypePairs.length > 0);
  }

  /**
   * Get all vertex types in graph.
   *
   * @returns {string[]}
   *
   * @memberof SchemaDesignerLogicService
   */
  getAllVertexTypes(): string[] {
    return this.graph.getAllVertexTypes();
  }

  /**
   * Get all edge types in graph.
   *
   * @returns {string[]}
   *
   * @memberof SchemaDesignerLogicService
   */
  getAllEdgeTypes(): string[] {
    return this.graph.getAllEdgeTypes();
  }

  /**
   * Get all global vertex or edge type names in graph.
   *
   * @returns {string[]}
   * @memberof SchemaDesignerLogicService
   */
  getAllGlobalTypes(): string[] {
    return this.graph.vertexTypes
      .filter((vertex) => !vertex.isLocal)
      .map((vertex) => vertex.name)
      .concat(this.graph.edgeTypes.filter((edge) => !edge.isLocal).map((edge) => edge.name));
  }

  /**
   * Get all local vertex or edge type names in graph.
   *
   * @returns {string[]}
   * @memberof SchemaDesignerLogicService
   */
  getAllLocalTypes(): string[] {
    return this.graph.vertexTypes
      .filter((vertex) => vertex.isLocal)
      .map((vertex) => vertex.name)
      .concat(this.graph.edgeTypes.filter((edge) => edge.isLocal).map((edge) => edge.name));
  }

  /**
   * Do semantic check on the current graph schema.
   *
   * @returns {ValidateResult}
   *
   * @memberof SchemaDesignerLogicService
   */
  semanticCheckGraph(): ValidateResult {
    const semanticCheck = this.graph.semanticCheck();
    if (!semanticCheck.success) {
      return semanticCheck;
    }
    // Make sure the schema vertex and edge names doesn't have naming conflictions
    // with other GSQL objects.
    return this.gsqlNameConflictionCheck(this.graph);
  }

  /**
   * Semantic check the graph schema. If pass, update the schema to the new status.
   *
   * @param {Graph} graph
   * @returns {ValidateResult}
   *
   * @memberof SchemaDesignerLogicService
   */
  semanticCheckAndUpdateGraph(graph: Graph): ValidateResult {
    // Check the schema itself.
    const semanticCheckResult = graph.semanticCheck();
    if (!semanticCheckResult.success) {
      return semanticCheckResult;
    }
    // Make sure the schema vertex and edge names doesn't have naming conflictions
    // with other GSQL objects.
    const gsqlNameConflictionCheck = this.gsqlNameConflictionCheck(graph);
    if (!gsqlNameConflictionCheck.success) {
      return gsqlNameConflictionCheck;
    }
    this.graph = this.history.update(graph);
    return {
      success: true,
    };
  }

  /**
   * Need make sure all vertex and edge type names doesn't conflict with other type names in GSQL.
   *
   * @private
   * @param {Graph} graph
   * @returns {ValidateResult}
   * @memberof SchemaDesignerLogicService
   */
  private gsqlNameConflictionCheck(graph: Graph): ValidateResult {
    const typeNames = (this.gsqlTypeNames[GLOBAL_GRAPH_NAME] || []).concat(this.gsqlTypeNames[graph.name] || []);
    // Check vertex type name.
    for (const vertexType of graph.vertexTypes) {
      if (typeNames.includes(vertexType.name) && !this.originalSchemaVertexTypeTypeNames.includes(vertexType.name)) {
        return {
          success: false,
          message: `Vertex name "${vertexType.name}" is used by another GSQL object.`,
        };
      }
    }
    // Check edge type (and reverse edge type) name.
    for (const edgeType of graph.edgeTypes) {
      if (typeNames.includes(edgeType.name) && !this.originalSchemaVertexTypeTypeNames.includes(edgeType.name)) {
        return {
          success: false,
          message: `Edge name "${edgeType.name}" is used by another GSQL object.`,
        };
      }
      if (edgeType.hasReverseEdge) {
        if (
          typeNames.includes(edgeType.reverseEdge) &&
          !this.originalSchemaVertexTypeTypeNames.includes(edgeType.reverseEdge)
        ) {
          return {
            success: false,
            message: `Reverse edge name "${edgeType.reverseEdge}" is used by another GSQL object.`,
          };
        }
      }
    }
    return {
      success: true,
    };
  }
}
