import * as React from "react";
const SvgEditor = (props) => /* @__PURE__ */ React.createElement("svg", { width: 33, height: 30, viewBox: "0 0 33 30", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M31.325 1H1.92505V29H31.325V1Z", stroke: "#F4B519", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M3.32495 5.19995H29.925", stroke: "#F4B519", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M4.02515 3.1001H5.42515", stroke: "#F4B519", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M6.125 3.1001H7.525", stroke: "#F4B519", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M8.2251 3.1001H9.6251", stroke: "#F4B519", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M13.825 20.6001L18.725 10.8001", stroke: "#F4811F", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M10.7505 11.4168L6.12502 16.0423L10.7505 20.6677", stroke: "#F4811F", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M21.8516 20.6677L26.7854 16.1964L21.8516 11.1084", stroke: "#F4811F", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })));
export default SvgEditor;
