import { Result } from '@/lib/type';
import { useQueryCPs, useQueryPublicCPs } from '@/pages/admin/settings/cloud_provider/hook';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { isCPDisabled } from '@/pages/admin/settings/cloud_provider/util';
import {
  deleteDatabase,
  deleteWorkSpace,
  getMeta,
  getWorkGroups,
  getWorkspaceOrgQuota,
  pauseWorkSpace,
  refreshWorkSpace,
  renameDatabase,
  renameWorkGroup,
  resumeWorkSpace,
  updateWorkSpace,
} from '@/pages/workgroup/api';
import {
  DatabaseT,
  UpdateWorkspaceRequest,
  WorkGroupT,
  WorkSpaceMeta,
  WorkSpaceOrgQuota,
  WorkspaceT,
} from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

export function useQueryGetMeta() {
  return useQuery<Result<WorkSpaceMeta>, AxiosError>(['workspaceMeta'], getMeta);
}

export function useQueryGetOrgQuota() {
  return useQuery<Result<WorkSpaceOrgQuota>, AxiosError>(['workspaceQuota'], getWorkspaceOrgQuota);
}

export function useQueryGetGroups(
  options?: Omit<UseQueryOptions<Result<WorkGroupT[]>, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(['groups'], getWorkGroups, options);
}

export function useMutationRenameWorkGroup() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; workgroup_name: string }>(
    ({ group_id, workgroup_name }) => {
      return renameWorkGroup(group_id, workgroup_name);
    },
    {
      onSuccess: async (data, { group_id, workgroup_name }) => {
        queryClient.invalidateQueries(['groups']);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              name: workgroup_name,
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useMutationUpdateWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<
    Result<WorkspaceT>,
    AxiosError,
    { group_id: string; space_id: string; data: UpdateWorkspaceRequest }
  >(
    ({ group_id, space_id, data }) => {
      return updateWorkSpace(group_id, space_id, data);
    },
    {
      onSuccess: async (_, { group_id, space_id, data }) => {
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group', group_id]);

        if (data.workspace_name) {
          queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
            if (!oldData?.Result) {
              return oldData;
            }
            let newData = {
              ...oldData,
              Result: {
                ...oldData.Result,
                workspaces: oldData.Result.workspaces.map((w) =>
                  w.workspace_id !== space_id
                    ? w
                    : ({
                        ...w,
                        name: data.workspace_name,
                      } as WorkspaceT)
                ),
              },
            };
            return newData;
          });
        }
      },
    }
  );
}

export function useMutationDeleteWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return deleteWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id, space_id }) => {
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['solutions', group_id]);
        queryClient.invalidateQueries(['quick_insights', group_id]);
        queryClient.invalidateQueries(['workspaceQuota']);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              workspaces: oldData.Result.workspaces.filter((w) => w.workspace_id !== space_id),
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useMutationPauseWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return pauseWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id, space_id }) => {
        queryClient.invalidateQueries(['group', group_id]);
        queryClient.invalidateQueries(['groups']);

        // update workspace status to 'Pending'
        queryClient.setQueryData<Result<WorkGroupT[]> | undefined>(['groups'], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: oldData.Result.map((group) =>
              group.workgroup_id !== group_id
                ? group
                : {
                    ...group,
                    workspaces: group.workspaces.map((w) =>
                      w.workspace_id !== space_id
                        ? w
                        : ({
                            ...w,
                            status: 'Pending',
                          } as WorkspaceT)
                    ),
                  }
            ),
          };
          return newData;
        });
      },
    }
  );
}

export function useMutationResumeWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return resumeWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id }) => {
        queryClient.invalidateQueries(['group', group_id]);
        queryClient.invalidateQueries(['groups']);
      },
    }
  );
}

export function useMutationRefreshWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return refreshWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id, space_id }) => {
        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              workspaces: oldData.Result.workspaces.map((w) =>
                w.workspace_id !== space_id
                  ? w
                  : ({
                      ...w,
                      refresh_status: 'Exporting',
                    } as WorkspaceT)
              ),
            },
          };

          queryClient.invalidateQueries(['group', group_id]);
          return newData;
        });
      },
    }
  );
}

export function useMutationRenameDatabase() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; db_id: string; database_name: string }>(
    ({ group_id, db_id, database_name }) => {
      return renameDatabase(group_id, db_id, database_name);
    },
    {
      onSuccess: async (_, { group_id, db_id, database_name }) => {
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group', group_id]);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              tg_databases: oldData.Result.tg_databases.map((d) =>
                d.database_id !== db_id
                  ? d
                  : ({
                      ...d,
                      name: database_name,
                    } as DatabaseT)
              ),
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useMutationDeleteDatabase() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; db_id: string }>(
    ({ group_id, db_id }) => {
      return deleteDatabase(group_id, db_id);
    },
    {
      onSuccess: async (_, { group_id, db_id }) => {
        queryClient.invalidateQueries(['groups']);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              tg_databases: oldData.Result.tg_databases.filter((d) => d.database_id !== db_id),
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useCloudProviders(filterFn?: (cp: CloudProviderT) => boolean) {
  const publicCPsQ = useQueryPublicCPs();
  const privateCpsQ = useQueryCPs();
  const loading = publicCPsQ.isLoading || privateCpsQ.isLoading;

  const cps: CloudProviderT[] = useMemo(() => {
    const cps = privateCpsQ.data?.Result || [];
    const publicCPs = (publicCPsQ.data?.Result || []).map((provider) => ({
      ...provider,
      type: 'public',
    }));
    return [...cps, ...publicCPs].filter(filterFn || (() => true)).sort((cp) => (isCPDisabled(cp) ? 1 : -1));
  }, [privateCpsQ.data?.Result, publicCPsQ.data?.Result, filterFn]);

  return { cps, isCpLoading: loading };
}
