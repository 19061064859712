import { Form } from '@/pages/workgroup/form/StyledComponent';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { useForm, Controller } from 'react-hook-form';
import { FormTitleContainer } from '@/components/styled';
import TooltipLabel from '@/components/TooltipLabel';
import { Input } from '@tigergraph/app-ui-lib/input';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Slider } from '@/components/ui/slider';
import { FormControl } from '@/components/FormControl';
import { WorkSpaceType } from '@/pages/workgroup/type';

type Props = {
  workspaceTypes: WorkSpaceType[];
  sizeData?: SizeData;
  enable_ha: boolean;
  hide_ha: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (size: SizeData, enable_ha: boolean) => void;
};

export type SizeData = {
  size: number;
  compressRatio: number;
};

// https://graphsql.atlassian.net/wiki/spaces/TGDesignDocs/pages/3142713703/Workspace+Sizing#Configuration-Auto-Suggestion
// RawDataSizeInGBs * (1 - CompressionRatio)*2 + 5
// 1<=compressRatio<=100
export function calculateSize(rawSize: number, compressRatio: number, workspaceTypes: WorkSpaceType[]) {
  const ramSizeInGBs = rawSize * (1 - compressRatio * 0.01) * 2 + 5;
  if (isNaN(ramSizeInGBs)) {
    return workspaceTypes[0];
  }
  for (let workspaceType of workspaceTypes) {
    if (parseInt(workspaceType.memory) > ramSizeInGBs) {
      return workspaceType;
    }
  }
  return workspaceTypes[workspaceTypes.length - 1];
}

export default function SizeSuggestion({
  workspaceTypes,
  isOpen,
  sizeData,
  enable_ha,
  hide_ha,
  onCancel,
  onConfirm,
}: Props) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<
    SizeData & {
      enable_ha: boolean;
    }
  >({
    mode: 'onBlur',
    defaultValues: {
      enable_ha,
      size: sizeData?.size || 10,
      compressRatio: sizeData?.compressRatio || 50,
    },
  });

  const [css, theme] = useStyletron();

  const size = watch('size');
  const compressRatio = watch('compressRatio');
  const workspaceType = calculateSize(size, compressRatio, workspaceTypes);

  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Size Suggestion</ModalHeader>
      <Form>
        <ModalBody>
          <div>
            <div>
              <FormTitleContainer>
                <TooltipLabel
                  label="What is your raw data size?"
                  tooltip={`Could you please provide information on the size of your raw data? This will help us understand the scale of data we will be working with.`}
                />
              </FormTitleContainer>
              <FormControl error={errors?.size?.message}>
                <Controller
                  control={control}
                  name="size"
                  rules={{
                    validate: (value) => {
                      if (!value || value < 0) {
                        return 'data size must great than 0';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange, ref, ...field } }) => {
                    return (
                      <Input
                        placeholder="Enter the size of your raw data in gigabytes (GB)."
                        {...field}
                        value={value > 0 ? `${value}` : ''}
                        onChange={(e) => onChange(Number(e.currentTarget.value))}
                        error={!!errors?.size}
                        autoComplete="off"
                        endEnhancer="GB"
                        type="number"
                      />
                    );
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormTitleContainer>
                <TooltipLabel
                  label="What is the expected compression ratio?"
                  tooltip={`The expected compression ratio refers to the ratio between the original size of the data before compression and the size of the data after compression. It helps in understanding how much the data can be reduced in size through compression techniques.`}
                />
              </FormTitleContainer>
              <FormControl error={errors?.compressRatio?.message}>
                <Controller
                  control={control}
                  name="compressRatio"
                  rules={{
                    validate: (value) => {
                      if (!value || value < 0) {
                        return 'compression ratio must great than 0';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange, ref, name, onBlur } }) => {
                    return (
                      <div
                        className={css({
                          display: 'flex',
                          gap: '8px',
                        })}
                      >
                        <div
                          className={css({
                            flex: 1,
                          })}
                        >
                          <Slider
                            value={[value]}
                            ref={ref}
                            name={name}
                            onBlur={onBlur}
                            min={0}
                            max={100}
                            step={1}
                            onValueChange={(value) => {
                              onChange(value[0]);
                            }}
                            onValueCommit={(value) => {
                              onChange(value[0]);
                            }}
                          />
                        </div>
                        <Input
                          readOnly
                          endEnhancer="%"
                          value={`${value}`}
                          overrides={{
                            Root: {
                              style: {
                                width: '56px',
                              },
                            },
                            Input: {
                              style: {
                                paddingLeft: '8px',
                                paddingRight: 0,
                              },
                            },
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </FormControl>
            </div>
            {/* {hide_ha ? null : (
              <div>
                <FormTitleContainer>
                  <TooltipLabel
                    label="Do you need HA (High Availability)?"
                    tooltip={`Would you like to enable High Availability (HA) for increased system reliability? This option ensures that your system remains operational even in the event of hardware or software failures.`}
                  />
                </FormTitleContainer>
                <FormControl>
                  <Controller
                    control={control}
                    name="enable_ha"
                    render={({ field: { value, onChange, ref, ...field } }) => {
                      return (
                        <RadioGroup
                          value={value ? 'yes' : 'no'}
                          onChange={(e) => {
                            const value = e.currentTarget.value;
                            onChange(value === 'yes');
                          }}
                          align={ALIGN.horizontal}
                          {...field}
                          overrides={{
                            RadioGroupRoot: {
                              style: {
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                gap: '64px',
                              },
                            },
                          }}
                        >
                          <Radio value="yes">yes</Radio>
                          <Radio value="no">no</Radio>
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              </div>
            )} */}
            <div>
              <FormTitleContainer>
                <TooltipLabel
                  label="Suggested Workspace Size"
                  tooltip="Recommended Workspace Size for Optimal Performance"
                />
              </FormTitleContainer>
              <div>
                <div
                  className={css({
                    display: 'grid',
                    gap: '32px',
                    marginTop: '8px',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    border: `1px solid ${theme.colors.divider}`,
                  })}
                >
                  <StyleItem
                    $style={{
                      borderRight: `1px solid ${theme.colors.divider}`,
                    }}
                  >
                    <span>Size:</span>
                    <StyleSizeValue>{workspaceType.typeName}</StyleSizeValue>
                  </StyleItem>

                  <StyleItem
                    $style={{
                      borderRight: `1px solid ${theme.colors.divider}`,
                    }}
                  >
                    <span>CPU:</span>
                    <StyleSizeValue>{workspaceType.cpu}</StyleSizeValue>
                  </StyleItem>
                  <StyleItem>
                    <span>MEMORY:</span>
                    <StyleSizeValue>{workspaceType.memory}</StyleSizeValue>
                  </StyleItem>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="secondary" type="button" onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton
            type="button"
            onClick={handleSubmit((data, e) => {
              e?.stopPropagation();
              onConfirm(
                {
                  size: data.size,
                  compressRatio: data.compressRatio,
                },
                data.enable_ha
              );
            })}
          >
            Apply
          </ModalButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

const StyleSizeValue = styled('span', ({ $theme }) => ({
  color: $theme.colors['text.primary'],
  marginLeft: '4px',
}));

const StyleItem = styled('div', ({ $theme }) => ({
  ...$theme.typography.Body2,
  fontSize: '14px',
  color: $theme.colors['text.secondary'],
  padding: '8px',
}));
