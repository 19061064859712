import { useEffect } from 'react';
import { useNavigate } from 'react-router';

// Wildcards are only allowed for the subdomain - the suggested way to handle multiple routes is to use a single callback route (e.g. /callback)
// and redirect to the desired route from within your application

// callback for auth0 authentication
export default function Callback() {
  const navigate = useNavigate();
  const redirectTo = localStorage.getItem('redirectTo') || '/';

  useEffect(() => {
    localStorage.removeItem('redirectTo');
    navigate(redirectTo, { replace: true });
  }, [navigate, redirectTo]);

  return null;
}
