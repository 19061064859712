import React from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { usePrevNextButtons } from './EmblaCarouselArrowButtons';
import useEmblaCarousel from 'embla-carousel-react';
import { Button } from '@tigergraph/app-ui-lib/button';
import { ChevronRight, ChevronLeft } from 'baseui/icon';
import './embla.css';
import { SHAPE } from 'baseui/button';
import { BaseProvider } from 'baseui';
import { createTheme } from '@tigergraph/app-ui-lib/Theme';

type PropType = {
  children: React.ReactNode;
};

const OPTIONS: EmblaOptionsType = { align: 'start' };

export function EmblaCarouselItem({ children }: { children: React.ReactNode }) {
  return <div className="embla__slide">{children}</div>;
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { children } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  // hardcode to light theme
  return (
    <BaseProvider theme={createTheme({})}>
      <section className="embla">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px 8px',
          }}
        >
          <Button kind="text" shape={SHAPE.square} onClick={onPrevButtonClick} disabled={prevBtnDisabled}>
            <ChevronLeft size={36} />
          </Button>
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">{children}</div>
          </div>
          <Button kind="text" shape={SHAPE.square} onClick={onNextButtonClick} disabled={nextBtnDisabled}>
            <ChevronRight size={36} />
          </Button>
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(index === selectedIndex ? ' embla__dot--selected' : '')}
            />
          ))}
        </div>
      </section>
    </BaseProvider>
  );
};

export default EmblaCarousel;
