const multiWords = [
  'CREATE QUERY',
  'CREATE OR REPLACE QUERY',
  'CREATE BATCH QUERY',
  'CREATE DISTRIBUTED QUERY',
  'INSTALL QUERY',
  'RUN QUERY',
  'SHOW QUERY',
  'DROP QUERY',
  'FOR GRAPH',
  'ORDER BY',
  'ELSE IF',
  'IN RANGE',
  'TYPEDEF TUPLE',
  'GROUP BY',
].map((word) => ({ label: word, type: 'multi_keyword' }));

// reserved words and non-reserved keywords come from: https://docs.tigergraph.com/gsql-ref/current/appendix/keywords-and-reserved-words
const reservedWords = [
  'ACCUM',
  'ADD',
  'ADMIN',
  'ALL',
  'ALLOCATE',
  'ALTER',
  'ANY',
  'AS',
  'ASC',
  'AVG',
  'BAG',
  'BATCH',
  'BIGINT',
  'BLOB',
  'BOOL',
  'BOOLEAN',
  'BOTH',
  'BREAK',
  'BY',
  'CALL',
  'CASCADE',
  'CASE',
  'CATCH',
  'CHAR',
  'CHARACTER',
  'CHECK',
  'CLOB',
  'COALESCE',
  'COMPRESS',
  'CONST',
  'CONSTRAINT',
  'CONTINUE',
  'COST',
  'COUNT',
  'CREATE',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'CURSOR',
  'DATETIME',
  'DATETIME_ADD',
  'DATETIME_SUB',
  'DECIMAL',
  'DECLARE',
  'DELETE',
  'DESC',
  'DISTRIBUTED',
  'DO',
  'DOUBLE',
  'DROP',
  'EDGE',
  'ELSE',
  'ELSEIF',
  'END',
  'EXCEPTION',
  'EXISTS',
  'FILE',
  'FILTER',
  'FIXED_BINARY',
  'FLOAT',
  'FOR',
  'FOREACH',
  'FROM',
  'GLOBAL',
  'GRANTS',
  'GRAPH',
  'GROUP',
  'GROUPBYACCUM',
  'HAVING',
  'HEADER',
  'HEAPACCUM',
  'IF',
  'IGNORE',
  'INDEX',
  'INPUT_LINE_FILTER',
  'INSERT',
  'INT',
  'INT16',
  'INT32',
  'INT32_T',
  'INT64_T',
  'INT8',
  'INTEGER',
  'INTERPRET',
  'INTO',
  'IS',
  'ISEMPTY',
  'JOB',
  'JOIN',
  'JSONARRAY',
  'JSONOBJECT',
  'KAFKA',
  'KEY',
  'LEADING',
  'LIMIT',
  'LIST',
  'LOAD',
  'LOADACCUM',
  'LOG',
  'LONG',
  'MAP',
  'MAX',
  'MIN',
  'NOBODY',
  'NOW',
  'OFFSET',
  'ON',
  'ORDER',
  'PACKAGE',
  'PINNED',
  'POST-ACCUM',
  'POST_ACCUM',
  'PRIMARY',
  'PRIMARY_ID',
  'PRINT',
  'PROXY',
  'QUERY',
  'QUIT',
  'RAISE',
  'RANGE',
  'REDUCE',
  'REPLACE',
  'RESET_COLLECTION_ACCUM',
  'RETURN',
  'RETURNS',
  'RUN',
  'S3',
  'SAMPLE',
  'SELECT',
  'SELECTVERTEX',
  'SET',
  'STATIC',
  'STREAM',
  'STRING',
  'SUM',
  'SYS.FILENAME',
  'SYS.INTERNAL_ID',
  'TARGET',
  'TEMP_TABLE',
  'THEN',
  'TO',
  'TO_CSV',
  'TO_DATETIME',
  'TRAILING',
  'TRANSLATESQL',
  'TRIM',
  'TRY',
  'TUPLE',
  'TYPEDEF',
  'UINT',
  'UINT16',
  'UINT32',
  'UINT32_T',
  'UINT64_T',
  'UINT8',
  'UINT8_T',
  'UPDATE',
  'UPSERT',
  'USING',
  'VALUES',
  'VERTEX',
  'WHEN',
  'WHERE',
  'WHILE',
  'WITH',
].map((word) => ({ label: word, type: 'keyword' }));

const nonReservedKeyWords = [
  'ABORT',
  'ACL',
  'ADMIN',
  'API',
  'APPROX_COUNT',
  'ATTRIBUTE',
  'BEGIN',
  'BITINDEX',
  'CHANGE',
  'CLEAR',
  'CONCAT',
  'DATA',
  'DATASRC',
  'DECRYPT',
  'DEFAULT_',
  'DEFINE',
  'DESCRIPTION',
  'DIRECTED',
  'EMPTY',
  'EXECUTE',
  'EXIT',
  'EXPORT',
  'TG_EXPR_FUNC',
  'TG_EXPR_UTIL',
  'EXPR_FUNC',
  'EXPR_UTIL',
  'EXTERN',
  'FILENAMEVAR',
  'FLATTEN',
  'FLATTENJSON',
  'GENERATEDATA',
  'GET',
  'GRANT',
  'HELP',
  'ICON',
  'IMPORT',
  'INSTALL',
  'JSON',
  'LEADER',
  'LOADING',
  'LOCAL',
  'LS',
  'MAX',
  'MIN',
  'NUMERIC',
  'OF',
  'OPTION',
  'OVERWRITE',
  'OWNER',
  'PAIR',
  'PASSWORD',
  'PRIVILEGE',
  'PUT',
  'READ',
  'RECOMPILE',
  'REJECT_LINE_RULE',
  'RESUME',
  'REVOKE',
  'ROLE',
  'RUN',
  'SCHEMA',
  'SCHEMA_CHANGE',
  'SECONDARY_ID',
  'SECRET',
  'SECURED',
  'SEPARATOR',
  'SHOW',
  'SPLIT',
  'STATS',
  'STATUS',
  'STORE',
  'SUBSTR',
  'SYNTAX',
  'TAG',
  'TAGS',
  'TEMPLATE',
  'TK',
  'TOKENLEN',
  'TOKEN_BANK',
  'TOFLOAT',
  'TOINT',
  'UNDIRECTED',
  'USE',
  'USER',
  'USERS',
  'VAL',
  'VECTOR',
  'VERSION',
  'VOID',
  'SINGLE',
  'LEGACY',
].map((word) => ({ label: word, type: 'word' }));

const types = [
  'INT',
  'UINT',
  'FLOAT',
  'DOUBLE',
  'STRING',
  'STRING COMPRESS',
  'DATETIME',
  'BOOL',
  'VERTEX',
  'EDGE',
  'JSONOBJECT',
  'JSONARRAY',
  'SET',
  'BAG',
  'FILE',
  'AvgAccum',
  'SumAccum',
  'MaxAccum',
  'MinAccum',
  'OrAccum',
  'AndAccum',
  'BitwiseOrAccum',
  'BitwiseAndAccum',
  'ListAccum',
  'SetAccum',
  'BagAccum',
  'MapAccum',
  'ArrayAccum',
  'HeapAccum',
  'GroupByAccum',
].map((word) => ({ label: word, type: 'type' }));

const constants = ['TRUE', 'FALSE', 'NULL', 'GSQL_UINT_MAX', 'GSQL_INT_MAX', 'GSQL_INT_MIN'].map((word) => ({
  label: word,
  type: 'constant',
}));

const operators = [
  'NOT',
  'AND',
  'OR',
  'BETWEEN',
  'IS NULL',
  'IS NOT NULL',
  'LIKE',
  'NOT LIKE',
  'ESCAPE',
  'UNION',
  'INTERSECT',
  'MINUS',
  'IN',
  'NOT IN',
].map((word) => ({ label: word, type: 'operator' }));

const functions = [
  'abs',
  'sqrt',
  'pow',
  'acos',
  'asin',
  'atan',
  'atan2',
  'ceil',
  'cos',
  'cosh',
  'exp',
  'floor',
  'fmod',
  'ldexp',
  'log',
  'log10',
  'sin',
  'sinh',
  'tan',
  'tanh',
  'to_string',
  'float_to_int',
  'str_to_int',
  'lower',
  'upper',
  'trim',
  'to_datetime',
  'epoch_to_datetime',
  'datetime_to_epoch',
  'datetime_format',
  'now',
  'year',
  'month',
  'day',
  'hour',
  'minute',
  'second',
  'datetime_add',
  'datetime_sub',
  'datetime_diff',
  'parse_json_object',
  'parse_json_array',
  'containsKey',
  'getInt',
  'getDouble',
  'getString',
  'getBool',
  'getJsonObject',
  'getJsonArray',
  'size',
  'clear',
  'outdegree',
  'neighbors',
  'neighborAttribute',
  'edgeAttribute',
  'getAttr',
  'setAttr',
  'isDirected',
  'count',
  'sum',
  'min',
  'max',
  'avg',
  'selectVertex',
  'to_vertex',
  'to_vertex_set',
  'coalesce',
  'getvid',
  'evaluate',
].map((word) => ({ label: word, type: 'function' }));

let graphInfo: string[] = [];
let wordsInQuery: string[] = [];

const dictionary = ([] as Array<string | { label: string; type: string }>).concat(
  multiWords,
  reservedWords,
  nonReservedKeyWords,
  constants,
  types,
  operators,
  functions
);

function setGraphInfo(newInfo: string[]) {
  graphInfo = newInfo;
}

function setWordsInQuery(newWordsInQuery: string[]) {
  wordsInQuery = newWordsInQuery;
}

function extractCodesWords(codes: string) {
  const queryCode = removeCommentsInCode(codes);
  // Remove string in quotes.
  const cleanQueryCode = queryCode.replace(new RegExp(/'(.|\n)*?'|"(.|\n)*?"/g), '').trim();

  // Split by any non-word character except '@'.
  let wordsInQuery = cleanQueryCode.split(/[^@\w]/);
  wordsInQuery = wordsInQuery.filter((word) => word !== '');

  setWordsInQuery(wordsInQuery);
}

function removeCommentsInCode(codes: string): string {
  // '\/\*(.|\n)*?\*\/' matches multiple lines comment
  // '(\/\/|#).*' matches single line comment
  const multiLineCommentPattern = new RegExp(/\/\*(.|\n)*?\*\//g);
  const singleLineCommentPattern = new RegExp(/(\/\/|#).*/g);
  return codes.replace(multiLineCommentPattern, '').replace(singleLineCommentPattern, '');
}

const allHints = [...new Set(dictionary.concat(graphInfo, wordsInQuery))];

export { extractCodesWords, setGraphInfo, allHints };
