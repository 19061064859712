import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body2 } from '@tigergraph/app-ui-lib/typography';

import { StyledSubTitle, StyledText, StyledTitle, InlineBlock } from './Style.tsx';

export const PrivacyPolicy = () => {
  const [css, theme] = useStyletron();

  return (
    <InlineBlock style={{ flexDirection: 'column', gap: '16px' }}>
      <StyledText>
        Protecting your private information is our priority. This Statement of Privacy governs data collection and usage
        for tigergraph.com, tigergraph.com.cn, their respective subdomains, and any other websites owned and operated by
        TigerGraph, Inc. (“TigerGraph”). The TigerGraph website may include opt-in services for data storage, data
        visualization, and data analysis. By using the TigerGraph website, you consent to the data practices described
        in this statement.
      </StyledText>
      <StyledTitle>Collection of Your Personal Information</StyledTitle>
      <div>
        <Body2 marginBottom="8px">TigerGraph may collect personally identiﬁable information, such as your:</Body2>
        <ul className={css({ margin: 0, paddingLeft: '20px' })}>
          <li>
            <StyledSubTitle>Name</StyledSubTitle>
          </li>
          <li>
            <StyledSubTitle>Address</StyledSubTitle>
          </li>
          <li>
            <StyledSubTitle>E-mail Address</StyledSubTitle>
          </li>
          <li>
            <StyledSubTitle>Phone Number</StyledSubTitle>
          </li>
          <li>
            <StyledSubTitle>IP Address</StyledSubTitle>
          </li>
          <li>
            <StyledSubTitle>Time and date of your access</StyledSubTitle>
          </li>
          <li>
            <StyledSubTitle>
              Personal information that you send to TigerGraph or allow TigerGraph to access on your behalf as part of
              the opt-in services
            </StyledSubTitle>
          </li>
        </ul>
      </div>
      <StyledText>
        If you purchase TigerGraph’s products and services, we collect billing and credit card information. This
        information is used to complete the purchase transaction. Please keep in mind that if you directly disclose
        personally identifiable information or personally sensitive data through TigerGraph’s public message boards,
        this information may be collected and used by others.
      </StyledText>
      <StyledText>
        TigerGraph encourages you to review the privacy statements of websites you choose to link to from TigerGraph so
        that you can understand how those websites collect, use and share your information. TigerGraph is not
        responsible for the privacy statements or other content on websites outside of the TigerGraph website.
      </StyledText>

      <StyledTitle>Use of Your Personal Information</StyledTitle>
      <StyledText>
        TigerGraph collects and uses your personal information to operate its website(s) and deliver the services you
        have requested.
      </StyledText>
      <StyledText>
        TigerGraph may also use your personally identifiable information to inform you of other products or services
        available from TigerGraph and its affiliates. TigerGraph may also contact you via surveys to conduct research
        about your opinion of current services or of potential new services that may be offered.
      </StyledText>
      <StyledText>TigerGraph does not sell, rent or lease your information to third parties.</StyledText>
      <StyledText>
        TigerGraph may share your information with trusted partners to help perform statistical analysis, send you email
        or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from
        using your personal information except to provide these services to TigerGraph, and they are required to
        maintain the conﬁdentiality of your information.
      </StyledText>
      <StyledText>
        TigerGraph may keep track of the websites and pages our users visit within TigerGraph, in order to determine
        what TigerGraph services are the most popular. This data is used to deliver customized content and advertising
        within TigerGraph to customers whose behavior indicates that they are interested in a particular subject area.
      </StyledText>
      <StyledText>
        TigerGraph will disclose your personal information, without notice, only if required to do so by law or in the
        good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal
        process served on TigerGraph or the site; (b) protect and defend the rights or property of TigerGraph ; and, (c)
        act under exigent circumstances to protect the personal safety of users of TigerGraph , or the public.
      </StyledText>

      <StyledTitle>Automatically Collected Information</StyledTitle>
      <StyledText>
        Information about your computer hardware and software may be automatically collected by TigerGraph. This
        information can include: your IP address, browser type, domain names, access times and referring website
        addresses. This information is used for the operation of the service, to maintain quality of the service, and to
        provide general statistics regarding use of the TigerGraph website.
      </StyledText>

      <StyledTitle>Use of Cookies</StyledTitle>
      <StyledText>
        The TigerGraph website may use “cookies” to help you personalize your online experience. A cookie is a text file
        that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses
        to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that
        issued the cookie to you.
      </StyledText>
      <StyledText>
        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a
        cookie is to tell the Web server that you have returned to a speciﬁc page. For example, if you personalize
        TigerGraph pages, or register with TigerGraph site or services, a cookie helps TigerGraph to recall your speciﬁc
        information on subsequent visits. This simpliﬁes the process of recording your personal information, such as
        billing addresses, shipping addresses, and so on. When you return to the same TigerGraph website, the
        information you previously provided can be retrieved, so you can easily use the TigerGraph features that you
        customized You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies,
        but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline
        cookies, you may not be able to ﬁrlly experience the interactive features of the TigerGraph services or websites
        you visit.
      </StyledText>

      <StyledTitle>Security of Your Personal Information</StyledTitle>
      <StyledText>
        TigerGraph secures your personal information from unauthorized access, use, or disclosure. TigerGraph uses the
        following methods for this purpose:
      </StyledText>
      <ul className={css({ color: theme.colors.black02, margin: 0, paddingLeft: '20px' })}>
        <li>
          <StyledSubTitle>
            SSL Protocol: Your data is encrypted when it travels to or from the TigerGraph website.
          </StyledSubTitle>
        </li>
        <li>
          <StyledSubTitle>
            Data uploaded to TigerGraph data services: TigerGraph uses Amazon Web Services (AWS) to provide our
            cloud-based graph analytics service. AWS is trusted by the world’s leading companies to provide reliable and
            secure service.
          </StyledSubTitle>
        </li>
        <li>
          <StyledSubTitle>
            See{' '}
            <a
              href="https://aws.amazon.com/compliance/data-privacy-faq/"
              target="_blank"
              style={{ color: theme.colors.secondary900 }}
              rel="noreferrer"
            >
              AWS
            </a>{' '}
            for more information about how they protect your data.
          </StyledSubTitle>
        </li>
        <li>
          <StyledSubTitle>
            When personal information is transmitted to other websites, it is protected through the use of encryption,
            such as the Secure Sockets Layer (SSL) protocol. Your credit card number is not stored on the TigerGraph
            website.
          </StyledSubTitle>
        </li>
      </ul>

      <StyledTitle>Children</StyledTitle>
      <StyledText>
        TigerGraph does not knowingly collect personally identiﬁable information from children under the age of
        thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this
        website.
      </StyledText>

      <StyledTitle>Changes To This Statement</StyledTitle>
      <StyledText>
        TigerGraph will occasionally update this Statement of Privacy to reﬂect company and customer feedback.
        TigerGraph encourages you to periodically review this Statement located at www.tigergraph.com/privacy, or to
        request a copy of this policy at the address below to be informed of how TigerGraph is protecting your
        information.
      </StyledText>

      <StyledTitle>Contact Information</StyledTitle>
      <StyledText>
        TigerGraph welcomes your questions or comments regarding this Statement of Privacy. If you believe that
        TigerGraph has not adhered to this Statement, to request changes to your personally identifiable information
        retained by TigerGraph, or to exercise choice on how we collect and process your personally identifiable
        information please contact TigerGraph at:
      </StyledText>
      <ul className={css({ margin: 0, paddingLeft: 0, listStyleType: 'none' })}>
        <li>
          <StyledSubTitle>TigerGraph</StyledSubTitle>
        </li>
        <li>
          <StyledSubTitle>3 Twin Dolphin Drive, Suite 225</StyledSubTitle>
        </li>
        <li>
          <StyledSubTitle>Redwood City , California 94065</StyledSubTitle>
        </li>
        <li>
          <StyledSubTitle>
            Email Address:{' '}
            <a href="mailto:privacy@tigergraph.com" style={{ color: theme.colors['text.link'] }}>
              privacy@tigergraph.com
            </a>
          </StyledSubTitle>
        </li>
      </ul>
      <StyledText>
        We will respond to requests as commercially reasonable, within the capabilities of our systems, and in
        accordance with applicable data privacy law.
      </StyledText>

      <Body2 $style={{ fontStyle: 'italic' }}>Effective on February 18, 2018</Body2>
    </InlineBlock>
  );
};
