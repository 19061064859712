import { useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useMutation } from 'react-query';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@tigergraph/app-ui-lib/modal';
import { Button } from '@tigergraph/app-ui-lib/button';
import { AxiosError } from 'axios';
import ErrorMessage from '../ErrorMessage';
import ConfirmButtons from '@/components/ConfirmButtons';
import { expand } from 'inline-style-expand-shorthand';
import { GLOBAL_GRAPH_NAME, deleteGraph } from '@tigergraph/tools-models';
import { showToast } from '@/components/styledToasterContainer';
import { KIND } from 'baseui/toast';
import { ID_TOKEN_KEY, useWorkspaceContext } from '@/contexts/workspaceContext';
import { WorkspaceT } from '@/pages/workgroup/type';

export interface DropGraphModalProps {
  isOpen: boolean;
  graphName: string;
  workspace: WorkspaceT;
  onClose: () => void;
}

export default function DropGraphModal(props: DropGraphModalProps) {
  const [css] = useStyletron();
  const { isOpen, graphName, workspace, onClose } = props;
  const { graphNames, currentGraph, setCurrentGraph, setGraphNames } = useWorkspaceContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const { mutate: mutateDropGraph, isLoading } = useMutation(
    ['dropGraph'],
    async (graphName: string) => {
      const response = await deleteGraph(
        {
          graph: graphName,
        },
        {
          baseURL: `https://${workspace.nginx_host}`,
          version: workspace.tg_version,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          },
        }
      );
      return response;
    },
    {
      onSuccess: () => {
        handleClose();
        if (currentGraph === graphName) {
          const newGraphName = graphNames.filter((name) => name !== GLOBAL_GRAPH_NAME && name !== graphName);
          if (newGraphName.length) {
            setCurrentGraph(newGraphName[0]);
          } else {
            setCurrentGraph('');
          }
        }
        setGraphNames(graphNames.filter((name) => name !== graphName));
        setErrorMessage(undefined);
        showToast({
          kind: KIND.positive,
          message: `Successfully dropped graph ${graphName}.`,
        });
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setErrorMessage(error.response?.data?.message);
        showToast({
          kind: KIND.negative,
          message: `Failed to drop graph ${graphName}\n${error.response?.data?.message}`,
        });
      },
    }
  );

  const handleClose = () => {
    onClose();
    setErrorMessage(undefined);
  };

  return (
    <div
      className={css({
        display: 'flex',
        width: '100%',
      })}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          mutateDropGraph(graphName);
        }
      }}
    >
      <Modal
        onClose={handleClose}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: {
              zIndex: 2,
            },
          },
        }}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Urbanist',
            fontSize: '16px',
            fontWeight: 600,
            ...expand({
              padding: '0',
            }),
          })}
        >
          Delete Graph
        </ModalHeader>
        <ModalBody>
          <div
            className={css({
              marginBottom: '8px',
              fontSize: '14px',
              fontWeight: 400,
            })}
          >
            Are you sure you want to drop graph {graphName}?
          </div>
          {errorMessage && <ErrorMessage message={errorMessage} />}
        </ModalBody>
        <ModalFooter>
          {isLoading ? (
            <Button isLoading={isLoading} />
          ) : (
            <ConfirmButtons
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              onConfirm={() => {
                mutateDropGraph(graphName);
              }}
              onCancel={handleClose}
            />
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
