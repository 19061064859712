import React from 'react';

export type Tutorial = {
  id: string;
  title: string;
  icon: React.ReactNode;
  abstract: string;
  sub_chapter: SubChapter[];
  routerKey: string;
};

export type SubChapter = {
  Title: string;
  Description: string;
  Url: string;
  TutorialID: string;
  Type: SubChapterType;
};

export enum SubChapterType {
  Link = 'Link',
  Video = 'Video',
}
