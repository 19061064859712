import { mergeOverrides } from 'baseui';
import { Notification } from 'baseui/notification';
import { Overrides } from 'baseui/overrides';
import { ToastOverrides, ToastProps } from 'baseui/toast';

import { IoAlertCircle, IoCheckmarkCircle, IoWarning } from 'react-icons/io5';
import { RiInformationFill } from 'react-icons/ri';

import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body1 } from '@tigergraph/app-ui-lib/typography';
import LinkButton from '@/components/LinkButton';
import { useAuth0 } from '@auth0/auth0-react';
import { logoutClearance } from '@/utils/utils';

export function BaseNotification({ overrides, ...props }: ToastProps) {
  const notificationOverrides: ToastOverrides = {
    Body: {
      style: {
        borderLeftWidth: '20px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        marginLeft: '0',
        marginRight: '0',
      },
    },
  };

  return (
    <Notification
      {...props}
      overrides={mergeOverrides(notificationOverrides as Overrides<any>, overrides as Overrides<any>)}
    ></Notification>
  );
}

export function InfoNotification({ children, overrides, ...props }: ToastProps) {
  // eslint-disable-next-line
  const [css] = useStyletron();

  const baseInfoNotificationOverrides: ToastOverrides = {
    Body: {
      style: {
        borderLeftColor: '#0A88E4',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), inset 4px 0px 0px #0A88E4',
        width: 'calc(100% - 32px)',
        marginBottom: '40px',
        overflow: 'hidden',
      },
    },
    InnerContainer: {
      style: {
        width: 'calc(100% - 24px)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '20px',
      },
    },
  };

  return (
    <BaseNotification
      {...props}
      overrides={mergeOverrides(baseInfoNotificationOverrides as Overrides<any>, overrides as Overrides<any>)}
    >
      <div
        className={css({
          height: '18px',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <RiInformationFill size={16} color="#0A88E4" />
      </div>
      <>{children}</>
    </BaseNotification>
  );
}

export function WarningNotification({ children, overrides, ...props }: ToastProps) {
  // eslint-disable-next-line
  const [css] = useStyletron();

  const baseWarningNotificationOverrides: ToastOverrides = {
    Body: {
      style: {
        width: 'calc(100% - 32px)',
        marginBottom: '40px',
        borderLeftColor: '#E49A0A',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), inset 4px 0px 0px #E49A0A',
        backgroundColor: '#FDF5E7',
        overflow: 'hidden',
      },
    },
    InnerContainer: {
      style: {
        display: 'flex',
        width: 'calc(100% - 24px)',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '20px',
      },
    },
  };

  return (
    <BaseNotification
      {...props}
      kind={'warning'}
      overrides={mergeOverrides(baseWarningNotificationOverrides as Overrides<any>, overrides as Overrides<any>)}
    >
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          height: '24px',
          width: '16px',
        })}
      >
        <IoWarning size={16} color="#E49A0A" />
      </div>

      <>{children}</>
    </BaseNotification>
  );
}

export function PositiveNotification({ children, overrides, ...props }: ToastProps) {
  // eslint-disable-next-line
  const [css] = useStyletron();

  const basePositiveNotificationOverrides: ToastOverrides = {
    Body: {
      style: {
        position: 'sticky',
        bottom: '24px',
        right: '24px',
        left: '24px',
        width: '100%vw',
        borderLeftColor: '#30A442',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), inset 4px 0px 0px #30A442',
        zIndex: '1000',
      },
    },
    InnerContainer: {
      style: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '20px',
      },
    },
  };

  return (
    <BaseNotification
      {...props}
      kind="positive"
      overrides={mergeOverrides(basePositiveNotificationOverrides as Overrides<any>, overrides as Overrides<any>)}
    >
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          height: '24px',
          width: '16px',
        })}
      >
        <IoCheckmarkCircle size={16} color="#30A442" />
      </div>

      <Body1>
        <>{children}</>
      </Body1>
    </BaseNotification>
  );
}

export function NegativeNotification({ children, overrides, ...props }: ToastProps) {
  // eslint-disable-next-line
  const [css] = useStyletron();

  const baseNegativeNotificationOverrides: ToastOverrides = {
    Body: {
      style: {
        position: 'sticky',
        bottom: '24px',
        right: '24px',
        left: '24px',
        width: '100%vw',
        borderLeftColor: '#B93535',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), inset 4px 0px 0px #B93535',
        zIndex: '1000',
      },
    },
    InnerContainer: {
      style: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '20px',
      },
    },
  };

  const { logout } = useAuth0();
  return (
    <div
      className={css({
        padding: '24px',
      })}
    >
      <BaseNotification
        {...props}
        kind="negative"
        closeable={false}
        overrides={mergeOverrides(baseNegativeNotificationOverrides as Overrides<any>, overrides as Overrides<any>)}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            height: '24px',
            width: '16px',
          })}
        >
          <IoAlertCircle size={16} color="#B93535" />
        </div>

        <Body1>
          <>{children}</>
        </Body1>
        <LinkButton
          onClick={() => {
            logoutClearance();
            logout({
              logoutParams: { returnTo: window.location.origin },
            });
          }}
        >
          Log out
        </LinkButton>
      </BaseNotification>
    </div>
  );
}
