import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { General, GeneralType } from '@/pages/workgroup/form/WorkSpaceSubForm';
import { useMutationUpdateWorkspace } from '@/pages/workgroup/hook';
import { UpdateWorkspaceRequest, WorkspaceT, WorkSpaceType } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

export default function GeneralForm({
  workspace,
  onClose,
  workspaceTypes,
}: {
  workspace: WorkspaceT;
  onClose: () => void;
  workspaceTypes: WorkSpaceType[];
}) {
  const form = useForm<GeneralType>({
    defaultValues: {
      workspace_type_name: workspace.workspace_type.typeName,
      // for edit mode, we do not allow to change enable_ha
    },
  });

  const { handleSubmit, watch } = form;

  const workspace_type_name = watch('workspace_type_name');
  const disabled = !workspace.is_rw || workspace.workspace_type.typeName === workspace_type_name;

  const updateWorkSpaceMutation = useMutationUpdateWorkspace();

  const onUpdate = (data: GeneralType) => {
    const { workspace_type_name } = data;
    const updateRequest: UpdateWorkspaceRequest = { workspace_type_name };

    const promise = updateWorkSpaceMutation.mutateAsync(
      {
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
        data: updateRequest,
      },
      {
        onSuccess() {
          onClose();
        },
      }
    );

    toast.promise(
      promise,
      {
        loading: 'Resizing workspace',
        success: (data) => 'Workspace resizing request accepted.',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  return (
    <StyledForm>
      <StyledFormBody>
        <General form={form} workspace={workspace} workspaceTypes={workspaceTypes} disableResize={!workspace.is_rw} />
      </StyledFormBody>
      <StyledFormAction>
        <Button type="button" kind="secondary" size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          disabled={disabled}
          onClick={handleSubmit(async (data) => {
            onUpdate(data);
          })}
        >
          Save
        </Button>
      </StyledFormAction>
    </StyledForm>
  );
}
