import { Result } from '@/lib/type';
import {
  addAllowIP,
  configAllowIP,
  deleteAllowIP,
  getMyIP,
  listAllowIP,
  updateAllowIP,
} from '@/pages/workgroup/tab/networkAccess/api';
import { AllowIP, MyIP } from '@/pages/workgroup/tab/networkAccess/type';
import { WorkGroupT } from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { FormData } from './type';

export function useMutationConfigAllowIP() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; enable: boolean }>(
    ({ group_id, enable }) => {
      return configAllowIP(group_id, enable);
    },
    {
      onSuccess: async (data, { group_id, enable }) => {
        queryClient.invalidateQueries(['group', group_id]);
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group_network']);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              enabled_allow_list: enable,
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useQueryGroupAccess(
  group_id: string,
  options?: Omit<UseQueryOptions<Result<MyIP>, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    ['group_network', group_id],
    () => {
      return getMyIP(group_id);
    },
    options
  );
}

export type GroupAccess = {
  current_ip: string;
  is_allowed: Map<string, boolean>;
};

export function useQueryGroupsAccess(
  group_ids: string[],
  options?: Omit<UseQueryOptions<GroupAccess, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    ['group_network', group_ids],
    async () => {
      const promises: Promise<Result<MyIP>>[] = [];
      for (let group_id of group_ids) {
        const promise = getMyIP(group_id);
        promises.push(promise);
      }
      const results = await Promise.all(promises);
      const groupAccess: GroupAccess = {
        current_ip: '',
        is_allowed: new Map<string, boolean>(),
      };
      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        const group_id = group_ids[i];
        if (result.Result) {
          const { current_ip, is_allowed } = result.Result;
          groupAccess.current_ip = current_ip;
          groupAccess.is_allowed.set(group_id, is_allowed);
        }
      }
      return groupAccess;
    },
    options
  );
}

export function useMutationAddAllowIP() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; data: FormData }>(
    ({ group_id, data }) => {
      return addAllowIP(group_id, data);
    },
    {
      onSuccess: async (_, { group_id }) => {
        queryClient.invalidateQueries(['allow_list', group_id]);
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group_network']);
      },
    }
  );
}

export function useMutationDeleteAllowIP() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; id: string }>(
    ({ group_id, id }) => {
      return deleteAllowIP(group_id, id);
    },
    {
      onSuccess: async (_, { group_id }) => {
        queryClient.invalidateQueries(['allow_list', group_id]);
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group_network']);
      },
    }
  );
}

export function useMutationUpdateAllowIP() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; id: string; data: FormData }>(
    ({ group_id, id, data }) => {
      return updateAllowIP(group_id, id, data);
    },
    {
      onSuccess: async (_, { group_id }) => {
        queryClient.invalidateQueries(['allow_list', group_id]);
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group_network']);
      },
    }
  );
}

export function useQueryListAllowIP(
  group_id: string,
  options?: Omit<UseQueryOptions<Result<AllowIP[]>, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    ['allow_list', group_id],
    () => {
      return listAllowIP(group_id);
    },
    options
  );
}
