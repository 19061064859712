import PluginIcon from '@/assets/plugin.svg?react';
import { cn } from '@/lib/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';

const WorkspaceAddonsEmpty = () => {
  const [css, theme] = useStyletron();
  return (
    <>
      <li className="relative flex flex-col items-center space-x-1 px-2 py-4 border rounded-[2px] w-full h-full">
        <PluginIcon />
        <span className={cn('font-[Roboto] text-xs', css({ color: theme.colors['text.tertiary'] }))}>
          No Add-on enabled
        </span>
      </li>
    </>
  );
};

export default WorkspaceAddonsEmpty;
