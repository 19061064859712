import Container from '@/components/landing/Container.tsx';
import { Modal } from '@tigergraph/app-ui-lib/modal';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Block } from 'baseui/block';
import { useQueryParams } from '@/components/landing/hooks.ts';
import { HeadingLarge } from 'baseui/typography';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import emailLogo from '@/assets/email-logo.png';

export function EmailSend() {
  const [css, theme] = useStyletron();
  const queryParams = useQueryParams();

  const email = (queryParams && decodeURI(queryParams.get('email') || '')) || 'email';

  return (
    <Modal
      closeable={false}
      isOpen={true}
      overrides={{
        Dialog: {
          style: {
            width: '800px',
            minWidth: '700px',
          },
        },
      }}
    >
      <Container overrides={{ Root: { style: { maxWidth: '726px !important' } } }}>
        <Block display="flex" flexDirection="column" alignItems="center">
          <HeadingLarge as="div">Great! Now verify your email</HeadingLarge>
          <Block marginTop="72px">
            <img alt="" src={emailLogo} />
          </Block>
          <Body2 marginTop="32px">
            Please check your{' '}
            <Body2 as="span" $style={{ fontWeight: 600 }}>
              {email}
            </Body2>{' '}
            inbox to verify your email, and log in to your account from there.
          </Body2>
        </Block>
      </Container>
    </Modal>
  );
}
