import NoPermissionIcon from '@/assets/no-permission.svg';
import NoPermissionDarkIcon from '@/assets/no-permission-dark.svg';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body1 } from '@tigergraph/app-ui-lib/typography';
import { useTheme } from '@/contexts/themeContext';

export default function NoPermission() {
  const [css] = useStyletron();

  const { themeType } = useTheme();
  const NoPermission = themeType === 'light' ? NoPermissionIcon : NoPermissionDarkIcon;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: '28px',
        width: '460px',
        margin: '0 auto',
        textAlign: 'center',
      })}
    >
      <img src={NoPermission} alt="No Permission" />
      <Body1>
        {"You don't have permission to access this page. Please request access from your organization's administrator."}
      </Body1>
    </div>
  );
}
