import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Drawer, DrawerHeader } from '@/components/Drawer';

import Empty from './icon/guide.svg';
import EmptyDark from './icon/guide-dark.svg';
import { WorkGroupT, isStatusActive } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { TableBuilderColumn } from 'baseui/table-semantic';
import toast from 'react-hot-toast';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { useMutationDeleteQuickInsights } from '@/insights/cloud/hook';
import { ApplicationT } from '@/insights/cloud/type';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { Interval, format, formatDuration, intervalToDuration } from 'date-fns';
import { Eye, Trash2Icon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { TableContainer } from '@/lib/styled';
import { useQuery } from 'react-query';
import { Result } from '@/lib/type';
import { AxiosError } from 'axios';
import { listQuickInsights } from '@/insights/cloud/api';
import { useAtom } from 'jotai';
import { quickInsightsAtom } from '@/pages/workgroup/atom';
import { useOrgContext } from '@/contexts/orgContext';
import { calculateRoleForSpace } from '@/pages/admin/user/type';
import LinkButton from '@/components/LinkButton';
import { useState } from 'react';
import { MdDashboard } from 'react-icons/md';
import { parseDate } from '@/lib/date';
import { useTheme } from '@/contexts/themeContext';
import { actionColumnOverrides } from '@/components/table';
import { useAccess } from '@/contexts/accessContext';

type Props = {
  group: WorkGroupT;
};

export default function QuickInsightLists({ group }: Props) {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const { userInfo } = useOrgContext();
  const { themeType } = useTheme();
  const myIP = useAccess();

  const [quickInsights, setQuickInsights] = useAtom(quickInsightsAtom);
  const { data, isLoading, isError, error } = useQuery<Result<ApplicationT[]>, AxiosError>(
    ['quick_insights', group.workgroup_id],
    async () => {
      return listQuickInsights(group.workgroup_id);
    },
    {
      onSuccess: (data) => {
        const results = data?.Result || [];
        for (let application of results) {
          if (application.cache_status === 'pending') {
            if (!quickInsights.find((item) => item.id === application.id)) {
              setQuickInsights((quickInsights) => quickInsights.concat(application));
            }
          }
        }
      },
    }
  );

  const deleteApplicationMutation = useMutationDeleteQuickInsights();

  const onDeleteApplication = (workgroup_id: string, workspace_id: string, app_id: string) => {
    const promise = deleteApplicationMutation.mutateAsync({
      workgroup_id,
      workspace_id,
      app_id,
    });
    toast.promise(
      promise,
      {
        loading: 'Loading',
        success: (data) => `${data.Message}`,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const [insightID, setInsightID] = useState<string | undefined>(undefined);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <ErrorDisplay label="Server Error:" error={error} />;
  }

  const { workspaces, tg_databases } = group;
  const apps = (data?.Result || []).map((application) => {
    const { id, workgroup_id, workspace_id, created_at, updated_at, cache_status, cache_error, default_graph } =
      application;
    let database;
    let workspace;
    for (let ws of workspaces) {
      if (ws.workspace_id === workspace_id) {
        workspace = ws;
        for (let db of tg_databases) {
          if (db.database_id === ws.database_id) {
            database = db;
            break;
          }
        }
        break;
      }
    }
    return {
      id,
      workgroup_id,
      workspace_id,
      created_at,
      updated_at,
      cache_status,
      cache_error,
      database,
      workspace,
      default_graph,
    };
  });

  const selectInsights = apps.find((app) => app.id === insightID);

  return (
    <>
      {apps.length > 0 && (
        <TableBuilder data={apps}>
          <TableBuilderColumn header="Database" id="database">
            {(row) => row.database?.name}
          </TableBuilderColumn>
          <TableBuilderColumn header="Workspace" id="workspace">
            {(row) => row.workspace?.name}
          </TableBuilderColumn>
          <TableBuilderColumn header="Graph" id="graph">
            {(row) => row.default_graph}
          </TableBuilderColumn>
          <TableBuilderColumn header="Status" id="status">
            {(row) => (
              <div className={css({ display: 'flex', whiteSpace: 'nowrap' })}>
                <Status application={row} />
                {row.cache_status === 'error' && (
                  <LinkButton $style={{ fontSize: '12px' }} onClick={() => setInsightID(row.id)}>
                    Err Log
                  </LinkButton>
                )}
              </div>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn header="Created on" id="createDate">
            {(row) => format(parseDate(row.created_at), 'yyyy-MM-dd HH:mm:ss')}
          </TableBuilderColumn>
          <TableBuilderColumn header="Updated on" id="updateDate">
            {(row) => format(parseDate(row.updated_at), 'yyyy-MM-dd HH:mm:ss')}
          </TableBuilderColumn>
          <TableBuilderColumn header="Age" id="age">
            {(row) => {
              const now = new Date();
              const updated_at = parseDate(row.updated_at);
              const interval: Interval = {
                start: updated_at,
                end: now,
              };

              const duration = intervalToDuration(interval);

              if (duration && duration.months && duration.months >= 1) {
                return formatDuration(duration, {
                  format: ['years', 'months'],
                });
              } else if (duration && duration.days && duration.days >= 1) {
                return formatDuration(duration, {
                  format: ['days'],
                });
              } else if (duration && duration.hours && duration.hours >= 1) {
                return formatDuration(duration, {
                  format: ['hours'],
                });
              } else {
                return formatDuration(duration, {
                  format: ['minutes', 'seconds'],
                });
              }
            }}
          </TableBuilderColumn>
          <TableBuilderColumn header="Actions" overrides={actionColumnOverrides}>
            {(row) => {
              const effectRole = calculateRoleForSpace(userInfo.roles, row.workgroup_id, row.workspace_id);
              return (
                <div
                  className={css({
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: '8px',
                  })}
                >
                  {row.cache_status === 'success' && isStatusActive(row.workspace?.status) && myIP.is_allowed ? (
                    <Button
                      kind="text"
                      shape="square"
                      onClick={() => {
                        navigate(`/groups/${group.workgroup_id}/dataprofiles/${row.id}?tab=dataprofiles`);
                      }}
                    >
                      <Eye size={16} />
                    </Button>
                  ) : null}
                  {effectRole === 'workspace-admins' && myIP.is_allowed ? (
                    <ConfirmStatefulPopover
                      confirmLabel="Confirm to delete"
                      onConfirm={() => onDeleteApplication(row.workgroup_id, row.workspace_id, row.id)}
                    >
                      <Button kind="text" shape="square">
                        <Trash2Icon size={16} />
                      </Button>
                    </ConfirmStatefulPopover>
                  ) : null}
                </div>
              );
            }}
          </TableBuilderColumn>
        </TableBuilder>
      )}
      {apps.length == 0 && (
        <TableContainer>
          <Container>
            <img src={themeType === 'light' ? Empty : EmptyDark} alt="No data profile" />
            <Body2>Go to the main dashboard and click on the menu to generate your first data profile.</Body2>
            <Button
              onClick={() => navigate(`/groups/${group.workgroup_id}?tab=general`)}
              size="large"
              startEnhancer={<MdDashboard size={20} />}
            >
              <span>Go to General</span>
            </Button>
          </Container>
        </TableContainer>
      )}
      {selectInsights ? (
        <Drawer isOpen={!!selectInsights} onClose={() => setInsightID(undefined)}>
          <DrawerHeader>Data Profile Error Log</DrawerHeader>
          <pre
            className={css({
              width: '658px',
              height: '100%',
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              backgroundColor: 'black',
              color: theme.colors.gray200,
              fontSize: '12px',
              padding: '8px',
            })}
          >
            {selectInsights.cache_error}
          </pre>
        </Drawer>
      ) : null}
    </>
  );
}

function Status({ application }: { application: ApplicationT }) {
  const { cache_status } = application;
  if (cache_status === 'success') {
    return (
      <Tag closeable={false} kind="positive">
        Ready
      </Tag>
    );
  }

  if (cache_status === 'error') {
    return (
      <Tag closeable={false} kind="negative">
        Failed
      </Tag>
    );
  }

  if (cache_status === 'pending') {
    return (
      <Tag
        closeable={false}
        kind="neutral"
        overrides={{
          Text: {
            style: {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            },
          },
        }}
      >
        In progress
        <Spinner $size="12px" $borderWidth="2px" />
      </Tag>
    );
  }

  return null;
}

const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: $theme.colors.gray1000,
  ...$theme.typography.Body1,
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '100%',
  textAlign: 'center',
  gap: '16px',
}));
