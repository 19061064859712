// import { getHint } from './gsql-hint';
// import { getLint } from './gsql-lint';

// const GSQL_MODE = 'text/x-gsql';

import { simpleMode } from '@codemirror/legacy-modes/mode/simple-mode';
import { allHints } from '@/utils/graphEditor/gsql-hint';

const gsqlRules = {
  // The start state contains the rules that are initially used.
  start: [
    {
      regex: new RegExp(/\b(CREATE|INSTALL|RUN|SHOW|DROP)[\s]QUERY\b/i),
      token: 'keyword',
    },
    {
      regex: new RegExp(
        [
          '\\b(FOR GRAPH|PRINT|LOG|RETURN|RETURNS|RAISE|TRY|EXCEPTION|SELECT|FROM|',
          'WHERE|SAMPLE|WHEN|ACCUM|POST-ACCUM|HAVING|ASC|DESC|LIMIT|OFFSET|',
          'DELETE|INSERT|VALUES|UPDATE|IF|THEN|ELSE|ELSE IF|WHILE|DO|FOREACH|',
          'END|IN RANGE|CASE|CONTINUE|BREAK|TYPEDEF TUPLE|STATIC|CREATE OR REPLACE QUERY|',
          'CREATE BATCH QUERY|CREATE DISTRIBUTED QUERY|ANY|API|AS|BATCH|BOTH|BY|CATCH|',
          'DISTINCT|FILTER|INTERPRET|INTERVAL|INTO|ISEMPTY|LASTHOP|LEADING|LOADACCUM|',
          'PER|PINNED|POST_ACCUM|RANGE|TARGET|TO_CSV|TRAILING|TYPEDEF|',
          // The keywords below only have syntax highlighting, no hinting.
          'COMPRESS|CREATE|DISTRIBUTED|FOR|GRAPH|GROUP|IS|LIST|MAP|MATCH|ORDER|PATH|',
          'CHECK|CLOB|CONSTRAINT|CONST|CURRENT_DATE|CURRENT_TIME|CURRENT_TIMESTAMP|CURSOR|',
          'DECLARE|ELSEIF|EXISTS|HEADER|IGNORE|INPUT_LINE_FILTER|JOB|JOIN|KAFKA|KEY|LOAD|',
          'LONG|NOBODY|ON|PRIMARY|PROXY|QUIT|REDUCE|RESET_COLLECTION_ACCUM|S3|UPSERT|USING|WITH|',
          'INDEX|TRANSLATESQL|TEMP_TABLE|',
          'ABORT|ACL|APPROX_COUNT|ATTRIBUTE|BEGIN|BITINDEX|CHANGE|CONCAT|DATA|DATASRC|DECRYPT|DEFAULT_|DEFINE|DESCRIPTION|DIRECTED|EMPTY|EXECUTE|EXIT|EXPORT|TG_EXPR_FUNC|TG_EXPR_UTIL|EXPR_FUNC|EXPR_UTIL|EXTERN|FILENAMEVAR|FLATTEN|FLATTENJSON|GENERATEDATA|GET|GRANT|HELP|ICON|IMPORT|INSTALL|JSON|LEADER|LOADING|LOCAL|LS|NUMERIC|OF|OPTION|OVERWRITE|OWNER|PAIR|PASSWORD|PRIVILEGE|PUT|READ|RECOMPILE|REJECT_LINE_RULE|RESUME|REVOKE|ROLE|SCHEMA|SCHEMA_CHANGE|SECONDARY_ID|SECRET|SECURED|SEPARATOR|SHOW|SPLIT|STATS|STATUS|STORE|SUBSTR|TAG|TAGS|TEMPLATE|TK|TOKENLEN|TOKEN_BANK|TOFLOAT|TOINT|UNDIRECTED|USE|USER|USERS|VAL|VECTOR|VERSION|VOID|SINGLE|LEGACY|',
          'PRIMARY_ID|QUERY|REPLACE|RUN|SRC|SYNTAX|TGT|TO|TUPLE|ADMIN|ALL|ALLOCATE|ALTER|CALL|CASCADE|ADD|DECIMAL|DROP)\\b',
        ].join(''),
        'i'
      ),
      token: 'keyword',
    },
    {
      // gsql types
      regex: new RegExp(
        [
          '\\b(INT|UINT|FLOAT|DOUBLE|STRING COMPRESS|STRING|DATETIME|BOOL|VERTEX|',
          'FIXED_BINARY|GLOBAL|INT16|INT32|INT32_T|INT64_T|INT8|INTEGER|',
          'UINT16|UINT32|UINT32_T|UINT64_T|UINT8|UINT8_T|',
          'EDGE|JSONOBJECT|JSONARRAY|SET|BAG|FILE|BIGINT|BLOB|BOOLEAN|CHAR|CHARACTER)\\b',
        ].join(''),
        'i'
      ),
      token: 'builtin',
    },
    {
      // gsql accumType
      regex: new RegExp(
        [
          '\\b(AvgAccum|SumAccum|MaxAccum|MinAccum|OrAccum|AndAccum|BitwiseOrAccum|',
          'BitwiseAndAccum|ListAccum|SetAccum|BagAccum|MapAccum|ArrayAccum|HeapAccum|',
          'GroupByAccum)\\b',
        ].join(''),
        'i'
      ),
      token: 'builtin-2',
    },
    {
      regex: new RegExp(
        [
          '\\b(abs|sqrt|pow|acos|asin|atan|atan2|ceil|cos|cosh|exp|floor|fmod|ldexp|',
          'log|log10|sin|sinh|tan|tanh|to_string|float_to_int|str_to_int|lower|upper|trim|',
          'to_datetime|epoch_to_datetime|datetime_to_epoch|datetime_format|now|',
          'year|month|day|hour|minute|second|datetime_add|datetime_sub|datetime_diff|',
          'parse_json_object|parse_json_array|containsKey|getInt|getDouble|getString|',
          'getBool|getJsonObject|getJsonArray|size|clear|outdegree|neighbors|',
          'neighborAttribute|edgeAttribute|getAttr|setAttr|isDirected|count|sum|min|max|avg|',
          'selectVertex|to_vertex|to_vertex_set|coalesce|getvid|evaluate)\\b',
        ].join(''),
        'i'
      ),
      token: 'function',
    },
    {
      regex: new RegExp(/0x[a-f\d]+|[-+]?(?:\.\d+|\d+\.?\d*)(?:e[-+]?\d+)?/i),
      token: 'number',
    },
    {
      regex: new RegExp(/"(?:[^\\]|\\.)*?(?:"|$)/i),
      token: 'string',
    },
    {
      regex: new RegExp(/TRUE|FALSE|NULL|GSQL_UINT_MAX|GSQL_INT_MAX|GSQL_INT_MIN/i),
      token: 'atom',
    },
    // Need to be placed above 'operator' so that its style is not overridden.
    {
      regex: new RegExp(/\/\/.*/),
      token: 'comment',
    },
    {
      regex: new RegExp(/#.*/),
      token: 'comment',
    },
    {
      regex: new RegExp(/\/\*/),
      token: 'comment',
      next: 'comment',
    },
    {
      regex: new RegExp(['[-+*=/%&|<>]|(<<|>>|>=|<=|==|!=)'].join(''), 'gi'),
      token: 'operator',
    },
    // Need to be placed above 'variable' so that its style is not overridden.
    {
      regex: new RegExp(
        [
          '\\b(NOT|AND|OR|BETWEEN|IS NULL|IS NOT NULL|LIKE|NOT LIKE|ESCAPE|UNION|INTERSECT|',
          'MINUS|IN|NOT IN)\\b',
        ].join(''),
        'gi'
      ),
      token: 'operator',
    },
    {
      regex: new RegExp(/(@){0,2}[\w]+/),
      token: 'variable',
    },
    {
      regex: new RegExp(/[{[(]/),
      indent: true,
    },
    {
      regex: new RegExp(/[}\])]/),
      dedent: true,
    },
  ],
  // The multi-line comment state.
  comment: [
    {
      regex: new RegExp(/.*?\*\//),
      token: 'comment',
      next: 'start',
    },
    {
      regex: new RegExp(/.*/),
      token: 'comment',
    },
  ],
  // The meta property contains global information about the mode. It
  // can contain properties like lineComment, which are supported by
  // all modes, and also directives like dontIndentStates, which are
  // specific to simple modes.
};

const gsql = simpleMode(gsqlRules);
gsql.languageData = {
  autocomplete: allHints,
  commentTokens: {
    line: '//',
    block: { open: '/*', close: '*/' },
  },
};

export default gsql;
