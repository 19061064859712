import CloudProviders from '@/pages/admin/settings/cloud_provider/CloudProvider';
import { Tabs, Tab, pageTabsOverrides, pageTabOverrides } from '@/components/Tab';
import OrgSettings from '@/pages/admin/settings/org_settings';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useSearchParams } from 'react-router-dom';

export default function Settings() {
  const [css] = useStyletron();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Tabs
      activeKey={searchParams.get('tab') || 'org_settings'}
      onChange={({ activeKey }) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('tab', `${activeKey}`);
        setSearchParams(newSearchParams, { replace: true });
      }}
      activateOnFocus
      overrides={pageTabsOverrides}
    >
      <Tab title="Organization Setting" key="org_settings" overrides={pageTabOverrides}>
        <OrgSettings />
      </Tab>
      {import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging' ? null : (
        <Tab title="Cloud Providers" key="cloud_providers" overrides={pageTabOverrides}>
          <CloudProviders />
        </Tab>
      )}
    </Tabs>
  );
}
