import { GLOBAL_GRAPH_NAME, GSQLPrivilege, UserInfo, UserPrivilege, UserRole } from '@tigergraph/tools-models';

export default class DBUser {
  username: string;
  superuser: boolean;
  roles: UserRole;
  privileges: UserPrivilege;
  userInfo: UserInfo;

  constructor(info: UserInfo) {
    this.username = info.name;
    this.superuser = !!info.isSuperUser;
    this.roles = info.roles || {};
    this.privileges = info.privileges || {};
    this.userInfo = info;
  }

  getUesrInfo(): UserInfo {
    return this.userInfo;
  }

  getUserRoles(graphName: string): string[] {
    return this.roles[graphName];
  }

  getPrivilegesForGraph(graphName: string): GSQLPrivilege[] {
    return this.privileges[graphName].privileges || [];
  }

  hasPrivilege(privilege: GSQLPrivilege, graphName: string): boolean {
    return (
      (this.privileges[graphName] && this.privileges[graphName].privileges.includes(privilege)) ||
      (this.privileges[GLOBAL_GRAPH_NAME] && this.privileges[GLOBAL_GRAPH_NAME].privileges.includes(privilege))
    );
  }

  getGraphList(): string[] {
    return Object.keys(this.roles);
  }
}
