import { ExternalGraph, GsqlQueryMeta, QueryMeta } from '@tigergraph/tools-models';

export interface Vertex {
  v_type: string;
  v_id: string;
  icon?: string;
  [key: string]: any;
}

export interface Edge {
  from_type?: string;
  from_id?: string;
  e_type?: string;
  to_type?: string;
  to_id?: string;
  icon?: string;
  [key: string]: any;
}

export interface NodeState {
  [type: string]: {
    [key: string]: any;
  };
}

export interface GraphState {
  vertices: NodeState;
  edges: NodeState;
}

export interface ChartSchema {
  results: any; // original schema data
  graph: GraphState; // visualizing schema vertices and edges
}

export type Graph = ExternalGraph;

export interface ChartData {
  graph: Graph;
  results: any;
}

export type FileRecordT = {
  owned_files: FileStore[];
  shared_files: FileStore[];
};

export interface FileStore {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  org_id?: string;
  is_folder: boolean;
  content: string;
  parent_id: string;
  is_editing?: boolean;
  files: FileStore[];
  owner_email?: string;
  permission: FilePermission;
  is_shared_folder?: boolean;
  is_GSQL_query?: boolean;
  is_placeholder?: boolean;
  queryInfo?: QueryMeta;
  originQueryInfo?: GsqlQueryMeta;
  graphName?: string; // for GSQL queries
}

export interface ActiveFile extends FileStore {
  sourceCode?: string;

  // below fields are used for temp files
  is_temp?: boolean;
  file_id?: string; // after the temp file is saved to the backend, file_id is assigned
  query_name?: string; // query name that the temp file is associated with
}

export enum FileChangeType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  MOVE = 'MOVE',
  UPDATE = 'UPDATE',
}

export enum FileSortType {
  DateCreated = 'DateCreated',
  LastModified = 'LastModified',
  Name = 'Name',
}

export enum FilePermission {
  Owner = 'owner',
  View = 'view',
  Edit = 'edit',
  None = 'none',
}

export enum FileShareType {
  Org = 'org',
  User = 'user',
}

export type FileShare = {
  created_at?: string;
  updated_at?: string;
  file_store_id: string;
  target: string;
  permission: FilePermission;
  type: FileShareType;
};

export type AddUpdateShare = {
  type: FileShareType;
  target?: string;
  permission: FilePermission;
};

export type DeleteShare = {
  type: FileShareType;
  target?: string;
};

export type UpdateFileShareRequest = {
  add_shares: AddUpdateShare[];
  update_shares: AddUpdateShare[];
  delete_shares: DeleteShare[];
};

export enum Access {
  Restricted = 0,
  OrgUsersView = 1,
  OrgUsersEdit = 2,
}

export type Action =
  | { type: 'SET_ALL'; data: FilePermissionForm }
  | { type: 'CHANGE_USER'; data: FileShare }
  | { type: 'ADD_USERS'; data: FileShare[] }
  | { type: 'SET_ACCESS'; data: Access }
  | { type: 'REMOVE_USER'; data: FileShare };

export type FilePermissionForm = {
  access: Access;
  userFileShares: FileShare[];
};

/** Different query status shown in front-end. */
export enum QueryStatus {
  Installing = 'installing',
  Disabled = 'disabled',
  Installed = 'installed',
  Draft = 'draft',
  Unknown = 'unknown',
}
