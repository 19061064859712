import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { Controller, useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { CopilotFormType } from '@/pages/workgroup/form/addons/drawer/copilot/type';
import { Select } from '@tigergraph/app-ui-lib/select';
import { useListAddonsLLMProvidersConfigurations } from '@/hooks/useListAddonsConfigurations';
import { EnabledAddonsType } from '@/hooks/useListEnabledAddons';
import { MetaAddonsType } from '@/hooks/useAddonsMeta';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { cn } from '@/lib/utils';

const WorkspaceAddonsEnableCopilotDrawer: FC<{
  enabledAddons: EnabledAddonsType;
  addonsMeta: MetaAddonsType;
}> = ({ enabledAddons }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext<CopilotFormType>();
  const { data: llmProviders, isFetching } = useListAddonsLLMProvidersConfigurations(enabledAddons.ID, {
    refetchOnWindowFocus: true,
  });
  const [css, theme] = useStyletron();

  return (
    <>
      <FormControl
        label={
          <div>
            <p
              className={cn(
                'flex font-[Roboto] font-semibold text-[#3F5870]',
                css({ color: theme.colors['dropdown.text'] })
              )}
            >
              LLM Provider
            </p>
            <p className={cn('mt-1 text-xs', css({ color: theme.colors['text.secondary'] }))}>
              Please select a LLM provider for your CoPilot Add-on.
            </p>
          </div>
        }
        error={errors.copilot_llm_provider_config_id?.message}
      >
        <Controller
          name="copilot_llm_provider_config_id"
          control={control}
          rules={{
            required: 'Please select LLM Provider.',
          }}
          render={({ field: { value, onChange, ref, ...field } }) => (
            <Select
              {...field}
              inputRef={ref}
              value={[{ id: value }]}
              onChange={({ value }) => {
                onChange(value?.[0]?.id);
              }}
              options={llmProviders?.map((i) => ({ label: i.Name, id: i.ID }))}
              size="compact"
              isLoading={isFetching}
              clearable={false}
              searchable={false}
            />
          )}
        />
      </FormControl>

      <p className={cn('text-xs font-[Roboto]', css({ color: theme.colors['text.secondary'] }))}>
        <span>Can{"'"}t find the LLM Provider you want? </span>
        <a
          className={css({ color: theme.colors['text.link'] })}
          href={`/marketplace/addons?config=${enabledAddons.ID}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to add
        </a>
      </p>
    </>
  );
};

export default WorkspaceAddonsEnableCopilotDrawer;
