import axios from 'axios';

const axiosController = axios.create({
  baseURL: import.meta.env.VITE_CONTROLLER_URL,
});

const axiosOrgService = axios.create({
  baseURL: import.meta.env.VITE_ORG_URL,
});

const axiosCluster = axios.create({});

axiosCluster.interceptors.response.use((response) => {
  if (response.status >= 200 && response.status < 400) {
    localStorage.removeItem('instanceLoginFailed');
  }
  return response;
});

export { axiosController, axiosOrgService, axiosCluster };
