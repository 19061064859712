import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router';

export function Landing() {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, getIdTokenClaims, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getIdTokenClaims().then((idToken) => {
        if (!idToken) {
          return;
        }
        if (idToken.metadata && !idToken.metadata.accepted_tos) {
          if (idToken.org_id) {
            // navigate to terms page with org_id
            navigate(`/terms?organization=${idToken.org_id}`);
          } else {
            // navigate to terms page
            navigate('/terms');
          }
        }
      });
    }
  }, [getIdTokenClaims, isAuthenticated, isLoading, logout, navigate]);

  return null;
}
