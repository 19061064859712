import { useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';

import TooltipLabel from '@/components/TooltipLabel';
import { FormatValidator, ValidateResult } from '@tigergraph/tools-models/utils';
import ErrorMessage from '@/components/ErrorMessage';

export interface ReverseEdgeProps {
  reverseEdge: string;
  validateResult: ValidateResult;
  onChangeReverseEdge: (reverseEdge: string) => void;
  onChangeValidateResult: (validateResult: ValidateResult) => void;
  readOnly?: boolean;
}

export function ReverseEdge({
  reverseEdge,
  validateResult,
  onChangeReverseEdge,
  onChangeValidateResult,
  readOnly,
}: ReverseEdgeProps) {
  const [css] = useStyletron();
  const [reverseEdgeName, setReverseEdgeName] = useState(reverseEdge);

  return (
    <div
      className={css({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      })}
    >
      <TooltipLabel
        label="Reverse Edge"
        tooltip="Reverse edges provide the most efficient way to perform graph queries and searches."
      />
      <Input
        onChange={(e) => {
          const edgeName = e.currentTarget.value;
          setReverseEdgeName(edgeName);
          const validateResult = FormatValidator.isName(edgeName);
          onChangeValidateResult(validateResult);
          onChangeReverseEdge(edgeName);
        }}
        value={reverseEdgeName}
        disabled={readOnly}
        size="compact"
        overrides={{
          Root: {
            style: {
              flexGrow: 1,
              fontSize: '14px',
            },
          },
        }}
        placeholder={'Edge name'}
        error={!validateResult?.success}
      />
      {!validateResult.success && <ErrorMessage message={validateResult?.message ?? ''} />}
    </div>
  );
}
