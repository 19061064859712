import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import useCopyClipboard from 'react-use-clipboard';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { MdContentCopy } from 'react-icons/md';
import { useEffect } from 'react';
import { showToast } from '@/components/styledToasterContainer';
import { InfoContainer, InfoContent, InfoTitle } from '@/pages/workgroup/tab/styled';
import IconButton from '@/components/IconButton';
import { HeadingSmallBold } from '@tigergraph/app-ui-lib/typography';
import { StatusCircle } from '@/pages/admin/settings/cloud_provider/StatusIndicator';
import { Info } from 'lucide-react';

export default function CloudProviderDetail({ cp }: { cp: CloudProviderT }) {
  const [css, theme] = useStyletron();
  const [serviceURLCopied, setServiceURLCopied] = useCopyClipboard(cp.endpoint_service_name, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (serviceURLCopied) {
      showToast({
        kind: 'positive',
        message: 'Service URL copied successfully.',
      });
    }
  }, [serviceURLCopied]);

  return (
    <StatefulPopover
      showArrow={false}
      placement={'bottom'}
      animateOutTime={100}
      triggerType="click"
      overrides={{
        Inner: {
          style: {
            background: theme.colors['background.primary'],
            padding: '16px 16px 24px 16px',
            border: `1px solid ${theme.colors['card.border']}`,
          },
        },
      }}
      content={({ close }) => (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            color: theme.colors['contentPrimary'],
          })}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            })}
          >
            <HeadingSmallBold $style={{ fontSize: '18px', lineHeight: '32px' }}>{cp.name}</HeadingSmallBold>
            <StatusCircle status={cp.status} />
          </div>
          <InfoContainer>
            <InfoTitle>AWS Role ARN:</InfoTitle>
            <InfoContent>{cp.role_arn}</InfoContent>
            <InfoTitle>Secure Connection:</InfoTitle>
            <InfoContent>{cp.options.secure_connection ? 'Enabled' : 'Disabled'}</InfoContent>
            {cp.options.secure_connection && (
              <>
                <InfoTitle>Trusted Account ID:</InfoTitle>
                <InfoContent>{cp.options.account_id}</InfoContent>
                <InfoTitle>Endpoint Service Name:</InfoTitle>
                <InfoContent>
                  <span>{cp.endpoint_service_name}</span>
                  <IconButton type="button" onClick={setServiceURLCopied}>
                    <MdContentCopy size="14" />
                  </IconButton>
                </InfoContent>
              </>
            )}
            <InfoTitle>User Owned VPC:</InfoTitle>
            <InfoContent>{!cp.vpc_owned_by_tg ? 'Enabled' : 'Disabled'}</InfoContent>
            {!cp.vpc_owned_by_tg && (
              <>
                <InfoTitle>VPC ID:</InfoTitle>
                <InfoContent>{cp.vpc_id}</InfoContent>
                <InfoTitle>Subnet ID:</InfoTitle>
                <InfoContent>{cp.subnet_id.join(';')}</InfoContent>
                <InfoTitle>Secure Group ID:</InfoTitle>
                <InfoContent>{cp.security_group_id}</InfoContent>
              </>
            )}
            <InfoTitle>EKS Name:</InfoTitle>
            <InfoContent>{cp.short_id}</InfoContent>
            <InfoTitle>EKS ID:</InfoTitle>
            <InfoContent>{cp.eks_id}</InfoContent>
            {cp.availability_zones && (
              <>
                <InfoTitle>Available Zones:</InfoTitle>
                <InfoContent>{cp.availability_zones.join(', ')}</InfoContent>
              </>
            )}
          </InfoContainer>
        </div>
      )}
    >
      <IconButton>
        <Info size={14} />
      </IconButton>
    </StatefulPopover>
  );
}
