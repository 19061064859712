import { MdVpnKey } from 'react-icons/md';
import { Checkbox } from 'baseui/checkbox';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Attribute } from '@tigergraph/tools-models';

export interface VertexAttributeKeyProps {
  attribute: Attribute;
  isPrimaryId: boolean;
  readOnly?: boolean;
  hidePrimaryKey?: boolean;
  primaryKeyOnly?: boolean;
  onChangeKey: (attribute: Attribute) => void;
}

export default function VertexAttributeKey({
  attribute,
  isPrimaryId,
  readOnly,
  hidePrimaryKey,
  primaryKeyOnly,
  onChangeKey,
}: VertexAttributeKeyProps) {
  const [css, theme] = useStyletron();

  const handleChange = (checked: boolean) => {
    if (isPrimaryId) {
      attribute.primaryIdAsAttribute = checked;
      onChangeKey(attribute);
    } else {
      attribute.hasIndex = checked;
      onChangeKey(attribute);
    }
  };

  return (
    <div
      className={css({
        display: 'flex',
        lineHeight: '35px',
        height: '35px',
        alignItems: 'center',
        columnGap: '8px',
      })}
    >
      {isPrimaryId ? (
        <>
          {!hidePrimaryKey && <MdVpnKey color={theme.colors['table.icon']} size={20} />}
          {!primaryKeyOnly && (
            <Checkbox
              disabled={readOnly || attribute.isUsingNewSyntax}
              overrides={{
                Label: {
                  style: {
                    minWidth: '95px',
                    paddingLeft: '8px',
                    fontSize: '12px',
                  },
                },
              }}
              checked={attribute.primaryIdAsAttribute || attribute.isPrimaryKey}
              onChange={(e) => {
                handleChange(e.currentTarget.checked);
              }}
            >
              {attribute.isUsingNewSyntax ? 'Primary key' : 'As attribute'}
            </Checkbox>
          )}
        </>
      ) : (
        <>
          {!hidePrimaryKey && <MdVpnKey color={theme.colors['icon.disabled']} size={20} />}
          {!primaryKeyOnly && (
            <Checkbox
              disabled={readOnly}
              overrides={{
                Label: {
                  style: {
                    paddingLeft: '8px',
                    fontSize: '12px',
                  },
                },
              }}
              checked={attribute.hasIndex}
              onChange={(e) => {
                handleChange(e.currentTarget.checked);
              }}
            >
              Index
            </Checkbox>
          )}
        </>
      )}
    </div>
  );
}
