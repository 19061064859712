import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';

import { HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ParagraphSmall } from 'baseui/typography';
import { Input } from '@tigergraph/app-ui-lib/input';
import { useState } from 'react';

export interface RestoreConfirmModalProps {
  showModal: boolean;
  onClose: () => void;
  onConfirm: () => void;
  backupTag?: string;
}

export function RestoreConfirmModal({ showModal, onClose, onConfirm, backupTag }: RestoreConfirmModalProps) {
  const [css] = useStyletron();
  const [inputValue, setInputValue] = useState('');

  const handleClose = () => {
    setInputValue('');
    onClose();
  };

  return (
    <Modal isOpen={showModal} onClose={handleClose}>
      <ModalHeader>Restore from backup</ModalHeader>
      <ModalBody>
        <ParagraphSmall
          className={css({
            marginBottom: '16px',
          })}
        >
          Restoring a backup is an offline operation. Your database will be down for a short period during the database
          restore. This action cannot be reversed.
        </ParagraphSmall>

        <HeadingMenuSelected>Type your backup name to continue</HeadingMenuSelected>
        <ParagraphSmall>
          To confirm that you want to restore this backup, please type <b>{backupTag}</b> in the input as it appears
          below.
        </ParagraphSmall>

        <Input
          overrides={{
            Root: {
              style: {
                marginTop: '16px',
              },
            },
          }}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.currentTarget.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={handleClose}>
          Cancel
        </ModalButton>
        <ModalButton disabled={inputValue !== backupTag} onClick={() => onConfirm()}>
          Restore
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
