import { styled } from '@tigergraph/app-ui-lib/Theme';

export const InfoContainer = styled('div', ({ $theme }) => {
  return {
    padding: '8px',
    backgroundColor: $theme.colors['background.secondary'],
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '12px 12px',
  };
});

export const InfoTitle = styled('span', ({ $theme }) => {
  return {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  };
});

export const InfoContent = styled('span', ({ $theme }) => {
  return {
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    gap: '12px',
  };
});
