import { useListAddonsLLMProvidersConfigurations } from '@/hooks/useListAddonsConfigurations';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Controller } from 'react-hook-form';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import QueriesTable from '@/pages/workgroup/form/addons/edit-drawer/copilot/table';
import { AddonsConfigureDrawerConfigComponent } from '@/pages/workgroup/form/addons/edit-drawer/config';
import { isWorkspaceCopilotEnable } from '@/utils/addons/copilot';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { cn } from '@/lib/utils';

const AddonsConfigureDrawerCopilot: AddonsConfigureDrawerConfigComponent = ({
  form,
  workspace,
  selectedEnabledAddons,
}) => {
  const { control } = form;
  const [css, theme] = useStyletron();
  const { data: llmProviders, isFetching: isLoadingLLMProviders } = useListAddonsLLMProvidersConfigurations(
    selectedEnabledAddons?.ID,
    {
      refetchOnWindowFocus: true,
    }
  );
  const isEnableCopilot = isWorkspaceCopilotEnable(workspace);

  return (
    <>
      <FormControl
        label={
          <h3 className="flex justify-between items-center font-semibold leading-4 text-sm">
            <span className={css({ color: theme.colors['dropdown.text'] })}>Choose LLM Provider</span>
            <a
              className={cn('text-xs font-normal', css({ color: theme.colors['text.link'] }))}
              href={`/marketplace/addons`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Configure LLM Providers
            </a>
          </h3>
        }
      >
        <Controller
          control={control}
          name={`addons.${selectedEnabledAddons?.AddonsID}.enable_configuration.copilot_llm_provider_config_id`}
          rules={
            {
              // required: 'Please select LLM Provider.',
            }
          }
          render={({ field: { value, onChange, ref, ...field } }) => (
            <Select
              size="compact"
              clearable={false}
              {...field}
              inputRef={ref}
              isLoading={isLoadingLLMProviders}
              value={[{ id: value }]}
              options={llmProviders?.map((i) => ({ label: i.Name, id: i.ID }))}
              onChange={({ value }) => onChange(value?.[0]?.id)}
            />
          )}
        />
      </FormControl>
      <p
        className={cn(
          'leading-4 mb-4 text-xs',
          css({
            color: theme.colors['text.secondary'],
          })
        )}
      >
        Please select the LLM provider that best aligns with your requirements for TigerGraph CoPilot. Consider factors
        such as performance, cost, integration capabilities, and support. The available options are OpenAI, GCP, Azure,
        and AWS Bedrock. Please contact your organization admin if you do not have permission to add new LLM providers.
      </p>
      {isEnableCopilot && <QueriesTable workspace={workspace} />}
    </>
  );
};

export default AddonsConfigureDrawerCopilot;
