import { useMemo } from 'react';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Value } from 'baseui/select';
import {
  LoadToEdgeData,
  LoadToVertexData,
  LoadingJobData,
  OneColumnMapping,
  OneColumnMappingDataSourceColumnSource,
  OneColumnMappingMappingWidgetSource,
  SourceType,
} from '@tigergraph/tools-models/loading-job';
import { expand } from 'inline-style-expand-shorthand';

export interface AttributeSourceSelectProps {
  loadingJob: LoadingJobData | undefined;
  loadingStatement: LoadToVertexData | LoadToEdgeData;
  columnMapping: OneColumnMapping | undefined;
  disabled: boolean;
  onChangeSources: (
    mappingSource: OneColumnMappingDataSourceColumnSource | OneColumnMappingMappingWidgetSource
  ) => void;
}

export function AttributeSourceSelect({
  loadingJob,
  loadingStatement,
  columnMapping,
  disabled,
  onChangeSources,
}: AttributeSourceSelectProps) {
  const sourceOptions = useMemo(() => {
    const options = [] as {
      id: string;
      label: string;
      mapping: OneColumnMappingDataSourceColumnSource | OneColumnMappingMappingWidgetSource;
    }[];
    if (loadingJob?.dataSet.dataSchema) {
      loadingJob.dataSet.dataSchema.forEach((item, index) => {
        if (item.name) {
          options.push({
            id: `data_source_column_${index}`,
            label: item.name,
            mapping: {
              // @ts-ignore
              type: SourceType.DataSourceColumn,
              columnIndex: index,
            },
          });
        }
      });
    }
    loadingStatement.mappingWidgets.forEach((mappingWidget, index) => {
      options.push({
        id: `token_function_${index}`,
        label: mappingWidget.name ?? `token_function_${index + 1}`,
        mapping: {
          // @ts-ignore
          type: SourceType.MappingWidget,
          mappingWidgetIndex: index,
        },
      });
    });

    return options;
  }, [loadingJob?.dataSet.dataSchema, loadingStatement.mappingWidgets]);

  const source = useMemo<Value>(() => {
    // @ts-ignore
    if (columnMapping?.sourceType === SourceType.DataSourceColumn) {
      return [{ id: `data_source_column_${columnMapping.index}` }];
      // @ts-ignore
    } else if (columnMapping?.sourceType === SourceType.MappingWidget) {
      return [{ id: `token_function_${columnMapping.index}` }];
    } else {
      return [];
    }
  }, [columnMapping]);

  return (
    <Select
      value={source}
      options={sourceOptions}
      onChange={({ value }) => {
        if (value.length === 0) {
          onChangeSources({
            // @ts-ignore
            type: SourceType.DataSourceColumn,
            columnIndex: -1,
          });
        } else {
          onChangeSources(value[0].mapping);
        }
      }}
      maxDropdownHeight={'230px'}
      disabled={disabled}
      size="compact"
      placeholder={disabled ? 'Not support' : 'Select'}
      overrides={{
        Root: {
          style: {
            minWidth: '120px',
            height: '32px',
            backgroundColor: 'inherit',
            fontSize: '12px',
          },
        },
        ControlContainer: {
          style: ({ $isFocused }) => ({
            backgroundColor: 'inherit',
            height: '32px',
            ...expand({
              borderWidth: $isFocused ? '1px' : '0',
            }),
          }),
        },
        ValueContainer: {
          style: {
            paddingLeft: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
          },
          props: {
            title: source[0]?.label,
          },
        },
        SingleValue: {
          style: {
            lineHeight: '30px',
          },
        },
        IconsContainer: {
          style: {
            paddingRight: '0px',
            paddingLeft: '0px',
          },
        },
        Popover: {
          props: {
            ignoreBoundary: true,
          },
        },
        DropdownOption: {
          props: {
            style: {
              fontSize: '12px',
            },
          },
        },
      }}
    />
  );
}
