import { Result } from '@/lib/type';
import {
  createSolution,
  deleteSolution,
  getSolutionCatalog,
  listAttachedWorkspace,
  getSolutionGSQL,
} from '@/pages/marketplace/solution/api';
import {
  AttachedWorkspaces,
  CreateSolution,
  SolutionGSQL,
  SolutionInstance,
  SolutionT,
} from '@/pages/marketplace/solution/type';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useQueryGetSolutionCatalog() {
  return useQuery<SolutionT[], AxiosError>(['solution-catalog'], async () => {
    return getSolutionCatalog();
  });
}

export function useQueryGetSolutionGSQL(solutionPath: string) {
  return useQuery<SolutionGSQL, AxiosError>(['solution-catalog', solutionPath], async () => {
    return getSolutionGSQL(solutionPath);
  });
}

export function useQueryListAttachedWorkspace() {
  return useQuery<Result<AttachedWorkspaces>, AxiosError>(['solution-workspace'], async () => {
    return listAttachedWorkspace();
  });
}

export function useMutationCreateSolution() {
  const queryClient = useQueryClient();
  return useMutation<Result<SolutionInstance>, AxiosError, CreateSolution>(
    async (data) => {
      return createSolution(data);
    },
    {
      onSuccess: async (result, data) => {
        queryClient.invalidateQueries(['solutions', data.workgroup_id]);
        queryClient.invalidateQueries(['solution-workspace']);
      },
    }
  );
}

export function useMutationDeleteSolution() {
  const queryClient = useQueryClient();
  return useMutation<Result<void>, AxiosError, { solution_id: string; workgroup_id: string; workspace_id: string }>(
    ({ workgroup_id, workspace_id, solution_id }) => {
      return deleteSolution(workgroup_id, workspace_id, solution_id);
    },
    {
      onSuccess: async (_, data) => {
        queryClient.invalidateQueries(['solutions', data.workgroup_id]);
        queryClient.invalidateQueries(['solution-workspace']);
      },
    }
  );
}
