import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '@/lib/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  const [css, theme] = useStyletron();
  const { disabled, min, max, value } = props;
  return (
    <>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          lineHeight: '16px',
          paddingBottom: '2px',
          color: disabled ? theme.colors['text.disabled'] : theme.colors['text.primary'],
        })}
      >
        <span>{min}</span>
        <span>{max}</span>
      </div>
      <SliderPrimitive.Root
        ref={ref}
        className={cn('relative flex w-full touch-none select-none items-center', className)}
        {...props}
      >
        <SliderPrimitive.Track
          className={cn(
            'relative w-full grow overflow-hidden',
            css({
              backgroundColor: theme.colors['background.accent.neutral.subtler'],
              height: '4px',
              borderRadius: '4px',
            })
          )}
        >
          <SliderPrimitive.Range
            className={cn(
              'absolute h-full',
              css({
                backgroundColor: disabled
                  ? theme.colors['background.accent.neutral.subtle']
                  : theme.colors['background.accent.gray.bolder'],
              })
            )}
          />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className={cn(
            'group block rounded-full ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
            css({
              width: '14px',
              height: '14px',
              borderRadius: '50%',
              border: `2px solid ${theme.colors['background.accent.gray.bold']}`,
              backgroundColor: theme.colors['background.primary'],
              ':hover': {
                boxShadow: `0 0 0 5px ${theme.colors['background.accent.gray.bold']}33`,
              },
              position: 'relative',
            })
          )}
        >
          <div
            className={cn(
              'hidden group-hover:block',
              css({
                position: 'absolute',
                color: theme.colors['text.inverse'],
                fontSize: '12px',
                lineHeight: '16px',
                padding: '8px 16px',
                borderRadius: '5px',
                backgroundColor: theme.colors['background.accent.gray.bolder'],
                top: '-46px',
                left: '50%',
                transform: 'translateX(-50%)',
                '::after': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  top: '100%',
                  width: 0,
                  height: 0,
                  borderTop: `5px solid ${theme.colors['background.accent.gray.bolder']}`,
                  borderRight: `5px solid transparent`,
                  borderBottom: `0 solid transparent`,
                  borderLeft: `5px solid transparent`,
                },
              })
            )}
          >
            {value}
          </div>
        </SliderPrimitive.Thumb>
      </SliderPrimitive.Root>
    </>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
