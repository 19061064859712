import { createQuickInsights, deleteApplication, getApplicationDetail, runApplication } from '@/insights/cloud/api';
import { ApplicationT } from '@/insights/cloud/type';
import { Result } from '@/lib/type';
import { generateQuickInsights } from '@/pages/dashboard/generateQuickInsights';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useMutationDeleteQuickInsights() {
  const queryClient = useQueryClient();
  return useMutation<Result<void>, AxiosError, { workgroup_id: string; workspace_id: string; app_id: string }>(
    ({ workgroup_id, workspace_id, app_id }) => {
      return deleteApplication(workgroup_id, workspace_id, app_id);
    },
    {
      onSuccess: async (_, { workgroup_id, app_id }) => {
        queryClient.setQueryData<Result<ApplicationT[]> | undefined>(['quick_insights', workgroup_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          return {
            ...oldData,
            Result: oldData.Result.filter((app) => app.id !== app_id),
          };
        });
      },
    }
  );
}

export function useMutationCreateQuickInsights() {
  const queryClient = useQueryClient();
  return useMutation<Result<ApplicationT>, AxiosError, { workspace_id: string; workgroup_id: string; graph: string }>(
    async ({ workgroup_id, workspace_id, graph }) => {
      const request = generateQuickInsights(workspace_id, workgroup_id, graph);

      return createQuickInsights(request);
    },
    {
      onSuccess: async (result, { workgroup_id }) => {
        queryClient.setQueryData<Result<ApplicationT[]> | undefined>(['quick_insights', workgroup_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          return {
            ...oldData,
            Result: oldData.Result.concat(result.Result!),
          };
        });
      },
    }
  );
}

export function useMutationRunApplication() {
  return useMutation<
    Result<ApplicationT>,
    AxiosError,
    { workgroup_id: string; workspace_id: string; app_id: string; force?: boolean }
  >(({ workgroup_id, workspace_id, app_id, force }) => {
    return runApplication(workgroup_id, workspace_id, app_id, force);
  });
}

export function useQueryApplicationDetail(workgroup_id: string, workspace_id: string, app_id: string) {
  return useQuery<Result<ApplicationT>, AxiosError>(['application', workgroup_id, workspace_id, app_id], async () => {
    return getApplicationDetail(workgroup_id, workspace_id, app_id);
  });
}
