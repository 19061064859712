import {
  Attribute,
  GSQLUdtJson,
  getUDTTuples,
  supportedContainerValueTypes,
  supportedMapKeyTypes,
} from '@tigergraph/tools-models';
import { EditableInput } from '@/components/editableInput';
import { StyledSelect } from '@/components/schemaDesigner/StyledSelect';
import { OnChangeParams, Option } from 'baseui/select';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { ID_TOKEN_KEY, useWorkspaceContext } from '@/contexts/workspaceContext';

export interface AttributeValueColumnProps {
  attribute: Attribute;
  isPrimaryId: boolean;
  onChange: (attr: Attribute) => void;
  readOnly?: boolean;
}

export function AttributeValueColumn({ attribute, isPrimaryId, onChange, readOnly }: AttributeValueColumnProps) {
  const { currentGraph, currentWorkspace } = useWorkspaceContext();
  const [udtOptions, setUdtOptions] = useState<Option[]>([]);
  const [udtValue, setUdtValue] = useState<Option[]>([]);
  const [shouldShowDefaultValue, setShouldShowDefaultValue] = useState(false);
  const [shouldShowValueType, setShouldShowValueType] = useState(false);
  const [shouldShowTupleName, setShouldShowTupleName] = useState(false);
  const [shouldShowValueTupleName, setShouldShowValueTupleName] = useState(false);

  const { data: udtList } = useQuery(['getUdtList', currentGraph], async () => {
    if (!currentWorkspace) {
      return [];
    }
    const response = await getUDTTuples(
      { graph: currentGraph },
      {
        baseURL: `https://${currentWorkspace.nginx_host}`,
        version: currentWorkspace.tg_version,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
        },
      }
    );
    return response.data.results as any;
  });

  const hasVertexOrEdgeType = (udt: GSQLUdtJson) => {
    for (let i = 0; i < udt.fields.length; i++) {
      const type = udt.fields[i].fieldType;
      if (type === 'VERTEX' || type === 'EDGE') {
        return true;
      }
    }
    return false;
  };

  const hasVarLengthString = (udt: GSQLUdtJson) => {
    for (const field of udt.fields) {
      if (field.fieldType === 'STRING' && field.length === undefined) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    udtList &&
      setUdtOptions(
        udtList
          .filter((udt: GSQLUdtJson) => !hasVertexOrEdgeType(udt))
          .filter((udt: GSQLUdtJson) => !hasVarLengthString(udt))
          .map((udt: GSQLUdtJson) => ({ id: udt.name, label: udt.name }))
      );
  }, [udtList]);

  useEffect(() => {
    const showTupleName = ['UDT'];
    const showValueType = ['SET', 'LIST', 'MAP'];
    const showDefaultValue = ['INT', 'UINT', 'BOOL', 'FLOAT', 'DOUBLE', 'STRING', 'DATETIME'];
    setShouldShowDefaultValue(showDefaultValue.includes(attribute.type.name));
    setShouldShowValueType(showValueType.includes(attribute.type.name));
    setShouldShowTupleName(showTupleName.includes(attribute.type.name));
    setShouldShowValueTupleName(showTupleName.includes(attribute.type.valueTypeName));
  }, [attribute.type.name, attribute.type.valueTypeName]);

  const showKeyType = ['MAP'];

  const attrMapKeyOptions = supportedMapKeyTypes
    .filter((type) => type !== 'STRING COMPRESS')
    .map((type) => ({ id: type, label: type }));

  const attrContainerValueOptions = supportedContainerValueTypes
    .filter((type) => type !== 'STRING COMPRESS')
    .map((type) => ({ id: type, label: type }));

  const handleDefaultValueChange = (value: string) => {
    const newAttribute = attribute.clone();
    newAttribute.defaultValue = value;
    newAttribute.hasDefaultValue = !!value;
    onChange(newAttribute);
  };

  const handleValueTypeChange = (params: OnChangeParams) => {
    const newAttribute = attribute.clone();
    newAttribute.type.valueTypeName = params.value[0].id as string;
    newAttribute.type.hasValueTypeName = true;
    newAttribute.type.hasValueTypeTupleName = params.value[0].id === 'UDT';
    onChange(newAttribute);
  };

  const handleKeyTypeChange = (params: OnChangeParams) => {
    const newAttribute = attribute.clone();
    newAttribute.type.keyTypeName = params.value[0].id as string;
    newAttribute.type.hasKeyTypeName = true;
    onChange(newAttribute);
  };

  const handleUdtNameChange = (params: OnChangeParams) => {
    const newAttribute = attribute.clone();
    const udtName = params.value[0].id as string;
    if (shouldShowTupleName) {
      newAttribute.type.hasTupleName = true;
      newAttribute.type.tupleName = udtName;
      setUdtValue(udtOptions.filter((type) => type.id === udtName));
    } else if (shouldShowValueTupleName) {
      newAttribute.type.hasValueTypeTupleName = true;
      newAttribute.type.valueTypeTupleName = udtName;
      setUdtValue(udtOptions.filter((type) => type.id === udtName));
    }
    onChange(newAttribute);
  };

  return (
    <>
      {isPrimaryId ? (
        <></>
      ) : (
        <>
          {shouldShowDefaultValue && (
            <EditableInput
              value={attribute.defaultValue}
              placeholder="Default value"
              readOnly={readOnly}
              onChange={handleDefaultValueChange}
            />
          )}
          {showKeyType.includes(attribute.type.name) && (
            <>
              <StyledSelect
                value={attrMapKeyOptions.filter((type) => type.id === attribute.type.keyTypeName)}
                options={attrMapKeyOptions}
                placeholder={'Key type'}
                onChange={handleKeyTypeChange}
              />
            </>
          )}
          {shouldShowValueType && (
            <StyledSelect
              value={attrContainerValueOptions.filter((type) => type.id === attribute.type.valueTypeName)}
              options={attrContainerValueOptions}
              placeholder={'Value type'}
              onChange={handleValueTypeChange}
            />
          )}
          {(shouldShowTupleName || shouldShowValueTupleName) && !shouldShowDefaultValue && (
            <StyledSelect
              noResultsMsg={'This graph has no UDT names'}
              value={udtValue}
              options={udtOptions}
              placeholder={'UDT name'}
              onChange={handleUdtNameChange}
            />
          )}
        </>
      )}
    </>
  );
}
