import { SubChapter } from '@/components/tutorial/type.ts';
import { Header } from '@/components/tutorial/style.tsx';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ParagraphXSmall } from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton';
import { useState } from 'react';

export interface SubChapterDetailProps {
  subChapter: SubChapter;
}

export function SubChapterDetail({ subChapter }: SubChapterDetailProps) {
  const [css, theme] = useStyletron();
  const [isLoaded, SetIsLoaded] = useState(false);

  const onloaded = () => {
    SetIsLoaded(true);
  };

  return (
    <div className={css({ position: 'relative' })}>
      <Header>{subChapter.Title}</Header>
      <ParagraphXSmall
        className={css({
          color: theme.colors['text.secondary'],
          lineHeight: '16px !important',
          marginBottom: '12px',
        })}
      >
        {subChapter.Description}
      </ParagraphXSmall>
      {true && (
        <div className={css({})}>
          <Skeleton height="288px" animation />
        </div>
      )}
      <div className={css({ position: 'relative', marginTop: '-288px' })}>
        <iframe id="ytplayer" width="450" height="288" src={subChapter.Url} onLoad={onloaded} allowFullScreen />
      </div>
    </div>
  );
}
