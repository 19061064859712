import type { ChangeEvent, MouseEvent } from 'react';
import TooltipLabel from '@/components/TooltipLabel';
import { LoadingIndicator } from '@/components/loading-indicator';
import { useOrgContext } from '@/contexts/orgContext';
import { MetaAddonsType, useAddonsMeta } from '@/hooks/useAddonsMeta';
import { EnabledAddonsType, useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { isOrgAdmin } from '@/pages/admin/user/type';
import EditWorkspaceAddons from '@/pages/workgroup/form/addons/edit-drawer';
import { drawerConfigComponentMap } from '@/pages/workgroup/form/addons/edit-drawer/config';
import { WorkspaceT } from '@/pages/workgroup/type';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { FC, useCallback, useMemo, useState } from 'react';
import SettingIcon from '@/assets/setting.svg?react';
import IconButton from '@/components/IconButton';
import { Controller, UseFormReturn } from 'react-hook-form';
import { AddonsMetaID } from '@/components/addons/consts';
import { cn } from '@/lib/utils';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { createPortal } from 'react-dom';

const canAddonsConfigure = (addonsID: string) => {
  return Boolean(drawerConfigComponentMap.get(addonsID));
};

const isAddonsEnabled = (addonsMeta: MetaAddonsType, workspace: WorkspaceT) => {
  if (addonsMeta.ID === AddonsMetaID.COPILOT && !workspace.is_rw) {
    return false;
  }
  return true;
};

const WorkspaceAddonsConfiguration: FC<{
  form: UseFormReturn;
  workspace: WorkspaceT;
  configureContainer?: HTMLDivElement | null;
}> = ({ form, configureContainer, workspace }) => {
  const { control } = form;
  const { data: addonsMeta, isLoading: isLoadingAddonsMeta } = useAddonsMeta();
  const [css, theme] = useStyletron();
  const { data: enabledAddonsMap, isLoading: isLoadingEnabledAddons } = useListEnabledAddons({
    select: useCallback((data?: EnabledAddonsType[]) => new Map(data?.map((item) => [item.AddonsID, item])), []),
  });
  const workspaceAddons = useMemo(() => {
    return addonsMeta?.AddonsList.filter((m) => enabledAddonsMap?.has(m.ID)).filter((m) =>
      isAddonsEnabled(m, workspace)
    );
  }, [addonsMeta, enabledAddonsMap, workspace]);
  const [isAddonsConfigOpen, setIsAddonsConfigOpen] = useState(false);
  const [selectedAddonsMeta, setSelectedAddonsMeta] = useState<MetaAddonsType>();
  const [selectedEnabledAddons, setSelectedEnabledAddons] = useState<EnabledAddonsType>();
  const { userInfo } = useOrgContext();

  const isLoading = isLoadingAddonsMeta || isLoadingEnabledAddons;

  const handleEnableAddons = (addonsMeta: MetaAddonsType) => {
    const enable = form.getValues(`addons.${addonsMeta.ID}.enable`);
    setSelectedAddonsMeta(addonsMeta);
    setSelectedEnabledAddons(enabledAddonsMap?.get(addonsMeta.ID));

    setIsAddonsConfigOpen(enable);
  };

  const handleConfigureAddons = (addonsMeta: MetaAddonsType) => {
    if (addonsMeta.ID === selectedAddonsMeta?.ID) {
      setIsAddonsConfigOpen(!isAddonsConfigOpen);
    } else {
      setIsAddonsConfigOpen(true);
    }
    setSelectedAddonsMeta(addonsMeta);
    setSelectedEnabledAddons(enabledAddonsMap?.get(addonsMeta.ID));
  };

  return (
    <>
      <div className="space-y-2">
        <TooltipLabel label="Add-ons" tooltip="" />
        {isLoading && <LoadingIndicator />}
        {workspaceAddons?.map((m) => (
          <div
            className={cn(
              'flex items-center rounded-[2px] border p-3',
              css({
                borderColor: theme.colors['border.tertiary'],
              })
            )}
            key={m.ID}
          >
            <img className="h-8 mr-3" src={m.WorkspaceAvatar} />
            <h4
              className={cn(
                'flex-grow text-sm leading-4 font-bold',
                css({
                  color: theme.colors['text.primary'],
                })
              )}
            >
              {m.Title}
            </h4>
            <span
              className={cn(
                'text-xs font-bold',
                css({
                  color: theme.colors['text.secondary'],
                })
              )}
            >
              {m.Amount}
            </span>
            {canAddonsConfigure(m.ID) && (
              <IconButton
                className="ml-1"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  handleConfigureAddons(m);
                }}
              >
                <SettingIcon />
              </IconButton>
            )}
            <Controller
              key={m.ID}
              name={`addons.${m.ID}.enable`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  checkmarkType="toggle"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.checked);
                    handleEnableAddons(m);
                  }}
                  checked={field.value}
                />
              )}
            />
          </div>
        ))}
        <p className={cn('text-xs font-[Roboto] space-x-1', css({ color: theme.colors['text.secondary'] }))}>
          {isOrgAdmin(userInfo.roles) ? (
            <>
              <span>Can{"'"}t find the Add-on you want?</span>
              <a className={css({ color: theme.colors['text.link'] })} href="/marketplace/addons" target="_blank">
                Click to add
              </a>
              .
            </>
          ) : (
            <span>{"Can't find the Add-On you want? Request addition from your organization's administrator."}</span>
          )}
        </p>
      </div>
      {configureContainer &&
        createPortal(
          <EditWorkspaceAddons
            form={form}
            workspace={workspace}
            isOpen={isAddonsConfigOpen}
            selectedAddonsMeta={selectedAddonsMeta}
            selectedEnabledAddons={selectedEnabledAddons}
          />,
          configureContainer
        )}
    </>
  );
};

export default WorkspaceAddonsConfiguration;
