import { useCallback, useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Attribute } from '@tigergraph/tools-models/topology';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { expand } from 'inline-style-expand-shorthand';
import ConfirmButtons from '@/components/ConfirmButtons';
import AttributeAdvancedSetting from '@/components/schemaDesigner/attributeEditor/AttributeAdvancedSetting';
import StyledButton from '@/pages/ingestion/loadData/configureMapping/StyledButton';
import { FaGear } from 'react-icons/fa6';
import { cloneDeep } from 'lodash-es';
import { ValidateResult } from '@tigergraph/tools-models';
import { CLOSE_SOURCE } from 'baseui/modal';

export interface AttributeAdvancedSettingModalProps {
  mountNode?: HTMLElement;
  name: string;
  attributes: Attribute[];
  isVertex: boolean;
  fromToVertexTypePairs?: {
    from: string;
    to: string;
  }[];
  directed?: boolean;
  reverseEdge?: string;
  readOnly?: boolean;
  onSaveVertex?: (attributes: Attribute[]) => ValidateResult;
  onSaveEdge?: (
    attributes: Attribute[],
    fromToVertexTypePairs: {
      from: string;
      to: string;
    }[],
    reverseEdge: string
  ) => ValidateResult;
}

export default function AttributeAdvancedSettingModal(props: AttributeAdvancedSettingModalProps) {
  const [css, theme] = useStyletron();
  const { name, isVertex, directed, readOnly, onSaveVertex, onSaveEdge } = props;
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [fromToVertexTypePairs, setFromToVertexTypePairs] = useState<
    {
      from: string;
      to: string;
    }[]
  >([]);
  const [reverseEdge, setReverseEdge] = useState('');
  const [openAdvancedSetting, setOpenAdvancedSetting] = useState<boolean>(false);
  const [validateResult, setValidateResult] = useState<ValidateResult>({ success: true, message: '' });

  const initializeStates = useCallback(() => {
    setAttributes(cloneDeep(props.attributes));
    if (props.fromToVertexTypePairs) {
      setFromToVertexTypePairs(cloneDeep(props.fromToVertexTypePairs));
    }
    if (props.reverseEdge !== undefined) {
      setReverseEdge(cloneDeep(props.reverseEdge));
    }
    setValidateResult({ success: true, message: '' });
  }, [props.attributes, props.fromToVertexTypePairs, props.reverseEdge]);

  const onConfirm = useCallback(() => {
    let res: ValidateResult | null = null;
    if (isVertex && onSaveVertex) {
      res = onSaveVertex(attributes);
    } else if (onSaveEdge) {
      res = onSaveEdge(
        attributes,
        fromToVertexTypePairs.filter((pair) => pair.from && pair.to),
        reverseEdge
      );
    }
    if (res) {
      setValidateResult(res);
      if (res.success) {
        setOpenAdvancedSetting(false);
      }
    }
  }, [attributes, fromToVertexTypePairs, isVertex, onSaveEdge, onSaveVertex, reverseEdge]);

  const onCancel = useCallback(() => {
    setAttributes(props.attributes);
    setOpenAdvancedSetting(false);
  }, [props.attributes]);

  return (
    <div
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <StyledButton
        kind="secondary"
        onClick={() => {
          setOpenAdvancedSetting(true);
          initializeStates();
        }}
      >
        <FaGear color={theme.colors['button.icon']} />
        Advanced Setting
      </StyledButton>
      <Modal
        isOpen={openAdvancedSetting}
        onClose={({ closeSource }) => {
          if (closeSource !== CLOSE_SOURCE.backdrop) {
            onCancel();
          }
        }}
        overrides={{
          Dialog: {
            style: {
              width: '660px',
            },
          },
        }}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Urbanist',
            fontSize: '16px',
            fontWeight: 600,
            color: theme.colors['modal.text'],
            ...expand({
              padding: '0',
            }),
          })}
        >
          {`Advanced Settings for ${isVertex ? 'Vertex' : 'Edge'}`}
          &nbsp;
          <span
            className={css({
              fontWeight: 400,
            })}
          >
            {name}
          </span>
        </ModalHeader>
        <ModalBody>
          <AttributeAdvancedSetting
            attributes={attributes}
            fromToVertexTypePairs={fromToVertexTypePairs}
            isVertex={isVertex}
            directed={!!directed}
            reverseEdge={reverseEdge}
            validateResult={validateResult}
            readOnly={readOnly}
            onChangeAttributes={setAttributes}
            onChangePairs={setFromToVertexTypePairs}
            onChangeReverseEdge={setReverseEdge}
            onChangeValidateResult={setValidateResult}
          />
        </ModalBody>
        <ModalFooter>
          <ConfirmButtons onConfirm={onConfirm} onCancel={onCancel} confirmLabel="Save" />
        </ModalFooter>
      </Modal>
    </div>
  );
}
