import { axiosOrgService } from '@/lib/network';
import { useQuery } from 'react-query';
import trackUtil from '@/utils/analytics-service.ts';

export default function useProfile() {
  return useQuery(
    ['profile'],
    async () => {
      const { data } = await axiosOrgService.get('/users/me');
      return data;
    },
    {
      onSuccess: (data) => {
        trackUtil.trackLoginComplete(data);
      },
    }
  );
}
