import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Select } from 'baseui/select';
import { Table } from 'baseui/table-semantic';
import { useSchemaDesignerContext } from '@/contexts/schemaDesignerContext';
import { SchemaAttributeTableWithSelect } from '@/utils/schemaDesigner/styleObject';
import { Button } from '@tigergraph/app-ui-lib/button';
import { expand } from 'inline-style-expand-shorthand';
import { MdDeleteOutline } from 'react-icons/md';
import { Plus } from 'baseui/icon';
import { useCallback, useMemo, useState } from 'react';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import ConfirmModal from '@/components/ConfirmModal';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import TooltipLabel from '@/components/TooltipLabel';

export interface FromToVertexPairTableProps {
  fromToVertexTypePairs: { from: string; to: string }[];
  onChangePairs: (fromToVertexTypePairs: { from: string; to: string }[]) => void;
  readOnly?: boolean;
}

export function FromToVertexPairTable({ fromToVertexTypePairs, onChangePairs, readOnly }: FromToVertexPairTableProps) {
  const [css, theme] = useStyletron();
  const { designerService } = useSchemaDesignerContext();
  const [deleteSelection, setDeleteSelection] = useState<{ [index: number]: boolean }>({});
  const [openDialog, setOpenDialog] = useState(false);

  const vertexOptions = designerService.getAllVertexTypes().map((vertexType) => {
    return {
      label: vertexType,
      id: vertexType,
    };
  });

  const handleAddPair = () => {
    onChangePairs([...fromToVertexTypePairs, { from: vertexOptions[0].id, to: vertexOptions[0].id }]);
  };

  const handleDeletePairsConfirm = () => {
    setOpenDialog(true);
  };

  const handleDeletePairs = () => {
    if (Object.keys(deleteSelection).length === fromToVertexTypePairs.length) {
      return;
    }
    const newPairs = [];
    for (let i = 0; i < fromToVertexTypePairs.length; i++) {
      if (!(i in deleteSelection)) {
        newPairs.push(fromToVertexTypePairs[i]);
      }
    }
    setDeleteSelection({});
    onChangePairs(newPairs);
  };

  const handleDeleteSelection = useCallback((index: number) => {
    setDeleteSelection((prevDeleteSelection) => {
      const isCheck = !prevDeleteSelection[index];
      if (isCheck) {
        return {
          ...prevDeleteSelection,
          [index]: !prevDeleteSelection[index],
        };
      } else {
        delete prevDeleteSelection[index];
        return { ...prevDeleteSelection };
      }
    });
  }, []);

  const handleHeaderDeleteSelection = useCallback(() => {
    setDeleteSelection((prevDeleteSelection) => {
      if (Object.keys(deleteSelection).length === fromToVertexTypePairs.length) {
        return {};
      } else {
        for (let i = 0; i < fromToVertexTypePairs.length; i++) {
          prevDeleteSelection[i] = true;
        }
        return { ...prevDeleteSelection };
      }
    });
  }, [deleteSelection, fromToVertexTypePairs]);

  const tableHeader = useMemo(() => {
    const tableHeader = [
      <Checkbox
        key={'delete_checkbox'}
        isIndeterminate={
          Object.keys(deleteSelection).length > 0 &&
          Object.keys(deleteSelection).length !== fromToVertexTypePairs.length
        }
        checked={
          Object.keys(deleteSelection).length > 0 &&
          Object.keys(deleteSelection).length === fromToVertexTypePairs.length
        }
        disabled={readOnly || fromToVertexTypePairs.length === 0}
        onChange={handleHeaderDeleteSelection}
      />,
      'From',
      'To',
    ];
    return tableHeader;
  }, [deleteSelection, fromToVertexTypePairs.length, handleHeaderDeleteSelection, readOnly]);

  return (
    <div
      className={css({
        width: '100%',
      })}
    >
      <div
        className={css({
          padding: '8px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <TooltipLabel
          label="Applies To"
          tooltip="Add more edge pairs which refer to the FROM and TO vertex types of an edge type"
        />
        <Button
          aria-label={Object.keys(deleteSelection).length > 0 ? 'Delete pair' : 'Add pair'}
          onClick={Object.keys(deleteSelection).length > 0 ? handleDeletePairsConfirm : handleAddPair}
          disabled={readOnly}
          kind={Object.keys(deleteSelection).length ? 'destructive' : 'secondary'}
          overrides={{
            BaseButton: {
              style: {
                ...expand({
                  padding: '3px',
                }),
              },
            },
          }}
        >
          {Object.keys(deleteSelection).length > 0 ? (
            <MdDeleteOutline color={theme.colors.negative} size={16} />
          ) : (
            <Plus size={16} />
          )}
        </Button>
      </div>
      <Table
        divider={'horizontal'}
        overrides={mergeOverrides(SchemaAttributeTableWithSelect(theme) as Overrides<any>, {
          Root: {
            style: {
              maxHeight: '400px',
              overflow: 'auto',
            },
          },
        })}
        columns={tableHeader}
        data={fromToVertexTypePairs.map((pair, index) => {
          return [
            <div
              key={`delete_checkbox_${pair.from}_${pair.to}`}
              className={css({
                display: 'flex',
                alignItems: 'center',
                lineHeight: '35px',
                height: '35px',
              })}
            >
              <Checkbox
                checked={!!deleteSelection[index]}
                disabled={readOnly}
                onChange={() => handleDeleteSelection(index)}
              />
            </div>,
            <Select
              onChange={(params) => {
                fromToVertexTypePairs[index].from = params.value[0].id as string;
                onChangePairs([...fromToVertexTypePairs]);
              }}
              options={vertexOptions}
              searchable={false}
              clearable={false}
              key={index}
              value={[{ label: pair.from, id: pair.from }]}
              disabled={readOnly}
              size="compact"
              overrides={{
                Root: {
                  style: {
                    height: '32px',
                    backgroundColor: 'inherit',
                    fontSize: '12px',
                  },
                },
                ControlContainer: {
                  style: {
                    backgroundColor: 'inherit',
                    height: '32px',
                    borderTopStyle: 'none',
                    borderLeftStyle: 'none',
                    borderRightStyle: 'none',
                    borderBottomStyle: 'none',
                  },
                },
                ValueContainer: {
                  style: {
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  },
                },
                SingleValue: {
                  style: {
                    lineHeight: '30px',
                  },
                },
                IconsContainer: {
                  style: {
                    paddingRight: '0px',
                    paddingLeft: '0px',
                  },
                },
                Popover: {
                  props: {
                    ignoreBoundary: true,
                  },
                },
                Dropdown: {
                  style: {
                    maxHeight: '300px',
                  },
                },
                DropdownOption: {
                  props: {
                    style: {
                      fontSize: '12px',
                    },
                  },
                },
              }}
            />,
            <Select
              onChange={(params) => {
                fromToVertexTypePairs[index].to = params.value[0].id as string;
                onChangePairs([...fromToVertexTypePairs]);
              }}
              options={vertexOptions}
              searchable={false}
              clearable={false}
              key={index}
              value={[{ label: pair.to, id: pair.to }]}
              disabled={readOnly}
              size="compact"
              overrides={{
                Root: {
                  style: {
                    height: '32px',
                    backgroundColor: 'inherit',
                    fontSize: '12px',
                  },
                },
                ControlContainer: {
                  style: {
                    backgroundColor: 'inherit',
                    height: '32px',
                    borderTopStyle: 'none',
                    borderLeftStyle: 'none',
                    borderRightStyle: 'none',
                    borderBottomStyle: 'none',
                  },
                },
                ValueContainer: {
                  style: {
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  },
                },
                SingleValue: {
                  style: {
                    lineHeight: '30px',
                  },
                },
                IconsContainer: {
                  style: {
                    paddingRight: '0px',
                    paddingLeft: '0px',
                  },
                },
                Popover: {
                  props: {
                    ignoreBoundary: true,
                  },
                },
                Dropdown: {
                  style: {
                    maxHeight: '300px',
                  },
                },
                DropdownOption: {
                  props: {
                    style: {
                      fontSize: '12px',
                    },
                  },
                },
              }}
            />,
          ];
        })}
      />
      <ConfirmModal
        open={openDialog}
        header="Warning"
        body={
          Object.keys(deleteSelection).length === fromToVertexTypePairs.length
            ? `At least one edge pair must remain. Please keep at least one edge pair.`
            : `Are you sure you want to delete the selected pair${Object.keys(deleteSelection).length > 1 ? 's' : ''}?`
        }
        onConfirm={() => {
          setOpenDialog(false);
          handleDeletePairs();
        }}
        onCancel={
          Object.keys(deleteSelection).length < fromToVertexTypePairs.length ? () => setOpenDialog(false) : undefined
        }
      />
    </div>
  );
}
