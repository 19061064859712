import { NewSpaceStepper } from '@/pages/workgroup/form/Stepper';
import { Outlet } from 'react-router-dom';
import { Container, ChildPage } from '@/pages/workgroup/form/StyledComponent';

export default function SpaceLayout() {
  return (
    <Container>
      <NewSpaceStepper />
      <ChildPage>
        <Outlet />
      </ChildPage>
    </Container>
  );
}
