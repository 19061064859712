import { Drawer, DrawerBody, DrawerHeader } from '@/components/Drawer.tsx';
import { Tab, Tabs } from '@/components/Tab.tsx';
import { axiosCluster } from '@/lib/network.ts';
import { GraphSelector } from '@/pages/explore/explore/GraphSelector.tsx';
import { ApiContainer } from '@/pages/workgroup/tab/restPP/ApiContainer.tsx';
import { WorkspaceT } from '@/pages/workgroup/type.ts';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Key, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DrawerHeadContainer } from '@/lib/styled.tsx';
import { LinkIcon } from '@/pages/home/icons.tsx';
import { ReadyApi } from '@/pages/workgroup/tab/restPP/Util.tsx';
import { BaseSkeleton } from '@/components/BaseSkeleton.tsx';
import { MdRefresh } from 'react-icons/md';
import { Button } from '@tigergraph/app-ui-lib/button';
import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { fetchGraphList } from '@/lib/instance_api';

interface RestppDrawerProps {
  isOpen: boolean;
  workspace: WorkspaceT;
  onClose: () => void;
}

export function RestppDrawer({ isOpen, workspace, onClose }: RestppDrawerProps) {
  const baseurl = `https://${workspace.nginx_host}`;

  const [css] = useStyletron();
  const [graph, setGraph] = useState<string>('');
  const [customEndpoints, setCustomEndpoints] = useState<{ [key: string]: object } | null>(null);
  const [activeKey, setActiveKey] = useState<Key>('Installed Query');
  const [searchInput, setSearchInput] = useState<string>('');

  const catalogQueryClient = useQuery(
    ['getCatalog', workspace.nginx_host, graph],
    async () => {
      const res = await axiosCluster.get(`${baseurl}/api/restpp/endpoints`, { params: { graph } });
      return res.data;
    },
    {
      enabled: graph !== '',
    }
  );
  const { refetch: refetchCatalog } = catalogQueryClient;

  const builtInQueryClient = useQuery(
    ['getInstalledQuery', workspace.nginx_host, graph],
    async () => {
      const res = await axiosCluster.get(`${baseurl}/api/restpp/endpoints`, {
        params: { builtin: true, graph },
      });
      return res.data;
    },
    {
      enabled: graph !== '',
    }
  );
  const { refetch: refetchBuiltIn } = builtInQueryClient;

  const handleClickRefresh = () => {
    refetchCatalog();
    refetchBuiltIn();
  };

  // filter custom endpoints using builtInEndpoints and allEndpoints
  useEffect(() => {
    const allEndpoints = catalogQueryClient.data?.results;
    const builtInEndpoints = builtInQueryClient.data?.results;

    // filter custom endpoints using builtInEndpoints and allEndpoints
    if (builtInEndpoints && allEndpoints) {
      let customEndpoints: { [key: string]: object } = {};
      for (let key in allEndpoints) {
        if (!builtInEndpoints[key] && allEndpoints[key].enabled && key.includes(graph)) {
          customEndpoints[key] = allEndpoints[key];
        }
      }
      setCustomEndpoints(customEndpoints);
    }
  }, [catalogQueryClient.data, builtInQueryClient.data, graph]);

  const simpleAuthClient = useQuery(
    ['graph_list', workspace?.nginx_host],
    async () => {
      if (!workspace) {
        return [];
      }

      return fetchGraphList(workspace?.nginx_host, workspace.tg_version, sessionStorage.getItem(ID_TOKEN_KEY)!);
    },
    {
      enabled: isOpen,
      onSuccess(data) {
        // setup default graph name
        if (data.length > 0) {
          const defaultGraph = data[0];
          setGraph(defaultGraph);
        }
      },
    }
  );

  const handleClose = () => {
    onClose();
    setActiveKey('Installed Query');
    setSearchInput('');
  };

  const tabContent = (endpoints: { [key: string]: object }, searchInput: string, readyApi: string[]) => {
    let filteredEndpoints = Object.keys(endpoints)
      .filter((item) => {
        if (!searchInput) return true;
        const search = searchInput.trim().toLowerCase();
        return item.toLowerCase().includes(search);
      })
      .filter((item) => {
        if (readyApi.length === 0) return true;
        return readyApi.includes(item);
      })
      .filter((item) => {
        if (!workspace.is_rw) {
          const apiType = item.split(' ')[0];
          return apiType === 'GET';
        }
        return true;
      })
      .sort((a, b) => -+(a > b));

    const isFetching = catalogQueryClient.isFetching || builtInQueryClient.isFetching || simpleAuthClient.isFetching;
    const isFetched = catalogQueryClient.isFetched && builtInQueryClient.isFetched && simpleAuthClient.isFetched;

    return (
      <>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
          })}
        >
          <div
            className={css({
              width: '28%',
              marginBottom: '16px',
            })}
          >
            <GraphSelector
              graphs={simpleAuthClient.data || []}
              graph={graph}
              isFetching={false}
              onGraphChanged={(graphName) => {
                setGraph(graphName);
              }}
            />
          </div>
          <Input
            value={searchInput}
            overrides={{
              Root: {
                style: {
                  width: '70%',
                  marginBottom: '16px',
                  height: '34px',
                },
              },
              Input: {
                style: {
                  fontSize: '14px',
                },
              },
            }}
            placeholder="Search"
            onChange={(e) => setSearchInput(e.currentTarget.value)}
          />
        </div>
        <div>
          {isFetching ? (
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              })}
            >
              <BaseSkeleton height={'42px'} />
              <BaseSkeleton height={'42px'} />
              <BaseSkeleton height={'42px'} />
              <BaseSkeleton height={'42px'} />
              <BaseSkeleton height={'42px'} />
              <BaseSkeleton height={'42px'} />
              <BaseSkeleton height={'42px'} />
              <BaseSkeleton height={'42px'} />
            </div>
          ) : (
            <>
              {isFetched && filteredEndpoints.length === 0 && <div>No endpoints available</div>}
              {isFetched && !graph && <div>Graph schema is empty, please create graph schema.</div>}
              {isFetched && graph && filteredEndpoints.length > 0 && (
                <div>
                  {filteredEndpoints.map((item) => {
                    return (
                      <ApiContainer
                        baseUrl={baseurl}
                        key={item}
                        apiName={item}
                        apiInfo={endpoints[item]}
                        graphName={graph}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={handleClose}>
        <DrawerHeader>
          <DrawerHeadContainer>
            <LinkIcon />
            Connect From API
          </DrawerHeadContainer>
        </DrawerHeader>
        <DrawerBody
          $style={{
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => {
              setActiveKey(activeKey as Key);
            }}
          >
            <Tab
              overrides={{
                Tab: {
                  style: {
                    ':focus-visible': 'none',
                  },
                },
                TabPanel: {
                  style: {
                    height: 'calc(100vh - 100px)',
                    minHeight: 'calc(100vh - 100px)',
                    overflow: 'auto',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  },
                },
              }}
              title={'Installed Query'}
              key={'Installed Query'}
            >
              {tabContent(customEndpoints || {}, searchInput, [])}
            </Tab>
            <Tab
              overrides={{
                TabPanel: {
                  style: {
                    height: 'calc(100vh - 100px)',
                    minHeight: 'calc(100vh - 100px)',
                    overflow: 'auto',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  },
                },
              }}
              title={'Built-In Query'}
              key={'Built-In Query'}
            >
              {tabContent(builtInQueryClient.data?.results || {}, searchInput, ReadyApi)}
            </Tab>
          </Tabs>
          <div className={css({ position: 'absolute', top: '4px', right: '18px' })}>
            <Button kind="text" shape="square" size="large" onClick={handleClickRefresh}>
              <MdRefresh size={24} />
            </Button>
          </div>
        </DrawerBody>
      </Drawer>
    </>
  );
}
