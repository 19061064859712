import { forwardRef, Ref } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { mergeOverrides } from 'baseui';
import { Button, ButtonProps } from '@tigergraph/app-ui-lib/button';
import { Overrides } from 'baseui/overrides';
import { SIZE } from 'baseui/button';

const StyledButton = forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
  const [, theme] = useStyletron();
  return (
    <Button
      {...props}
      ref={ref}
      size={SIZE.compact}
      overrides={mergeOverrides(
        {
          BaseButton: {
            style: {
              whiteSpace: 'nowrap',
              height: '24px',
              display: 'flex',
              columnGap: '4px',
            },
          },
        },
        props.overrides as Overrides<any>
      )}
    >
      {props.children}
    </Button>
  );
});

export default StyledButton;
