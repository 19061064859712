import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { SHAPE, SIZE } from 'baseui/button';
import { Plus } from 'lucide-react';
import EmptyIcon from './empty.svg';
import EmptyDarkIcon from './empty.svg';
import { Body2, Label } from '@tigergraph/app-ui-lib/typography';
import React from 'react';
import { useTheme } from '@/contexts/themeContext';

export function AddHeader({
  onAdd,
  label,
  disabled,
}: {
  onAdd: () => void;
  label: React.ReactNode;
  disabled?: boolean;
}) {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
      })}
    >
      <span
        className={css({
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        })}
      >
        {label}
      </span>
      <Button kind="secondary" shape={SHAPE.square} size={SIZE.compact} onClick={onAdd} disabled={disabled}>
        <Plus size={16} />
      </Button>
    </div>
  );
}

export function Empty({ title, description }: { title: string; description: string }) {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '32px',
        marginBottom: '32px',
      })}
    >
      <img
        src={themeType === 'light' ? EmptyIcon : EmptyDarkIcon}
        alt="empty"
        className={css({
          width: '47px',
          height: '57px',
        })}
      />
      <Body2
        $style={{
          marginTop: '8px',
          marginBottom: '4px',
        }}
      >
        {title}
      </Body2>
      <Label
        $style={{
          color: theme.colors['text.tertiary'],
        }}
      >
        {description}
      </Label>
    </div>
  );
}
