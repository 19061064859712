import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ErrorMessageProps {
  message: string;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  const [css, theme] = useStyletron();
  const { message } = props;
  return (
    <div
      className={css({
        display: 'flex',
        color: theme.colors.negative,
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        alignItems: 'center',
        paddingTop: '4px',
      })}
    >
      <div className={css({ marginRight: '8px' })}>
        <FontAwesomeIcon icon={faTriangleExclamation as IconProp} />
      </div>
      {message}
    </div>
  );
}
