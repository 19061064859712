import { BlockProps } from 'baseui/block';
import { styled } from '@tigergraph/app-ui-lib/Theme';
import { Body2, HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';

export const StyledTitle = ({ children }: BlockProps) => {
  return (
    <HeadingMenuSelected>
      <>{children}</>
    </HeadingMenuSelected>
  );
};

export const StyledSubTitle = ({ children }: BlockProps) => {
  return (
    <Body2 as="span" $style={{ fontWeight: 700 }}>
      <>{children}</>
    </Body2>
  );
};

export const StyledText = ({ children }: BlockProps) => {
  return (
    <Body2>
      <>{children}</>
    </Body2>
  );
};

export const InlineBlock = styled('div', {
  display: 'flex',
});
