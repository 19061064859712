import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { WorkspaceT } from '@/pages/workgroup/type.ts';

interface TutorialContextValue {
  shouldShowCongratulationModal: boolean;
  setShouldShowCongratulationModal: (shouldShow: boolean) => void;
  workspace: WorkspaceT | null;
  setWorkspace: (workspace: WorkspaceT) => void;
}

const TutorialContext = createContext<TutorialContextValue>({} as TutorialContextValue);

const useTutorialContext = () => {
  const context = useContext(TutorialContext);
  return context;
};

const TutorialProvider: FC<PropsWithChildren> = (props) => {
  const [shouldShowCongratulationModal, setShouldShowCongratulationModal] = useState(false);
  const [workspace, setWorkspace] = useState<WorkspaceT | null>(null);

  const TutorialContextValue: TutorialContextValue = {
    shouldShowCongratulationModal,
    setShouldShowCongratulationModal,
    workspace,
    setWorkspace,
  };

  return <TutorialContext.Provider value={TutorialContextValue} {...props} />;
};

export { TutorialProvider, useTutorialContext };
