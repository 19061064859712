export function getCallbackURL(path?: string, isOrgLogin?: boolean) {
  const publicPath = ['/emailSend'];
  const { origin, pathname, search } = window.location;
  // avoid overwrite the localStorage with wrong pathname
  if (pathname !== '/callback' || (path && publicPath.some((p) => path.includes(p)))) {
    const redirectTo = path ?? `${pathname}${search}`;
    localStorage.setItem('redirectTo', redirectTo);
  }
  return `${origin}/callback${isOrgLogin ? '?isOrgLogin=true' : ''}`;
}
