import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useWorkspaceContext } from '@/contexts/workspaceContext.tsx';
import { useOrgContext } from '@/contexts/orgContext';
import { isGroupAdmin, isOrgAdmin } from '@/pages/admin/user/type';
import wsUnavailable from '@/assets/ws-unavailable.svg';
import { Link } from 'react-router-dom';

const InactiveStatus = () => {
  const [css, theme] = useStyletron();
  const { userInfo } = useOrgContext();
  const { currentWorkspace, workspaces } = useWorkspaceContext();
  const orgAdmin = isOrgAdmin(userInfo.roles);
  const groupAdmin = isGroupAdmin(userInfo.roles, '');

  // current workspace is not active and we have workspaces
  const isNonActiveWorkspace = !currentWorkspace && workspaces.length > 0;

  // only org admin and group admin can create workspace
  const canCreateSpace = orgAdmin || groupAdmin;

  if (currentWorkspace?.status !== 'Active') {
    return (
      <div
        className={css({
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          rowGap: '16px',
        })}
      >
        <img
          src={wsUnavailable}
          className={css({
            width: '70px',
          })}
        />
        <div
          className={css({
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 400,
            fontFamily: 'Roboto',
            color: theme.colors['text.secondary'],
          })}
        >
          {canCreateSpace ? (
            isNonActiveWorkspace ? (
              'Please select a workspace from the dropdown in the top right corner.'
            ) : (
              <span>
                {'Please '}
                <Link
                  to="/groups/new"
                  className={css({
                    color: theme.colors.accent400,
                  })}
                >
                  create workspace
                </Link>
                {' to start designing schema.'}
              </span>
            )
          ) : (
            'Please contact your administrator to create workspace to start designing the schema.'
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default InactiveStatus;
