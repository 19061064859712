import { AxiosError } from 'axios';
import { useStyletron } from 'styletron-react';
import { StyledToast } from './styledToasterContainer';
import { KIND } from 'baseui/toast';
import { getErrorMessage } from '@/utils/utils';

export function ErrorDisplay({
  error,
  label,
  isGSQLError,
}: {
  error: AxiosError | Error | null | undefined;
  label?: string;
  isGSQLError?: boolean;
}) {
  const [css] = useStyletron();
  if (!error) {
    return null;
  }

  let message = getErrorMessage(error);

  // 1. is gsql error
  // 2. detected if contain `interpreted mode`
  //      interpret query run will failed with different reason, only show interpreted query tips is contains `interpreted mode`
  const isInterpretGSQLError = isGSQLError && message.includes('interpreted mode');

  return (
    <div role="alert" className={css({ padding: '12px' })}>
      <StyledToast
        message={
          <div>
            {label ?? 'Something went wrong:'}
            <div
              className={css({
                marginTop: '8px',
                maxHeight: '320px',
                overflow: 'auto',
              })}
            >
              {message}
              {isInterpretGSQLError ? (
                <span>
                  GSQL queries run in interpreted mode have some{' '}
                  <a
                    href="https://docs.tigergraph.com/gsql-ref/current/appendix/interpreted-gsql-limitations"
                    rel="noreferrer"
                    target="_blank"
                  >
                    limitations
                  </a>
                  . Switch to GraphStudio to install the query and then run the installed query in Insights.
                </span>
              ) : null}
            </div>
          </div>
        }
        kind={KIND.negative}
        closeable={false}
      />
    </div>
  );
}
