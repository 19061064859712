import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';

export function getSubnetId(subnetId: string) {
  return subnetId
    .trim()
    .split(',')
    .map((id) => id.trim())
    .filter((id) => !!id);
}

export function isCPDisabled(cp: CloudProviderT) {
  return !['active', 'upgradecomplete', 'upgrading'].includes(cp.status);
}
