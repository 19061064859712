import { Popover } from '@tigergraph/app-ui-lib/popover';
import { Button } from '@tigergraph/app-ui-lib/button';
import IconButton from '@/components/IconButton.tsx';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import Help from '../../pages/home/icons/help.svg?react';
import { useLocalStorageState } from 'ahooks';
import { popoverOverrides } from '@/components/confirmPopover';

export interface TutorialMenuProps {
  onClick: () => void;
}

export function TutorialMenu({ onClick }: TutorialMenuProps) {
  const SHOULD_SHOW_TUTORIAL_TOOLTIP = 'should-show-tutorial-tooltip';
  const [css, theme] = useStyletron();
  const [isOpenTooltip, setIsOpenTooltip] = useLocalStorageState<boolean>(SHOULD_SHOW_TUTORIAL_TOOLTIP, {
    defaultValue: true,
  }) as any;

  return (
    <IconButton
      onClick={() => onClick()}
      $style={{
        width: '100%',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        fontSize: '12px',
        padding: '8px 12px 8px 0px',
      }}
    >
      <span>
        <Help />
      </span>
      <Popover
        overrides={popoverOverrides}
        autoFocus={false}
        content={() => {
          return (
            <>
              <div
                className={css({
                  fontSize: '14px',
                  fontWeight: 600,
                  marginBottom: '12px',
                })}
              >
                Start your journey with our tutorials
              </div>
              <div>Tap here to explore our tutorials and begin your learning experience.</div>
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'flex-end',
                })}
              >
                <Button
                  overrides={{
                    BaseButton: {
                      style: {
                        marginTop: '12px',
                      },
                    },
                  }}
                  onClick={(event) => {
                    setIsOpenTooltip(false);
                    event.stopPropagation();
                  }}
                >
                  Got it!
                </Button>
              </div>
            </>
          );
        }}
        isOpen={isOpenTooltip}
      >
        <span>Tutorials</span>
      </Popover>
    </IconButton>
  );
}
