import { styled } from '@tigergraph/app-ui-lib/Theme';

export const TableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '16px',
  paddingBottom: '16px',
  height: '100%',
});

export const DrawerHeadContainer = styled('div', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});
