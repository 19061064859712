import { Result } from '@/lib/type';
import { getCloudProviders, getPublicCloudProviders } from '@/pages/admin/settings/cloud_provider/api';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';

export const useQueryCPs = () => {
  const queryClient = useQueryClient();
  return useQuery<Result<CloudProviderT[]>, AxiosError>(['cloud-providers'], getCloudProviders, {
    refetchInterval: 60000,
  });
};

export const useQueryPublicCPs = () => {
  const queryClient = useQueryClient();
  return useQuery<Result<CloudProviderT[]>, AxiosError>(['cloud-providers', 'public'], getPublicCloudProviders);
};
