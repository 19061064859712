import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { styled } from '@tigergraph/app-ui-lib/Theme';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';

export const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '24px',
  color: $theme.colors.primary900,
  flexDirection: 'column',
}));

export function LoadingIndicator({ message }: { message?: string }) {
  const [css] = useStyletron();
  return (
    <Container>
      <Spinner $size={'20px'} $borderWidth={'2px'} />
      {message && (
        <span
          className={css({
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '12px',
            textAlign: 'center',
            color: '#909090',
            paddingTop: '16px',
          })}
        >
          {message}
        </span>
      )}
    </Container>
  );
}
