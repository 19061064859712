import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { ApplicationT, CreateQuickInsightsRequest } from './type';

export async function listQuickInsights(group_id: string): Promise<Result<ApplicationT[]>> {
  const response = await axiosController.get(`v2/dashboards`, { params: { workgroup_id: group_id } });
  return response.data;
}

export async function createQuickInsights(data: CreateQuickInsightsRequest): Promise<Result<ApplicationT>> {
  const response = await axiosController.post(`v2/dashboards/${data.workgroup_id}/${data.workspace_id}`, data);
  return response.data;
}

export async function getApplicationDetail(
  workgroup_id: string,
  workspace_id: string,
  app_id: string
): Promise<Result<ApplicationT>> {
  const response = await axiosController.get(`v2/dashboards/${workgroup_id}/${workspace_id}/${app_id}`);
  return response.data;
}

export async function deleteApplication(
  workgroup_id: string,
  workspace_id: string,
  app_id: string
): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/dashboards/${workgroup_id}/${workspace_id}/${app_id}`);
  return response.data;
}

export async function runApplication(
  workgroup_id: string,
  workspace_id: string,
  app_id: string,
  force?: boolean
): Promise<Result<ApplicationT>> {
  const response = await axiosController.post(
    `v2/dashboards/${workgroup_id}/${workspace_id}/${app_id}/run${force ? '?force=true' : ''}`
  );
  return response.data;
}
