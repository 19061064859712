import { RoleType } from '@/lib/models';

export type Region = {
  name: string;
  description: string;
};

export type TGVersion = {
  name: string;
  supportS3: false;
};

export type WorkSpaceType = {
  typeName: string;
  storage: string;
  cpu: string;
  memory: string;
  cost_per_hour: number;
  cost_per_hour_with_ha: number;
  partition: number;
};

export type WorkSpaceMeta = {
  regions: Region[];
  tgVersions: TGVersion[];
  workspaceTypes: WorkSpaceType[];
};

export type WorkSpaceOrgQuota = {
  rw_workspace_limit: number;
  ro_workspace_limit: number;
  total_workspace_limit: number;
  rw_workspace_count: number;
  ro_workspace_count: number;
};

export type FolderStatistics = {
  billed_size: number;
};

export type DatabaseT = {
  database_id: string;
  name: string;
  workgroup_id: string;
  tg_version: string;
  create_time: string;
  folder_statistics?: FolderStatistics;
};

export type WorkspaceStatus =
  | 'Active'
  | 'Idle'
  | 'Stopped'
  | 'Error'
  | 'Pending'
  | 'Updating'
  | 'Upgrading'
  | 'Resizing'
  | 'Stopping'
  | 'Resuming'
  | 'Terminating'
  | 'Pausing'
  | 'Degraded';

export type WorkspaceRefreshStatus = 'Init' | 'Exporting' | 'Importing' | 'Failed' | 'Success' | 'Interrupted' | '';
export type ConditionType =
  | 'Normal'
  | 'InitializeRoll'
  | 'InitializePost'
  | 'UpdateRoll'
  | 'UpgradeRoll'
  | 'UpgradePost'
  | 'ExpandRoll'
  | 'ExpandPost'
  | 'ExpandRollBack'
  | 'ShrinkPre'
  | 'ShrinkRoll'
  | 'ConfigUpdate'
  | 'PauseRoll'
  | 'Paused'
  | 'ResumeRoll'
  | 'HAUpdate'
  | 'Degraded'
  | 'NotReady';

export type WorkspaceT = {
  name: string;
  status: WorkspaceStatus;
  condition_type: ConditionType;
  workspace_id: string;
  workgroup_id: string;
  workgroup_name: string;
  database_name: string;
  database_id: string;
  tg_version: string;
  created_at: string;
  creator: string;
  last_modified_time: string;
  workspace_type: WorkSpaceType;
  is_rw: boolean;
  nginx_host: string;
  role: RoleType;
  enable_auto_start: boolean;
  enable_ha: boolean;
  enable_copilot: boolean;
  copilot_llm_provider_config_id: string;
  auto_stop_minutes: number;
  snapshot_time: string;
  refresh_status: WorkspaceRefreshStatus;
  addons?: WorkspaceAddons[];
  vertex_count?: number;
  edge_count?: number;

  canAccess: boolean;
};

export type WorkGroupT = {
  workgroup_id: string;
  name: string;
  org_id: string;
  region: string;
  tg_databases: DatabaseT[];
  workspaces: WorkspaceT[];
  creator: string;
  role: RoleType;
  enable_allow_list: boolean;
  cloud_provider_id: string;
};

export type CreateWorkGroupRequest = {
  name: string;
  region: string;
  platform: string;
  cloud_provider_id: string;
};

export type CreateWorkSpaceRequest = {
  workspace_type_name: string;
  tg_database_id: string;
  tg_version: string;
  cloud_provider_id: string;

  name: string;
  size: number;
  is_rw: boolean;
  enable_auto_start: boolean;
  auto_stop_minutes: number;
  enable_copilot?: boolean;
  copilot_llm_provider_config_id?: string;

  workgroup_id: string;
  addons: {
    addons_id: string;
    enable: boolean;
    enable_configuration: Record<string, undefined | string | number>;
  }[];

  enable_ha: boolean;
  solution_install_request?: {
    name: string;
    solution_catalog_id: string;
    data_source_origin: string;
  };
};

export type UpdateWorkspaceRequest = {
  workspace_type_name?: string;
  enable_auto_start?: boolean;
  auto_stop_minutes?: number;
  enable_copilot?: boolean;
  workspace_name?: string;
  addons?: {
    addons_id: string;
    enable: boolean;
    enable_configuration: Record<string, undefined | string | number>;
  }[];
  enable_ha?: boolean;
};

export type CreateDatabaseRequest = {
  name: string;
  tg_version: string;
  workgroup_id: string;
};

export type BackupSchedule = {
  schedule: string;
  pause: boolean;
};

export type WorkspaceAddons = {
  enable: boolean;
  addons_id: string;
  // workspace_id: string;
  enable_configuration: Record<string, string>;
};

export function isStatusActive(status: WorkspaceStatus) {
  // treat degraded the same as active
  return status === 'Active' || status === 'Degraded';
}

// when in Degraded status
// 1. can not install query (disable install solution/generate data profile)
// 2. can not load data (disable load data)
export function isStatusDegraded(status: WorkspaceStatus) {
  return status === 'Degraded';
}

// Paused + Auto Start On
export function isStatusIdle(status: WorkspaceStatus) {
  return status === 'Idle';
}

// Paused + Auto Start off
export function isStatusPaused(status: WorkspaceStatus) {
  return status === 'Stopped';
}

export function isStatusError(status: WorkspaceStatus) {
  return status === 'Error';
}

export function isStatusInitializing(status: WorkspaceStatus) {
  return status === 'Pending';
}

export function isStatusPending(status: WorkspaceStatus) {
  return (
    status === 'Pending' ||
    status === 'Updating' ||
    status === 'Upgrading' ||
    status === 'Resizing' ||
    status === 'Stopping' ||
    status === 'Resuming' ||
    status === 'Terminating' ||
    status === 'Pausing'
  );
}

export function isRefreshIng(refresh_status: WorkspaceRefreshStatus) {
  return (
    refresh_status === 'Init' ||
    refresh_status === 'Exporting' ||
    refresh_status === 'Importing' ||
    refresh_status === 'Interrupted'
  );
}

export function canRefresh(refresh_status: WorkspaceRefreshStatus) {
  return !refresh_status || refresh_status === 'Success' || refresh_status === 'Failed';
}

// check quota for creating workspace

export function canCreateWorkspace(orgQuota: WorkSpaceOrgQuota) {
  return orgQuota?.total_workspace_limit > orgQuota?.rw_workspace_count + orgQuota?.ro_workspace_count;
}

export function canCreateWorkspaceWithNewGroup(orgQuota: WorkSpaceOrgQuota) {
  return canCreateWorkspace(orgQuota) && canCreateRWWorkspace(orgQuota);
}

export function canCreateRWWorkspace(orgQuota: WorkSpaceOrgQuota) {
  return orgQuota.rw_workspace_limit > orgQuota.rw_workspace_count;
}

export function canCreateROWorkspace(orgQuota: WorkSpaceOrgQuota) {
  return orgQuota.ro_workspace_limit > orgQuota.ro_workspace_count;
}

export function workspaceQuotaError(orgQuota: WorkSpaceOrgQuota) {
  return `Your organization has reached its workspace limits: a maximum of ${
    orgQuota.total_workspace_limit
  } total workspaces, ${orgQuota.rw_workspace_limit} read-write ${pluralize(
    orgQuota.rw_workspace_limit,
    'workspace',
    'workspaces'
  )}, or ${
    orgQuota.ro_workspace_limit
  } read-only workspaces. To create a new workspace, please delete an existing one or contact support for help.`;
}

export function workspaceQuotaRWError(orgQuota: WorkSpaceOrgQuota) {
  return `Your organization has reached the limit of ${orgQuota.rw_workspace_limit} read-write ${pluralize(
    orgQuota.rw_workspace_limit,
    'workspace',
    'workspaces'
  )}. To create a new read-write workspace, please delete an existing one or contact support for help.`;
}

export function workspaceQuotaROError(orgQuota: WorkSpaceOrgQuota) {
  return `Your organization has reached the limit of ${orgQuota.ro_workspace_limit} read-only workspaces. To create a new read-only workspace, please delete an existing one or contact support for help.`;
}

function pluralize(count = 0, singular = '', plural = '') {
  return count === 1 ? singular : plural;
}
