import { ApplicationT } from '@/insights/cloud/type';
import { SolutionInstance } from '@/pages/marketplace/solution/type';
import { WorkspaceT } from '@/pages/workgroup/type';
import { atom } from 'jotai';

export const workSpacesAtom = atom<WorkspaceT[]>([]);

export const quickInsightsAtom = atom<ApplicationT[]>([]);

export const solutionsAtom = atom<SolutionInstance[]>([]);
