import { CheckButton } from '@tigergraph/app-ui-lib/checkbutton';
import { useQueryGetSolutionCatalog } from '@/pages/marketplace/solution/hook';
import { useStyletron, styled } from '@tigergraph/app-ui-lib/Theme';
import DefaultIcon from '../../../marketplace/solution/icon/default.svg';
import EmptyIcon from './empty.svg';

type Props = {
  solutionName?: string;
  onSolutionNameChange: (solution?: string) => void;
};

const descriptions: { [key: string]: string } = {
  'Start From Scratch': 'Create a new graph from scratch',
  'Transaction Fraud': "Real-time fraud detection using TigerGraph's graph algorithms reduces financial losses",
  'Mule Account Detection': 'Detecting illicit mule accounts in finance using advanced graph algorithms for security',
  'Entity Resolution KYC': 'Advanced solution for financial institutions to resolve customer entities, prevent fraud',
  'Application Fraud': 'Advanced fraud detection using analytics and machine learning for secure applications',
  'Product Recommendation':
    'Advanced system provides personalized product recommendations using machine learning for user engagement',
  'Entity Resolution': 'Enhances data quality by unifying customer records across diverse sources',
  'Customer 360 Financial': 'Customer 360 Kit aggregates data for comprehensive customer insights in finance',
  'Supply Chain Management':
    'Comprehensive digital twin for Manufacturing, Procurement, Sales, Inventory Management domains',
  'Network Infrastructure':
    'Visualize network infrastructure in TigerGraph for real-time incident detection and prevention',
};

export default function WorkspaceSolutionSelect(props: Props) {
  const { solutionName, onSolutionNameChange } = props;
  const { data: solutions = [] } = useQueryGetSolutionCatalog();
  const [css, theme] = useStyletron();

  return (
    <div>
      <h2 className={css({ ...theme.typography.HeadingMedium })}>Solutions</h2>
      <p className={css({ ...theme.typography.Body2, color: theme.colors['text.secondary'], marginBottom: '8px' })}>
        Deploy pre-packaged graph solutions with schemas, queries, and sample data.
      </p>
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 245px)',
          gap: '16px',
        })}
      >
        <CheckButton
          type="button"
          key="empty"
          selected={!solutionName}
          onClick={() => onSolutionNameChange(undefined)}
          className={css({
            padding: '16px 8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignItems: 'center',
          })}
        >
          <img
            src={EmptyIcon}
            alt="empty solution"
            className={css({
              width: '32px',
              height: '32px',
            })}
          />
          <Title>Start From Scratch</Title>
          <Desc>{descriptions['Start From Scratch']}</Desc>
        </CheckButton>
        {solutions.map((solution) => {
          return (
            <CheckButton
              type="button"
              key={solution.name}
              selected={solution.name === solutionName}
              onClick={() => onSolutionNameChange(solution.name)}
              className={css({
                padding: '16px 8px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'center',
              })}
            >
              <img
                src={solution.icon || DefaultIcon}
                alt={solution.name}
                className={css({
                  width: '32px',
                  height: '32px',
                })}
              />
              <Title>{solution.name}</Title>
              <Desc>{descriptions[solution.name]}</Desc>
            </CheckButton>
          );
        })}
      </div>
    </div>
  );
}

export const Title = styled('div', {});
export const Desc = styled('div', {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '1.2',
});
