import { CustomTheme, styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

import { STYLE_TYPE } from 'baseui/checkbox';
import { FormControlOverrides } from 'baseui/form-control';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';

import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { FormControl } from '@/components/FormControl';
import TooltipLabel from '@/components/TooltipLabel';

import { WorkSpaceType, WorkspaceT } from '@/pages/workgroup/type';
import { filterProps } from '@tigergraph/tools-ui/insights/utils';
import { forwardRef, useState } from 'react';
import { FormTitleContainer } from '@/components/styled';
import { MdBuild } from 'react-icons/md';
import { Desc, Form } from './StyledComponent';
import { CustomizeWorkspace } from '@/pages/home/icons.tsx';
import { DrawerHeadContainer } from '@/lib/styled.tsx';
import { formatPricePerHour } from '@/utils/format';
import { useOrgContext } from '@/contexts/orgContext';
import { SubFormData } from '@/pages/workgroup/form/type';
import SizeSuggestion, { calculateSize, SizeData } from '@/pages/workgroup/form/SizeSuggestion';
import currency from 'currency.js';

export type Props = {
  isOpen: boolean;
  forceSuspend?: boolean;
  onClose: () => void;
  subFormData: SubFormData;
  onSubFormDataChanged: (subFormData: SubFormData) => void;
  workspaceTypes: WorkSpaceType[];
  disableHA: boolean;
};

export default function WorkSpaceSubForm({
  isOpen,
  forceSuspend,
  onClose,
  subFormData,
  onSubFormDataChanged,
  workspaceTypes,
  disableHA,
}: Props) {
  const [css, theme] = useStyletron();
  const form = useForm<SubFormData>({
    defaultValues: subFormData,
  });

  const { handleSubmit } = form;

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerHeader>
          <DrawerHeadContainer>
            <CustomizeWorkspace />
            Workspace Configuration
          </DrawerHeadContainer>
        </DrawerHeader>
        <DrawerBody
          className={css({
            backgroundColor: theme.colors['background.primary'],
          })}
        >
          <Form>
            <Accordion type="multiple" defaultValue={['General', 'Advanced Settings']}>
              <AccordionItem value="General">
                <AccordionTrigger>General</AccordionTrigger>
                <AccordionContent>
                  <General
                    workspaceTypes={workspaceTypes}
                    form={form as unknown as UseFormReturn<GeneralType, any, undefined>}
                  />
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="Advanced Settings">
                <AccordionTrigger>Advanced Settings</AccordionTrigger>
                <AccordionContent>
                  <Advanced
                    forceSuspend={forceSuspend}
                    form={form as unknown as UseFormReturn<AdvancedType, any, undefined>}
                    disableHA={disableHA}
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </Form>
        </DrawerBody>
        <DrawerAction className={css({ backgroundColor: theme.colors['background.primary'] })}>
          <Button type="button" onClick={onClose} kind="secondary" size="large">
            Cancel
          </Button>
          <Button
            type="button"
            size="large"
            onClick={handleSubmit(async (data, e) => {
              onSubFormDataChanged(data);
              onClose();
              e?.stopPropagation();
            })}
          >
            Save
          </Button>
        </DrawerAction>
      </Drawer>
    </>
  );
}

const StyleTypeItem = styled('div', ({ $theme }) => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: $theme.colors['text.secondary'],
  paddingLeft: '10px',
  paddingRight: '10px',
  borderLeft: `1px solid ${$theme.colors.divider}`,
}));

const StyleTypeValue = styled('span', ({ $theme }) => ({
  color: $theme.colors['text.primary'],
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 'normal',
}));

const formControlOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        ...theme.typography.Body2,
        marginTop: '-8px',
      };
    },
  },
};

type ItemProps = {
  [x: string]: any;
  workspaceType: WorkSpaceType;
};

const WorkspaceLabel = ({ workspaceType, typeName }: { workspaceType?: WorkSpaceType; typeName: string }) => {
  const [css] = useStyletron();
  if (!workspaceType) {
    return <div>{typeName}</div>;
  }
  return (
    <div
      className={css({
        flex: 1,
      })}
    >
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1.3fr',
        })}
      >
        <StyleTypeValue>{workspaceType.typeName}</StyleTypeValue>
        <StyleTypeItem>
          CPU:<StyleTypeValue>{workspaceType.cpu}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          MEMORY:<StyleTypeValue>{workspaceType.memory}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          Cost: <StyleTypeValue>{formatPricePerHour(currency(workspaceType.cost_per_hour / 100))}</StyleTypeValue>
        </StyleTypeItem>
      </div>
    </div>
  );
};

const WorkspaceSize = forwardRef<HTMLLIElement, ItemProps>((props, ref) => {
  const [css, theme] = useStyletron();
  const { item: workspaceType, ...rest } = props;
  return (
    <li
      // todo(lin):
      // for now, we ignore focus/active state
      {...filterProps(rest)}
      ref={ref}
      className={css({
        cursor: 'pointer',
        listStyle: 'none',
        padding: '16px 40px 16px 14px',
        borderBottom: `1px solid ${theme.colors.divider}`,
      })}
    >
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1.3fr',
        })}
      >
        <StyleTypeValue>{workspaceType.typeName}</StyleTypeValue>
        <StyleTypeItem>
          CPU:<StyleTypeValue>{workspaceType.cpu}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          MEMORY:<StyleTypeValue>{workspaceType.memory}</StyleTypeValue>
        </StyleTypeItem>
        <StyleTypeItem>
          Cost: <StyleTypeValue>{formatPricePerHour(currency(workspaceType.cost_per_hour / 100))}</StyleTypeValue>
        </StyleTypeItem>
      </div>
    </li>
  );
});

export type GeneralType = {
  workspace_type_name: string;
  enable_ha?: boolean;
};

export function General({
  workspaceTypes,
  disableResize,
  form,
  workspace,
}: {
  workspaceTypes: WorkSpaceType[];
  disableResize?: boolean;
  form: UseFormReturn<GeneralType, any, undefined>;
  workspace?: WorkspaceT;
}) {
  const [css, theme] = useStyletron();
  const { control, setValue, watch } = form;
  const enable_ha = watch('enable_ha') || false;

  const [sizeData, setSizeData] = useState<SizeData | undefined>(undefined);
  const [openSuggestion, setOpenSuggestion] = useState(false);

  const { currentOrg, userInfo } = useOrgContext();
  const url = `mailto:beta-support@tigergraph.com?subject=TigerGraph Cloud 4 Beta Support - Request New Workspace Size - ${userInfo.email} - ${currentOrg.org_id} - ${currentOrg.org_name}&body=Hi Support Team,%0A%0A%0A%0A%0A%0ARegards,%0A${userInfo.email}`;

  return (
    <>
      <FormTitleContainer>
        <TooltipLabel
          label="Customize Workspace Size"
          tooltip={`Customize Workspace Size allows you to tailor the capacity and resources allocated to your workspace based on your specific needs and requirements.`}
        />
        <Button
          startEnhancer={<MdBuild />}
          kind="secondary"
          size="compact"
          type="button"
          onClick={() => setOpenSuggestion(true)}
        >
          Size Suggestion
        </Button>
      </FormTitleContainer>
      <Controller
        control={control}
        name="workspace_type_name"
        render={({ field: { value, onChange, ref, ...field } }) => {
          return (
            <Select
              options={workspaceTypes}
              value={[{ id: value }]}
              onChange={(params) => {
                onChange(params.value[0].typeName);
              }}
              clearable={false}
              inputRef={ref}
              {...field}
              disabled={disableResize}
              searchable={false}
              overrides={{
                DropdownListItem: {
                  component: WorkspaceSize,
                },
                SingleValue: {
                  component: ({ value }: { value: string }) => {
                    const workspaceType = workspaceTypes.find((item) => item.typeName === value);
                    return <WorkspaceLabel workspaceType={workspaceType} typeName={value} />;
                  },
                },
              }}
            />
          );
        }}
      />
      <Desc
        $style={{
          marginTop: '12px',
          marginBottom: '24px',
        }}
      >
        Can&apos;t find your need? Please{' '}
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={css({
            color: theme.colors.secondary800,
          })}
        >
          contact us
        </a>{' '}
        if you need more compute resources
      </Desc>
      {/* reset state */}
      {openSuggestion ? (
        <SizeSuggestion
          workspaceTypes={workspaceTypes}
          sizeData={sizeData}
          enable_ha={enable_ha}
          isOpen={openSuggestion}
          onCancel={() => setOpenSuggestion(false)}
          // hide ha when edit mode
          hide_ha={!!workspace}
          onConfirm={(sizeData, enable_ha) => {
            setOpenSuggestion(false);
            setSizeData(sizeData);

            // apply the suggestion
            const workspaceType = calculateSize(sizeData.size, sizeData.compressRatio, workspaceTypes);
            setValue('workspace_type_name', workspaceType.typeName);
            setValue('enable_ha', enable_ha);
          }}
        />
      ) : null}
    </>
  );
}

export type AdvancedType = {
  // auto stop
  autoSuspend: boolean;
  auto_stop_minutes: number;

  // auto start
  enable_auto_start: boolean;

  enable_ha: boolean;
};

export function Advanced({
  forceSuspend,
  form,
  disableHA,
}: {
  forceSuspend?: boolean;
  form: UseFormReturn<AdvancedType, any, undefined>;
  disableHA: boolean;
}) {
  const [css, theme] = useStyletron();

  const {
    control,
    watch,
    formState: { errors },
  } = form;
  const autoSuspend = watch('autoSuspend');

  return (
    <>
      <FormTitleContainer>
        <TooltipLabel
          label="Auto Suspend"
          tooltip={`Automatically puts a compute instance to sleep when it's not actively working. This helps save
                  resources and costs by stopping the instance from consuming unnecessary computing power when it's
                  not needed. You can easily wake up the instance whenever you want to use it again.`}
        />
        <Controller
          control={control}
          name="autoSuspend"
          render={({ field: { value, onChange, ref: _, ...field } }) => (
            <Checkbox
              checked={value}
              labelPlacement="left"
              disabled={forceSuspend}
              onChange={() => {
                onChange(!value);
              }}
              {...field}
              checkmarkType={STYLE_TYPE.toggle_round}
            />
          )}
        />
      </FormTitleContainer>
      {autoSuspend ? (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <FormControl
            label="Auto suspend time in minutes."
            error={errors?.auto_stop_minutes?.message}
            overrides={formControlOverrides}
          >
            <Controller
              rules={{
                min: {
                  value: 10,
                  message: 'The minimum auto suspend waiting time is 10 minutes',
                },
                max: {
                  value: 360,
                  message: 'The maximum auto suspend waiting time is 360 minutes',
                },
              }}
              control={control}
              name="auto_stop_minutes"
              render={({ field }) => (
                <Input {...field} error={!!errors?.auto_stop_minutes} type="number" disabled={!autoSuspend} />
              )}
            />
          </FormControl>
        </div>
      ) : null}
      <FormTitleContainer>
        <TooltipLabel
          label="Auto Resume"
          tooltip={`Automatically activates a previously suspended workspace when it's needed. This ensures that the
                  workspace is ready to handle workloads without any manual intervention. You can wake up the workspace
                  using REST APIs or by accessing the UI.`}
        />
        <Controller
          control={control}
          name="enable_auto_start"
          render={({ field: { value, onChange, ref: _, ...field } }) => (
            <Checkbox
              checked={value}
              labelPlacement="left"
              onChange={() => {
                onChange(!value);
              }}
              {...field}
              checkmarkType={STYLE_TYPE.toggle_round}
            />
          )}
        />
      </FormTitleContainer>
      {/* <FormTitleContainer>
        <TooltipLabel
          label="High Availability"
          tooltip={`High Availability ensures uninterrupted access to services by employing redundant systems that
                  automatically take over in case of failures, thus minimizing downtime.`}
        />
        <Controller
          control={control}
          name="enable_ha"
          render={({ field: { value, onChange, ref: _, ...field } }) => (
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              })}
            >
              <span
                className={css({
                  fontSize: '12px',
                  color: theme.colors['text.secondary'],
                })}
              >
                SLA: 99% Standard Availability Assurance.
              </span>
              <Checkbox
                checked={value}
                disabled={disableHA}
                labelPlacement="left"
                onChange={() => {
                  onChange(!value);
                }}
                {...field}
                checkmarkType={STYLE_TYPE.toggle_round}
              />
            </div>
          )}
        />
      </FormTitleContainer> */}
    </>
  );
}
