import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import useToolsLink from '@/hooks/useToolsLink';
import { fetchGraphList } from '@/lib/instance_api';
import {
  useMutationAssignDBRoles,
  useMutationRevokeDBRoles,
  useQueryListDBRoles,
  useQueryListDBUserRoles,
} from '@/pages/admin/user/hook';
import { AddHeader, Empty } from '@/pages/workgroup/tab/iam/db_user/components';
import { WorkspaceT } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { Select } from '@tigergraph/app-ui-lib/select';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { GLOBAL_GRAPH_NAME } from '@tigergraph/tools-models';
import { LoadingIndicator } from '@/components/loading-indicator';
import { ErrorDisplay } from '@/components/error';
import { useEffect, useMemo, useRef, useState } from 'react';
import DeleteIcon from '@/assets/delete.svg?react';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';
import { MdOutlineRefresh } from 'react-icons/md';
import { Button } from '@tigergraph/app-ui-lib/button';

type ConfirmProps = {
  isOpen: boolean;
  name: string;
  onClose: () => void;
  workspace: WorkspaceT;
};

export default function AssignRoles({ isOpen, onClose, name, workspace }: ConfirmProps) {
  const [css] = useStyletron();
  const initStateRef = useRef(false);
  const baseURL = `https://${workspace.nginx_host}`;

  const [userRolesByGraph, setUserRoleByGraph] = useState(new Map<string, string[]>());

  const userRoleQuery = useQueryListDBUserRoles(name, {
    baseURL,
    version: workspace.tg_version,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
    },
  });
  const dbRoleQuery = useQueryListDBRoles({
    baseURL,
    version: workspace.tg_version,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
    },
  });
  const graphListQuery = useQuery<string[], AxiosError>(['roles'], async () => {
    return fetchGraphList(
      workspace.nginx_host,
      workspace.tg_version,
      sessionStorage.getItem(ID_TOKEN_KEY)!,
      ['READ_ROLE', 'WRITE_ROLE'],
      true
    );
  });

  const assignDBRoles = useMutationAssignDBRoles();
  const revokeDBRoles = useMutationRevokeDBRoles();

  const graphList = graphListQuery.data;
  const dbRoles = dbRoleQuery.data;
  const userRoles = userRoleQuery.data;

  const preUserRolesByGraph = useMemo(() => {
    if (!userRoles || !dbRoles) {
      return null;
    }

    const {
      builtIn: { global = [] },
      userDefinedRoles,
    } = dbRoles;

    const globalRoles = [...global];
    if (userDefinedRoles[GLOBAL_GRAPH_NAME]) {
      globalRoles.push(...userDefinedRoles[GLOBAL_GRAPH_NAME]);
    }

    const { roles } = userRoles;

    const userRolesByGraph = new Map<string, string[]>();
    const graphs = Object.keys(roles);
    for (let graph of graphs) {
      let rolesForGraph = [...roles[graph]];
      if (graph !== GLOBAL_GRAPH_NAME) {
        // remove global role for local graph
        rolesForGraph = rolesForGraph.filter((r) => !globalRoles.includes(r));
      }
      if (rolesForGraph.length > 0) {
        userRolesByGraph.set(graph, rolesForGraph);
      }
    }

    return userRolesByGraph;
  }, [userRoles, dbRoles]);

  useEffect(() => {
    if (!preUserRolesByGraph) {
      return;
    }

    if (initStateRef.current) {
      return;
    }

    // only want to init the state once
    initStateRef.current = true;
    setUserRoleByGraph(preUserRolesByGraph);
  }, [preUserRolesByGraph]);

  const dbRolesByGraph = useMemo(() => {
    if (!dbRoles || !graphList) {
      return new Map<string, string[]>();
    }

    const {
      builtIn: { global = [], local = [] },
      userDefinedRoles,
    } = dbRoles;

    const dbRolesByGraph = new Map<string, string[]>();
    for (let graph of graphList) {
      if (graph === GLOBAL_GRAPH_NAME) {
        const roles = [...global];
        if (userDefinedRoles[graph]) {
          roles.push(...userDefinedRoles[graph]);
        }
        dbRolesByGraph.set(graph, roles);
      } else {
        const roles = [...local];
        if (userDefinedRoles[graph]) {
          roles.push(...userDefinedRoles[graph]);
        }
        dbRolesByGraph.set(graph, roles);
      }
    }

    return dbRolesByGraph;
  }, [dbRoles, graphList]);

  const existedGraphs = Array.from(userRolesByGraph.keys());

  const { assign, revoke } = diffUserRoleByGraph(userRolesByGraph, preUserRolesByGraph);
  const onSave = async () => {
    try {
      {
        const graphs = Array.from(assign.keys());
        for (let graph of graphs) {
          const roles = assign.get(graph)!;
          await assignDBRoles.mutateAsync({
            username: name,
            roles,
            graphName: graph,
            config: {
              baseURL: `https://${workspace.nginx_host}`,
              version: workspace.tg_version,
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
              },
            },
          });
        }
      }

      {
        const graphs = Array.from(revoke.keys());
        for (let graph of graphs) {
          const roles = revoke.get(graph)!;
          await revokeDBRoles.mutateAsync({
            username: name,
            roles,
            graphName: graph,
            config: {
              baseURL: `https://${workspace.nginx_host}`,
              version: workspace.tg_version,
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
              },
            },
          });
        }
      }

      showToast({
        kind: 'positive',
        message: `Roles saved successfully for ${name}.`,
      });
    } catch (error) {
      showToast({
        kind: 'negative',
        message: `Failed to assign/revoke roles: ${getErrorMessage(error as AxiosError)}`,
      });
      return;
    }

    onClose();
  };

  let userIsNotExisted = false;

  if (userRoleQuery.isError) {
    let message = getErrorMessage(userRoleQuery.error);
    if (message.indexOf("doesn't exist")) {
      userIsNotExisted = true;
    }
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      overrides={{
        Dialog: {
          style: {
            width: '50%',
          },
        },
      }}
    >
      <ModalHeader>Access Management</ModalHeader>
      <ModalBody>
        <div
          className={css({
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          })}
        >
          {userRoleQuery.isLoading || dbRoleQuery.isLoading || graphListQuery.isLoading ? <LoadingIndicator /> : null}
          {userRoleQuery.isError || dbRoleQuery.isError || graphListQuery.isError ? (
            <ErrorDisplay error={userRoleQuery.error || dbRoleQuery.error || graphListQuery.error} />
          ) : null}
          {userIsNotExisted ? (
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
              })}
            >
              It takes a few seconds to synchronize the user to the tigergraph database. Click{' '}
              <Button kind="text" shape="square" onClick={() => userRoleQuery.refetch()}>
                <MdOutlineRefresh size={16} />
              </Button>{' '}
              to refresh.
            </div>
          ) : null}
          {graphList && dbRoles && userRoles ? (
            <>
              <AddHeader
                label={`Assign Roles to ${name}`}
                disabled={userRolesByGraph.size === graphList.length}
                onAdd={() => {
                  setUserRoleByGraph(
                    new Map(userRolesByGraph).set(
                      chooseDefaultGraphName(graphList, Array.from(userRolesByGraph.keys())),
                      []
                    )
                  );
                }}
              />
              {userRolesByGraph.size === 0 ? (
                <Empty title="No Roles" description="Click the + button to assign a role to this user" />
              ) : null}
              {[...userRolesByGraph.entries()].map(([graph, roles]) => (
                <RoleItem
                  key={graph}
                  graph={graph}
                  roles={roles}
                  graphList={graphList.filter((g) => !existedGraphs.includes(g) || g === graph)}
                  dbRolesList={dbRolesByGraph.get(graph)!}
                  onChangeGraph={(newGraph) => {
                    const newMap = new Map(userRolesByGraph);
                    newMap.delete(graph);
                    newMap.set(newGraph, []);
                    setUserRoleByGraph(newMap);
                  }}
                  onChangeRoles={(roles) => {
                    setUserRoleByGraph(new Map(userRolesByGraph).set(graph, roles));
                  }}
                  onDelete={() => {
                    const newMap = new Map(userRolesByGraph);
                    newMap.delete(graph);
                    setUserRoleByGraph(newMap);
                  }}
                />
              ))}
              <Tips workspace={workspace} />
            </>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={onClose}>
          Cancel
        </ModalButton>
        <ModalButton
          onClick={onSave}
          disabled={assign.size + revoke.size === 0}
          isLoading={assignDBRoles.isLoading || revokeDBRoles.isLoading}
        >
          Save
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function Tips({ workspace }: { workspace: WorkspaceT }) {
  const [css] = useStyletron();
  const link = useToolsLink(workspace, '/admin/#/management/users');

  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'end',
      })}
    >
      {"Can't find the role I want ?"}
      <a href={link} target="_blank" rel="noreferrer" style={{ color: '#0A88E4', marginLeft: '6px' }}>
        Click here to create.
      </a>
    </div>
  );
}

type Props = {
  graph: string;
  roles: string[];
  graphList: string[];
  dbRolesList: string[];
  onChangeGraph: (graph: string) => void;
  onChangeRoles: (roles: string[]) => void;
  onDelete: () => void;
};

function RoleItem({ graph, roles, graphList, dbRolesList, onChangeGraph, onChangeRoles, onDelete }: Props) {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        gap: '8px',
        alignItems: 'flex-start',
      })}
    >
      <div
        className={css({
          width: '200px',
        })}
      >
        <div
          className={css({
            marginBottom: '4px',
            color: theme.colors['text.secondary'],
          })}
        >
          Select a graph
        </div>
        <Select
          options={graphList.map((g) => ({ id: g, label: g === GLOBAL_GRAPH_NAME ? 'global' : g }))}
          value={[{ id: graph }]}
          onChange={(params) => {
            if (params.value.length > 0) {
              onChangeGraph(params.value[0].id as string);
            }
          }}
          clearable={false}
          deleteRemoves={false}
          backspaceRemoves={false}
        />
      </div>
      <div
        className={css({
          flexBasis: 0,
          flexGrow: 1,
          minWidth: 0,
        })}
      >
        <div
          className={css({
            marginBottom: '4px',
          })}
        >
          Select a role
        </div>
        <Select
          options={dbRolesList.map((r) => ({ id: r, label: r }))}
          value={roles.map((r) => ({ id: r }))}
          onChange={(params) => {
            onChangeRoles(params.value.map((v) => v.id as string));
          }}
          clearable={false}
          multi={true}
          placeholder="Select a role"
        />
      </div>
      <div
        className={css({
          marginTop: '22px',
          color: theme.colors['text.secondary'],
        })}
      >
        <Button kind="text" shape="square" onClick={onDelete}>
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
}

function chooseDefaultGraphName(allGraphs: string[], usedGraph: string[]) {
  return allGraphs.find((g) => !usedGraph.includes(g))!;
}

function diffUserRoleByGraph(
  userRolesByGraph: Map<string, string[]>,
  preUserRolesByGraph: Map<string, string[]> | null
) {
  const assign = new Map<string, string[]>();
  const revoke = new Map<string, string[]>();

  if (!preUserRolesByGraph) {
    return {
      assign,
      revoke,
    };
  }

  // find the roles that need to be assigned
  for (let [graph, roles] of userRolesByGraph) {
    if (!preUserRolesByGraph.has(graph) && roles.length > 0) {
      assign.set(graph, roles);
      continue;
    }

    const preRoles = preUserRolesByGraph.get(graph) || [];
    const newRoles = roles.filter((r) => !preRoles.includes(r));
    if (newRoles.length > 0) {
      assign.set(graph, newRoles);
    }
  }

  // find the roles that need to be revoked
  for (let [graph, roles] of preUserRolesByGraph) {
    if (!userRolesByGraph.has(graph) && roles.length > 0) {
      revoke.set(graph, roles);
      continue;
    }

    const newRoles = userRolesByGraph.get(graph) || [];
    const removedRoles = roles.filter((r) => !newRoles.includes(r));
    if (removedRoles.length > 0) {
      revoke.set(graph, removedRoles);
    }
  }

  return {
    assign,
    revoke,
  };
}
