import { styled } from '@tigergraph/app-ui-lib/Theme';

// need to use design token for iconButton
const IconButton = styled('button', ({ $theme }) => {
  return {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
    lineHeight: 0,
    borderRadius: '2px',
    color: $theme.colors['button.icon'],
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: $theme.colors['button.background.default.hover'],
    },
    ':active': {
      backgroundColor: $theme.colors['button.background.default.press'],
    },
    ':focus-visible': {
      outline: `2px solid ${$theme.colors.borderSelected}`,
    },
    ':disabled': {
      cursor: 'not-allowed',
      backgroundColor: $theme.colors['button.background.disabled'],
    },
    ':disabled:hover': {
      cursor: 'not-allowed',
      backgroundColor: $theme.colors['button.background.disabled'],
    },
  };
});

export default IconButton;
