import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Attribute } from '@tigergraph/tools-models/topology';
import { FromToVertexPairTable } from '@/components/schemaDesigner/attributeEditor/FromToVertexPairTable';
import AttributeKeyTable from '@/components/schemaDesigner/attributeEditor/AttributeKeyTable';
import { ReverseEdge } from '@/components/schemaDesigner/attributeEditor/ReverseEdge';
import { ValidateResult } from '@tigergraph/tools-models/utils';

export interface AttributeAdvancedSettingProps {
  attributes: Attribute[];
  isVertex: boolean;
  directed: boolean;
  reverseEdge: string;
  fromToVertexTypePairs: {
    from: string;
    to: string;
  }[];
  validateResult: ValidateResult;
  readOnly?: boolean;
  onChangeAttributes: (attributes: Attribute[]) => void;
  onChangePairs: (
    pairs: {
      from: string;
      to: string;
    }[]
  ) => void;
  onChangeReverseEdge: (reverseEdge: string) => void;
  onChangeValidateResult: (validateResult: ValidateResult) => void;
}

export default function AttributeAdvancedSetting(props: AttributeAdvancedSettingProps) {
  const [css] = useStyletron();
  const { isVertex, directed } = props;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      })}
    >
      {!isVertex && <FromToVertexPairTable {...props} />}
      {!isVertex && directed && <ReverseEdge {...props} />}
      <AttributeKeyTable {...props} />
    </div>
  );
}
