import { axiosCluster } from '@/lib/network';
import { Return } from '@/lib/type';

export type ConfigType = {
  [key: string]: number | string;
};

export async function getConfig(
  nginx_host: string,
  keys: string[]
): Promise<
  Return<{
    [key: string]: number | string;
  }>
> {
  const params = new URLSearchParams();
  for (let key of keys) {
    params.append('key', key);
  }

  const response = await axiosCluster.get(`https://${nginx_host}/api/config`, {
    params,
  });
  return response.data;
}

export async function restartService(nginx_host: string, services: string[]): Promise<Return<void>> {
  const params = new URLSearchParams();
  for (let service of services) {
    params.append('serviceName', service);
  }

  const response = await axiosCluster.post(`https://${nginx_host}/api/service/restart`, null, {
    params,
  });
  return response.data;
}

export async function updateConfig(nginx_host: string, config: ConfigType): Promise<Return<void>> {
  const params: { [key: string]: string } = {};
  for (let key of Object.keys(config)) {
    params[key] = `${config[key]}`;
  }

  const response = await axiosCluster.post(`https://${nginx_host}/api/config`, params);
  return response.data;
}
