import { Result } from '@/lib/type';
import { getWorkSpaceDetail } from '@/pages/workgroup/api';
import { workSpacesAtom } from '@/pages/workgroup/atom';
import { WorkGroupT, WorkspaceT, isRefreshIng, isStatusActive, isStatusPending } from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useQuery, useQueryClient } from 'react-query';
import { useTutorialContext } from '@/components/tutorial/tutorialContext.tsx';
import useProfile from '@/lib/useProfile';

const pollingInterval = 3 * 1000;

export function WorkspacesPoll() {
  const [workspaces] = useAtom(workSpacesAtom);

  const { data: profile } = useProfile();
  let userEmail = '';
  if (profile) {
    userEmail = profile.email;
  }

  return (
    <div>
      <div>
        {workspaces.map((workspace) => (
          <WorkspacePoll workspace={workspace} key={workspace.workspace_id} userEmail={userEmail} />
        ))}
      </div>
    </div>
  );
}

function WorkspacePoll({ workspace, userEmail }: { workspace: WorkspaceT; userEmail: string }) {
  const [, setWorkspaces] = useAtom(workSpacesAtom);
  const queryClient = useQueryClient();
  const { setShouldShowCongratulationModal, setWorkspace } = useTutorialContext();

  useQuery<Result<WorkspaceT>, AxiosError>(
    ['space', workspace.workspace_id],
    async () => {
      return getWorkSpaceDetail(workspace.workgroup_id, workspace.workspace_id);
    },
    {
      initialData: {
        Error: false,
        Result: workspace,
      },
      onError: () => {
        // when error, remove it from the polling list
        setWorkspaces((oldData) => {
          return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
        });
      },
      onSuccess: (data) => {
        // update workgroup cache
        // for refresh, it will change the status from active -> pending -> active
        if (data?.Result) {
          queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', workspace.workgroup_id], (oldData) => {
            if (!oldData?.Result) {
              return oldData;
            }
            let newData = {
              ...oldData,
              Result: {
                ...oldData.Result,
                workspaces: oldData.Result.workspaces.map((w) =>
                  w.workspace_id !== workspace.workspace_id ? w : data.Result!
                ),
              },
            };
            return newData;
          });
        }

        // Polling until
        // 1. the status is not pending
        // 2. the refresh status is not ing
        if (data?.Result && !isStatusPending(data.Result.status) && !isRefreshIng(data.Result.refresh_status)) {
          queryClient.invalidateQueries(['groups']);

          // remove the workspace from the polling list
          setWorkspaces((oldData) => {
            return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
          });

          // only show notification for active status
          if (!isStatusActive(data.Result.status)) {
            return;
          }

          if (workspace.condition_type !== 'InitializeRoll' && workspace.condition_type !== 'InitializePost') {
            return;
          }

          // only show notification for the creator
          if (userEmail !== data.Result.creator) {
            return;
          }

          // show the congratulation popover
          setShouldShowCongratulationModal(true);
          setWorkspace(workspace);
        }
      },
      refetchInterval: (data) => {
        if (!data?.Result) {
          setWorkspaces((oldData) => {
            return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
          });
          return false;
        }

        const workspace = data?.Result;
        let shouldFetch = false;
        if (isStatusPending(workspace.status) || isRefreshIng(workspace.refresh_status)) {
          shouldFetch = true;
        }

        if (!shouldFetch) {
          return false;
        }

        return pollingInterval;
      },
    }
  );

  return null;
}
