import { ColumnOverrides } from 'baseui/table-semantic';

export const columnOverrides: ColumnOverrides = {
  // TableBodyCell: {
  //   style: {
  //     paddingTop: 0,
  //     paddingBottom: 0,
  //     verticalAlign: 'center',
  //   },
  // },
};

export const actionColumnOverrides: ColumnOverrides = {
  TableHeadCell: {
    style: {
      // make cell width fit content
      width: '1%',
    },
  },
  TableBodyCell: {
    style: {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
};
