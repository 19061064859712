import { Navigate, Outlet } from 'react-router-dom';
import { useOrgContext } from '@/contexts/orgContext.tsx';
import { isOrgAdmin } from '@/pages/admin/user/type.ts';

const ProtectedRoutes = () => {
  const { userInfo } = useOrgContext();

  return isOrgAdmin(userInfo.roles) ? <Outlet /> : <Navigate to="/groups" replace />;
};

export default ProtectedRoutes;
