import { Slider } from '@/components/ui/slider';
import { WorkspaceT } from '@/pages/workgroup/type';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Controller, useForm } from 'react-hook-form';
import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useEffect, useState } from 'react';
import { useListAlertRules } from '@/hooks/useListAlertRules';
import {
  useMutateCreateAlertRule,
  useMutateDeleteAlertRule,
  useMutateUpdateAlertRule,
} from '@/hooks/useMutateAlertRules';
import { Result } from '@/lib/type';
import { LoadingIndicator } from '@/components/loading-indicator';
import toast from 'react-hot-toast';
import { ErrorDisplay } from '@/components/error';
import TooltipLabel from '@/components/TooltipLabel';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';

type AlertsProps = {
  workspace: WorkspaceT;
  onClose?: () => void;
};

type AlertFormData = {
  enable_cpu_limit: boolean;
  tigergraph_cpu_usage: number;
  enable_memory_limit: boolean;
  tigergraph_memory_usage_percent: number;
};

const percentValidate = (value: string | number) => {
  const val = Number(value);
  if (val > 0 && val <= 100) {
    return;
  }
  return 'invalid value';
};

export default function Alerts({ workspace, onClose }: AlertsProps) {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AlertFormData>({
    defaultValues: {
      tigergraph_cpu_usage: 80,
      tigergraph_memory_usage_percent: 60,
    },
  });
  const {
    data: rules,
    isFetching: isLoadingRules,
    refetch: refreshAlertRules,
  } = useListAlertRules({
    workspaceID: workspace.workspace_id,
    workgroupID: workspace.workgroup_id,
  });
  const [error, setError] = useState<Error>();
  const cpuAlertRule = rules?.find((i) => i.metric === 'tigergraph_cpu_usage');
  const memAlertRule = rules?.find((i) => i.metric === 'tigergraph_memory_usage_percent');

  const { mutateAsync: createRule, isLoading: isCreatingRule } = useMutateCreateAlertRule();
  const { mutateAsync: updateRule, isLoading: isUpdatingRule } = useMutateUpdateAlertRule();
  const { mutateAsync: deleteRule, isLoading: isDeletingRule } = useMutateDeleteAlertRule();

  const enableCPULimit = watch('enable_cpu_limit');
  const enableMemoryLimit = watch('enable_memory_limit');

  const handleSave = async (data: AlertFormData) => {
    const ruleItems = [
      { metricName: 'tigergraph_cpu_usage', enable: data.enable_cpu_limit },
      { metricName: 'tigergraph_memory_usage_percent', enable: data.enable_memory_limit },
    ] as const;
    const commonParams = { workgroupID: workspace.workgroup_id, workspaceID: workspace.workspace_id };

    const promiseGroup: Promise<Result>[] = [];
    for (const ruleItem of ruleItems) {
      const alertRule = rules?.find((i) => i.metric === ruleItem.metricName);
      const threshold = Number(data[ruleItem.metricName]);
      if (alertRule) {
        if (ruleItem.enable) {
          // update
          promiseGroup.push(
            updateRule({
              ...commonParams,
              ruleID: alertRule.id,
              threshold,
            })
          );
        } else {
          // delete
          promiseGroup.push(
            deleteRule({
              ...commonParams,
              ruleID: alertRule.id,
            })
          );
        }
      } else if (ruleItem.enable) {
        // create
        promiseGroup.push(
          createRule({
            ...commonParams,
            metric: ruleItem.metricName,
            threshold,
          })
        );
      }
    }

    const loadingToastID = toast.loading('Applying workspace alert rules...');
    try {
      await Promise.all(promiseGroup);
      await refreshAlertRules();
      toast.success('Successfully apply workspace alert rules.');
      onClose?.();
    } catch (e) {
      setError(e instanceof Error ? e : new Error('Internal Error'));
    } finally {
      toast.dismiss(loadingToastID);
    }
  };

  useEffect(() => {
    if (cpuAlertRule) {
      setValue('enable_cpu_limit', true);
      setValue('tigergraph_cpu_usage', cpuAlertRule.threshold);
    } else {
      setValue('enable_cpu_limit', false);
      setValue('tigergraph_cpu_usage', 80);
    }

    if (memAlertRule) {
      setValue('enable_memory_limit', true);
      setValue('tigergraph_memory_usage_percent', memAlertRule.threshold);
    } else {
      setValue('enable_memory_limit', false);
      setValue('tigergraph_memory_usage_percent', 60);
    }
  }, [cpuAlertRule, memAlertRule, setValue]);

  return (
    <StyledForm>
      <StyledFormBody>
        <ErrorDisplay error={error} />
        {isLoadingRules ? (
          <LoadingIndicator />
        ) : (
          <ul>
            <li>
              <div className="flex items-center justify-between">
                <TooltipLabel
                  label="CPU Limit"
                  tooltip="Set the CPU usage threshold percentage at which you want to receive an alert. For example, setting this to 80% means you will be alerted when CPU usage reaches 80%."
                />
                <Controller
                  control={control}
                  name="enable_cpu_limit"
                  render={({ field: { value, ...field } }) => (
                    <Checkbox checked={value} {...field} checkmarkType="toggle" />
                  )}
                />
              </div>
              {enableCPULimit && (
                <FormControl error={errors.tigergraph_cpu_usage?.message}>
                  <div className="flex items-center space-x-2">
                    <div className="flex-1 space-y-1 pb-6">
                      <Controller
                        control={control}
                        name="tigergraph_cpu_usage"
                        render={({ field: { value, onChange, ...field } }) => (
                          <Slider
                            {...field}
                            value={[value]}
                            onValueChange={(value) => onChange(value?.[0])}
                            step={1}
                            min={1}
                            max={100}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="tigergraph_cpu_usage"
                      rules={{
                        validate: percentValidate,
                      }}
                      render={({ field }) => (
                        <Input {...field} type="number" overrides={{ Root: { style: { width: '70px' } } }} />
                      )}
                    />
                  </div>
                </FormControl>
              )}
            </li>
            <li>
              <div className="flex items-center justify-between">
                <TooltipLabel
                  label="Memory Limit"
                  tooltip="Set the memory usage threshold percentage at which you want to receive an alert. For example, setting this to 75% means you will be alerted when memory usage reaches 75%."
                />
                <Controller
                  control={control}
                  name="enable_memory_limit"
                  render={({ field: { value, ...field } }) => (
                    <Checkbox checked={value} {...field} checkmarkType="toggle" />
                  )}
                />
              </div>
              {enableMemoryLimit && (
                <FormControl error={errors.tigergraph_memory_usage_percent?.message}>
                  <div className="flex items-center space-x-2">
                    <div className="flex-1 space-y-1 pb-6">
                      <Controller
                        control={control}
                        name="tigergraph_memory_usage_percent"
                        render={({ field: { value, onChange, ...field } }) => (
                          <Slider
                            {...field}
                            value={[value]}
                            onValueChange={(value) => onChange(value?.[0])}
                            min={1}
                            max={100}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="tigergraph_memory_usage_percent"
                      rules={{
                        validate: percentValidate,
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          max={100}
                          min={1}
                          type="number"
                          overrides={{ Root: { style: { width: '70px' } } }}
                        />
                      )}
                    />
                  </div>
                </FormControl>
              )}
            </li>
          </ul>
        )}
      </StyledFormBody>
      <StyledFormAction>
        <Button type="button" kind="secondary" size="large" onClick={() => onClose?.()}>
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          onClick={handleSubmit(handleSave)}
          isLoading={isCreatingRule || isDeletingRule || isUpdatingRule}
        >
          Save
        </Button>
      </StyledFormAction>
    </StyledForm>
  );
}
