import { useMatch } from 'react-router-dom';
import { CustomTheme, styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ProgressBar, ProgressBarOverrides } from 'baseui/progress-bar';

const progressBarOverrides: ProgressBarOverrides = {
  Bar: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        backgroundColor: theme.colors['background.accent.neutral.subtler'],
        height: '6px',
      };
    },
  },
  BarProgress: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        backgroundColor: theme.colors['background.selected.bold'],
      };
    },
  },
  BarContainer: {
    style: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  Root: {
    style: {
      width: '98px',
    },
  },
};

export const NewGroupStepper = () => {
  const isNewGroup = !!useMatch('/groups/new');
  const isConfig = !!useMatch('/groups/new/spaces/config');

  return (
    <Steps>
      <Step active={isNewGroup} finished={isConfig} label="Create Workgroup" step={1} />
      <ProgressBar value={isNewGroup ? 50 : 100} overrides={progressBarOverrides} />
      <Step active={isConfig} finished={false} label="Create Workspace" step={2} />
    </Steps>
  );
};

export const NewSpaceStepper = () => {
  const isConfig = !!useMatch('/groups/:groupID/spaces/config');

  return (
    <Steps>
      <Step active={isConfig} finished={false} label="Create Workspace" step={1} />
    </Steps>
  );
};

const Steps = styled('div', ({ $theme }) => ({
  ...$theme.typography.Body1,
  display: 'flex',
  justifyContent: 'center',
  gap: '32px',
  borderBottom: `1px solid ${$theme.colors.divider}`,
  padding: '16px 0',
  backgroundColor: $theme.colors['background.alternative'],
}));

function Step({ active, finished, label, step }: { active: boolean; finished?: boolean; label: string; step: number }) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        color: active || finished ? theme.colors.primary : theme.colors.contentSecondary,
        fontWeight: active ? '600' : '400',
        display: 'flex',
        gap: '16px',
      })}
    >
      <span
        className={css({
          width: '24px',
          height: '24px',
          borderRadius: '100%',
          backgroundColor:
            finished || active
              ? theme.colors['background.selected.bold']
              : theme.colors['background.accent.neutral.subtle'],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: '500',
          color: theme.colors['text.inverse'],
        })}
      >
        {finished ? <MarkIcon /> : step}
      </span>
      <span>{label}</span>
    </div>
  );
}

const MarkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M8.055 13.336 5.279 10.56a.797.797 0 1 0-1.128 1.128l3.344 3.344a.797.797 0 0 0 1.128 0l8.464-8.464a.797.797 0 1 0-1.128-1.128l-7.904 7.896Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.854.4h19.2v19.2H.854z" />
      </clipPath>
    </defs>
  </svg>
);
