import { styled } from '@tigergraph/app-ui-lib/Theme';

export const FormSectionTitle = styled('div', ({ $theme }) => ({
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '16px',
  marginBottom: '10px',
}));

export const FormSection = styled('div', {
  marginBottom: '24px',
});

export const FormTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px',
});
