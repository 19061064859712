import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import {
  AttachedWorkspaces,
  CreateSolution,
  SolutionGSQL,
  SolutionInstance,
  SolutionT,
} from '@/pages/marketplace/solution/type';
import axios from 'axios';

const s3 = axios.create({});

export async function getSolutionCatalog(): Promise<SolutionT[]> {
  const response = await s3.get(`https://${import.meta.env.VITE_SOLUTION_BUCKET}.s3.amazonaws.com/list.json`);
  return response.data.filter((s: any) => !s.is_library);
}

export async function getSolutionGSQL(path: string): Promise<SolutionGSQL> {
  const response = await s3.get(`https://${import.meta.env.VITE_SOLUTION_BUCKET}.s3.amazonaws.com/${path}/meta.json`);
  return response.data;
}

export async function listSolution(workgroup_id: string): Promise<Result<SolutionInstance[]>> {
  const response = await axiosController.get(`/v2/solution-kit`, { params: { workgroup_id: workgroup_id } });
  return response.data;
}

export async function listAttachedWorkspace(): Promise<Result<AttachedWorkspaces>> {
  const response = await axiosController.get(`/v2/solution-kit/attached-workspace`);
  return response.data;
}

export async function createSolution(data: CreateSolution): Promise<Result<SolutionInstance>> {
  const response = await axiosController.post(`/v2/solution-kit/${data.workgroup_id}/${data.workspace_id}`, data);
  return response.data;
}

export async function deleteSolution(
  workgroup_id: string,
  workspace_id: string,
  solutionID: string
): Promise<Result<void>> {
  const response = await axiosController.delete(`/v2/solution-kit/${workgroup_id}/${workspace_id}/${solutionID}`);
  return response.data;
}

export async function getSolution(
  workgroup_id: string,
  workspace_id: string,
  solutionID: string
): Promise<Result<SolutionInstance>> {
  const response = await axiosController.get(`/v2/solution-kit/${workgroup_id}/${workspace_id}/${solutionID}`);
  return response.data;
}
