import useInterval from 'ahooks/lib/useInterval';
import { ProgressBar } from '@tigergraph/app-ui-lib/progress-bar';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactFlow, { BaseEdge, EdgeLabelRenderer, EdgeProps, Handle, NodeProps, Position } from 'reactflow';
import { format } from 'date-fns';
import 'reactflow/dist/style.css';
import { CopyIcon, X } from 'lucide-react';
import useCopyClipboard from 'react-use-clipboard';
import { EditorIcon, ExplorerIcon, GroupIcon, IngestionIcon } from '@/pages/home/icons';
import { useIsMutating } from 'react-query';
import WaitingGif from '../icons/waiting.gif';
import { MdPlayCircleOutline } from 'react-icons/md';
import DegradeIcon from './icons/degrade.svg?react';
import { CustomTheme, styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';

import {
  DatabaseT,
  WorkGroupT,
  WorkspaceStatus,
  WorkspaceT,
  isRefreshIng,
  isStatusActive,
  isStatusError,
  isStatusPending,
  isStatusIdle,
  canCreateWorkspace,
  WorkSpaceOrgQuota,
  workspaceQuotaError,
  isStatusPaused,
  isStatusDegraded,
  isStatusInitializing,
} from '@/pages/workgroup/type';

import { DatabaseIcon, SpaceIcon } from '@/pages/home/icons';
import ConnectionMenu from '@/pages/workgroup/tab/ConnectionMenu';
import {
  ConfirmResumeWorkspace,
  SettingMenuForDatabase,
  SettingMenuForWorkSpace,
} from '@/pages/workgroup/tab/SettingMenu';
import {
  calculateNodesAndEdges,
  groupWorkSpacesByDatabase,
  SpaceWidth,
  NodeHeightNormal,
  NodeHeightInProgress,
  DatabaseWidth,
} from '@/pages/workgroup/tab/Graph';
import useSize from 'ahooks/lib/useSize';
import { calculateRoleForGroup, calculateRoleForSpace } from '@/pages/admin/user/type';
import { useOrgContext } from '@/contexts/orgContext';
import ParagraphLabel from '@/components/PagagraphLabel';
import {
  useCloudProviders,
  useMutationRefreshWorkspace,
  useMutationRenameDatabase,
  useMutationRenameWorkGroup,
  useMutationUpdateWorkspace,
} from '@/pages/workgroup/hook';
import { getErrorMessage } from '@/utils/utils';
import { showToast } from '@/components/styledToasterContainer';
import { Button } from '@tigergraph/app-ui-lib/button';
import { PLACEMENT, Popover, PopoverOverrides, TRIGGER_TYPE } from 'baseui/popover';
import { parseDate } from '@/lib/date';
import { Plus } from 'baseui/icon';
import IconButton from '@/components/IconButton';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { HeadingSmallBold, Label } from '@tigergraph/app-ui-lib/typography';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import EditableText, { EditableTextHandle } from '@/components/EditableText';
import { isWorkspaceCopilotEnable } from '@/utils/addons/copilot';
import { InfoContainer, InfoContent, InfoTitle } from '@/pages/workgroup/tab/styled';
import SchemaIcon from '@/assets/graph-schema.svg?react';
import clsx from 'clsx';
import { useMenuItemAddons } from '@/pages/workgroup/tab/ConnectionMenu/ConnectionMenuAddons';
import { useAddonsMeta } from '@/hooks/useAddonsMeta';
import { useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { AccessBlocked } from '@/pages/workgroup/icon';
import { useAccess } from '@/contexts/accessContext';
import { isCPDisabled } from '@/pages/admin/settings/cloud_provider/util';

const nodeTypes = { database: DatabaseNode, workspace: WorkspaceNode };
const edgeTypes = { databaseEdge: DatabaseEdge };

export default function General({ group, orgQuota }: { group: WorkGroupT; orgQuota: WorkSpaceOrgQuota }) {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);
  const size = useSize(ref);
  let maxWidth = size?.width ? size.width - SpaceWidth - DatabaseWidth : 64;
  if (maxWidth >= 280) {
    maxWidth = 280;
  }

  const groupedWorkspaces = useMemo(() => {
    return groupWorkSpacesByDatabase(group);
  }, [group]);

  const { nodes, edges, totalHeight } = useMemo(() => {
    return calculateNodesAndEdges(groupedWorkspaces, theme, maxWidth);
  }, [groupedWorkspaces, maxWidth, theme]);

  const { userInfo } = useOrgContext();
  const effectRole = calculateRoleForGroup(userInfo.roles, group.workgroup_id);

  const isCreatingDatabase = useIsMutating({
    mutationKey: ['createDatabase', group.name],
  });

  const isCreatingWorkspace = useIsMutating({
    mutationKey: ['createWorkspace', group.name],
  });

  const updateName = useMutationRenameWorkGroup();
  const handleRef = useRef<EditableTextHandle | null>(null);

  // preload addons meta and enabled addons
  useAddonsMeta();
  useListEnabledAddons();

  const myIP = useAccess();

  const { cps } = useCloudProviders();
  const groupCp = cps.find((cp) => cp.id === group.cloud_provider_id);

  const createDisabled = !myIP.is_allowed || (groupCp && isCPDisabled(groupCp));

  const createButton = (enableCreate: boolean) => {
    return (
      <Button
        disabled={createDisabled}
        onClick={() => {
          if (enableCreate) {
            navigate('spaces/config');
          }
        }}
        startEnhancer={<Plus size={16} />}
        overrides={{
          BaseButton: {
            style: {
              opacity: enableCreate ? 1 : 0.5,
            },
          },
        }}
        aria-label="create workspace"
      >
        Create
      </Button>
    );
  };

  return (
    <div
      ref={ref}
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      })}
    >
      {isCreatingDatabase || isCreatingWorkspace ? (
        <div
          className={css({
            backgroundColor: theme.colors['background.alternative'],
            borderRadius: '2px',
            boxShadow: theme.colors['shadow.popup'],
            marginLeft: '-16px',
            marginRight: '-16px',
            marginTop: '-16px',
            height: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
          })}
        >
          <img
            src={WaitingGif}
            alt="waiting"
            className={css({
              width: '48px',
              height: '48px',
            })}
          />
          <Label>Creating your workspace ...</Label>
        </div>
      ) : null}

      <div
        className={css({
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        })}
      >
        <GroupTitle className={css({ display: 'inline' })}>
          <div
            className={css({
              lineHeight: '30px',
              marginRight: '10px',
              marginTop: '8px',
              display: 'inline-flex',
              height: '30px',
              position: 'relative',
              top: '3px',
            })}
          >
            <GroupIcon />
          </div>
          <EditableText
            ref={handleRef}
            text={group.name}
            disableEdit={effectRole !== 'workgroup-admins'}
            onTextChange={(name) => {
              updateName.mutate(
                {
                  group_id: group.workgroup_id,
                  workgroup_name: name,
                },
                {
                  onError(error, variables, context) {
                    showToast({
                      kind: 'negative',
                      message: `${getErrorMessage(error)}`,
                    });
                    if (handleRef.current) {
                      handleRef.current.reset();
                    }
                  },
                }
              );
            }}
          />
          <span
            className={css({
              color: theme.colors['text.secondary'],
              padding: '4px 8px',
              background: theme.colors['background.secondary'],
              marginLeft: '12px',
              fontSize: '12px',
              display: 'inline-flex',
            })}
          >
            {group.region.toUpperCase()}
          </span>
        </GroupTitle>
      </div>
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: `${DatabaseWidth}px ${maxWidth}px ${SpaceWidth}px`,
        })}
      >
        <SpaceTitle>Database</SpaceTitle>
        <div />
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          })}
        >
          <SpaceTitle>Workspace</SpaceTitle>
          {effectRole === 'workgroup-admins' ? (
            canCreateWorkspace(orgQuota) ? (
              createButton(true)
            ) : (
              <>
                <StatefulTipsPopover
                  content={workspaceQuotaError(orgQuota)}
                  triggerType={TRIGGER_TYPE.hover}
                  focusLock={true}
                  overrides={{
                    Body: {
                      style: {
                        maxWidth: '450px',
                      },
                    },
                  }}
                >
                  {createButton(false)}
                </StatefulTipsPopover>
              </>
            )
          ) : null}
        </div>
      </div>
      <div
        className={css({
          height: `${totalHeight}px`,
        })}
      >
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={{
            // hide React flow watermark
            hideAttribution: true,
          }}
          nodes={nodes}
          edges={edges}
          zoomOnScroll={false}
          zoomOnPinch={false}
          panOnDrag={false}
          panOnScroll={false}
          zoomOnDoubleClick={false}
          selectionOnDrag={false}
          nodesDraggable={false}
          nodesConnectable={false}
          edgesFocusable={false}
          preventScrolling={false}
        />
      </div>
    </div>
  );
}

export function Space({
  workspace,
  database,
  workgroup,
  showRWTag,
  showAddon = false,
  editable = true,
  renderConnectionMenu,
  workspaceInfoPlacement = PLACEMENT.right,
}: {
  workspace: WorkspaceT;
  workgroup?: WorkGroupT;
  showRWTag?: boolean;
  showAddon?: boolean;
  database?: DatabaseT;
  editable?: boolean;
  renderConnectionMenu?: ReactNode;
  workspaceInfoPlacement?: string;
}) {
  const [css, theme] = useStyletron();
  const updateName = useMutationUpdateWorkspace();
  const { userInfo } = useOrgContext();
  const effectRole = calculateRoleForSpace(userInfo.roles, workspace.workgroup_id, workspace.workspace_id);
  const handleRef = useRef<EditableTextHandle | null>(null);
  const isWorkspaceEnabled = isWorkspaceCopilotEnable(workspace);
  const [showPopover, setShowPopover] = useState(false);
  const myIP = useAccess();

  const delayClose = () => {
    setTimeout(() => {
      setShowPopover(false);
    }, 100);
  };

  const [showResume, setShowResume] = useState(false);
  const onResume = () => {
    setShowResume(true);
  };

  const userRoleForSpace = workspace
    ? calculateRoleForSpace(userInfo.roles, workspace.workgroup_id, workspace.workspace_id)
    : 'users';
  const hasResumePermission = userRoleForSpace === 'workspace-admins';

  return (
    <Box
      className={clsx(
        css({
          cursor: 'initial',
          width: `${SpaceWidth}px`,
          height: `${isStatusInitializing(workspace.status) ? NodeHeightInProgress : NodeHeightNormal}px`,
          position: 'relative',
          backgroundColor: theme.colors['background.alternative'],
        }),
        'workspace-box',
        'nodrag'
      )}
    >
      <Row>
        <span
          className={css({
            flex: 0,
            gap: '6px',
          })}
        >
          <SpaceIcon />
        </span>
        {workgroup && (
          <>
            <span className="text-[#909090]">{workgroup.name}</span>
            <span className="text-[#909090]">/</span>
          </>
        )}
        {!editable ? (
          workspace.name
        ) : (
          <EditableText
            ref={handleRef}
            text={workspace.name}
            onTextChange={(name) => {
              updateName.mutate(
                {
                  group_id: workspace.workgroup_id,
                  space_id: workspace.workspace_id,
                  data: {
                    workspace_name: name,
                  },
                },
                {
                  onError(error) {
                    showToast({
                      kind: 'negative',
                      message: `${getErrorMessage(error)}`,
                    });
                    if (handleRef.current) {
                      handleRef.current.reset();
                    }
                  },
                }
              );
            }}
            compact={true}
            maxWidth={isStatusInitializing(workspace.status) ? '83px' : '220px'}
            disableEdit={effectRole !== 'workspace-admins'}
            onClick={() => {
              setShowPopover(!showPopover);
            }}
          />
        )}

        <Popover
          isOpen={showPopover}
          onEsc={delayClose}
          onClickOutside={(e) => {
            const popover = document.querySelector(`#${workspace.workspace_id}-popover`);
            const target = e.target as HTMLElement | null;
            if (target && popover?.contains(target)) {
              return;
            }

            if (target?.closest('.workspace-popover')) {
              return;
            }

            delayClose();
          }}
          content={
            <WorkspacePopoverContent
              workspace={workspace}
              database={database}
              onClose={() => setShowPopover(false)}
              onResume={onResume}
            />
          }
          placement={PLACEMENT.bottom}
          overrides={popOverrides}
          autoFocus={false}
        >
          <div
            className={css({
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
            })}
          />
        </Popover>
        {!myIP.is_allowed ? (
          <StatefulPopover
            content={`Your IP ${myIP.current_ip} is not allowed to access this workspace`}
            triggerType="hover"
          >
            <IconButton>
              <AccessBlocked />
            </IconButton>
          </StatefulPopover>
        ) : null}
        {isStatusInitializing(workspace.status) && workspace.last_modified_time ? (
          <EstimateTime workspace={workspace} />
        ) : (
          <StatusIndicator status={workspace.status} />
        )}
      </Row>
      {isStatusInitializing(workspace.status) ? <Progress workspace={workspace} /> : null}
      <Row>
        <span className="mr-auto space-x-2 flex items-center">
          {[workspace.workspace_type.typeName, showRWTag && (workspace.is_rw ? 'R/W' : 'R/O')]
            .filter(Boolean)
            .map((name) => (
              <span
                key={String(name)}
                className={clsx(
                  'rounded-2xl leading-[18px] px-3 text-xs',
                  css({
                    border: `1px solid ${theme.colors['tag.border.default']}`,
                    color: theme.colors['tag.text.default'],
                  })
                )}
              >
                {name}
              </span>
            ))}
        </span>

        {renderConnectionMenu ? (
          renderConnectionMenu
        ) : (
          <>
            {isStatusPaused(workspace.status) ? (
              <Button kind="secondary" onClick={onResume} disabled={!hasResumePermission}>
                Resume
              </Button>
            ) : (
              <ConnectionMenu workspace={workspace} database={database} />
            )}
            <SettingMenuForWorkSpace workspace={workspace} database={database} />
          </>
        )}
      </Row>
      {showResume ? (
        <ConfirmResumeWorkspace isOpen={showResume} onClose={() => setShowResume(false)} workspace={workspace} />
      ) : null}
    </Box>
  );
}

function Database({
  database,
  disableDelete,
  is_ro,
  workspace,
  disableUpdate,
}: {
  database: DatabaseT;
  disableDelete: boolean;
  is_ro?: boolean;
  workspace?: WorkspaceT;
  disableUpdate?: boolean;
}) {
  const [css, theme] = useStyletron();

  const updateName = useMutationRenameDatabase();
  const handleRef = useRef<EditableTextHandle | null>(null);

  const refreshMutation = useMutationRefreshWorkspace();

  const { userInfo } = useOrgContext();
  const effectRole = calculateRoleForSpace(
    userInfo.roles,
    workspace?.workgroup_id || '',
    workspace?.workspace_id || ''
  );

  const onRefresh = () => {
    if (!workspace) {
      return;
    }

    refreshMutation.mutate(
      {
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
      },
      {
        onError: (err) => {
          showToast({
            kind: 'negative',
            message: `${getErrorMessage(err)}`,
          });
        },
      }
    );
  };

  return (
    <Box
      className={clsx(
        css({
          alignSelf: 'center',
          width: `${DatabaseWidth}px`,
          height: `${NodeHeightNormal}px`,
          cursor: 'initial',
          justifyContent: 'center',
          backgroundColor: theme.colors['background.alternative'],
        }),
        'database-box',
        'nodrag'
      )}
    >
      <Row>
        <span
          className={css({
            flex: 0,
          })}
        >
          <DatabaseIcon />
        </span>
        <span
          className={css({
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
            minWidth: 0,
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <EditableText
            ref={handleRef}
            disableEdit={is_ro || effectRole !== 'workspace-admins'}
            text={database.name}
            onTextChange={(name) => {
              updateName.mutate(
                {
                  group_id: database.workgroup_id,
                  db_id: database.database_id,
                  database_name: name,
                },
                {
                  onError(error) {
                    showToast({
                      kind: 'negative',
                      message: `${getErrorMessage(error)}`,
                    });
                    if (handleRef.current) {
                      handleRef.current.reset();
                    }
                  },
                }
              );
            }}
            compact={true}
          />
          {is_ro && (
            <span
              className={css({
                lineHeight: '16px',
                marginLeft: '4px',
                fontSize: '12px',
                padding: '2px 4px',
                backgroundColor: theme.colors['background.tertiary'],
                flexShrink: 0,
              })}
            >
              Snapshot
            </span>
          )}
        </span>
        {!is_ro ? <SettingMenuForDatabase database={database} disableDelete={disableDelete} /> : null}
      </Row>
      {is_ro && workspace ? (
        <Row $style={{ justifyContent: 'space-between' }}>
          <ParagraphLabel>
            {workspace.snapshot_time ? format(parseDate(workspace.snapshot_time), 'yyyy-MM-dd HH:mm:ss') : ''}
          </ParagraphLabel>
          {isRefreshIng(workspace.refresh_status) || refreshMutation.isLoading ? (
            <Spinner $size={'16px'} $borderWidth={'2px'} />
          ) : (
            <ConfirmStatefulPopover
              confirmLabel={`Are you sure you want to update the database?`}
              onConfirm={onRefresh}
            >
              <Button
                kind="secondary"
                size="compact"
                disabled={
                  refreshMutation.isLoading ||
                  // 1. if no rw workspace, disable update
                  // 2. if the workspace is not active, we should disable the update button
                  disableUpdate ||
                  !isStatusActive(workspace.status)
                }
              >
                Update
              </Button>
            </ConfirmStatefulPopover>
          )}
        </Row>
      ) : null}
    </Box>
  );
}

export function DegradedIndicator() {
  const [css, theme] = useStyletron();

  return (
    <StatefulTipsPopover
      content={
        <div
          className={css({
            maxWidth: '340px',
          })}
        >
          <div>
            Your workspace is currently in a degraded state. It may take 5 to 10 minutes to recover. During this time,
            you may experience the following impacts:
          </div>
          <ol
            className={css({
              listStyleType: 'decimal',
              listStylePosition: 'inside',
            })}
          >
            <li>can not create schema.</li>
            <li>can not load data.</li>
            <li>can not install query.</li>
          </ol>
        </div>
      }
      triggerType={TRIGGER_TYPE.hover}
      ignoreBoundary
    >
      <IconButton>
        <DegradeIcon color={theme.colors['icon.informative']} />
      </IconButton>
    </StatefulTipsPopover>
  );
}

export function StatusIndicator({
  status,
  size = 'default',
}: {
  status: WorkspaceStatus;
  size?: 'compact' | 'default';
}) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      })}
    >
      {isStatusDegraded(status) ? <DegradedIndicator /> : null}
      <StatefulTipsPopover content={status} triggerType={TRIGGER_TYPE.hover} placement={PLACEMENT.right} ignoreBoundary>
        <IconButton>
          {isStatusPending(status) ? (
            <Spinner
              $size="14px"
              $borderWidth="2px"
              className={css({
                borderTopColor: theme.colors['background.accent.gray.bold'],
              })}
            />
          ) : (
            <div
              aria-label="workspace-status"
              className={css({
                borderRadius: '50%',
                height: size === 'compact' ? '8px' : '12px',
                width: size === 'compact' ? '8px' : '12px',
                flexShrink: 0,
                backgroundColor: isStatusDegraded(status)
                  ? theme.colors['icon.informative']
                  : isStatusActive(status)
                  ? theme.colors['icon.success']
                  : isStatusError(status)
                  ? theme.colors['icon.danger']
                  : isStatusIdle(status) || isStatusPaused(status)
                  ? theme.colors['icon.disabled']
                  : 'none',
              })}
            />
          )}
        </IconButton>
      </StatefulTipsPopover>
    </div>
  );
}

const estimateDuration = 4 * 60;

function EstimateTime({ workspace }: { workspace: WorkspaceT }) {
  const { last_modified_time } = workspace;
  const now = Date.now();
  const lastTransitionAt = parseDate(last_modified_time);
  const diff = (now - lastTransitionAt.getTime()) / 1000;
  const remains = estimateDuration - diff;
  const m = Math.trunc(remains / 60);
  let s = Math.floor(remains - m * 60);
  s = Math.max(s, 1);

  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  const [css, theme] = useStyletron();
  return (
    <span
      className={css({
        fontSize: '12px',
        color: theme.colors['text.secondary'],
      })}
    >
      {(m == 0 && s < 10) || m < 0
        ? 'Nearly done'
        : 'Estimate:' +
          (m > 0 ? `${m}${m > 1 ? ' mins ' : ' min '}` : ' ') +
          (s > 0 ? `${s}${s > 1 ? ' seconds' : ' second'}` : '')}
    </span>
  );
}

function calculatePercent(last_modified_time: string, estimateInSeconds: number) {
  // the create workspace api return empty value.
  if (!last_modified_time) {
    return 0;
  }
  const now = Date.now();
  const lastTransitionAt = parseDate(last_modified_time);
  const diff = (now - lastTransitionAt.getTime()) / 1000;
  // at most 98%
  const percent = Math.floor((diff / estimateInSeconds) * 100);
  return Math.min(percent, 98);
}

function Progress({ workspace }: { workspace: WorkspaceT }) {
  const { last_modified_time } = workspace;

  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  const percent = calculatePercent(last_modified_time, estimateDuration);

  return <ProgressBar value={percent} />;
}

export const GroupTitle = styled('div', ({ $theme }) => ({
  ...$theme.typography.HeadingMenu,
}));

export const SpaceGroupContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '316px 204px 316px',
  rowGap: '50px',
  marginBottom: '50px',
});

export const SpaceTitle = styled('div', ({ $theme }) => ({
  ...$theme.typography.HeadingMenu,
}));

export const Box = styled('div', ({ $theme }) => ({
  padding: '16px 12px',
  borderRadius: '4px',
  border: `1px solid ${$theme.colors.divider}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const Row = styled('div', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

function DatabaseNode({
  data,
}: NodeProps<{
  database: DatabaseT;
  disableDelete: boolean;
  is_ro?: boolean;
  workspace?: WorkspaceT;
  disableUpdate?: boolean;
}>) {
  return (
    <>
      <Handle type="source" position={Position.Right} isConnectable={false} id="right" />
      <Handle type="source" position={Position.Bottom} isConnectable={false} id="bottom" />
      <Handle type="target" position={Position.Left} isConnectable={false} id="left" />
      <Database
        database={data.database}
        disableDelete={data.disableDelete}
        is_ro={data.is_ro}
        workspace={data.workspace}
        disableUpdate={data.disableUpdate}
      />
    </>
  );
}

function WorkspaceNode({ data }: NodeProps<{ database?: DatabaseT; workspace: WorkspaceT }>) {
  return (
    <>
      <Handle type="target" position={Position.Left} isConnectable={false} id="left" />
      <Space database={data.database} workspace={data.workspace} showAddon={true} />
    </>
  );
}

// M start point
// L end point
// A arc (rx ry x-axis-rotation large-arc-flag sweep-flag x y)
export function DatabaseEdge(props: EdgeProps & { hideLabel?: boolean }) {
  const { sourceX, sourceY, targetX, targetY, label, hideLabel, ...rest } = props;
  const radiusX = 20;
  const radiusY = 30;
  const edgePath = `M ${sourceX} ${sourceY} L ${sourceX} ${targetY - radiusY} A ${radiusX} ${radiusY} 0 0 0  ${
    sourceX + radiusX
  } ${targetY} L ${targetX} ${targetY}`;

  const labelX = (sourceX + targetX) / 2;
  const labelY = targetY;

  return (
    <>
      <BaseEdge path={edgePath} {...rest} />
      {hideLabel || true ? null : (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              background: '#f8f8f8',
              color: '#B3B3B3',
              fontWeight: '600',
              fontSize: 15,
              padding: '6px 8px',
            }}
            className="nodrag nopan"
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

function WorkspacePopoverContent({
  workspace,
  onClose,
  database,
  onResume,
}: {
  workspace: WorkspaceT;
  database?: DatabaseT;
  onClose: () => void;
  onResume: () => void;
}) {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      })}
      id={`.${workspace.workspace_id}-popover`}
    >
      <WorkspaceHeader workspace={workspace} onClose={onClose} database={database} onResume={onResume} />
      <WorkspaceInfo workspace={workspace} />
      <WorkspaceConnect workspace={workspace} database={database} />
      <WorkspaceAddon workspace={workspace} database={database} />
    </div>
  );
}

function WorkspaceHeader({
  workspace,
  database,
  onClose,
  onResume,
}: {
  workspace: WorkspaceT;
  database?: DatabaseT;
  onClose: () => void;
  onResume: () => void;
}) {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      })}
    >
      <SpaceIcon />
      <HeadingSmallBold $style={{ fontSize: '18px', lineHeight: '32px' }}>{workspace.name}</HeadingSmallBold>
      <StatusIndicator status={workspace.status} />
      <span
        className={css({
          flex: 1,
        })}
      />
      {isStatusPaused(workspace.status) ? (
        <IconButton onClick={onResume}>
          <MdPlayCircleOutline size={24} />
        </IconButton>
      ) : null}
      <SettingMenuForWorkSpace popover={true} workspace={workspace} database={database} />
      <IconButton onClick={onClose}>
        <X size={24} />
      </IconButton>
    </div>
  );
}

function WorkspaceInfo({ workspace }: { workspace: WorkspaceT }) {
  const [hostURLCopied, setHostURLCopied] = useCopyClipboard(workspace.nginx_host, {
    successDuration: 1000,
  });

  const [workgroupIdCopied, setWorkgroupIdCopied] = useCopyClipboard(workspace.workgroup_id, {
    successDuration: 1000,
  });

  const [workspaceIdCopied, setWorkspaceIdCopied] = useCopyClipboard(workspace.workspace_id, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (hostURLCopied) {
      showToast({
        kind: 'positive',
        message: 'Workspace host url copied successfully.',
      });
    }
  }, [hostURLCopied]);

  useEffect(() => {
    if (workgroupIdCopied) {
      showToast({
        kind: 'positive',
        message: 'Workgroup ID copied successfully.',
      });
    }
  }, [workgroupIdCopied]);

  useEffect(() => {
    if (workspaceIdCopied) {
      showToast({
        kind: 'positive',
        message: 'Workspace ID copied successfully.',
      });
    }
  }, [workspaceIdCopied]);

  return (
    <InfoContainer>
      <InfoTitle>ID:</InfoTitle>
      <InfoContent>
        <span>{workspace.workspace_id}</span>
        <IconButton type="button" onClick={setWorkspaceIdCopied}>
          <CopyIcon size="12" />
        </IconButton>
      </InfoContent>
      <InfoTitle>Workgroup ID:</InfoTitle>
      <InfoContent>
        <span>{workspace.workgroup_id}</span>
        <IconButton type="button" onClick={setWorkgroupIdCopied}>
          <CopyIcon size="12" />
        </IconButton>
      </InfoContent>
      <InfoTitle>Host:</InfoTitle>
      <InfoContent>
        <span>{workspace.nginx_host}</span>
        <IconButton type="button" onClick={setHostURLCopied}>
          <CopyIcon size="12" />
        </IconButton>
      </InfoContent>
      <InfoTitle>Type:</InfoTitle>
      <InfoContent>{workspace.is_rw ? 'Read-Write' : 'Read-Only'}</InfoContent>
      <InfoTitle>Version:</InfoTitle>
      <InfoContent>{workspace.tg_version}</InfoContent>
      <InfoTitle>Owner:</InfoTitle>
      <InfoContent>{workspace.creator}</InfoContent>
      <InfoTitle>Auto Start:</InfoTitle>
      <InfoContent>{workspace.enable_auto_start ? 'Enabled' : 'Disabled'}</InfoContent>
      <InfoTitle>Auto Suspend:</InfoTitle>
      <InfoContent>
        {workspace.auto_stop_minutes === 0
          ? 'Disabled'
          : workspace.auto_stop_minutes + (workspace.auto_stop_minutes === 1 ? ' min' : ' mins')}
      </InfoContent>
      {/* <InfoTitle>High Availability:</InfoTitle>
      <InfoContent>{workspace.enable_ha ? 'Enabled' : 'Disabled'}</InfoContent> */}
    </InfoContainer>
  );
}

function WorkspaceConnect({ workspace, database }: { workspace: WorkspaceT; database?: DatabaseT }) {
  const [css] = useStyletron();
  const navigate = useNavigate();
  const enabled = isStatusActive(workspace.status);

  return (
    <div
      className={css({
        display: 'flex',
        gap: '4px',
      })}
    >
      <Button
        kind="secondary"
        startEnhancer={<SchemaIcon width={18} height={18} />}
        onClick={() => navigate('/design-schema?workspace_id=' + workspace.workspace_id)}
        disabled={!enabled}
      >
        Design Schema
      </Button>
      <Button
        kind="secondary"
        startEnhancer={<IngestionIcon />}
        onClick={() => navigate('/ingestion?workspace_id=' + workspace.workspace_id)}
        disabled={!enabled}
      >
        Load Data
      </Button>
      <Button
        kind="secondary"
        startEnhancer={<EditorIcon />}
        onClick={() => navigate('/editor?workspace_id=' + workspace.workspace_id)}
        disabled={!enabled}
      >
        GSQL Editor
      </Button>
      <Button
        kind="secondary"
        startEnhancer={<ExplorerIcon />}
        onClick={() => navigate('/explore?workspace_id=' + workspace.workspace_id)}
        disabled={!enabled}
      >
        Explore Graph
      </Button>
      <ConnectionMenu workspace={workspace} database={database} popover={true} />
    </div>
  );
}

function WorkspaceAddon({ workspace, database }: { workspace: WorkspaceT; database?: DatabaseT }) {
  const [css] = useStyletron();
  const navigate = useNavigate();
  const { addonsMenuItems, getLink, handleMenuItemClick, isMenuItemDisabled } = useMenuItemAddons(workspace);

  return (
    <div
      className={css({
        display: 'flex',
        gap: '4px',
      })}
    >
      {addonsMenuItems.map((menuItem) => (
        <Button
          kind="secondary"
          key={menuItem.name}
          onClick={() => {
            handleMenuItemClick(menuItem);
            if (menuItem.interationType === 'tools-link') {
              window.open(getLink(menuItem), '_blank');
            } else if (menuItem.interationType === 'link') {
              navigate(menuItem.url);
            }
          }}
          disabled={isMenuItemDisabled(menuItem)}
          startEnhancer={
            <img
              src={menuItem.icon}
              aria-hidden={true}
              className={css({
                height: '16px',
              })}
            />
          }
        >
          {menuItem.name.startsWith('TigerGraph') ? menuItem.name.slice('TigerGraph'.length) : menuItem.name}
        </Button>
      ))}
    </div>
  );
}

const popOverrides: PopoverOverrides = {
  Body: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        marginLeft: '0px',
        boxShadow: theme.colors['shadow.popup'],
        border: `1px solid ${theme.colors.divider}`,
      };
    },
  },
  Inner: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        padding: '16px 16px 24px 16px',
        backgroundColor: theme.colors['background.primary'],
      };
    },
  },
};
