import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

export type EnabledAddonsType = {
  ID: string;
  Enabled: boolean;
  AddonsID: string;
};

export type ListEnabledAddonsReturnType = Result<EnabledAddonsType[]>;

export const useListEnabledAddons = <T = EnabledAddonsType[]>(
  opt?: UseQueryOptions<EnabledAddonsType[] | undefined, AxiosError, T, string[]>
) => {
  return useQuery({
    ...opt,
    queryKey: ['list', 'installed', 'addons'],
    queryFn: async () => {
      const res = await axiosController.get<ListEnabledAddonsReturnType>('/v2/installed-addons');
      return res.data.Result;
    },
  });
};
