import * as React from 'react';
import { useStyletron, styled } from 'baseui';
import { Override } from 'baseui/overrides';
// @ts-ignore
import { getOverride, getOverrideProps } from 'baseui/helpers/overrides';
import Logo from '@/pages/home/logo';

export interface ContainerOverrides {
  Root?: Override<any>;
}

const StyledRoot = styled('div', ({ $theme }) => {
  return {
    marginLeft: 'auto',
    marginRight: 'auto',
    [$theme.mediaQuery.small]: {
      maxWidth: '92vw',
      paddingTop: '48px',
      paddingBottom: '24px',
    },
    [$theme.mediaQuery.medium]: {
      maxWidth: '1048px',
    },
  };
});

export default function Container(props: { children: React.ReactNode; overrides?: ContainerOverrides }) {
  const { children, overrides = {} } = props;

  const [css, theme] = useStyletron();

  const { Root: RootOverride } = overrides;

  const Root = getOverride(RootOverride) || StyledRoot;

  return (
    <Root {...getOverrideProps(RootOverride)}>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
        })}
      >
        <Logo />
      </div>
      {children}
    </Root>
  );
}
