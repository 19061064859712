import { showToast } from '@/components/styledToasterContainer';
import { Result } from '@/lib/type';
import { getSolution } from '@/pages/marketplace/solution/api';
import { SolutionInstance } from '@/pages/marketplace/solution/type';
import { solutionsAtom } from '@/pages/workgroup/atom';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const pollingInterval = 3 * 1000;

export function SolutionsPoll() {
  const [solutions] = useAtom(solutionsAtom);

  return (
    <div>
      <div>
        {solutions.map((solution) => (
          <SolutionPoll solution={solution} key={solution.id} />
        ))}
      </div>
    </div>
  );
}

function SolutionPoll({ solution: sol }: { solution: SolutionInstance }) {
  const [, setSolutions] = useAtom(solutionsAtom);
  const queryClient = useQueryClient();
  const navigator = useNavigate();

  useQuery<Result<SolutionInstance>, AxiosError>(
    ['solution', sol.workgroup_id, sol.workspace_id, sol.id],
    async () => {
      return getSolution(sol.workgroup_id, sol.workspace_id, sol.id);
    },
    {
      initialData: {
        Error: false,
        Result: sol,
      },
      onError: () => {
        // when error, remove it from the polling list
        setSolutions((oldData) => {
          return oldData.filter((item) => item.id !== sol.id);
        });
      },
      onSuccess: (data) => {
        if (data?.Result) {
          const solution = data?.Result;

          // update cache(mainly to update the gsql log)
          queryClient.setQueryData<Result<SolutionInstance[]> | undefined>(
            ['solutions', solution.workgroup_id],
            (oldData) => {
              if (!oldData?.Result) {
                return oldData;
              }
              let newData = {
                ...oldData,
                Result: oldData.Result.map((s) => (s.id !== solution.id ? s : solution)),
              };
              return newData;
            }
          );

          let allDone = true;

          if (solution.solution_status === 'install_pending' || solution.solution_status === 'uninstall_pending') {
            allDone = false;
          }

          if (!allDone) {
            return;
          }

          // remove the item from the polling list
          setSolutions((oldData) => {
            return oldData.filter((item) => item.id !== solution.id);
          });

          let message = '';
          if (solution.solution_status === 'installed') {
            message = `Solution ${solution.name} is successfully installed on ${sol.workspace_name}`;
          } else if (solution.solution_status === 'install_error') {
            message = `Failed to install solution ${solution.name} on ${sol.workspace_name}`;
          }

          showToast({
            kind: solution.solution_status === 'installed' ? 'positive' : 'negative',
            message: <div>{message}</div>,
            autoHideDuration: 0,
            title: `Solution Installation ${solution.solution_status === 'installed' ? 'Succeeded' : 'Failed'}`,
            action: () => {
              navigator(`/groups/${solution.workgroup_id}?tab=solution`);
            },
          });
        }
      },
      refetchInterval: (data) => {
        if (!data?.Result) {
          setSolutions((oldData) => {
            return oldData.filter((item) => item.id !== solution.id);
          });
          return false;
        }

        const solution = data?.Result;
        let shouldFetch = false;

        if (solution.solution_status === 'install_pending' || solution.solution_status === 'uninstall_pending') {
          shouldFetch = true;
        }

        if (!shouldFetch) {
          return false;
        }

        return pollingInterval;
      },
    }
  );

  return null;
}
