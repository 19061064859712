import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { getAxiosInstance, simpleAuth } from '@tigergraph/tools-models';
import axios from 'axios';
import { compare } from 'compare-versions';

const instance = axios.create({});

interface Response<T> {
  error: boolean;
  message: string;
  results: T;
}

function prepare(nginx_host: string, idToken: string) {
  instance.defaults.baseURL = `https://${nginx_host}`;
  instance.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
  getAxiosInstance().defaults.baseURL = `https://${nginx_host}`;
  getAxiosInstance().defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
}

function getDefaultTargetPrivileges(tgVersion: string) {
  if (compare(tgVersion, '4.0.x', '>')) {
    return ['READ_SCHEMA', 'READ_DATA'];
  }
  return ['READ_SCHEMA', 'READ_DATA', 'WRITE_QUERY', 'READ_QUERY'];
}

export async function fetchGraphList(
  nginx_host: string,
  tgVersion: string,
  idToken: string,
  targetPrivileges: string[] = getDefaultTargetPrivileges(tgVersion),
  includeGlobalGraph = false
) {
  prepare(nginx_host, idToken);
  const response = (
    await simpleAuth({
      baseURL: `https://${nginx_host}`,
      version: tgVersion,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
      },
    })
  ).data;
  const graphList: string[] = [];
  if (!response['error']) {
    const results = response['results'];
    const privileges = results['privileges'];
    const roles = results['roles'];

    let hasGlobalPrivileges = !!results['isSuperUser'];
    if (
      Object.keys(privileges).includes('1') &&
      ((Array.isArray(privileges['1']) && hasPrivileges(privileges['1'], targetPrivileges)) ||
        hasPrivileges(privileges['1']['privileges'], targetPrivileges))
    ) {
      hasGlobalPrivileges = true;
      if (includeGlobalGraph) {
        graphList.push('1');
      }
    }

    for (const graphName of Object.keys(privileges).filter((q) => q !== '1')) {
      if (hasGlobalPrivileges) {
        graphList.push(graphName);
      } else {
        const graphPrivileges = privileges[graphName];
        const graphRoles = roles?.[graphName];

        if (
          (Array.isArray(graphPrivileges) && hasPrivileges(graphPrivileges, targetPrivileges)) ||
          hasPrivileges(graphPrivileges['privileges'], targetPrivileges) ||
          (Array.isArray(graphRoles) && graphRoles.length > 0)
        ) {
          graphList.push(graphName);
        }
      }
    }
  }
  return graphList;
}

function hasPrivileges(privileges: Array<string>, targetPrivileges: Array<string>): boolean {
  if (privileges && targetPrivileges.every((privilege) => privileges.includes(privilege))) {
    return true;
  }
  return false;
}
