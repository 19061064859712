import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { useMutation } from 'react-query';

type MutateAlertCommonParams = {
  workgroupID: string;
  workspaceID: string;
};

export type UseMutateCreateAlertRuleParams = {
  metric: string;
  threshold: number;
} & MutateAlertCommonParams;

export const useMutateCreateAlertRule = () => {
  return useMutation({
    mutationFn: async ({ workgroupID, workspaceID, ...data }: UseMutateCreateAlertRuleParams) => {
      const res = await axiosController.post<Result>(`/v2/alert/${workgroupID}/${workspaceID}`, data);
      return res.data;
    },
  });
};

export type UseMutateDeleteAlertRule = {
  ruleID: string;
} & MutateAlertCommonParams;
export const useMutateDeleteAlertRule = () => {
  return useMutation({
    mutationFn: async ({ workgroupID, workspaceID, ruleID }: UseMutateDeleteAlertRule) => {
      const res = await axiosController.delete<Result>(`/v2/alert/${workgroupID}/${workspaceID}/${ruleID}`);
      return res.data;
    },
  });
};

export type UseMutateUpdateAlertRule = {
  ruleID: string;
  threshold: number;
} & MutateAlertCommonParams;
export const useMutateUpdateAlertRule = () => {
  return useMutation({
    mutationFn: async ({ workgroupID, workspaceID, ruleID, ...data }: UseMutateUpdateAlertRule) => {
      const res = await axiosController.put<Result>(`/v2/alert/${workgroupID}/${workspaceID}/${ruleID}`, data);
      return res.data;
    },
  });
};
