import { highlightActiveLineGutter, keymap, KeyBinding, tooltips } from '@codemirror/view';
import { defaultKeymap } from '@codemirror/commands';
import ReactCodeMirror, { Statistics } from '@uiw/react-codemirror';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SelectionRange, Text } from '@codemirror/state';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { useDebounceEffect } from 'ahooks';
import { StreamLanguage } from '@codemirror/language';
import gsql from '@/utils/graphEditor/gsql-mode';
import { useEditorContext } from '@/contexts/graphEditorContext';
import useEditorTheme from '@/pages/editor/useEditorTheme';

export interface CodeEditorProps {
  value: string;
  editable?: boolean;
  enableLint: boolean;
  onRunAll: () => void;
  onRunSelection: (code: string) => void;
  onCodeChange: (code: string) => void;
  onSaveFile: (code: string) => void;
}

type Position = {
  line: number;
  ch: number;
};

function posToOffset(doc: Text, pos: Position) {
  return doc.line(pos.line + 1).from + pos.ch;
}

export const GSQLEditorClassName = 'cm-gsql-container';

export function CodeEditor({
  value,
  editable = false,
  enableLint,
  onRunAll,
  onRunSelection,
  onCodeChange,
  onSaveFile,
}: CodeEditorProps) {
  const { currentWorkspace } = useWorkspaceContext();
  const { selectedCode, setSelectedCode } = useEditorContext();
  const [range, setRange] = useState<SelectionRange | null>(null); // real-time range
  const [runRange, setRunRange] = useState<SelectionRange | null>(null); // range when click the run button
  const [isRunSelection, setIsRunSelection] = useState(false);

  const [editorValue, setEditorValue] = useState(value);

  const codemirrorRef = useRef<any>();

  useDebounceEffect(
    () => {
      onCodeChange(editorValue);
    },
    [editorValue, onCodeChange],
    { wait: 200, maxWait: 1000 }
  );

  const customKeymap: KeyBinding[] = useMemo(() => {
    const saveCmd = () => {
      if (codemirrorRef?.current?.view) {
        // get content from codemirror rather than react state to avoid this hook changes on every keystroke
        const content = codemirrorRef.current.view.state.doc.toString();
        onSaveFile(content);
      }
      return true;
    };

    const isMac = /Mac|iPad/i.test(navigator.platform);

    return [
      {
        key: isMac ? 'Cmd-s' : 'Ctrl-s',
        run: saveCmd,
      },
      {
        key: isMac ? 'Cmd-Enter' : 'Ctrl-Enter',
        // prevent the default keymap by returning true
        // we implement this keymap in Header.tsx
        run: () => true,
      },
    ];
  }, [onSaveFile]);

  const handleCodeChange = useCallback((value: string) => {
    setIsRunSelection(false);
    setEditorValue(value);
  }, []);

  const handleStatisticChange = useCallback(
    (value: Statistics) => {
      setRange(value.selectionAsSingle);
      setSelectedCode(value.selectionCode);
    },
    [setSelectedCode]
  );

  // const selectionLineGutter = useMemo(() => {
  //   const selectionMarker = new (class extends GutterMarker {
  //     constructor() {
  //       super();
  //     }
  //     toDOM() {
  //       const el = document.createElement('div');
  //       el.style.borderLeft = '4px solid #75ADE4';
  //       el.style.height = '100%';
  //       return el;
  //     }
  //   })();

  //   return gutter({
  //     lineMarker: (view, line) => {
  //       const state = view.state;
  //       const { selection } = state;

  //       if (selection.ranges.length > 0 && runRange) {
  //         const { from, to } = runRange;
  //         const isLineSelected = !((from <= line.from && to <= line.from) || (from >= line.to && to >= line.to));
  //         return isLineSelected && isRunSelection ? selectionMarker : null;
  //       } else {
  //         return null;
  //       }
  //     },
  //   });
  // }, [isRunSelection, runRange]);

  const extensions = useMemo(() => {
    return [
      highlightActiveLineGutter(),
      StreamLanguage.define(gsql),
      keymap.of(customKeymap.concat(defaultKeymap)),
      tooltips({
        position: 'absolute',
        tooltipSpace(view) {
          return view.dom.getBoundingClientRect();
        },
      }),
    ];
  }, [customKeymap]);

  const basicSetup = useMemo(() => {
    return {
      defaultKeymap: false,
      highlightActiveLineGutter: false,
      highlightActiveLine: false,
    };
  }, []);

  const theme = useEditorTheme();

  const codeEditor = useMemo(() => {
    return (
      <ReactCodeMirror
        value={editorValue}
        className={GSQLEditorClassName}
        height={'100%'}
        theme={theme}
        onChange={handleCodeChange}
        editable={editable}
        extensions={extensions}
        basicSetup={basicSetup}
        onStatistics={handleStatisticChange}
        ref={codemirrorRef}
      />
    );
  }, [editorValue, theme, handleCodeChange, editable, extensions, basicSetup, handleStatisticChange]);

  return <>{codeEditor}</>;
}
