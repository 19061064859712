import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { AllowIP, FormData, MyIP } from '@/pages/workgroup/tab/networkAccess/type';

export async function configAllowIP(workgroup_id: string, enable: boolean): Promise<Result<void>> {
  const response = await axiosController.put(
    `v2/workgroups/${workgroup_id}/allow_list/${enable ? 'enable' : 'disable'}`
  );
  return response.data;
}

export async function getMyIP(workgroup_id: string): Promise<Result<MyIP>> {
  const response = await axiosController.get(`v2/workgroups/${workgroup_id}/allow_list/current`);
  return response.data;
}

export async function addAllowIP(workgroup_id: string, data: FormData): Promise<Result<void>> {
  const response = await axiosController.post(`v2/workgroups/${workgroup_id}/allow_list`, data);
  return response.data;
}

export async function deleteAllowIP(workgroup_id: string, id: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/workgroups/${workgroup_id}/allow_list/${id}`);
  return response.data;
}

export async function updateAllowIP(workgroup_id: string, id: string, data: FormData): Promise<Result<void>> {
  const response = await axiosController.put(`v2/workgroups/${workgroup_id}/allow_list/${id}`, data);
  return response.data;
}

export async function listAllowIP(workgroup_id: string): Promise<Result<AllowIP[]>> {
  const response = await axiosController.get(`v2/workgroups/${workgroup_id}/allow_list`);
  return response.data;
}
