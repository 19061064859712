import currency from 'currency.js';

// refer: https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
export function formatBytes(bytes?: number, dm = 1) {
  if (bytes === 0) return '0 Byte';
  if (!bytes) return 'N/A';
  const k = 1024;

  if (bytes < k) {
    return '<1KB';
  }

  const sizes = [`${bytes <= 1 ? 'Byte' : 'Bytes'}`, 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

export function formatCount(bytes?: number, dm = 1) {
  if (bytes === 0) return '0';
  if (!bytes) return 'N/A';
  const k = 1000;

  if (bytes < k) {
    return '<1K';
  }

  const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

export const formatPrice =
  (suffix = '') =>
  (price: number | currency) => {
    const opt = { symbol: ' TCR', pattern: `# !${suffix}` };

    if (price instanceof currency) {
      return price.format(opt);
    } else {
      return currency(price, opt).format();
    }
  };

export const formatPricePerHour = formatPrice('/hour');

// format number:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
