import Empty from '../../assets/empty-schema.svg';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ShortcutsMenu } from '@tigergraph/app-ui-lib/shortcuts-menu';
import { buildShortcut } from '@/utils/schemaDesigner';

export function EmptySchema({ isLoading, enableSave }: { isLoading?: boolean; enableSave?: boolean }) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      {isLoading ? (
        <img
          src={Empty}
          style={{
            display: 'inline-block',
            width: '596px',
          }}
        />
      ) : (
        <div
          className={css({
            opacity: 0.5,
          })}
        >
          <ShortcutsMenu
            shortcuts={buildShortcut({ enableSave: !!enableSave })}
            showTitle={false}
            center
            columnWidth={280}
            column={2}
          />
        </div>
      )}
    </div>
  );
}
