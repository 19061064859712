import { OnboardingTask } from '@/components/userguide/utils';
import { MyOrg, MyOrgList, UserInfo, UserRole } from '@/lib/models';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';

interface OrgContextValue {
  userInfo: UserInfo;
  setUserInfo(userInfo: UserInfo): void;
  orgList: MyOrgList;
  setOrgList(orgList: MyOrgList): void;
  currentOrg: MyOrg;
  setCurrentOrg(org: MyOrg): void;
  onboardingTasks: OnboardingTask[];
  setOnboardingTasks(onboardingTasks: OnboardingTask[]): void;
}

const OrgContext = createContext<OrgContextValue>({} as OrgContextValue);

const useOrgContext = () => {
  const context = useContext(OrgContext);
  const { currentOrg } = context;
  const setLocalStorage = useCallback(
    (key: string, value: string) => {
      if (currentOrg) {
        localStorage.setItem(`${currentOrg.org_id}-${key}`, value);
      }
    },
    [currentOrg]
  );
  const getLocalStorage = useCallback(
    (key: string) => {
      if (currentOrg) {
        return localStorage.getItem(`${currentOrg.org_id}-${key}`);
      }
    },
    [currentOrg]
  );
  return {
    ...context,
    setLocalStorage,
    getLocalStorage,
  };
};

const OrgProvider: FC<PropsWithChildren> = (props) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(() => {
    const userInfo = { roles: [] as UserRole[] } as UserInfo;
    return userInfo;
  });
  const [orgList, setOrgList] = useState<MyOrgList>([]);
  const [currentOrg, setCurrentOrg] = useState<MyOrg>({} as MyOrg);
  const [onboardingTasks, setOnboardingTasks] = useState<OnboardingTask[]>([]);

  const orgContextValue: OrgContextValue = {
    userInfo,
    setUserInfo,
    orgList,
    setOrgList,
    currentOrg,
    setCurrentOrg,
    onboardingTasks,
    setOnboardingTasks,
  };

  return <OrgContext.Provider value={orgContextValue} {...props} />;
};

export { OrgProvider, useOrgContext };
