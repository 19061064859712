import { AddonsMetaID } from '@/components/addons/consts';
import { WorkspaceT } from '@/pages/workgroup/type';

export type CopilotConfig = {
  enable: boolean;
};

export const getWorkspaceCopilotAddonsConfig = (workspace?: WorkspaceT): CopilotConfig => {
  const copilotAddonsRelation = workspace?.addons?.find((i) => i.addons_id === AddonsMetaID.COPILOT);

  if (copilotAddonsRelation) {
    return {
      enable: copilotAddonsRelation.enable,
    };
  }

  // compatible old data
  return {
    enable: Boolean(workspace?.enable_copilot),
  };
};

export const isWorkspaceCopilotEnable = (workspace?: WorkspaceT) => getWorkspaceCopilotAddonsConfig(workspace).enable;
