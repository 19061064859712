import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { axiosController } from '@/lib/network';

export interface Auth0 {
  domain: string;
  client_id: string;
  lobby_client_id: string;
  redirect_uri?: string;
  audience: string;
  scopes: string[];
}

export interface AppConfig {
  Releases: {
    [key: string]: string;
  };
  WhiteList: {
    [key: string]: string;
  };
}

interface ConfigContext {
  auth0?: Auth0;
  stripeKey: string;
  domainSeparator: string;
  replicaPriceFactor: number;
  appConfig: AppConfig;
}

const defaultConfig: ConfigContext = {
  auth0: undefined,
  stripeKey: '',
  domainSeparator: '',
  replicaPriceFactor: 1,
  appConfig: {
    Releases: {},
    WhiteList: {},
  },
};

export const configContext = createContext<ConfigContext>(defaultConfig);

const transferAppConfig = (appConfig: any) => {
  const { Releases, WhiteList } = appConfig;
  return {
    Releases: Releases
      ? Releases.reduce((acc: { [key: string]: string }, release: { Version: string; ToolsOrigin: string }) => {
          acc[release.Version] = release.ToolsOrigin;
          return acc;
        }, {})
      : {},
    WhiteList: WhiteList
      ? WhiteList.reduce((acc: { [key: string]: string }, whiteList: { ClusterID: string; ToolsOrigin: string }) => {
          acc[whiteList.ClusterID] = whiteList.ToolsOrigin;
          return acc;
        }, {})
      : {},
  };
};

const cloudConfigKey = 'cloud-config';

export const ConfigProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const [config, setConfig] = useState<ConfigContext>(() => {
    const cachedConfig = sessionStorage.getItem(cloudConfigKey);
    if (cachedConfig) {
      return JSON.parse(cachedConfig) as ConfigContext;
    }
    return defaultConfig;
  });
  const getConfig = async () => {
    return axiosController.get('/config/client').then(({ data: data }) => {
      const { Result: result } = data;
      const config: ConfigContext = {
        auth0: {
          domain: result.Auth0HostName,
          client_id: result.Auth0ClientID,
          lobby_client_id: result.Auth0LobbyClientID,
          audience: result.Auth0Audience,
          scopes: result.Auth0Scopes,
        },
        appConfig: transferAppConfig(result.AppConfig),
        stripeKey: result.StripePublicKey,
        domainSeparator: result.SolutionDomainSeparator,
        replicaPriceFactor: result.SolutionReplicaPriceFactor,
      };
      setConfig(config);
      // cache it for performance
      sessionStorage.setItem(cloudConfigKey, JSON.stringify(config));
    });
  };

  useEffect(() => {
    getConfig();
  }, []);

  if (!config.auth0) {
    return null;
  }

  return (
    <configContext.Provider value={config} {...props}>
      {children}
    </configContext.Provider>
  );
};

export function useConfig() {
  const config = useContext(configContext);
  if (!config.auth0) {
    throw new Error('No auth0 config found');
  }
  return config as Required<ConfigContext>;
}
