import { styled } from '@tigergraph/app-ui-lib/Theme';
import { StyledTableBodyCell as BaseBodyCell, StyledTableHeadCell as BaseTableCell } from 'baseui/table-semantic';

export const StyledTableBodyCell = styled(BaseBodyCell, () => {
  return {
    padding: '8px !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    fontWeight: 400,
  };
});

export const StyledTableHeadCell = styled(BaseTableCell, () => ({
  padding: '8px !important',
  fontSize: '12px !important',
  lineHeight: '16px !important',
  fontWeight: 600,
}));
