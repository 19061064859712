import { CreateQuickInsightsRequest } from '@/insights/cloud/type';

export function generateQuickInsights(
  workspace_id: string,
  workgroup_id: string,
  graphName: string
): CreateQuickInsightsRequest {
  return {
    name: 'Quick Insights',
    workgroup_id,
    workspace_id,
    default_graph: graphName,
    install_queries: [
      {
        name: 'q3_statistics',
        body: `
        CREATE DISTRIBUTED QUERY q3_statistics(
          ) FOR GRAPH ${graphName} SYNTAX V1{
            SumAccum<INT> @@node_count;
            SumAccum<INT> @@edge_count;
            Nodes = {ANY};
            Nodes =
              SELECT s
              FROM   Nodes:s
              POST-ACCUM (s)
                     @@node_count += 1
            ;
            Tmp =
              SELECT s
              FROM   Nodes:s -(:e)- :t
              ACCUM  @@edge_count += 1
            ;
          
            PRINT @@node_count;
            PRINT @@edge_count;
            PRINT ROUND(@@edge_count * 1.0 / @@node_count, 2) AS average_degree;
          }
        `,
      },
      {
        name: 'q1_node_type_count_statistics',
        body: `CREATE DISTRIBUTED QUERY q1_node_type_count_statistics(/* Parameters here */) FOR GRAPH ${graphName} { 
          TYPEDEF Tuple<STRING node_type, int node_count> NodeCount;
          MapAccum<STRING, INT> @@map_node_type_count;
          SetAccum<NodeCount>@@OutputList;
          Nodes = {ANY};
          Nodes =
            SELECT s
            FROM   Nodes:s
            POST-ACCUM (s)
              @@map_node_type_count += (s.type -> 1)
          ;
        
          FOREACH (k, v) IN @@map_node_type_count DO
              @@OutputList+=NodeCount(k,v);
          END;
          
          
          PRINT @@OutputList;
        }`,
      },
      {
        name: 'q2_edge_type_count_statistics',
        body: `CREATE DISTRIBUTED QUERY q2_edge_type_count_statistics(/* Parameters here */) FOR GRAPH ${graphName} {
          TYPEDEF Tuple<STRING edge_type, int edge_count> EdgeCount;
          MapAccum<STRING, INT> @@map_edge_type_count;
          SetAccum<EdgeCount>@@OutputList;
          Nodes = {ANY};
          Tmp =
            SELECT s
            FROM   Nodes:s -(:e)- :t
            ACCUM
                  @@map_edge_type_count += (e.type -> 1)
          ;
         FOREACH (k, v) IN @@map_edge_type_count DO
              @@OutputList+=EdgeCount(k,v);
          END;
          PRINT @@OutputList;
        }`,
      },
      {
        name: 'q4_map_degree_range_count_statistics',
        body: `CREATE DISTRIBUTED QUERY q4_map_degree_range_count_statistics(UINT base = 10,
          UINT max_exponent = 5) FOR GRAPH ${graphName} SYNTAX V1 { 
          TYPEDEF Tuple<STRING range_type, int degree_count> DegreeCount;
          SetAccum<DegreeCount>@@OutputList;
          MapAccum<STRING, INT> @@map_degree_range_count;
          SumAccum<INT> @outdegree;
          SumAccum<INT> @@edge_count;
          Nodes = {ANY};
            
          Tmp =
            SELECT s
            FROM   Nodes:s -(:e)- :t
            ACCUM  @@edge_count += 1,
                   s.@outdegree += 1
          ;
            
          Nodes =
            SELECT s
            FROM   Nodes:s
            POST-ACCUM (s)
                   UINT min_value = 0,
                   UINT i = 0,
                   UINT max_value = pow(base, i),
                   WHILE i < max_exponent DO
                     IF s.@outdegree >= min_value AND s.@outdegree < max_value THEN
                       @@map_degree_range_count += ("[" + to_string(min_value) + ", " + to_string(max_value) + ")" -> 1)
                     END,
                     min_value = max_value,
                     i = i + 1,
                     max_value = pow(base, i)
                   END,
                   IF s.@outdegree >= min_value THEN
                     @@map_degree_range_count += (">= " + to_string(min_value) -> 1)
                   END
          ;
            
         FOREACH (k, v) IN @@map_degree_range_count DO
              @@OutputList+=DegreeCount(k,v);
          END;
          
          PRINT @@OutputList;
        }        
        `,
      },
    ],
    page: {
      name: 'quick insights page',
      position: 1,
      config: {
        layouts: {
          md: [
            {
              h: 22,
              i: '8YNfCfJhnEmSp84YLd5jKi',
              moved: false,
              static: false,
              w: 6,
              x: 6,
              y: 9,
            },
            {
              h: 9,
              i: 'cjeaswG1voYxCZz3dQXm61',
              moved: false,
              static: false,
              w: 12,
              x: 0,
              y: 0,
            },
            {
              h: 24,
              i: 'f6HWQCTR5ahZdycbWvyA1Q',
              moved: false,
              static: false,
              w: 6,
              x: 0,
              y: 30,
            },
            {
              h: 22,
              i: 'ibh3yCs7jdkT5TFtPaq2pz',
              moved: false,
              static: false,
              w: 6,
              x: 0,
              y: 9,
            },
            {
              h: 24,
              i: 'hTJ3HLpnSvVpFxZusdo32x',
              moved: false,
              static: false,
              w: 6,
              x: 6,
              y: 30,
            },
          ],
        },
      },
    },
    widgets: [
      {
        name: 'statistics',
        query_type: 'installed',
        query: 'q2_edge_type_count_statistics',
        query_params: '',
        graph: graphName,
        params: {},
        config: {
          chartSettings: {
            category: [
              {
                id: 'edge_type',
                type: 'string',
              },
            ],
            groupMode: [
              {
                id: 'Stacked',
              },
            ],
            sortBy: 'category',
            tableHeaders: ['edge_count', 'edge_type'],
            tableIndex: 0,
            value: [
              {
                id: 'edge_count',
                type: 'number',
              },
            ],
            values: [
              {
                id: 'fN6goXbjYaRvCqZ9LFUn5J',
                label: 'untitled',
                name: '',
                styleRule: [],
                xaxis: '',
                yaxis: '',
              },
            ],
          },
          graphName: graphName,
          hideWidgetName: false,
          id: '8YNfCfJhnEmSp84YLd5jKi',
          patternLimit: 300,
          query: '',
          queryType: 'pattern',
          refreshRate: 0,
          searchPattern: [
            {
              data: 'q2_edge_type_count_statistics',
              id: 'q2_edge_type_count_statistics',
              type: 'QUERY',
            },
          ],
          staticData: '{}',
          title: 'Edge Type Count',
          type: 'bar',
          version: '1703171638493598862',
        },
      },
      {
        name: 'statistics',
        query_type: 'installed',
        query: 'q3_statistics',
        query_params: '',
        graph: graphName,
        params: {},
        config: {
          chartSettings: {
            horizontalAlign: 'left',
            values: [
              {
                fontColor: '#212121',
                fontSize: 90,
                iconColor: '#686868',
                iconPostion: 'RightTop',
                iconSize: 24,
                iconURL: '/studio/assets/gvis/icons/builtin/64/277-graph.png',
                id: '9UcEfCN3ummuk1UQwuBHvt',
                key: '@@node_count',
                label: '# of Nodes',
                labelColor: '#5f5f5f',
                labelPostion: '',
                labelSize: 18,
                styleRule: [],
              },
              {
                fontColor: '#1a1a1a',
                fontSize: 90,
                iconColor: '#575757',
                iconPostion: 'RightTop',
                iconSize: 24,
                iconURL: '/studio/assets/gvis/icons/builtin/64/251-strategy.png',
                id: 'p1ESfLPBe1482nafCn4YG8',
                key: '@@edge_count',
                label: '# of Edges',
                labelColor: '#676767',
                labelPostion: '',
                labelSize: 18,
                styleRule: [],
              },
              {
                fontColor: '#1c1c1c',
                fontSize: 90,
                iconColor: '#646464',
                iconPostion: 'RightTop',
                iconSize: 24,
                iconURL: '/studio/assets/gvis/icons/builtin/64/240-manager-5.png',
                id: 'aoP1JFexThU6dVTpLDQoQK',
                key: 'average_degree',
                label: 'Average Degree',
                labelColor: '#686868',
                labelPostion: '',
                labelSize: 18,
                styleRule: [],
              },
            ],
            verticalAlign: 'middle',
          },
          graphName: graphName,
          hideWidgetName: true,
          id: 'cjeaswG1voYxCZz3dQXm61',
          patternLimit: 300,
          query: '',
          queryType: 'pattern',
          refreshRate: 0,
          searchPattern: [
            {
              data: 'q3_statistics',
              id: 'q3_statistics',
              type: 'QUERY',
            },
          ],
          staticData: '{}',
          title: 'New Widget',
          type: 'value',
          version: '1703168806761336597',
        },
      },
      {
        name: 'statistics',
        query_type: 'installed',
        query: 'q4_map_degree_range_count_statistics',
        query_params: '',
        graph: graphName,
        params: {},
        config: {
          chartSettings: {
            category: [
              {
                id: 'range_type',
                type: 'string',
              },
            ],
            groupMode: [
              {
                id: 'Stacked',
              },
            ],
            sortBy: 'category',
            tableHeaders: ['degree_count', 'range_type'],
            tableIndex: 0,
            value: [
              {
                id: 'degree_count',
                type: 'number',
              },
            ],
            values: [
              {
                id: 'fN6goXbjYaRvCqZ9LFUn5J',
                label: 'untitled',
                name: '',
                styleRule: [],
                xaxis: '',
                yaxis: '',
              },
            ],
          },
          graphName: graphName,
          hideWidgetName: false,
          id: 'f6HWQCTR5ahZdycbWvyA1Q',
          patternLimit: 300,
          query: '',
          queryType: 'pattern',
          refreshRate: 0,
          searchPattern: [
            {
              data: 'q4_map_degree_range_count_statistics',
              id: 'q4_map_degree_range_count_statistics',
              type: 'QUERY',
            },
          ],
          staticData: '{}',
          title: 'Degree Range Count',
          type: 'bar',
          version: '1703171719370093825',
        },
      },
      {
        name: 'statistics',
        query_type: 'installed',
        query: 'q1_node_type_count_statistics',
        query_params: '',
        graph: graphName,
        params: {},
        config: {
          chartSettings: {
            category: [
              {
                id: 'node_type',
                type: 'string',
              },
            ],
            groupMode: [
              {
                id: 'Stacked',
              },
            ],
            sortBy: 'category',
            tableHeaders: ['node_count', 'node_type'],
            tableIndex: 0,
            value: [
              {
                id: 'node_count',
                type: 'number',
              },
            ],
            values: [
              {
                id: 'fN6goXbjYaRvCqZ9LFUn5J',
                label: 'untitled',
                name: '',
                styleRule: [],
                xaxis: '',
                yaxis: '',
              },
            ],
          },
          graphName: graphName,
          hideWidgetName: false,
          id: 'hTJ3HLpnSvVpFxZusdo32x',
          patternLimit: 300,
          query: '',
          queryType: 'pattern',
          refreshRate: 0,
          searchPattern: [
            {
              data: 'q1_node_type_count_statistics',
              id: 'q1_node_type_count_statistics',
              type: 'QUERY',
            },
          ],
          staticData: '{}',
          title: 'Node Type Distribution',
          type: 'pie',
          version: '1703171789038187662',
        },
      },
      {
        name: 'statistics',
        query_type: 'installed',
        query: 'q1_node_type_count_statistics',
        query_params: '',
        graph: graphName,
        params: {},
        config: {
          chartSettings: {
            category: [
              {
                id: 'node_type',
                type: 'string',
              },
            ],
            groupMode: [
              {
                id: 'Stacked',
              },
            ],
            sortBy: 'category',
            tableHeaders: ['node_count', 'node_type'],
            tableIndex: 0,
            value: [
              {
                id: 'node_count',
                type: 'number',
              },
            ],
            values: [
              {
                id: 'fN6goXbjYaRvCqZ9LFUn5J',
                label: 'untitled',
                name: '',
                styleRule: [],
                xaxis: '',
                yaxis: '',
              },
            ],
          },
          graphName: graphName,
          hideWidgetName: false,
          id: 'ibh3yCs7jdkT5TFtPaq2pz',
          patternLimit: 300,
          query: '',
          queryType: 'pattern',
          refreshRate: 0,
          searchPattern: [
            {
              data: 'q1_node_type_count_statistics',
              id: 'q1_node_type_count_statistics',
              type: 'QUERY',
            },
          ],
          staticData: '{}',
          title: 'Node Type Count',
          type: 'bar',
          version: '1703171532819059131',
        },
      },
    ],
  };
}
