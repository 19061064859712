import { Return } from '@/lib/type';
import { ConfigType, getConfig, restartService, updateConfig } from '@/pages/workgroup/form/setting/api';
import { AxiosError } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';

type ConfigReturnType = Awaited<ReturnType<typeof getConfig>>;

export function useQueryConfig(
  nginx_host: string,
  keys: string[],
  options?: Omit<UseQueryOptions<ConfigReturnType, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery<ConfigReturnType, AxiosError>(
    [nginx_host, 'config', keys],
    async () => {
      return getConfig(nginx_host, keys);
    },
    options
  );
}

// do not invalidate the config cache as UI will only show after isFetching is false
export function useMutationRestartService() {
  return useMutation<Return<void>, AxiosError, { nginx_host: string; services: string[] }>(
    ({ nginx_host, services }) => {
      return restartService(nginx_host, services);
    }
  );
}

export function useMutationUpdateConfig() {
  return useMutation<Return<void>, AxiosError, { nginx_host: string; config: ConfigType }>(({ nginx_host, config }) => {
    return updateConfig(nginx_host, config);
  });
}
