import { CloudProviderStatus } from '@/pages/admin/settings/cloud_provider/types';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';

const statusLabelMap: Record<CloudProviderStatus, string> = {
  start: 'Start',
  active: 'Active',
  stale: 'Inactive',
  provisioning: 'Provisioning',
  provisionfailed: 'Provision Failed',
  deleting: 'Deleting',
  deleted: 'Deleted',
  deletefailed: 'Delete Failed',
  upgradefailed: 'Upgrade Failed',
  upgrading: 'Upgrading',
  upgradecomplete: 'Upgrade Completed',
};

function getStatusColors(status: CloudProviderStatus) {
  switch (status) {
    case 'active':
    case 'upgradecomplete':
      return { color: '#30A442', backgroundColor: '#E9F7E4' };
    case 'deleting':
    case 'provisioning':
    case 'upgrading':
      return { backgroundColor: '#F9F4E5', color: '#B67B00' };
    case 'deletefailed':
    case 'provisionfailed':
    case 'upgradefailed':
    case 'deleted':
      return { backgroundColor: '#FEEFEE', color: '#D8000C' };
    default:
      return { backgroundColor: '#F7F7F7', color: '#666666' };
  }
}

export function isErrorStatus(status: CloudProviderStatus) {
  return status === 'deletefailed' || status === 'provisionfailed' || status === 'upgradefailed' || status === 'stale';
}

export default function StatusIndicator({ status }: { status: CloudProviderStatus }) {
  const [css, theme] = useStyletron();
  const colors = getStatusColors(status);
  return (
    <div
      className={css({
        padding: '2px 12px',
        borderRadius: '16px',
        backgroundColor: colors.backgroundColor,
        color: colors.color,
        fontSize: '12px',
        lineHeight: '16px',
        display: 'table-cell',
        alignItems: 'center',
        textAlign: 'center',
        gap: '6px',
        minWidth: '57px',
      })}
    >
      {statusLabelMap[status]}
    </div>
  );
}

export function StatusCircle({ status }: { status: CloudProviderStatus }) {
  const [css, theme] = useStyletron();
  const colors = getStatusColors(status);

  return (
    <div
      className={css({
        borderRadius: '50%',
        height: '12px',
        width: '12px',
        flexShrink: 0,
        backgroundColor: colors.color,
      })}
    />
  );
}
