import React from 'react';
import { StatefulPopover, Popover, PopoverOverrides } from 'baseui/popover';
import { CustomTheme, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { PLACEMENT } from 'baseui/popover';
import { Button } from '@tigergraph/app-ui-lib/button';
import { KIND, SIZE } from 'baseui/button';

type StatefulPopoverProps = {
  placement?: keyof typeof PLACEMENT;
  children: React.ReactNode;
  confirmLabel: React.ReactNode;
  onConfirm: () => void;
};

type PopoverProps = {
  isOpen: boolean;
  onClose: () => void;
} & StatefulPopoverProps;

export const popoverOverrides: PopoverOverrides = {
  Arrow: {
    style: ({ $theme }) => {
      const customTheme = $theme as CustomTheme;
      return {
        backgroundColor: customTheme.colors['background.primary'],
        border: `1px solid ${customTheme.colors.divider}`,
      };
    },
  },
  Body: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        boxShadow: theme.colors['shadow.popup'],
        border: `1px solid ${theme.colors.divider}`,
        backgroundColor: theme.colors['background.primary'],
      };
    },
  },
  Inner: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '12px',
        paddingRight: '12px',
        color: theme.colors['text.primary'],
        backgroundColor: theme.colors['background.primary'],
      };
    },
  },
};

function ConfirmBody({
  confirmLabel,
  onConfirm,
  close,
}: {
  confirmLabel: React.ReactNode;
  onConfirm: () => void;
  close: () => void;
}) {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '320px',
        ...theme.typography.Body2,
      })}
    >
      {confirmLabel}
      <div
        className={css({
          paddingTop: '8px',
          display: 'flex',
          justifyContent: 'flex-end',
        })}
      >
        <Button
          kind="secondary"
          size={SIZE.compact}
          onClick={close}
          overrides={{
            BaseButton: {
              style: {
                marginRight: '12px',
              },
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            close();
            onConfirm();
          }}
          kind={KIND.primary}
          size={SIZE.compact}
        >
          OK
        </Button>
      </div>
    </div>
  );
}

export function ConfirmStatefulPopover({
  placement = PLACEMENT.auto,
  children,
  confirmLabel,
  onConfirm,
}: StatefulPopoverProps) {
  return (
    <StatefulPopover
      showArrow={true}
      content={({ close }) => <ConfirmBody confirmLabel={confirmLabel} onConfirm={onConfirm} close={close} />}
      overrides={popoverOverrides}
      placement={placement}
      ignoreBoundary={true}
    >
      {children}
    </StatefulPopover>
  );
}

export function ConfirmPopover({
  isOpen,
  onClose,
  placement = PLACEMENT.top,
  children,
  confirmLabel,
  onConfirm,
}: PopoverProps) {
  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={() => onClose()}
      onEsc={() => onClose()}
      showArrow={true}
      content={() => <ConfirmBody confirmLabel={confirmLabel} onConfirm={onConfirm} close={onClose} />}
      overrides={popoverOverrides}
      placement={placement}
    >
      {children}
    </Popover>
  );
}
