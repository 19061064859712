import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Select } from '@tigergraph/app-ui-lib/select';

import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useMutationCreateQuickInsights } from '@/insights/cloud/hook';
import Metric1 from './icon/metric1.svg';
import Metric2 from './icon/metric2.svg';
import Metric3 from './icon/metric3.svg';
import Metric4 from './icon/metric4.svg';
import { GenerateInsights } from '@/pages/home/icons.tsx';
import { DrawerHeadContainer } from '@/lib/styled.tsx';
import { CheckButton } from '@tigergraph/app-ui-lib/checkbutton';
import { useQuery } from 'react-query';
import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { fetchGraphList } from '@/lib/instance_api';
import { FormControl } from '@/components/FormControl';
import { useState } from 'react';
import { Desc } from '@/pages/workgroup/form/StyledComponent';
import { AxiosError } from 'axios';
import { useStatistics } from '@tigergraph/tools-ui/insights/pages/chart/useMetaHook';
import { WorkspaceT } from '@/pages/workgroup/type';
import { ErrorDisplay } from '@/components/error';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  databaseName?: string;
  workspace: WorkspaceT;
};

export default function CreateQuickInsights({ isOpen, onClose, databaseName, workspace }: Props) {
  const [css, theme] = useStyletron();

  const navigate = useNavigate();
  const { workspace_id, workgroup_id, nginx_host, tg_version } = workspace;

  const createQuickInsightsMutation = useMutationCreateQuickInsights();

  const [graph, setGraph] = useState('');

  const buttonClassName = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    justifyContent: 'center',
    padding: '10px',
    opacity: '1 !important',
  });

  const { data: graphs, isLoading } = useQuery(
    ['graph_list', nginx_host],
    () => fetchGraphList(nginx_host, tg_version, sessionStorage.getItem(ID_TOKEN_KEY)!),
    {
      onSuccess(data) {
        if (data && data.length > 0) {
          setGraph(data[0]);
        }
      },
      onError(err) {
        showToast({
          kind: 'negative',
          message: getErrorMessage(err as AxiosError),
        });
      },
    }
  );

  const { statistics, isFetching, error } = useStatistics(`https://${nginx_host}`, graph, !!graph);
  let haveEdges = false;
  let haveNodes = false;
  for (let item of statistics) {
    if (item['v_type'] && item['count'] > 0) {
      haveNodes = true;
    } else if (item['e_type'] && item['count'] > 0) {
      haveEdges = true;
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <DrawerHeadContainer>
          <GenerateInsights />
          Generate Data Profile for
        </DrawerHeadContainer>
        <span
          className={css({
            ...theme.typography.HeadingSmall,
          })}
        >
          {databaseName}
        </span>
      </DrawerHeader>
      <DrawerBody>
        <Desc
          $style={{
            marginTop: '0px',
          }}
        >
          Data Profile harnesses the power of advanced algorithms to provide users with valuable initial information
          about their data, all without any manual effort. By analyzing the data behind the scenes, Data Profile
          automatically generates meaningful insights and presents them in an easily understandable format.
        </Desc>
        <Desc
          $style={{
            marginTop: '12px',
          }}
        >
          With Data Profile, users can quickly gain a high-level understanding of their data without diving into complex
          analysis or writing code. The algorithms employed by Auto Insights identify patterns, trends, and key metrics,
          allowing users to grasp important aspects of their data at a glance.
        </Desc>
        <form
          className={css({
            marginTop: '12px',
          })}
        >
          <FormControl label="Insights Metrics">
            <>
              <Desc
                className={css({
                  marginBottom: '12px',
                })}
              >
                Select the metrics you want to generate insights for.
              </Desc>
              <div
                className={css({
                  display: 'grid',
                  gap: '12px 15px',
                  gridTemplateColumns: '1fr 1fr',
                })}
              >
                <CheckButton selected={true} disabled={true} className={buttonClassName}>
                  <span>Number of vertices</span>
                  <img src={Metric1} width={32} height={32} />
                </CheckButton>
                <CheckButton selected={true} disabled={true} className={buttonClassName}>
                  Number of edges
                  <img src={Metric2} width={32} height={32} />
                </CheckButton>
                <CheckButton selected={true} disabled={true} className={buttonClassName}>
                  Degree distribution
                  <img src={Metric3} width={32} height={32} />
                </CheckButton>
                <CheckButton selected={true} disabled={true} className={buttonClassName}>
                  Average degree
                  <img src={Metric4} width={32} height={32} />
                </CheckButton>
              </div>
            </>
          </FormControl>
          <FormControl
            label="Select graph"
            error={
              !isLoading && graphs?.length === 0
                ? 'There is no graph available in the database to generate insights.'
                : null
            }
          >
            <>
              <Desc
                className={css({
                  marginBottom: '12px',
                })}
              >
                Select the graph you want to generate insights for.
              </Desc>
              <Select
                isLoading={isLoading}
                options={graphs?.map((graph) => ({ id: graph, name: graph })) || []}
                value={[{ id: graph }]}
                labelKey="name"
                valueKey="id"
                onChange={(params) => {
                  if (params.value[0]) {
                    setGraph(params.value[0].id as string);
                  }
                }}
                clearable={false}
              />
            </>
          </FormControl>
          {isFetching && (
            <div
              className={css({
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              })}
            >
              <Spinner $size={'20px'} $borderWidth={'2px'} />
              We are checking the database and graph to determine if a data profile can be generated.
            </div>
          )}
          {error && <ErrorDisplay error={error} />}
          {graph && !isFetching && (!haveEdges || !haveNodes) ? (
            <ErrorDisplay
              label="Error:"
              error={
                new Error(
                  `The graph you selected does not have any vertices or edges, so a data profile cannot be generated.`
                )
              }
            />
          ) : null}
        </form>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind="secondary" size="large">
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          disabled={!graph || createQuickInsightsMutation.isLoading || !haveEdges || !haveNodes}
          onClick={async () => {
            createQuickInsightsMutation.mutate(
              { workgroup_id, workspace_id, graph },
              {
                onSuccess: async (data) => {
                  showToast({
                    kind: 'positive',
                    message: data.Message,
                  });

                  onClose();

                  navigate({
                    pathname: `/groups/${workgroup_id}`,
                    search: `?${createSearchParams({
                      tab: 'dataprofiles',
                    })}`,
                  });
                },
                onError: (error) => {
                  showToast({
                    kind: 'negative',
                    message: getErrorMessage(error),
                  });
                },
              }
            );
          }}
          isLoading={createQuickInsightsMutation.isLoading}
        >
          Generate
        </Button>
      </DrawerAction>
    </Drawer>
  );
}
