import { DrawerAction, DrawerBody } from '@/components/Drawer';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { Button } from '@tigergraph/app-ui-lib/button';

import UserTable from '@/pages/workgroup/tab/iam/db_user/UserTable';
import { useState } from 'react';
import { AddHeader } from '@/pages/workgroup/tab/iam/db_user/components';

export type Props = {
  onClose: () => void;
  group: WorkGroupT;
  space: WorkspaceT;
};

export function DatabaseAdmin({ onClose, space }: Props) {
  const [showInsertForm, setShowInsertForm] = useState(false);

  return (
    <>
      <DrawerBody>
        <AddHeader label="Add User" onAdd={() => setShowInsertForm(true)} />
        <UserTable workspace={space} showInsertForm={showInsertForm} onCancel={() => setShowInsertForm(false)} />
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind="secondary" size="large">
          Cancel
        </Button>
      </DrawerAction>
    </>
  );
}
