import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body2, HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';
import {
  CreateSolution,
  ExploreGraph,
  GraphStudio,
  GSQLEditorXS,
  LoadData,
  Monitor,
} from '@/components/tutorial/icons.tsx';
import { GraphIcon } from '@/pages/home/icons.tsx';
import { useNavigate } from 'react-router';
import { useTutorialContext } from '@/components/tutorial/tutorialContext.tsx';
import { useConfig } from '@/lib/config.tsx';
import { useOrgContext } from '@/contexts/orgContext.tsx';
import { getToolsLink } from '@/hooks/useToolsLink.ts';
import { useWorkspaceContext } from '@/contexts/workspaceContext';

export function GettingStartTutorial({ onClose }: { onClose: () => void }) {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const { workspace } = useTutorialContext();
  const { currentWorkspace } = useWorkspaceContext();
  const { appConfig } = useConfig();
  const { currentOrg } = useOrgContext();

  const Section = styled('div', {
    padding: '8px',
    border: `1px solid ${theme.colors['border.quaternary']}`,
  });

  const SubSection = styled('div', {
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.colors['background.tertiary'],
    },
    ':active': {
      backgroundColor: theme.colors['background.accent.neutral.subtler'],
    },
  });

  const SectionHeader = styled('div', {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginBottom: '8px',
  });

  const SectionLabel = styled('div', {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.colors['text.secondary'],
    marginLeft: '8px',
    borderLeft: `1px dashed ${theme.colors['border.quaternary']}`,
    paddingLeft: '16px',
  });

  const ParagraphLabel = styled('div', ({ $theme }) => {
    return {
      fontSize: '12px',
      lineHeight: '16px',
    };
  });

  const Divider = () => {
    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          color: theme.colors['text.tertiary'],
          gap: '3px',
          fontSize: '12px',
        })}
      >
        OR
      </div>
    );
  };

  const navigateToStudio = () => {
    if (!workspace) {
      return;
    }
    const url = getToolsLink(appConfig, currentOrg, workspace, '/studio');
    window.open(url, '_blank');
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      <ParagraphLabel
        $style={{
          color: theme.colors['text.secondary'],
        }}
      >
        Once you&apos;ve set up your workspace, you can easily define a schema, load your data, and start writing
        queries using the user-friendly GSQL query language. This guide will walk you through each step, whether
        you&apos;re new to graphs or an experienced professional, making it easy to get started and explore the power of
        TigerGraph Cloud.
      </ParagraphLabel>
      <div
        className={css({
          display: 'flex',
          gap: '24px',
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            width: '427px',
            height: '467px',
          })}
        >
          <div
            className={css({
              paddingTop: '16px',
              paddingBottom: '16px',
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
            })}
          >
            <HeadingMenuSelected>Starter</HeadingMenuSelected>
          </div>

          <div
            className={css({
              display: 'flex',
              gap: '16px',
              flexDirection: 'column',
            })}
          >
            <Section
              $style={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <SubSection onClick={() => handleNavigate('/ingestion')}>
                <SectionHeader>
                  <LoadData />
                  <Body2>Load Data</Body2>
                </SectionHeader>
                <SectionLabel>Load your data into TigerGraph in few clicks.</SectionLabel>
              </SubSection>

              <Divider />

              <SubSection onClick={() => handleNavigate('/marketplace/solutions')}>
                <SectionHeader>
                  <CreateSolution />
                  <Body2>Create solution</Body2>
                </SectionHeader>
                <SectionLabel>Start with industry standard solution kit.</SectionLabel>
              </SubSection>
            </Section>

            <Section>
              <SubSection onClick={() => handleNavigate('/editor')}>
                <SectionHeader>
                  <GSQLEditorXS />
                  <Body2>GSQL Editor</Body2>
                </SectionHeader>
                <SectionLabel>Edit and run GSQL query on your workspace.</SectionLabel>
              </SubSection>
            </Section>

            <Section>
              <SubSection onClick={() => handleNavigate('/explore')}>
                <SectionHeader>
                  <ExploreGraph />
                  <Body2>Explore Graph</Body2>
                </SectionHeader>
                <SectionLabel>Explore your graph data by pattern search or TigerGraph CoPilot.</SectionLabel>
              </SubSection>
            </Section>
          </div>
        </div>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            width: '427px',
            height: '467px',
            flexGrow: 1,
          })}
        >
          <div
            className={css({
              paddingTop: '16px',
              paddingBottom: '16px',
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
            })}
          >
            <HeadingMenuSelected>Professional</HeadingMenuSelected>
          </div>

          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            })}
          >
            <div
              className={css({
                display: 'flex',
                gap: '8px',
              })}
            >
              <div
                className={css({
                  display: 'flex',
                  gap: '8px',
                  flexDirection: 'column',
                })}
              >
                <Section
                  $style={{
                    display: 'flex',
                    gap: '16px',
                  }}
                >
                  <div
                    className={css({
                      display: 'flex',
                      gap: '8px',
                      flexDirection: 'column',
                    })}
                  >
                    <SubSection
                      onClick={() => {
                        if (workspace) {
                          handleNavigate(`/editor?showSchema=true&workspace_id=${workspace.workspace_id}`);
                        } else if (currentWorkspace) {
                          handleNavigate(`/editor?showSchema=true&workspace_id=${currentWorkspace.workspace_id}`);
                        }
                      }}
                    >
                      <SectionHeader>
                        <GraphIcon />
                        <Body2>Design Schema</Body2>
                      </SectionHeader>
                      <SectionLabel>Create your first graph model. </SectionLabel>
                    </SubSection>
                    <SubSection onClick={() => handleNavigate('/ingestion')}>
                      <SectionHeader>
                        <LoadData />
                        <Body2>Load Data</Body2>
                      </SectionHeader>
                      <SectionLabel>Load your data into TigerGraph in few clicks.</SectionLabel>
                    </SubSection>
                  </div>
                  <Divider />
                  <SubSection
                    onClick={() => handleNavigate('/marketplace/solutions')}
                    className={css({
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    })}
                  >
                    <SectionHeader>
                      <CreateSolution />
                      <Body2>Create solution</Body2>
                    </SectionHeader>
                    <SectionLabel>Start with industry standard solution kit.</SectionLabel>
                  </SubSection>
                </Section>
                <Section>
                  <SubSection onClick={() => handleNavigate('/editor')}>
                    <SectionHeader>
                      <GSQLEditorXS />
                      <Body2>GSQL Editor</Body2>
                    </SectionHeader>
                    <SectionLabel>Edit and run GSQL query on your workspace.</SectionLabel>
                  </SubSection>
                </Section>
              </div>
              <Section
                $style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <SubSection
                  $style={{
                    minWidth: '108px',
                    width: '108px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                  onClick={() => navigateToStudio()}
                >
                  <div
                    className={css({
                      display: 'flex',
                      justifyContent: 'center',
                    })}
                  >
                    <GraphStudio />
                  </div>
                  <ParagraphLabel $style={{ textAlign: 'center' }}>
                    TigerGraph classic visual development environment.
                  </ParagraphLabel>
                </SubSection>
              </Section>
            </div>

            <Section>
              <SubSection
                onClick={() => {
                  if (workspace) {
                    handleNavigate(`/groups/${workspace.workgroup_id}?tab=monitor`);
                  } else if (currentWorkspace) {
                    handleNavigate(`/groups/${currentWorkspace.workgroup_id}?tab=monitor`);
                  }
                }}
              >
                <SectionHeader>
                  <Monitor />
                  <Body2>Monitor</Body2>
                </SectionHeader>
                <SectionLabel>Monitor the status and performance of your workspace.</SectionLabel>
              </SubSection>
            </Section>

            <Section>
              <SubSection onClick={() => handleNavigate('/explore')}>
                <SectionHeader>
                  <ExploreGraph />
                  <Body2>Explore Graph</Body2>
                </SectionHeader>
                <SectionLabel>Explore your graph data by pattern search or TigerGraph CoPilot.</SectionLabel>
              </SubSection>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
}
