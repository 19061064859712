import { ExternalGraph, ExternalNode, ExternalLink, addMissingNode } from '@tigergraph/tools-models/gvis/insights';
import { Schema } from '@tigergraph/tools-ui/graph/type';

export function convertSchemaToGraph(schema: Schema): ExternalGraph {
  let nodes: ExternalNode[] = [];
  let links: ExternalLink[] = [];

  const { VertexTypes: vertexTypes, EdgeTypes: edgeTypes } = schema;

  if (!vertexTypes) {
    return {
      nodes,
      links,
    };
  }

  for (const vertexType of vertexTypes) {
    const attributes = getSchemaAttributes(vertexType['Attributes'].filter((attr) => !attr.IsPrimaryKey));

    const node: ExternalNode = {
      attrs: attributes,
      id: vertexType['Name'],
      type: vertexType['Name'],
    };

    nodes.push(node);
  }

  for (const edgeType of edgeTypes) {
    const attributes = getSchemaAttributes(edgeType['Attributes']);
    if (edgeType['EdgePairs']) {
      edgeType['EdgePairs'].forEach((edgePair) => {
        const link: ExternalLink = {
          source: {
            id: edgePair['From'],
            type: edgePair['From'],
          },
          target: {
            id: edgePair['To'],
            type: edgePair['To'],
          },
          type: edgeType['Name'],
          attrs: attributes,
          // @ts-ignore: the EdgePair type is not correct
          ...(edgeType['IsDirected'] ? { directed: true } : null),
        };
        links.push(link);
      });
    } else {
      const link: ExternalLink = {
        source: {
          id: edgeType['FromVertexTypeName'],
          type: edgeType['FromVertexTypeName'],
        },
        target: {
          id: edgeType['ToVertexTypeName'],
          type: edgeType['ToVertexTypeName'],
        },
        type: edgeType['Name'],
        attrs: attributes,
        ...(edgeType['IsDirected'] ? { directed: true } : null),
      };
      links.push(link);
    }
  }

  const graph = {
    nodes,
    links,
  };

  const patchedGraph = addMissingNode(graph);
  return patchedGraph;
}

function getSchemaAttributes(
  schemaAttributes: {
    AttributeName: string;
    AttributeType: {
      Name: string;
    };
  }[]
) {
  return schemaAttributes.reduce((acc, schemaAttribute) => {
    return {
      ...acc,
      [schemaAttribute.AttributeName]: schemaAttribute.AttributeType.Name,
    };
  }, {});
}
