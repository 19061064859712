import { useRef } from 'react';
import { SchemaDesignerLogicService } from './services/schema-designer-logic.service';
import { SchemaChangeLogicService } from './services/schema-change-logic.service';
import { History, LoadingJobData } from '@tigergraph/tools-models';

export const useSchemaDesignerLogicService = () => {
  const schemaDesignerLogicServiceRef = useRef<SchemaDesignerLogicService>();
  if (!schemaDesignerLogicServiceRef.current) {
    schemaDesignerLogicServiceRef.current = new SchemaDesignerLogicService();
  }
  return schemaDesignerLogicServiceRef.current;
};

export const useSchemaChangeLogicService = () => {
  const schemaChangeLogicServiceRef = useRef<SchemaChangeLogicService>();
  if (!schemaChangeLogicServiceRef.current) {
    schemaChangeLogicServiceRef.current = new SchemaChangeLogicService();
  }
  return schemaChangeLogicServiceRef.current;
};

export const useLoadingJobsHistory = () => {
  const loadingJobsHistoryRef = useRef<History<{ [key: string]: LoadingJobData }>>();
  if (!loadingJobsHistoryRef.current) {
    loadingJobsHistoryRef.current = new History<{ [key: string]: LoadingJobData }>();
  }
  return loadingJobsHistoryRef.current;
};
