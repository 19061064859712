import { CustomTheme, styled } from '@tigergraph/app-ui-lib/Theme';

const ParagraphLabel = styled('span', ({ $theme }) => {
  const theme = $theme as CustomTheme;
  return {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.colors.contentSecondary,
  };
});

export default ParagraphLabel;
