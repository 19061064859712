import type { EnabledAddonsType } from '@/hooks/useListEnabledAddons';
import type { MetaAddonsType } from '@/hooks/useAddonsMeta';
import { FC, useMemo } from 'react';
import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';

import SolutionIcon from '@/assets/solution.svg?react';
import ConfirmButtons from '@/components/ConfirmButtons';
import EmblaCarousel, { EmblaCarouselItem } from '@/pages/marketplace/solution/carousel/EmblaCarousel';
import AddonsDrawerCommonHeader from '@/components/addons/AddonsCommonHeader';
import { FormProvider, useForm } from 'react-hook-form';
import { AddonsMetaID } from '@/components/addons/consts';
import WorkspaceAddonsEnableCopilotDrawer from '@/pages/workgroup/form/addons/drawer/copilot';
import {
  createWorkspaceEnableAtom,
  getCopilotPrice,
  getInsightsPrice,
  useAddonsIsEnabled,
} from '@/pages/workgroup/form/addons/store';
import { useSetAtom } from 'jotai';
import currency from 'currency.js';

const drawerConfigMap = new Map<string, FC<{ enabledAddons: EnabledAddonsType; addonsMeta: MetaAddonsType }>>([
  [AddonsMetaID.COPILOT, WorkspaceAddonsEnableCopilotDrawer],
]);

const WorkspaceAddonsEnableDrawer: FC<{
  enabledAddons?: EnabledAddonsType;
  addonsMeta?: MetaAddonsType;
  computePrice?: number;
  onCancel?(): void;
  onChange?(params: Record<string, string | number | boolean>): void;
}> = ({ enabledAddons, addonsMeta, computePrice, onCancel, onChange }) => {
  const descriptionList = addonsMeta?.Announcement || [addonsMeta?.Description];
  const setAddonsEnable = useSetAtom(createWorkspaceEnableAtom);
  const isEnabled = useAddonsIsEnabled(enabledAddons?.AddonsID as string);
  const form = useForm({
    defaultValues: {
      copilot_llm_provider_config_id: '',
    },
  });
  const { handleSubmit } = form;
  const DrawerFormContent = drawerConfigMap.get(enabledAddons?.AddonsID as string);
  const amount = useMemo(() => {
    if (!computePrice) {
      return '--';
    }
    const computeCurrency = currency(computePrice).divide(100);
    if (addonsMeta?.ID === AddonsMetaID.COPILOT) {
      return `${getCopilotPrice(computeCurrency)} TCR`;
    } else if (addonsMeta?.ID === AddonsMetaID.INSIGHTS) {
      return `${getInsightsPrice(computeCurrency)} TCR`;
    } else {
      return 'Free';
    }
  }, [computePrice, addonsMeta?.ID]);

  const handleConfirm = handleSubmit((value) => {
    setAddonsEnable((v) => ({ ...v, [enabledAddons?.AddonsID as string]: !isEnabled }));
    if (isEnabled) {
      // for disable confirm, clean all data
      form.reset();
      onChange?.(form.getValues());
    } else {
      // for enable confirm
      onChange?.(value);
    }
  });

  const handleClose = () => {
    if (isEnabled) {
      // update form data if enabled
      handleSubmit((value) => onChange?.(value))();
    } else {
      // cancel form data if disabled
      onCancel?.();
    }
  };

  return (
    <Drawer isOpen={Boolean(enabledAddons && addonsMeta)} onClose={handleClose}>
      <DrawerHeader>
        <SolutionIcon />
        Add-Ons
      </DrawerHeader>
      <DrawerBody>
        <div className="space-y-4">
          {addonsMeta && <AddonsDrawerCommonHeader {...addonsMeta} Amount={amount} />}
          {addonsMeta?.BannerImageList?.length && (
            <EmblaCarousel>
              {addonsMeta?.BannerImageList.map((image, i) => (
                <EmblaCarouselItem key={i}>
                  <div className="flex justify-center items-center">
                    <img className="h-[200px]" src={image} />
                  </div>
                </EmblaCarouselItem>
              ))}
            </EmblaCarousel>
          )}
          {descriptionList.map((d, idx) => (
            <p className="text-sm" key={idx}>
              {d}
            </p>
          ))}
          <FormProvider {...form}>
            {DrawerFormContent && enabledAddons && addonsMeta && (
              <DrawerFormContent enabledAddons={enabledAddons} addonsMeta={addonsMeta} />
            )}
          </FormProvider>
        </div>
      </DrawerBody>
      <DrawerAction>
        <ConfirmButtons
          confirmLabel={isEnabled ? 'Disable' : 'Enable'}
          cancelLabel="Back"
          onConfirm={handleConfirm}
          onCancel={handleClose}
        />
      </DrawerAction>
    </Drawer>
  );
};

export default WorkspaceAddonsEnableDrawer;
