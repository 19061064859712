import { NewGroupStepper } from '@/pages/workgroup/form/Stepper';
import { Container, ChildPage } from '@/pages/workgroup/form/StyledComponent';
import { Outlet } from 'react-router-dom';

export default function GroupLayout() {
  return (
    <Container>
      <NewGroupStepper />
      <ChildPage>
        <Outlet />
      </ChildPage>
    </Container>
  );
}
