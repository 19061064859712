import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { AxiosError } from 'axios';
import { FetchQueryOptions, QueryObserverOptions, useQuery } from 'react-query';

export type AddonsListResponse = Result<
  {
    ID: string;
    AddonsInstalledID: string;
    ConfigName: string;
    ConfigValue: string;
  }[]
>;

export const useListAddonsConfigurations = (
  installedAddonsID?: string,
  configName?: string,
  opt?: FetchQueryOptions<AddonsListResponse['Result'], AxiosError>
) => {
  return useQuery({
    staleTime: 2000,
    enabled: Boolean(installedAddonsID && configName),
    ...opt,
    queryKey: ['addons', 'configuration', configName, installedAddonsID],
    queryFn: async () => {
      const res = await axiosController.get<AddonsListResponse>(
        `/v2/add-ons/${installedAddonsID}/configs/${configName}`
      );

      return res.data.Result;
    },
  });
};

export type LLMProviderValueType = {
  ID: string;
  Name: string;
  Provider: string;
  APIKey: string;
  EncryptedAPIKey: string;
};

export const useListAddonsLLMProvidersConfigurations = (
  installedAddonsID?: string,
  opt?: QueryObserverOptions<AddonsListResponse['Result'], AxiosError> & { enabled?: boolean }
) => {
  const { data, ...o } = useListAddonsConfigurations(installedAddonsID, 'copilot_llm_providers', opt);
  return {
    data: data?.map((i) => {
      let data: LLMProviderValueType;
      try {
        data = { ...JSON.parse(i.ConfigValue), ID: i.ID };
      } catch (e) {
        // do something
        data = { ID: i.ID, Name: '', Provider: '', APIKey: '', EncryptedAPIKey: '' };
      }

      return data;
    }),
    ...o,
  };
};
