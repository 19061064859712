import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { SpaceIconSM } from '@/pages/home/icons';
import { StatusIndicator } from '@/pages/workgroup/tab/General';
import { WorkspaceT, isStatusError, isStatusIdle, isStatusPaused, isStatusPending } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Select } from '@tigergraph/app-ui-lib/select';
import { OnChangeParams, Option } from 'baseui/select';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConfirmResumeWorkspace } from '@/pages/workgroup/tab/SettingMenu';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import IconButton from '@/components/IconButton';
import { AccessBlocked } from '@/pages/workgroup/icon';
export interface WorkspaceSelectorProps {
  // controlled mode will not effect the global workspace
  isLocalSelector?: boolean;
  workspace?: WorkspaceT;
  onWorkspaceChange?: (workspace: WorkspaceT) => void;

  // scope the workspaces to a specific workgroup
  workgroupId?: string;

  filterWorkspace?: (workspace: WorkspaceT) => boolean;
  width?: string;
  enableAllSelect?: boolean;
}

export function WorkspaceSelector({
  isLocalSelector,
  workspace,
  onWorkspaceChange,
  workgroupId,
  width,
  filterWorkspace,
  enableAllSelect,
}: WorkspaceSelectorProps) {
  const [css, theme] = useStyletron();
  const { currentWorkspace, isLoading, workspaces, groupsAccess } = useWorkspaceContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showResume, setShowResume] = useState(false);
  const [selectWorkspace, setSelectWorkspace] = useState<WorkspaceT | null>(null);

  const getWorkspaces = useCallback(
    (workspaces: WorkspaceT[]) => {
      return (
        workspaces
          .filter((space) => (workgroupId ? space.workgroup_id === workgroupId : true))
          // filter out error workspaces
          .filter((workspace) => enableAllSelect || !isStatusError(workspace.status))
          .filter(filterWorkspace || (() => true))
          .map((workspace) => ({
            ...workspace,
            disabled: enableAllSelect ? false : isStatusPending(workspace.status) || !workspace.canAccess,
          }))
      );
    },
    [workgroupId, filterWorkspace, enableAllSelect]
  );

  const handleSelect = (params: OnChangeParams) => {
    const workspace = params.value[0] as WorkspaceT;

    // if the workspace is pending, do not change the workspace
    if (!enableAllSelect) {
      if (isStatusError(workspace.status)) {
        return;
      }
      if (isStatusPending(workspace.status)) {
        return;
      }
      if (isStatusPaused(workspace.status) || isStatusIdle(workspace.status)) {
        setShowResume(true);
        setSelectWorkspace(workspace);
        return;
      }
    }

    if (onWorkspaceChange) {
      onWorkspaceChange(workspace);
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('workspace_id', workspace.workspace_id);
    setSearchParams(newSearchParams, {
      replace: true,
    });
  };

  const getLabel = ({ option, isValue }: { option?: Option; isValue?: boolean }) => {
    const disableAccess = !enableAllSelect && !option!.canAccess;

    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        })}
      >
        {isValue && (
          <div
            className={css({
              minWidth: '12px',
              color: theme.colors.gray800,
            })}
          >
            <SpaceIconSM />
          </div>
        )}
        <span
          className={css({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
          })}
        >
          <span
            className={css({
              color: '#909090',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            })}
          >
            {option!.workgroup_name}
          </span>
          <span>/</span>
          <span
            className={css({
              fontSize: '14px',
            })}
          >
            {option!.name}
          </span>
          {disableAccess ? (
            <StatefulPopover
              content={`Your IP ${groupsAccess?.current_ip} is not allowed to access this workspace`}
              triggerType="hover"
              ignoreBoundary={true}
            >
              <IconButton
                className={css({
                  marginRight: isValue ? '-6px' : 0,
                })}
              >
                <AccessBlocked />
              </IconButton>
            </StatefulPopover>
          ) : null}
        </span>
        <StatusIndicator status={option!.status} size="compact" />
      </div>
    );
  };

  return (
    <>
      <Select
        getOptionLabel={getLabel}
        getValueLabel={({ option }) => getLabel({ option, isValue: true })}
        isLoading={isLoading}
        options={getWorkspaces(workspaces)}
        value={isLocalSelector ? (workspace ? [workspace] : []) : currentWorkspace ? [currentWorkspace] : []}
        valueKey="workspace_id"
        labelKey="name"
        placeholder="Select a workspace"
        onChange={handleSelect}
        clearable={false}
        searchable={false}
        overrides={{
          Root: {
            style: {
              width: width || '308px',
              marginRight: '16px',
            },
          },
          Popover: {
            props: {
              ignoreBoundary: true,
              popperOptions: {
                modifiers: {
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                },
              },
              overrides: {
                Body: {
                  style: {
                    zIndex: 1,
                  },
                },
              },
            },
          },
          IconsContainer: {
            props: {
              // E2E test locator
              'aria-label': 'workspace-select',
            },
          },
        }}
      />
      {selectWorkspace ? (
        <ConfirmResumeWorkspace
          isOpen={showResume}
          workspace={selectWorkspace}
          onClose={() => {
            setShowResume(false);
            setSelectWorkspace(null);
          }}
        />
      ) : null}
    </>
  );
}
