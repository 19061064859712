import { completeOnboardingTaskReq, getOnboardingTasksReq, OnboardingTask } from '@/components/userguide/utils';
import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';

interface UserGuideContextValue {
  onboardingTasks: OnboardingTask[];
  isFetching: boolean;
  completeOnboardingTask: (name: string) => Promise<void>;
  allFinished: boolean;
}

const UserGuideContext = createContext<UserGuideContextValue>({} as UserGuideContextValue);

const useUserGuideContext = () => {
  const context = useContext(UserGuideContext);
  return context;
};

const UserGuideProvider: FC<PropsWithChildren> = (props) => {
  const queryClient = useQueryClient();
  const { data: onboardingTasks = [], isFetching } = useQuery('onboarding-tasks', getOnboardingTasksReq);

  const completeOnboardingTask = useCallback(
    async (name: string) => {
      const task = onboardingTasks.find((task) => task.task_name === name);
      if (task && !task.completed) {
        await completeOnboardingTaskReq(task.id);
        task.completed = true;
        queryClient.setQueryData(
          'onboarding-tasks',
          onboardingTasks.map((t) => (t.task_name === name ? task : t))
        );
        queryClient.invalidateQueries(['user-credits']);
      }
    },
    [onboardingTasks, queryClient]
  );

  const allFinished = onboardingTasks.length > 0 && onboardingTasks.every((t) => t.completed);

  const UserGuideContextValue: UserGuideContextValue = {
    onboardingTasks,
    isFetching,
    completeOnboardingTask,
    allFinished,
  };

  return <UserGuideContext.Provider value={UserGuideContextValue} {...props} />;
};

export { UserGuideProvider, useUserGuideContext };
