import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';

type UseMutateRegisterQueriesParams = {
  nginxHost: string;
  graphName: string;
  queries: string[];
};

export const useMutateUpsertRegisterQueries = () => {
  return useMutation<void, AxiosError, UseMutateRegisterQueriesParams>({
    mutationFn: async ({ graphName, nginxHost, queries }) => {
      return await axios.post(
        `https://copilot-${nginxHost}/${graphName}/upsert_from_gsql`,
        {
          queries,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          },
        }
      );
    },
  });
};

export const useMutateDeleteRegisterQueries = () => {
  return useMutation<void, AxiosError, UseMutateRegisterQueriesParams>({
    mutationFn: async ({ graphName, nginxHost, queries }) => {
      return await axios.post(
        `https://copilot-${nginxHost}/${graphName}/delete_from_gsql`,
        {
          queries,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          },
        }
      );
    },
  });
};
