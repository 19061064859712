import { Checkbox } from 'baseui/checkbox';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Attribute, supportedDiscriminatorAttributeTypes } from '@tigergraph/tools-models';

export interface EdgeAttributeKeyProps {
  attribute: Attribute;
  readOnly?: boolean;
  onChangeKey: (attribute: Attribute) => void;
}

export default function EdgeAttributeKey({ attribute, readOnly, onChangeKey }: EdgeAttributeKeyProps) {
  const [css, theme] = useStyletron();

  const handleAttributeIsDiscriminatorChange = (isDiscriminator: boolean) => {
    attribute.isDiscriminator = isDiscriminator;
    onChangeKey(attribute);
  };

  return (
    <div
      className={css({
        display: 'flex',
        lineHeight: '35px',
        height: '35px',
        alignItems: 'center',
      })}
    >
      <Checkbox
        disabled={!supportedDiscriminatorAttributeTypes.includes(attribute.type.name) || readOnly}
        overrides={{
          Label: {
            style: {
              paddingLeft: '0px',
            },
          },
        }}
        checked={attribute.isDiscriminator}
        onChange={(e) => {
          handleAttributeIsDiscriminatorChange(e.currentTarget.checked);
        }}
      />
    </div>
  );
}
