import { Drawer, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { Tabs, Tab } from '@/components/Tab';
import { DrawerHeadContainer } from '@/lib/styled';
import { CustomizeWorkspace } from '@/pages/home/icons.tsx';
import Addon from '@/pages/workgroup/form/setting/Addon';
import Advanced from '@/pages/workgroup/form/setting/Advanced';
import Alerts from '@/pages/workgroup/form/setting/Alerts';
import General from '@/pages/workgroup/form/setting/General';
import Tigergraph from '@/pages/workgroup/form/setting/Tigergraph';
import { WorkspaceT, WorkSpaceType } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useRef, useState } from 'react';

export default function WorkspaceSetting({
  workspaceTypes,
  workspace,
  isOpen,
  onClose,
}: {
  workspaceTypes: WorkSpaceType[];
  workspace: WorkspaceT;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [activeKey, setActiveKey] = useState('General');
  const [css, theme] = useStyletron();
  const editConfigurationContainer = useRef<HTMLDivElement>(null);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <div ref={editConfigurationContainer}></div>
      <DrawerHeader className={css({ backgroundColor: theme.colors['background.primary'] })}>
        <DrawerHeadContainer>
          <CustomizeWorkspace />
          Workspace Configuration
        </DrawerHeadContainer>
      </DrawerHeader>
      <DrawerBody
        $style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Tabs
          size="compact"
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey as string);
          }}
          activateOnFocus={false}
          overrides={{
            TabList: {
              style: {
                backgroundColor: theme.colors['background.primary'],
                paddingTop: '8px',
              },
            },
          }}
        >
          <Tab title="General" key="General">
            <General workspace={workspace} onClose={onClose} workspaceTypes={workspaceTypes} />
          </Tab>
          <Tab title="Advanced Settings" key="Advanced">
            <Advanced workspace={workspace} onClose={onClose} />
          </Tab>
          <Tab title="Add-ons" key="Add-ons">
            <Addon configureContainer={editConfigurationContainer.current} workspace={workspace} onClose={onClose} />
          </Tab>
          <Tab title="Alerts" key="Alerts">
            <Alerts workspace={workspace} onClose={onClose} />
          </Tab>
          <Tab title="Graph Admin" key="Graph Admin">
            <Tigergraph workspace={workspace} onClose={onClose} />
          </Tab>
        </Tabs>
      </DrawerBody>
    </Drawer>
  );
}
